import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Header, Menu, Message, Segment} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {
    useAddCoverageMutation,
    useGetCoveragesQuery,
} from "../../../../../Services/InsurancePackage/Queries";
import {
    CoverageDTO,
    CoverageGroup,
    CoverageOrOption,
    CoverageOrOptionType,
} from "../../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import CoverageCard from "./CoverageCard";
import FormCoverage from "./FormCoverage";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

const CoverageList: React.FC<{
    coverageGroups: CoverageGroup[] | undefined;
    coverageOrOption: string;
    activeCoverageGroupId: number;
    version: VersionDTO | undefined;
    selectCoverageOptions: any;
}> = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const coveragesQueryResult = useGetCoveragesQuery(String(props.activeCoverageGroupId));

    const coverageToCoverageOrOption = (coverage: CoverageDTO): CoverageOrOptionType => {
        return {
            id: coverage.id,
            code: null,
            label: null,
            optional: coverage.optional,
            derogatoryManagementCommissionRate: coverage.derogatoryManagementCommissionRate,
            derogatoryContributionCommissionRate: coverage.derogatoryContributionCommissionRate,
            registryId: coverage.registryId,
            registryCode: coverage.coverageCode,
            registryLabel: coverage.coverageLabel,

            createdDate: coverage.createdDate,
            modifiedDate: coverage.modifiedDate,
            createdBy: coverage.createdBy,
            modifiedBy: coverage.modifiedBy,
            createdOnVersionId: coverage.createdOnVersionId,
        };
    };

    // Add coverage
    const addCoverageMutation = useAddCoverageMutation(
        params.id,
        String(props.activeCoverageGroupId),
        params.insurancePackageId,
        params.versionId
    );
    const addCoverageForm = useForm<any>();
    const submitAddCoverageForm = (form: any) => {
        addCoverageMutation.mutate({
            registryId: form.selectedItem.registryId,
            optional: false,
            rule: null,
            derogatoryContributionCommissionRate: form.contributionCommissionRadioButton
                ? Number(form.contributionCommissionInput)
                : null,
            derogatoryManagementCommissionRate: form.managementCommissionRadioButton
                ? Number(form.managementCommissionInput)
                : null,
        });
    };

    const [defaultValueCode, setDefaultValueCode] = useState<string>("");
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    const [defaultValueSelectedItem, setDefaultValueSelectedItem] = useState<number | null>(null);
    const [defaultValueContribCom, setDefaultValueContribCom] = useState<string>("");
    const [defaultValueContribComCheck, setDefaultValueContribComCheck] = useState<boolean>(false);
    const [defaultValueManagementCom, setDefaultValueManagementCom] = useState<string>("");
    const [defaultValueManagementCheck, setDefaultValueManagementCheck] = useState<boolean>(false);

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <>
            <Menu borderless style={{border: "0", boxShadow: "none"}}>
                <Menu.Item style={{padding: "0px 10px"}}>
                    <Header size="large">{t(props.coverageOrOption, {count: 2})}</Header>
                </Menu.Item>
                <Menu.Menu position="right">
                    <Menu.Item style={{padding: "0px 10px"}}>
                        {props.version !== undefined && props.version.pipDate === null && (
                            <ModalAdd
                                isValidationDisabled={Object.keys(addCoverageForm.formState.errors).length !== 0}
                                isPending={addCoverageMutation.isPending}
                                isSuccess={addCoverageMutation.isSuccess}
                                isError={addCoverageMutation.isError}
                                error={addCoverageMutation.error}
                                resetMutation={addCoverageMutation.reset}
                                onValidate={addCoverageForm.handleSubmit(submitAddCoverageForm)}
                                onCancel={() => {
                                    setDefaultValueCode("");
                                    setDefaultValueLabel("");
                                    setDefaultValueSelectedItem(null);
                                    setDefaultValueContribCom("");
                                    setDefaultValueContribComCheck(false);
                                    setDefaultValueManagementCom("");
                                    setDefaultValueManagementCheck(false);
                                }}
                                onSuccess={() => {
                                    setDefaultValueCode("");
                                    setDefaultValueLabel("");
                                    setDefaultValueSelectedItem(null);
                                    setDefaultValueContribCom("");
                                    setDefaultValueContribComCheck(false);
                                    setDefaultValueManagementCom("");
                                    setDefaultValueManagementCheck(false);
                                }}
                                objectToAdd={"Coverage"}
                                iconOnOpenButton
                                openButtonIcon="plus"
                                renderModalContent={() => (
                                    <FormCoverage
                                        control={addCoverageForm.control}
                                        errors={addCoverageForm.formState.errors}
                                        setValue={addCoverageForm.setValue}
                                        coverageOrOption={CoverageOrOption.COVERAGE}
                                        selectCoverageOptions={props.selectCoverageOptions}
                                        defaultValueCode={defaultValueCode}
                                        setDefaultValueCode={setDefaultValueCode}
                                        defaultValueLabel={defaultValueLabel}
                                        setDefaultValueLabel={setDefaultValueLabel}
                                        defaultValueSelectedItem={defaultValueSelectedItem}
                                        setDefaultValueSelectedItem={setDefaultValueSelectedItem}
                                        defaultValueContribCom={defaultValueContribCom}
                                        setDefaultValueContribCom={setDefaultValueContribCom}
                                        defaultValueContribComCheck={defaultValueContribComCheck}
                                        setDefaultValueContribComCheck={setDefaultValueContribComCheck}
                                        defaultValueManagementCom={defaultValueManagementCom}
                                        setDefaultValueManagementCom={setDefaultValueManagementCom}
                                        defaultValueManagementCheck={defaultValueManagementCheck}
                                        setDefaultValueManagementCheck={setDefaultValueManagementCheck}
                                    />
                                )}
                                objectToAddContext={"female"}
                            />
                        )}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {coveragesQueryResult !== undefined &&
                coveragesQueryResult.data !== undefined &&
                coveragesQueryResult.data.length === 0 && (
                    <Segment style={{border: "0", boxShadow: "none"}}>
                        <Message warning header={t("No coverage")} content={t("There is no coverage for this group")} />
                    </Segment>
                )}

            {coveragesQueryResult !== undefined &&
                coveragesQueryResult.isSuccess &&
                coveragesQueryResult.data !== null && (
                    <div className={"ui cards " + columnNumberAsText} ref={ref}>
                        {coveragesQueryResult.data.map((coverage, index) => (
                            <CoverageCard
                                key={"coverage-card-" + index}
                                coverageOrOption={coverageToCoverageOrOption(coverage)}
                                activeCoverageGroupId={props.activeCoverageGroupId}
                            />
                        ))}
                    </div>
                )}
        </>
    );
};

export default CoverageList;
