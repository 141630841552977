import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Container, Grid} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetSplittings} from "../../../Services/Splitting/Queries";
import SplittingDetails from "./Fragements/SplittingDetails";
import {Breadcrumb} from "../../../Components/Routes/BreadcrumbsInjectorWrapper";

export interface IParams extends Params {
    id: string;
    splittingId: string;
}

const SplittingPage = ({sections}: {sections: Breadcrumb[]}) => {
    const {t} = useTranslation();
    const {id: productId, splittingId} = useParams() as IParams;
    const {data: splittings, isSuccess} = useGetSplittings(productId);

    const menus = new SidebarMenu(
        "",
        isSuccess
            ? splittings.map(
                  (splitting) =>
                      new SidebarItem(
                          `${splitting.code}-${splitting.label}`,
                          splitting.label,
                          `/products/${productId}/splittings/${splitting.id}`
                      )
              )
            : []
    );

    return (
        <Container fluid>
            <Sidebar title={t("Splittings")} menus={[menus]} />
            <CustomBreadcrumb sections={sections} />
            <div className="main-container">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <SplittingDetails productId={productId} splittingId={splittingId} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default SplittingPage;
