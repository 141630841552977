import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "@tanstack/react-query";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {addRegistry, deleteRegistry, updateRegistry, getRegistry, getRegistries} from "./AxiosRequests";
import {RegistryEntryDTO, RegistryEntryType} from "./Types";

export const useGetRegistriesByTypeQuery = (
    registryEntryType: RegistryEntryType | null
): UseQueryResult<Array<RegistryEntryDTO>, Error> => {
    return useQuery({
        queryKey: ["getRegistries", registryEntryType],
        queryFn: () => getRegistries(registryEntryType),
    });
};

export const useGetRegistryQuery = (registryEntryId: string): UseQueryResult<RegistryEntryDTO, Error> => {
    return useQuery({
        queryKey: ["getRegistry", registryEntryId],
        queryFn: () => getRegistry(registryEntryId),
    });
};

export const useAddRegistryMutation = (): UseMutationResult<RegistryEntryDTO, CustomError, RegistryEntryDTO, Error> => {
    return useMutation<RegistryEntryDTO, CustomError, RegistryEntryDTO, Error>({
        mutationFn: (registry: RegistryEntryDTO) => addRegistry(registry),
        onSuccess: async (result: RegistryEntryDTO) => {
            await queryClient.invalidateQueries({queryKey: ["getRegistries", result.type]});
            await queryClient.invalidateQueries({queryKey: ["getRegistries", null]});
        },
    });
};

export const useUpdateRegistryMutation = (
    registryEntryId: string
): UseMutationResult<RegistryEntryDTO, CustomError, RegistryEntryDTO, Error> => {
    return useMutation<RegistryEntryDTO, CustomError, RegistryEntryDTO, Error>({
        mutationFn: (editedEntry: RegistryEntryDTO) => updateRegistry(registryEntryId, editedEntry),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRegistries"]});
            await queryClient.invalidateQueries({queryKey: ["getRegistry", registryEntryId]});
        },
    });
};

export const useDeleteRegistryMutation = (registryEntryId: string): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteRegistry(registryEntryId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRegistries"]});
            queryClient.removeQueries({queryKey: ["getRegistry", registryEntryId]});
        },
    });
};

