import {useEffect, useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Dropdown, Icon, Input, Segment} from "semantic-ui-react";
import EntityPicker from "../../../../../../Components/Modals/EntityPicker";
import ModalDelete from "../../../../../../Components/Modals/ModalDelete";
import {ComputingRuleTypeEnum} from "../../../../../../Services/ComputingRule/Types";
import {OperandEnum} from "../../../../../../Services/Condition/Types";
import {
    DeductibleCapitalCondition,
    DeductibleCapitalConditionDTO,
    DeductibleCapitalConditionElementDTO,
    DeductibleCapitalConditionElementFormat,
} from "../../../../../../Services/DeductibleCapital/Types";
import {useGetPricingCriteriasList} from "../../../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItem, PricingCriteriaItemType, PricingCriterias} from "../../../../../../Services/PricingCriterias/Types";
import {useGetQuestionsQuery} from "../../../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../../../Services/Question/Types";
import {useGetVariablesList} from "../../../../../../Services/Variables/Queries";
import {Variable, VariableType, VariableValueType} from "../../../../../../Services/Variables/Types";
import {GenericReducerActionType} from "../../../../../../Utils/ReducerUtils";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../../../../../Utils/SortUtils";
import { PricingCriteriaCard } from "../../../../Version/Fragments/PricingCriterias/PricingCriteriaCard";
import QuestionCard from "../../../../Version/Fragments/Questions/QuestionCard";
import { VariableCard } from "../../../../Version/Fragments/Variables/VariableCard";
import {AssignmentType, DeductibleCapitalConditionActionType} from "./DeductibleCapitalConditionAssignementReducer";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

type DeductibleCapitalConditionAssignementUpdatePropsType = {
    conditionState: DeductibleCapitalConditionDTO;
    dispatchCondition: React.Dispatch<DeductibleCapitalConditionActionType>;
    originalCondition: DeductibleCapitalCondition;
    numberOfConditions: number | undefined;
};

enum Direction {
    R = "RIGHT",
    L = "LEFT",
}

export type ActionType =
    | {type: "remove"; payload: number}
    | {type: "move"; payload: {index: number; direction: Direction}}
    | {
          type: "edit";
          payload: {index: number; value: DeductibleCapitalConditionElementDTO; assignmentType: AssignmentType};
      }
    | {type: "updateList"; payload: DeductibleCapitalConditionElementDTO[]}
    | {type: "reset"};

const DeductibleCapitalConditionAssignementUpdateEdit = ({
    conditionState,
    dispatchCondition,
    originalCondition,
    numberOfConditions = 0,
}: DeductibleCapitalConditionAssignementUpdatePropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const computingRuleKindList: {
        key: number;
        text: string;
        value: OperandEnum;
        type: "female" | "male";
    }[] = [
        {key: 1, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 2, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 3, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {key: 4, text: "Computing rule variable", value: OperandEnum.VARIABLE_COMPUTING_RULE, type: "female"},
    ];

    const dropdownTypeOptions: {
        key: number;
        text: string;
        value: ComputingRuleTypeEnum;
        optionList?: {key: number; text: string; value: string}[];
    }[] = [
        {
            key: 1,
            text: t("enums.ComputingRuleTypeEnum." + ComputingRuleTypeEnum.DATA),
            value: ComputingRuleTypeEnum.DATA,
            optionList: computingRuleKindList,
        },
        {
            key: 2,
            text: t("Text"),
            value: ComputingRuleTypeEnum.FIXED_VALUE,
        },
    ];

    const textFormat = [
        {key: "INTEGER", text: "1000", value: DeductibleCapitalConditionElementFormat.INTEGER},
        {key: "DECIMAL", text: "1000,00", value: DeductibleCapitalConditionElementFormat.DECIMAL},
        {
            key: "INTEGER_WITH_SEPARATOR",
            text: "1 000",
            value: DeductibleCapitalConditionElementFormat.INTEGER_WITH_SEPARATOR,
        },
        {
            key: "DECIMAL_WITH_SEPARATOR",
            text: "1 000,00",
            value: DeductibleCapitalConditionElementFormat.DECIMAL_WITH_SEPARATOR,
        },
    ];

    const findDeductibleCapitalConditionElementFormat = (value: string | undefined) => {
        if (value === DeductibleCapitalConditionElementFormat.DECIMAL)
            return DeductibleCapitalConditionElementFormat.DECIMAL;
        else if (value === DeductibleCapitalConditionElementFormat.INTEGER)
            return DeductibleCapitalConditionElementFormat.INTEGER;
        else if (value === DeductibleCapitalConditionElementFormat.INTEGER_WITH_SEPARATOR)
            return DeductibleCapitalConditionElementFormat.INTEGER_WITH_SEPARATOR;
        else return DeductibleCapitalConditionElementFormat.DECIMAL_WITH_SEPARATOR;
    };

    const {data: variablesComputingRules} = useGetVariablesList(params.versionId, VariableType.R);

    const [indexRulePartOperand, setIndexRulePartOperand] = useState<number>(0);
    const [selectedOperand, setSelectedOperand] = useState<{
        key: number;
        text: string;
        value: OperandEnum;
        type: "female" | "male";
    }>();

    const reducerDeductibleCapitalConditionElementList = (
        state: {deductibleCapitalConditionElementList: DeductibleCapitalConditionElementDTO[]},
        action: ActionType
    ) => {
        const conditionElementList = [...state.deductibleCapitalConditionElementList];

        switch (action.type) {
            case "remove":
                conditionElementList.splice(action.payload, 1);
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {deductibleCapitalConditionElementList: conditionElementList},
                });
                return {deductibleCapitalConditionElementList: conditionElementList};
            case "move":
                if (action.payload.direction === Direction.L) {
                    conditionElementList.splice(
                        action.payload.index - 1,
                        0,
                        conditionElementList.splice(action.payload.index, 1)[0]
                    );
                } else {
                    conditionElementList.splice(
                        action.payload.index + 1,
                        0,
                        conditionElementList.splice(action.payload.index, 1)[0]
                    );
                }
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                    payload: {deductibleCapitalConditionElementList: conditionElementList},
                });
                return {deductibleCapitalConditionElementList: conditionElementList};
            case "edit":
                conditionElementList[action.payload.index] = action.payload.value;
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: action.payload.assignmentType,
                    payload: {deductibleCapitalConditionElementList: conditionElementList},
                });

                return {deductibleCapitalConditionElementList: conditionElementList};
            case "updateList":
                return {deductibleCapitalConditionElementList: action.payload};
            case "reset":
                dispatchCondition({
                    type: GenericReducerActionType.EDIT,
                    assignmentType: AssignmentType.NOTHING,
                    payload: {deductibleCapitalConditionElementList: []},
                });
                return {deductibleCapitalConditionElementList: []};
            default:
                throw new Error();
        }
    };

    const [state, dispatchDeductibleCapitalConditionElementList] = useReducer(
        reducerDeductibleCapitalConditionElementList,
        {
            deductibleCapitalConditionElementList: originalCondition.deductibleCapitalConditionElements || [],
        }
    );

    useEffect(() => {
        dispatchDeductibleCapitalConditionElementList({
            type: "updateList",
            payload:
                originalCondition.deductibleCapitalConditionElements ?? conditionState.deductibleCapitalConditionElementDTOs ?? [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfConditions, originalCondition]);

    const textStyle = {
        marginRight: "5px",
    };

    const getConditionElementContent = (conditionElement: DeductibleCapitalConditionElementDTO): string => {
        if (conditionElement.questionName) return conditionElement.questionName;
        else if (conditionElement.pricingCriteriaName) return conditionElement.pricingCriteriaName;
        else if (conditionElement.variableName) return conditionElement.variableName;
        else if (conditionElement.text) return conditionElement.text;

        throw new Error("No content found");
    };

    return (
        <Segment secondary>
            {selectedOperand !== undefined && selectedOperand.value === OperandEnum.QUESTION && (
                <EntityPicker
                    object={selectedOperand.text}
                    objectContext={selectedOperand.type}
                    entityListGetMethod={useGetQuestionsQuery}
                    entityListGetParameters={[params.versionId, params.versionId !== undefined]}
                    renderCardContent={(question: QuestionDTO) => (
                        <QuestionCard
                            question={question}
                            versionId={params.versionId}
                            onClick={(operandItem: QuestionDTO) => {
                                const rulePart: DeductibleCapitalConditionElementDTO = {
                                    order: indexRulePartOperand + 1,
                                    text: null,
                                    format:
                                        operandItem.valueType === QuestionValueTypeEnum.NOMBRE
                                            ? DeductibleCapitalConditionElementFormat.DECIMAL_WITH_SEPARATOR
                                            : null,
        
                                    questionName: operandItem.name,
                                    pricingCriteriaName: null,
                                    variableName: null,
                                };
                                dispatchDeductibleCapitalConditionElementList({
                                    type: "edit",
                                    payload: {
                                        index: indexRulePartOperand,
                                        value: rulePart,
                                        assignmentType: AssignmentType.QUESTION,
                                    },
                                });
                                setSelectedOperand(undefined);
                            }}
                        />
                    )}
                    filterEntity={(questionToFilter: QuestionDTO) =>
                        questionToFilter.valueType !== QuestionValueTypeEnum.DATE
                    }
                    filterBySearch={(questionToFilter: QuestionDTO, search: string): boolean => {
                        return (
                            questionToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                            questionToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onCancel={() => setSelectedOperand(undefined)}
                    defaultSortMethod={(a: QuestionDTO, b: QuestionDTO) =>
                        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                    }
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                />
            )}

            {selectedOperand !== undefined && selectedOperand.value === OperandEnum.CRITERE && (
                <EntityPicker
                    object={selectedOperand.text}
                    objectContext={selectedOperand.type}
                    entityListGetMethod={useGetPricingCriteriasList}
                    entityListGetParameters={[params.versionId]}
                    renderCardContent={(criteria: PricingCriteriaItem) => (
                        <PricingCriteriaCard
                            versionId={params.versionId}
                            pricingCriteria={criteria}
                            onClick={(operandItem: PricingCriteriaItem | PricingCriterias) => {
                                const rulePart: DeductibleCapitalConditionElementDTO = {
                                    order: indexRulePartOperand + 1,
                                    text: null,
                                    format:
                                        operandItem.type === PricingCriteriaItemType.NUMERIC
                                            ? DeductibleCapitalConditionElementFormat.DECIMAL_WITH_SEPARATOR
                                            : null,
        
                                    questionName: null,
                                    pricingCriteriaName: operandItem.name,
                                    variableName: null,
                                };
                                dispatchDeductibleCapitalConditionElementList({
                                    type: "edit",
                                    payload: {
                                        index: indexRulePartOperand,
                                        value: rulePart,
                                        assignmentType: AssignmentType.PRICING_CRITERIA,
                                    },
                                });
                                setSelectedOperand(undefined);
                            }}
                        />
                    )}
                    filterEntity={(criteriaToFilter: PricingCriteriaItem) =>
                        criteriaToFilter.type !== PricingCriteriaItemType.DATE
                    }
                    filterBySearch={(criteriaToFilter: PricingCriteriaItem, search: string): boolean => {
                        return (
                            criteriaToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                            criteriaToFilter.type.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onCancel={() => setSelectedOperand(undefined)}
                    defaultSortMethod={(a: PricingCriteriaItem, b: PricingCriteriaItem) =>
                        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                    }
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                />
            )}

            {selectedOperand !== undefined && selectedOperand.value === OperandEnum.VARIABLE && (
                <EntityPicker
                    object={selectedOperand.text}
                    objectContext={selectedOperand.type}
                    entityListGetMethod={useGetVariablesList}
                    entityListGetParameters={[params.versionId, VariableType.V, params.versionId !== undefined]}
                    renderCardContent={(variable: Variable) => (
                        <VariableCard
                            versionId={params.versionId}
                            variable={variable}
                            onClick={(operandItem: Variable) => {
                                const rulePart: DeductibleCapitalConditionElementDTO = {
                                    order: indexRulePartOperand,
                                    text: null,
                                    format:
                                        operandItem.valueType === VariableValueType.NUMERIC
                                            ? DeductibleCapitalConditionElementFormat.DECIMAL_WITH_SEPARATOR
                                            : null,
        
                                    questionName: null,
                                    pricingCriteriaName: null,
                                    variableName: operandItem.name,
                                };
                                dispatchDeductibleCapitalConditionElementList({
                                    type: "edit",
                                    payload: {
                                        index: indexRulePartOperand,
                                        value: rulePart,
                                        assignmentType: AssignmentType.VARIABLE,
                                    },
                                });
        
                                setSelectedOperand(undefined);
                            }}
                        />
                    )}
                    filterEntity={(variableToFilter: Variable) =>
                        variableToFilter.valueType !== VariableValueType.DATE
                    }
                    filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                        return (
                            variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                            variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onCancel={() => setSelectedOperand(undefined)}
                    defaultSortMethod={(a: Variable, b: Variable) =>
                        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                    }
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                />
            )}

            {selectedOperand !== undefined && selectedOperand.value === OperandEnum.VARIABLE_COMPUTING_RULE && (
                <EntityPicker
                    object={selectedOperand.text}
                    objectContext={selectedOperand.type === "female" ? "female" : "male"}
                    entityListGetMethod={useGetVariablesList}
                    entityListGetParameters={[params.versionId, VariableType.R]}
                    renderCardContent={(variable: Variable) => (
                        <VariableCard
                            versionId={params.versionId}
                            variable={variable}
                            onClick={(operandItem) => {
                                const conditionElement: DeductibleCapitalConditionElementDTO = {
                                    order: indexRulePartOperand + 1,
                                    text: null,
                                    format:
                                        operandItem.valueType === VariableValueType.NUMERIC
                                            ? DeductibleCapitalConditionElementFormat.DECIMAL_WITH_SEPARATOR
                                            : null,
        
                                    questionName: null,
                                    pricingCriteriaName: null,
                                    variableName: operandItem.name,
                                };
        
                                dispatchDeductibleCapitalConditionElementList({
                                    type: "edit",
                                    payload: {
                                        index: indexRulePartOperand,
                                        value: conditionElement,
                                        assignmentType: AssignmentType.VARIABLE_COMPUTING_RULE,
                                    },
                                });
                                setSelectedOperand(undefined);
                            }}
                        />
                    )}
                    filterEntity={(variableToFilter: Variable) =>
                        variableToFilter.valueType !== VariableValueType.DATE
                    }
                    filterBySearch={(variableToFilter: Variable, search: string): boolean => {
                        return (
                            variableToFilter.name.toLowerCase().includes(search.toLowerCase()) ||
                            variableToFilter.valueType.toLowerCase().includes(search.toLowerCase()) ||
                            search === ""
                        );
                    }}
                    onCancel={() => setSelectedOperand(undefined)}
                    defaultSortMethod={(a: Variable, b: Variable) =>
                        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                    }
                    sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                />
            )}

            {state.deductibleCapitalConditionElementList !== null &&
                state.deductibleCapitalConditionElementList.map((conditionElement, index) => {
                    const content = getConditionElementContent(conditionElement);

                    return (
                        <div
                            style={{display: "inline-block", textAlign: "right"}}
                            className="rulePart"
                            key={content}>
                            <div className="icons">
                                {index > 0 && (
                                    <Icon
                                        color="black"
                                        name="long arrow alternate left"
                                        size="small"
                                        link
                                        onClick={() => {
                                            dispatchDeductibleCapitalConditionElementList({
                                                type: "move",
                                                payload: {index: index, direction: Direction.L},
                                            });
                                        }}
                                    />
                                )}
                                {index < state.deductibleCapitalConditionElementList.length - 1 && (
                                    <Icon
                                        color="black"
                                        name="long arrow alternate right"
                                        size="small"
                                        link
                                        onClick={() => {
                                            dispatchDeductibleCapitalConditionElementList({
                                                type: "move",
                                                payload: {index: index, direction: Direction.R},
                                            });
                                        }}
                                    />
                                )}
                                <Icon
                                    color="red"
                                    name="close"
                                    size="small"
                                    link
                                    style={{margin: "0px"}}
                                    onClick={() => {
                                        dispatchDeductibleCapitalConditionElementList({
                                            type: "remove",
                                            payload: index,
                                        });
                                    }}
                                />
                            </div>

                            <div className="rulePartItem">
                                {conditionElement &&
                                (conditionElement.questionName !== null ||
                                    conditionElement.pricingCriteriaName !== null ||
                                    conditionElement.variableName !== null) ? (
                                    <div>
                                        {conditionElement.format !== null ? (
                                            <Button.Group>
                                                <Button
                                                    className="data"
                                                    basic
                                                    attached="left"
                                                    size="small"
                                                    content={content}
                                                    onClick={() => {
                                                        if (conditionElement.variableName !== null) {
                                                            const isComputingRule = variablesComputingRules?.find(
                                                                (el) => el.name === conditionElement.variableName
                                                            );

                                                            setSelectedOperand(
                                                                computingRuleKindList.find(
                                                                    (el) =>
                                                                        t(el.value) ===
                                                                        (isComputingRule
                                                                            ? OperandEnum.VARIABLE_COMPUTING_RULE
                                                                            : OperandEnum.VARIABLE)
                                                                )
                                                            );
                                                        } else if (conditionElement.questionName !== null) {
                                                            setSelectedOperand(
                                                                computingRuleKindList.find(
                                                                    (el) => t(el.value) == t(OperandEnum.QUESTION)
                                                                )
                                                            );
                                                        } else if (conditionElement.pricingCriteriaName !== null) {
                                                            setSelectedOperand(
                                                                computingRuleKindList.find(
                                                                    (el) => t(el.value) == t(OperandEnum.CRITERE)
                                                                )
                                                            );
                                                        }

                                                        setIndexRulePartOperand(index);
                                                    }}
                                                />
                                                <Dropdown
                                                    as={Button}
                                                    attached="right"
                                                    basic
                                                    button
                                                    style={textStyle}
                                                    size="small"
                                                    options={textFormat}
                                                    defaultValue={conditionElement.format}
                                                    onChange={(_, data) => {
                                                        const format = findDeductibleCapitalConditionElementFormat(
                                                            String(data.value)
                                                        );
                                                        conditionElement = {
                                                            ...conditionElement,
                                                            format: format,
                                                        };

                                                        dispatchDeductibleCapitalConditionElementList({
                                                            type: "edit",
                                                            payload: {
                                                                index: index,
                                                                value: conditionElement,
                                                                assignmentType: AssignmentType.FIXED_VALUE,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Button.Group>
                                        ) : (
                                            <Button
                                                className="data"
                                                basic
                                                style={{
                                                    ...textStyle,
                                                    height: "39px",
                                                }}
                                                attached={conditionElement.format !== null ? "left" : undefined}
                                                size="small"
                                                content={content}
                                                onClick={() => {
                                                    if (conditionElement.variableName !== null) {
                                                        const isComputingRule = variablesComputingRules?.find(
                                                            (el) => el.name === conditionElement.variableName
                                                        );

                                                        setSelectedOperand(
                                                            computingRuleKindList.find(
                                                                (el) =>
                                                                    t(el.value) ===
                                                                    (isComputingRule
                                                                        ? OperandEnum.VARIABLE_COMPUTING_RULE
                                                                        : OperandEnum.VARIABLE)
                                                            )
                                                        );
                                                    } else if (conditionElement.questionName !== null) {
                                                        setSelectedOperand(
                                                            computingRuleKindList.find(
                                                                (el) => t(el.value) == t(OperandEnum.QUESTION)
                                                            )
                                                        );
                                                    } else if (conditionElement.pricingCriteriaName !== null) {
                                                        setSelectedOperand(
                                                            computingRuleKindList.find(
                                                                (el) => t(el.value) == t(OperandEnum.CRITERE)
                                                            )
                                                        );
                                                    }

                                                    setIndexRulePartOperand(index);
                                                }}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <Input
                                        style={{
                                            ...textStyle,
                                            height: "39px",
                                            verticalAlign: "baseline",
                                        }}
                                        onChange={(_, data) => {
                                            dispatchDeductibleCapitalConditionElementList({
                                                type: "edit",
                                                payload: {
                                                    index: index,
                                                    value: {...conditionElement, text: data.value},
                                                    assignmentType: AssignmentType.FIXED_VALUE,
                                                },
                                            });
                                        }}
                                        defaultValue={conditionElement.text}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}

            <Dropdown
                className="addComputingRuleItem"
                as={Button}
                color="blue"
                simple
                compact
                style={{padding: 8.25}}
                icon={<Icon name="plus" style={{margin: 0}} />}>
                <Dropdown.Menu>
                    {dropdownTypeOptions.map((option) => (
                        <Dropdown.Item
                            data-cy="first"
                            value={option.optionList !== undefined ? undefined : option.value}
                            key={option.key}>
                            {option.optionList !== undefined ? (
                                <>
                                    <Icon name="dropdown" />
                                    <span className="text">{option.text}</span>
                                    <Dropdown.Menu data-cy="second">
                                        {option.optionList.map((opt) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedOperand(
                                                        opt.value === ComputingRuleTypeEnum.DATA
                                                            ? computingRuleKindList.find(
                                                                    (rule) => rule.value === opt.value
                                                                )
                                                            : undefined
                                                    );
                                                    setIndexRulePartOperand(
                                                        state.deductibleCapitalConditionElementList.length
                                                    );
                                                    setSelectedOperand(
                                                        computingRuleKindList.find(
                                                            (rule) => rule.value === opt.value
                                                        )
                                                    );
                                                }}
                                                value={opt.value}
                                                key={opt.key}>
                                                {t(opt.text)}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </>
                            ) : (
                                <Dropdown.Item
                                    onClick={() => {
                                        const rulePart: DeductibleCapitalConditionElementDTO = {
                                            order: state.deductibleCapitalConditionElementList.length + 1,
                                            text: "",
                                            format: null,

                                            questionName: null,
                                            pricingCriteriaName: null,
                                            variableName: null,
                                        };
                                        dispatchDeductibleCapitalConditionElementList({
                                            type: "edit",
                                            payload: {
                                                index: state.deductibleCapitalConditionElementList.length,
                                                value: rulePart,
                                                assignmentType: AssignmentType.FIXED_VALUE,
                                            },
                                        });
                                        setIndexRulePartOperand(state.deductibleCapitalConditionElementList.length);
                                    }}
                                    value={option.value}
                                    key={option.key}>
                                    {option.text}
                                </Dropdown.Item>
                            )}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {state.deductibleCapitalConditionElementList !== null &&
                state.deductibleCapitalConditionElementList.length > 0 && (
                    <ModalDelete
                        isPending={false}
                        isSuccess={false}
                        isError={false}
                        onValidate={() => {
                            dispatchDeductibleCapitalConditionElementList({
                                type: "reset",
                            });
                        }}
                        objectToDelete={t("computing rules")}
                        objectType={"plurial"}
                        basicButton={true}
                        iconAndTextOnOpenButton={false}
                        iconOnOpenButton
                        renderOpenButton={() => (
                            <Button
                                title={t("Erase all")}
                                className="deleteAllComputingRuleItem"
                                color="red"
                                icon="close"
                                compact
                                onClick={() =>
                                    dispatchDeductibleCapitalConditionElementList({
                                        type: "reset",
                                    })
                                }></Button>
                        )}
                    />
                )}
        </Segment>
    );
};

export default DeductibleCapitalConditionAssignementUpdateEdit;
