import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Params, useParams } from "react-router-dom";
import { Button, Divider, Grid, Header, Radio, Segment, Tab } from "semantic-ui-react";
import { ProductBehaviorWithRegistryDTO } from "../../../../Services/ProductBehaviour/Types";
import "../../Product/Fragments/ProductDetails/Products.css";
import BehaviourDetailsEdit from "./BehaviourDetailsEdit";

interface IParams extends Params {
    id: string;
    coverageId: string;
}

type BehaviourDetailsPropsType = {
    behaviour: ProductBehaviorWithRegistryDTO;
};

const BehaviourDetails = ({behaviour}: BehaviourDetailsPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [isEditing, setIsEditing] = useState(false);
    const toggleEditBehaviourDetailsModal = () => setIsEditing(!isEditing);

    return (
        <Tab.Pane style={{padding: "20px"}}>
            {isEditing ? (
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <BehaviourDetailsEdit
                                behaviourDetails={behaviour}
                                toggleEditBehaviourDetailsModal={toggleEditBehaviourDetailsModal}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ) : (
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Segment style={{padding: "25px"}}>
                                <Header as="h2" dividing>
                                    {behaviour.registry.type === "G" ? t("Coverage") : t("Fee")}
                                </Header>
                                <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                    <Grid.Row>
                                        <Grid.Column width={7}>
                                            <strong>{t("Code")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>{behaviour.registry.code}</Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("Label")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column behaviour-testid={"labelContent"} width={9}>
                                            {behaviour.registry.label}
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("Charging mode")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            {t(`enums.ChargingModeEnum.${behaviour.productRegistryJoin.chargingMode}`)}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Header as="h2" dividing>
                                    {t("company")}
                                </Header>

                                <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                    <Grid.Row>
                                        <Grid.Column width={7}>
                                            <strong>{t("Company code")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>{behaviour.productRegistryJoin.companyCode}</Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("contribution commission rate")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            {behaviour.productRegistryJoin.contributionCommissionRate}%
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("management commission rate")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            {behaviour.productRegistryJoin.managementCommissionRate}%
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <Header as="h2" dividing>
                                    {t("behaviour")}
                                </Header>

                                <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                    <Grid.Row>
                                        <Grid.Column width={7}>
                                            <strong>{t("emit on deadline")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <Radio
                                                aria-label={"deadline"}
                                                toggle
                                                readOnly
                                                checked={behaviour.productRegistryJoin.emitOnDeadline}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("renew on deadline")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <Radio
                                                aria-label={"renew on deadline"}
                                                toggle
                                                readOnly
                                                checked={behaviour.productRegistryJoin.renewOnDeadline}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("exclude VAT free")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <Radio
                                                aria-label={"exclude VAT free"}
                                                toggle
                                                readOnly
                                                checked={behaviour.productRegistryJoin.excludeVATFree}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("apply prorata")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <Radio
                                                aria-label={"apply prorata"}
                                                toggle
                                                readOnly
                                                checked={behaviour.productRegistryJoin.applyProrata}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("Refund mode")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            {t(`enums.RefundMode.${behaviour.productRegistryJoin.refundMode}`)}
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("Apply discount")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <Radio
                                                aria-label={"apply discount"}
                                                toggle
                                                readOnly
                                                checked={behaviour.productRegistryJoin.applyDiscount}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width={7}>
                                            <strong>{t("Exoneration active")} : </strong>
                                        </Grid.Column>
                                        <Grid.Column width={9}>
                                            <Radio
                                                aria-label={"exoneration active"}
                                                toggle
                                                readOnly
                                                checked={behaviour.productRegistryJoin.exonerationActive}
                                            />
                                        </Grid.Column>

                                        {behaviour.registry.type === "G" && (
                                            <>
                                                <Grid.Column width={7}>
                                                    <strong>{t("Apply terrorism tax")} : </strong>
                                                </Grid.Column>
                                                <Grid.Column width={9}>
                                                    <Radio
                                                        aria-label={"Apply terrorism tax"}
                                                        toggle
                                                        readOnly
                                                        checked={behaviour.productRegistryJoin.applyTerrorismTax}
                                                    />
                                                </Grid.Column>
                                            </>
                                        )}
                                    </Grid.Row>
                                </Grid>

                                <div className={"bottom-button-card"} style={{bottom: 0}}>
                                    <Divider />
                                    <Button secondary name="return" as={Link} to={`/products/${params.id}`}>
                                        {t("return")}
                                    </Button>
                                    <Button primary name="editBehaviour" onClick={toggleEditBehaviourDetailsModal}>
                                        {t("edit")}
                                    </Button>
                                </div>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Tab.Pane>
    );
};

export default BehaviourDetails;
