import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import {VariableType, VariableValueType} from "../../../../Services/Variables/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {codeValidation} from "../../../Global/Form/Validation";

const FormVariable = ({
    errors,
    control,
    type,
    defaultValueName,
    setDefaultValueName,
    defaultValueType,
    setDefaultValueType,
    defaultValueSaveQuote,
    setDefaultValueSaveQuote,
}: any) => {
    const {t} = useTranslation();

    const variableValueTypeOptions = [
        {key: 0, value: VariableValueType.STRING, text: t("enums.VariableValueType.STRING")},
        {key: 1, value: VariableValueType.DATE, text: t("enums.VariableValueType.DATE")},
        {key: 2, value: VariableValueType.NUMERIC, text: t("enums.VariableValueType.NUMERIC")},
    ];

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueName}
                            rules={{required: true, pattern: codeValidation()}}
                            name={"name"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Name")}
                                    placeholder={t("Variable name")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueName(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="50"
                                    error={getErrorMessage(t, errors, "name")}
                                />
                            )}
                        />

                        {type !== VariableType.R && (
                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={defaultValueType}
                                name={"valueType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        label={t("Type")}
                                        name={name}
                                        required
                                        onBlur={onBlur}
                                        placeholder={t("Variable type")}
                                        onChange={(_, {value}) => {
                                            setDefaultValueType(value as VariableValueType);
                                            onChange(value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, errors, "valueType")}
                                        options={variableValueTypeOptions}
                                    />
                                )}
                            />
                        )}

                        <Controller
                            control={control}
                            defaultValue={defaultValueSaveQuote}
                            name={"saveQuote"}
                            render={({field: {name, value, onChange}}) => (
                                <Form.Checkbox
                                    toggle
                                    label={t("Save quote")}
                                    name={name}
                                    onChange={(_, {checked}) => {
                                        setDefaultValueSaveQuote(checked);
                                        onChange(checked);
                                    }}
                                    checked={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormVariable;
