import FileSaver from "file-saver";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router";
import {Button, Checkbox, Container, Divider, Dropdown, Form, Grid, Header, Icon, Segment} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import EntityPickerGrid from "../../../Components/Modals/EntityPickerGrid";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalDuplicate from "../../../Components/Modals/ModalDuplicate";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import SimpleList from "../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../Components/SimpleList/SimpleListTypes";
import TextOverflow from "../../../Components/Text/TextOverflow";
import {downloadQuestionValuesList} from "../../../Services/Question/AxiosRequests";
import {
    useDeleteQuestionMutation,
    useDuplicateQuestionMutation,
    useGetQuestionQuery,
    useGetQuestionsQuery,
    useImportValuesFromDimensionGridMutation,
    useUpdateQuestionMutation,
    useUploadQuestionListValues,
} from "../../../Services/Question/Queries";
import {QuestionValue, QuestionValueTypeEnum} from "../../../Services/Question/Types";
import {useGetVersionQuery} from "../../../Services/Version/Queries";
import getErrorMessage from "../../Global/Form/ErrorMessage";
import BreadcrumbQuestion from "./Fragments/BreadcrumbQuestion";
import ModalAddQuestion from "./Fragments/ModalAddQuestion";
import FormDuplicateQuestion from "./QuestionDetails/FormDuplicateQuestion";

/* eslint-disable react/prop-types */

interface IParams extends Params {
    id: string;
    versionId: string;
    questionId: string;
}

const QuestionPage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const getVersionQueryResult = useGetVersionQuery(params.id, params.versionId);

    const getQuestionsQuery = useGetQuestionsQuery(params.versionId);
    const {data: question, status: getQuestionStatus} = useGetQuestionQuery(params.versionId, params.questionId);
    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const deleteQuestionMutation = useDeleteQuestionMutation(params.id, params.versionId, params.questionId);
    const handleDelete = () => {
        deleteQuestionMutation.mutate();
    };

    const [duplicateQuestionName, setDuplicateQuestionName] = useState("");
    const duplicateQuestionForm = useForm<{name: string}>({mode: "onBlur", shouldUnregister: true});
    const resetFormDuplicateQuestion = () => {
        setDuplicateQuestionName("");
    };

    const duplicateQuestionMutation = useDuplicateQuestionMutation(params.id, params.versionId, params.questionId);
    const sumbitDuplicateQuestionForm = (form: {name: string}) => {
        duplicateQuestionMutation.mutate({
            ...form,
        });
    };

    const [editingMode, setEditingMode] = useState<boolean>(false);
    const [isMandatoryEditing, setIsMandatoryEditing] = useState<boolean | undefined>(false);
    const handleChangeEditingMode = () => {
        setEditingMode(!editingMode);
        setIsMandatoryEditing(question?.mandatory);
    };
    const questionTypeOptions = [
        {key: 1, text: t("Date"), value: QuestionValueTypeEnum.DATE},
        {key: 2, text: t("Number"), value: QuestionValueTypeEnum.NOMBRE},
        {key: 3, text: t("List"), value: QuestionValueTypeEnum.LISTE},
        {key: 4, text: t("String"), value: QuestionValueTypeEnum.STRING},
    ];
    const [valueTypeSelected, setValueTypeSelected] = useState<QuestionValueTypeEnum>(
        question?.valueType ? question.valueType : QuestionValueTypeEnum.STRING
    );
    const handleValueTypeChange = (valueSelected: string) => {
        const optionSelected = questionTypeOptions.find((option) => option.value === valueSelected);
        if (optionSelected) setValueTypeSelected(optionSelected.value);
    };
    const inputTypeByValueType = (valueType: QuestionValueTypeEnum) => {
        switch (valueType) {
            case "STRING":
            case "LISTE":
                return "text";
            case "NOMBRE":
                return "number";
            case "DATE":
                return "date";
        }
    };

    const updateQuestionMutation = useUpdateQuestionMutation(params.versionId, params.questionId);
    const updateQuestionForm = useForm<{
        defaultValue: string;
        name: string;
        valueType: QuestionValueTypeEnum;
        mandatory: boolean;
    }>({mode: "onBlur", shouldUnregister: true});
    const submitUpdateQuestion = (form: {
        defaultValue: string;
        name: string;
        valueType: QuestionValueTypeEnum;
        mandatory: boolean;
    }) => {
        let questionValues: Array<QuestionValue> | null = null;
        if (
            isMandatoryEditing &&
            valueTypeSelected === QuestionValueTypeEnum.LISTE &&
            question?.valueType !== QuestionValueTypeEnum.LISTE
        ) {
            questionValues = [
                {
                    value: t("Default"),
                    label: t("Default value"),
                },
            ];
        } else if (
            !isMandatoryEditing &&
            valueTypeSelected === QuestionValueTypeEnum.LISTE &&
            question?.valueType !== QuestionValueTypeEnum.LISTE
        ) {
            questionValues = [
                {
                    value: form.defaultValue,
                    label: null,
                },
            ];
        }

        updateQuestionMutation.mutate({
            ...form,
            questionValueDTOs: questionValues,
        });
    };

    const getModalStatus = () => Object.keys(updateQuestionForm.formState.errors).length !== 0;

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const uploadMutation = useUploadQuestionListValues(params.versionId, params.questionId);

    const handleExportData = async () => {
        const blob = new Blob([await downloadQuestionValuesList(params.versionId, params.questionId)], {
            type: "text/csv",
        });
        FileSaver.saveAs(blob, question?.name + ".csv");
    };
    const handleMutate = () => uploadMutation.mutate(selectedFile);
    const handleSetFile = ({target: {files}}: React.ChangeEvent<HTMLInputElement>) =>
        setSelectedFile(files !== null ? files[0] : null);
    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    const importValues = useImportValuesFromDimensionGridMutation(params.versionId, params.questionId);
    const [selectedValues, setSelectedValues] = useState<{gridCode: string; dimensionCode: string} | undefined>(
        undefined
    );
    const [openEntityPicker, setOpenEntityPicker] = useState<boolean>(false);
    const uploadFromExcel = () => {
        hiddenFileInput.current?.click();
    };
    const uploadFromGrid = () => {
        setOpenEntityPicker(true);
    };
    const [isImportValueModalOpen, setIsImportValueModalOpen] = useState(false);
    const clearSelectedDimension = () => {
        setIsImportValueModalOpen(false);
        setOpenEntityPicker(false);
        setSelectedValues(undefined);
    };

    useEffect(() => {
        if (getQuestionsQuery.isSuccess && getQuestionsQuery.data !== undefined) {
            const sidebarMenus = new SidebarMenu(
                "",
                getQuestionsQuery.data.map(
                    (questionItem) =>
                        new SidebarItem(
                            questionItem.name,
                            questionItem.name,
                            `/products/${params.id}/versions/${params.versionId}/questions/${questionItem.id}`
                        )
                )
            );
            setMenus([sidebarMenus]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getQuestionsQuery.isSuccess, getQuestionsQuery.data]);

    useEffect(() => {
        setEditingMode(false);
    }, [params.questionId]);

    useEffect(() => {
        if (getQuestionStatus === "success" && question !== undefined) setValueTypeSelected(question.valueType);
    }, [getQuestionStatus, question]);

    return (
        <Container fluid>
            <Sidebar
                title={t("Questions")}
                menus={menus}
                renderAdditionnalButton={
                    getVersionQueryResult.isSuccess && getVersionQueryResult.data.pipDate === null
                        ? () => (
                              <ModalAddQuestion
                                  alternateOpenButton={() => (
                                      <Button primary name="new_question" className="menu-button">
                                          {t("New entity", {entity: t("Question").toLowerCase(), context: "female"})}
                                      </Button>
                                  )}
                              />
                          )
                        : undefined
                }
            />
            {getQuestionStatus === "pending" && <CustomBreadcrumb sections={props.sections} />}
            {question !== undefined && getQuestionStatus === "success" && (
                <BreadcrumbQuestion question={question} sections={props.sections} />
            )}
            {getQuestionStatus === "success" && question !== undefined && (
                <div className="main-container">
                    <Grid columns={2} stackable>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Segment className="card-container">
                                    <Header as="h2" dividing>
                                        <TextOverflow value={question?.name} />
                                    </Header>

                                    {editingMode ? (
                                        <Form>
                                            <Grid columns="equal" verticalAlign="middle" name="question-info">
                                                <Grid.Column width={6}>
                                                    <strong>{t("Name")} * : </strong>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <Controller
                                                        control={updateQuestionForm.control}
                                                        rules={{required: true, maxLength: 50}}
                                                        name="name"
                                                        defaultValue={question?.name}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Form.Input
                                                                fluid
                                                                name={name}
                                                                required
                                                                maxLength={50}
                                                                placeholder={t("Name of the question")}
                                                                onBlur={onBlur}
                                                                onChange={onChange}
                                                                value={value}
                                                                error={getErrorMessage(
                                                                    t,
                                                                    updateQuestionForm.formState.errors,
                                                                    name,
                                                                    undefined,
                                                                    50
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>

                                                <Grid.Column width={6}>
                                                    <strong>{t("Type")} * : </strong>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <Controller
                                                        control={updateQuestionForm.control}
                                                        rules={{required: true}}
                                                        name="valueType"
                                                        defaultValue={question?.valueType}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Form.Select
                                                                fluid
                                                                name={name}
                                                                required
                                                                placeholder={t("Choose a question type")}
                                                                onBlur={onBlur}
                                                                onChange={(_e, {value}) => {
                                                                    if (value !== undefined) {
                                                                        handleValueTypeChange(value.toString());
                                                                        onChange(value);
                                                                    }
                                                                }}
                                                                value={value}
                                                                options={questionTypeOptions}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>

                                                <Grid.Column width={6}>
                                                    <strong>{t("Mandatory")} : </strong>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <Controller
                                                        control={updateQuestionForm.control}
                                                        name="mandatory"
                                                        defaultValue={question?.mandatory}
                                                        render={({field: {value, name, onChange, onBlur}}) => (
                                                            <Form.Checkbox
                                                                toggle
                                                                name={name}
                                                                checked={value}
                                                                onBlur={onBlur}
                                                                onChange={(_e, {checked}) => {
                                                                    setIsMandatoryEditing(checked);
                                                                    onChange(checked);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>

                                                {!isMandatoryEditing && (
                                                    <>
                                                        <Grid.Column width={6}>
                                                            <strong>{t("Default value")} * : </strong>
                                                        </Grid.Column>
                                                        <Grid.Column width={10}>
                                                            {question.valueType !== "LISTE" ||
                                                            valueTypeSelected !== QuestionValueTypeEnum.LISTE ? (
                                                                <Controller
                                                                    name="defaultValue"
                                                                    defaultValue={question.defaultValue}
                                                                    rules={{
                                                                        required: true,
                                                                        maxLength: 30,
                                                                    }}
                                                                    control={updateQuestionForm.control}
                                                                    render={({
                                                                        field: {name, value, onChange, onBlur},
                                                                    }) => (
                                                                        <Form.Input
                                                                            fluid
                                                                            required
                                                                            type={inputTypeByValueType(
                                                                                valueTypeSelected
                                                                            )}
                                                                            name={name}
                                                                            placeholder={t("Default value")}
                                                                            onChange={(_e, {value}) => {
                                                                                onChange(value);
                                                                            }}
                                                                            onBlur={onBlur}
                                                                            value={value}
                                                                            maxLength="30"
                                                                            error={getErrorMessage(
                                                                                t,
                                                                                updateQuestionForm.formState.errors,
                                                                                name,
                                                                                undefined,
                                                                                30
                                                                            )}
                                                                        />
                                                                    )}
                                                                />
                                                            ) : (
                                                                <Controller
                                                                    name="defaultValue"
                                                                    defaultValue={question?.defaultValue}
                                                                    control={updateQuestionForm.control}
                                                                    render={({
                                                                        field: {name, value, onChange, onBlur},
                                                                    }) => (
                                                                        <Form.Select
                                                                            fluid
                                                                            name={name}
                                                                            required
                                                                            placeholder={t("Choose a default value")}
                                                                            onBlur={onBlur}
                                                                            onChange={(_e, {value}) => {
                                                                                onChange(value);
                                                                            }}
                                                                            value={value}
                                                                            options={
                                                                                question?.questionValues.map(
                                                                                    (valueOption) => {
                                                                                        return {
                                                                                            key: valueOption.id,
                                                                                            value: valueOption.value,
                                                                                            text: valueOption.value,
                                                                                        };
                                                                                    }
                                                                                ) || []
                                                                            }
                                                                            error={getErrorMessage(
                                                                                t,
                                                                                updateQuestionForm.formState.errors,
                                                                                name,
                                                                                undefined,
                                                                                50
                                                                            )}
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </Grid.Column>
                                                    </>
                                                )}
                                            </Grid>

                                            <div className="bottom-button-card">
                                                <Divider />
                                                <Button name="cancel" color="black" onClick={handleChangeEditingMode}>
                                                    {t("Cancel")}
                                                </Button>
                                                <ModalUpdate
                                                    isModalDisabled={getModalStatus()}
                                                    isPending={updateQuestionMutation.isPending}
                                                    isError={updateQuestionMutation.isError}
                                                    isSuccess={updateQuestionMutation.isSuccess}
                                                    resetMutation={updateQuestionMutation.reset}
                                                    onSuccess={() => handleChangeEditingMode()}
                                                    error={updateQuestionMutation.error}
                                                    onValidate={updateQuestionForm.handleSubmit(submitUpdateQuestion)}
                                                    objectToUpdate={t("Question")}
                                                    objectType="female"
                                                />
                                            </div>
                                        </Form>
                                    ) : (
                                        <>
                                            <Grid columns="equal" className="grid-padding">
                                                <Grid.Row role="row">
                                                    <Grid.Column width={6} role="cell">
                                                        <strong>{t("Name")} : </strong>
                                                    </Grid.Column>
                                                    <Grid.Column width={10} role="cell">
                                                        <TextOverflow value={question?.name} />
                                                    </Grid.Column>

                                                    <Grid.Column width={6} role="cell">
                                                        <strong>{t("Type")} : </strong>
                                                    </Grid.Column>
                                                    <Grid.Column width={10} role="cell">
                                                        {question?.valueType}
                                                    </Grid.Column>

                                                    <Grid.Column width={6} role="cell">
                                                        <strong>{t("Mandatory")} : </strong>
                                                    </Grid.Column>
                                                    <Grid.Column width={10} role="cell">
                                                        <Checkbox toggle checked={question.mandatory} disabled />
                                                    </Grid.Column>

                                                    {!question.mandatory && (
                                                        <>
                                                            <Grid.Column width={6} role="cell">
                                                                <strong>{t("Default value")} : </strong>
                                                            </Grid.Column>
                                                            <Grid.Column width={10} role="cell">
                                                                {(question?.valueType == QuestionValueTypeEnum.DATE &&
                                                                    t("format_date", {
                                                                        value: question?.defaultValue,
                                                                    })) ||
                                                                    question?.defaultValue}
                                                            </Grid.Column>
                                                        </>
                                                    )}
                                                </Grid.Row>
                                            </Grid>

                                            {getVersionQueryResult.isSuccess &&
                                                getVersionQueryResult.data.pipDate === null && (
                                                    <div className="bottom-button-card">
                                                        <Divider />
                                                        <Button
                                                            name="update"
                                                            color="blue"
                                                            onClick={handleChangeEditingMode}
                                                        >
                                                            {t("edit")}
                                                        </Button>
                                                        <ModalDuplicate
                                                            isValidationDisabled={
                                                                Object.keys(duplicateQuestionForm.formState.errors)
                                                                    .length !== 0
                                                            }
                                                            onValidate={duplicateQuestionForm.handleSubmit(
                                                                sumbitDuplicateQuestionForm
                                                            )}
                                                            onCancel={resetFormDuplicateQuestion}
                                                            onSuccess={resetFormDuplicateQuestion}
                                                            objectToDuplicate="Question"
                                                            openModalButtonName="duplicate"
                                                            isSuccess={duplicateQuestionMutation.isSuccess}
                                                            isPending={duplicateQuestionMutation.isPending}
                                                            isError={duplicateQuestionMutation.isError}
                                                            error={duplicateQuestionMutation.error}
                                                            resetMutation={duplicateQuestionMutation.reset}
                                                            objectToDuplicateContext={"female"}
                                                            objectToDuplicateCodeOrLabel={question.name}
                                                            renderModalContent={() => (
                                                                <FormDuplicateQuestion
                                                                    errors={duplicateQuestionForm.formState.errors}
                                                                    control={duplicateQuestionForm.control}
                                                                    defaultValueName={duplicateQuestionName}
                                                                    setDefaultValueName={setDuplicateQuestionName}
                                                                />
                                                            )}
                                                            renderOpenButton={() => {
                                                                return (
                                                                    <Button name="duplicateQuestion" color="green">
                                                                        {t("Duplicate")}
                                                                    </Button>
                                                                );
                                                            }}
                                                        />
                                                        <ModalDelete
                                                            onValidate={handleDelete}
                                                            objectToDelete="Question"
                                                            objectType="female"
                                                            openModalButtonName="remove"
                                                            isSuccess={deleteQuestionMutation.isSuccess}
                                                            isPending={deleteQuestionMutation.isPending}
                                                            isError={deleteQuestionMutation.isError}
                                                            resetMutation={deleteQuestionMutation.reset}
                                                        />
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </Segment>
                            </Grid.Column>
                            {question?.valueType === "LISTE" && (
                                <Grid.Column width={6}>
                                    <Segment className="available-values-list">
                                        <Grid>
                                            <Grid.Row style={{paddingBottom: "10px"}}>
                                                <Grid.Column textAlign="left" verticalAlign="middle" width={12}>
                                                    <Header as="h2">{t("available values list")}</Header>
                                                </Grid.Column>

                                                <Grid.Column textAlign="right" width={4}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <Button
                                                            name="download"
                                                            onClick={handleExportData}
                                                            positive
                                                            icon
                                                        >
                                                            <Icon name="download" />
                                                        </Button>

                                                        {getVersionQueryResult.isSuccess &&
                                                            getVersionQueryResult.data.pipDate === null && (
                                                                <>
                                                                    <input
                                                                        ref={hiddenFileInput}
                                                                        type={"file"}
                                                                        hidden
                                                                        onChange={handleSetFile}
                                                                    />

                                                                    <Dropdown
                                                                        data-cy="importButton"
                                                                        style={{
                                                                            backgroundColor: "#21ba45",
                                                                            color: "white",
                                                                        }}
                                                                        icon={"upload"}
                                                                        className="icon"
                                                                        direction="left"
                                                                        button
                                                                        item
                                                                    >
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item
                                                                                onClick={() => uploadFromExcel()}
                                                                            >
                                                                                {t("importFromCSV")}
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item
                                                                                onClick={() => uploadFromGrid()}
                                                                            >
                                                                                {t("importFromGrid")}
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>

                                                                    {openEntityPicker && (
                                                                        <EntityPickerGrid
                                                                            versionId={params.versionId}
                                                                            onClickOnEntity={(
                                                                                gridCode: string,
                                                                                dimensionCode: string
                                                                            ) => {
                                                                                setIsImportValueModalOpen(true);
                                                                                setSelectedValues({
                                                                                    gridCode: gridCode,
                                                                                    dimensionCode: dimensionCode,
                                                                                });
                                                                            }}
                                                                            onCancel={clearSelectedDimension}
                                                                        />
                                                                    )}

                                                                    {isImportValueModalOpen && (
                                                                        <ModalUpdate
                                                                            hidden
                                                                            isModalOpenAtStart
                                                                            customContent={t(
                                                                                "You are about to replace the question values"
                                                                            )}
                                                                            onSuccess={clearSelectedDimension}
                                                                            onCancel={clearSelectedDimension}
                                                                            onClose={clearSelectedDimension}
                                                                            resetMutation={importValues.reset}
                                                                            isSuccess={importValues.isSuccess}
                                                                            isPending={importValues.isPending}
                                                                            isError={importValues.isError}
                                                                            error={importValues.error}
                                                                            onValidate={() =>
                                                                                selectedValues !== undefined
                                                                                    ? importValues.mutate(
                                                                                          selectedValues
                                                                                      )
                                                                                    : null
                                                                            }
                                                                        />
                                                                    )}

                                                                    {selectedFile !== null && (
                                                                        <ModalUpdate
                                                                            hidden
                                                                            isModalOpenAtStart
                                                                            customContent={t(
                                                                                "You are about to replace all question values"
                                                                            )}
                                                                            onSuccess={clearSelectedFile}
                                                                            onCancel={clearSelectedFile}
                                                                            onClose={clearSelectedFile}
                                                                            resetMutation={uploadMutation.reset}
                                                                            isSuccess={uploadMutation.isSuccess}
                                                                            isPending={uploadMutation.isPending}
                                                                            isError={uploadMutation.isError}
                                                                            error={uploadMutation.error}
                                                                            onValidate={handleMutate}
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <Divider />
                                        <SimpleList
                                            tableName="questionValues"
                                            columns={QuestionTypeListValuesTableColumns}
                                            globalData={[{key: "questionValueId", value: question.questionValues}]}
                                            rows={getQuestionStatus === "success" ? question.questionValues : []}
                                        />
                                    </Segment>
                                </Grid.Column>
                            )}
                        </Grid.Row>
                    </Grid>
                </div>
            )}
        </Container>
    );
};

export default QuestionPage;

const QuestionTypeListValuesTableColumns = [
    {
        text: "value",
        dataField: "value",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Label",
        dataField: "label",
        type: DataTypeColumnEnum.STRING,
    },
];
