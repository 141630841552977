import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {Header, Menu} from "semantic-ui-react";
import {useGetCoverageGroupsQuery} from "../../../../../Services/InsurancePackage/Queries";
import {CoverageGroup} from "../../../../../Services/InsurancePackage/Types";
import DeductibleCapitalSelectMenu from "./DeductibleCapitalSelectMenu";
import {DeductibleCapitalActiveItemType, DeductibleCapitalSelectedTypeEnum} from "./DeductibleCapitalsList";

type DeductibleCapitalMenuType = {
    insurancePackageId: string;
    activeItem: DeductibleCapitalActiveItemType | null;
    setActiveItem: React.Dispatch<React.SetStateAction<DeductibleCapitalActiveItemType | null>>;
};

const DeductibleCapitalMenu = ({
    insurancePackageId: insurancePackageId,
    activeItem,
    setActiveItem,
}: DeductibleCapitalMenuType) => {
    const getCoverageGroups = useGetCoverageGroupsQuery(insurancePackageId);

    const location = useLocation();

    useEffect(() => {
        setActiveItem(
            location.state && location.state.activeType !== null && location.state.activeId
                ? {
                      type: location.state.activeType,
                      id: location.state.activeId,
                  }
                : null
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Menu vertical fluid pointing>
            {getCoverageGroups.isSuccess &&
                getCoverageGroups.data !== undefined &&
                getCoverageGroups.data.map((coverageGroup: CoverageGroup) => (
                    <React.Fragment key={coverageGroup.id}>
                        <Menu.Item
                            name={coverageGroup.label}
                            active={
                                activeItem?.id === coverageGroup.id &&
                                activeItem?.type === DeductibleCapitalSelectedTypeEnum.COVERAGE_GROUP
                            }
                            onClick={() =>
                                setActiveItem({
                                    type: DeductibleCapitalSelectedTypeEnum.COVERAGE_GROUP,
                                    id: coverageGroup.id,
                                })
                            }
                        >
                            <Header size="medium">{coverageGroup.label}</Header>
                        </Menu.Item>
                        <Menu.Menu>
                            <DeductibleCapitalSelectMenu
                                coverageGroupId={coverageGroup.id}
                                activeItem={activeItem}
                                setActiveItem={setActiveItem}
                            />
                        </Menu.Menu>
                    </React.Fragment>
                ))}
        </Menu>
    );
};

export default DeductibleCapitalMenu;
