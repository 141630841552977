import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import ModalUpdateInsurancePackage from "../../../Pages/Products/InsurancePackage/Fragments/ModalUpdateInsurancePackage";
import {
    useDeleteInsurancePackageMutation,
    useDuplicateInsurancePackageMutation,
} from "../../../Services/InsurancePackage/Queries";
import {InsurancePackageItem} from "../../../Services/InsurancePackage/Types";
import ModalDelete from "../../Modals/ModalDelete";
import ModalDuplicate from "../../Modals/ModalDuplicate";
import FormDuplicateInsurancePackage from "./FormDuplicateInsurancePackage";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

const InsurancePackageActions: React.FC<{
    insurancePackage: InsurancePackageItem;
    isVersionValid: boolean;
}> = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const deleteInsurancePackageMutation = useDeleteInsurancePackageMutation(
        params.id,
        params.versionId,
        params.insurancePackageId
    );

    const handleInsurancePackageDelete = () => {
        deleteInsurancePackageMutation.mutate();
    };

    const duplicateInsurancePackageMutation = useDuplicateInsurancePackageMutation(
        params.versionId,
        params.insurancePackageId,
        params.id
    );
    const duplicateInsurancePackageForm = useForm<any>();
    const submitDuplicateInsurancePackageForm = (form: any) => {
        duplicateInsurancePackageMutation.mutate({
            code: form.duplicated_insurance_package_code,
            label: form.duplicated_insurance_package_label,
        });
    };

    const [duplicateInsurancePackageCode, setDuplicateInsurancePackageCode] = useState("");
    const [duplicateInsurancePackageLabel, setDuplicateInsurancePackageLabel] = useState("");
    const resetFormDuplicateInsurancePackage = () => {
        setDuplicateInsurancePackageCode("");
        setDuplicateInsurancePackageLabel("");
    };

    return (
        <>
            <Grid.Column textAlign={"right"} className={"breadcrumb-actions"}>
                <div className={"breadcrumb-actions-button-list"}>
                    {!props.isVersionValid && (
                        <div>
                            <ModalUpdateInsurancePackage insurancePackage={props.insurancePackage} />

                            <ModalDuplicate
                                isValidationDisabled={
                                    Object.keys(duplicateInsurancePackageForm.formState.errors).length !== 0
                                }
                                isPending={duplicateInsurancePackageMutation.isPending}
                                isSuccess={duplicateInsurancePackageMutation.isSuccess}
                                isError={duplicateInsurancePackageMutation.isError}
                                error={duplicateInsurancePackageMutation.error}
                                resetMutation={duplicateInsurancePackageMutation.reset}
                                onCancel={resetFormDuplicateInsurancePackage}
                                onSuccess={resetFormDuplicateInsurancePackage}
                                onValidate={duplicateInsurancePackageForm.handleSubmit(
                                    submitDuplicateInsurancePackageForm
                                )}
                                objectToDuplicate="Insurance package"
                                objectToDuplicateCodeOrLabel={props.insurancePackage.code}
                                objectToDuplicateContext="female"
                                renderModalContent={() => (
                                    <FormDuplicateInsurancePackage
                                        errors={duplicateInsurancePackageForm.formState.errors}
                                        control={duplicateInsurancePackageForm.control}
                                        defaultValueCode={duplicateInsurancePackageCode}
                                        setDefaultValueCode={setDuplicateInsurancePackageCode}
                                        defaultValueLabel={duplicateInsurancePackageLabel}
                                        setDefaultValueLabel={setDuplicateInsurancePackageLabel}
                                    />
                                )}
                                renderOpenButton={() => {
                                    return (
                                        <Button name="duplicateInsurancePackage" color="green">
                                            {t("Duplicate")}
                                        </Button>
                                    );
                                }}
                            />
                            <ModalDelete
                                onValidate={handleInsurancePackageDelete}
                                resetMutation={deleteInsurancePackageMutation.reset}
                                objectToDelete={t("Insurance package")}
                                isSuccess={deleteInsurancePackageMutation.isSuccess}
                                isPending={deleteInsurancePackageMutation.isPending}
                                isError={deleteInsurancePackageMutation.isError}
                                objectType={"female"}
                            />
                        </div>
                    )}
                </div>
            </Grid.Column>
        </>
    );
};

export default InsurancePackageActions;
