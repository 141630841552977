import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Checkbox, Divider, Form, Grid, Header, Segment} from "semantic-ui-react";
import {AuthContext} from "../../../../Components/AuthProvider/AuthProvider";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {useDeleteUserMutation, useGetUserQuery, useUpdateUserMutation} from "../../../../Services/Company/Queries";
import {Role, UserDTO} from "../../../../Services/Company/Types";
import {handleNullablePropertyAsDateTime} from "../../../../Utils/TextUtils";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {emailValidation} from "../../../Global/Form/Validation";

type UserDetailsPropsType = {
    companyId: string;
    userId: string;
};

type UserDetailsViewPropsType = {
    companyId: string;
    user: UserDTO;
    t: Function;
    toggleEditMode: () => void;
};

type UserDetailsEditPropsType = {
    companyId: string;
    user: UserDTO;
    t: Function;
    toggleEditMode: () => void;
};

const UserDetails = ({companyId, userId}: UserDetailsPropsType) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);

    const userQueryResult = useGetUserQuery(companyId, userId);
    const toggleEditMode = () => userQueryResult.data && setEditMode(!editMode);

    if (userQueryResult.data === undefined) return null;

    if (editMode)
        return (
            <UserDetailsEdit companyId={companyId} user={userQueryResult.data} t={t} toggleEditMode={toggleEditMode} />
        );
    return <UserDetailsView companyId={companyId} user={userQueryResult.data} t={t} toggleEditMode={toggleEditMode} />;
};

const UserDetailsView = ({companyId, user, toggleEditMode}: UserDetailsViewPropsType) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const deleteUserMutation = useDeleteUserMutation(companyId, String(user.id));
    const handleDelete = () => deleteUserMutation.mutate();
    const onUserDeleteSuccess = () => {
        navigate(`/transversal_settings/users`);
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("User")}
            </Header>

            <Grid verticalAlign="middle" className="page-card-padding">
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Username")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{user.username}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Email")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{user.email}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Role")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{user.role}</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Enabled")} : </strong>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox toggle checked={user.enabled} disabled />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Blocked")} : </strong>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox toggle checked={user.blocked} disabled />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Credentials Expired")} : </strong>
                    </Grid.Column>
                    <Grid.Column>
                        <Checkbox toggle checked={user.credentialsExpired} disabled />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                    <Grid.Column>
                        <strong>{t("Last login date")} : </strong>
                    </Grid.Column>
                    <Grid.Column>{handleNullablePropertyAsDateTime(user.lastLoginDate, t)}</Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />

            <div className="bottom-button-card">
                <Button primary name="edit-user" onClick={toggleEditMode}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="delete-user"
                    isSuccess={deleteUserMutation.isSuccess}
                    isPending={deleteUserMutation.isPending}
                    isError={deleteUserMutation.isError}
                    resetMutation={deleteUserMutation.reset}
                    error={deleteUserMutation.error}
                    onSuccess={onUserDeleteSuccess}
                    onValidate={handleDelete}
                    objectToDelete="User"
                    objectType="nonbinary"
                />
            </div>
        </Segment>
    );
};

const UserDetailsEdit = ({companyId, user, t, toggleEditMode}: UserDetailsEditPropsType) => {
    const {user: loggedUser, logout} = React.useContext(AuthContext);
    const [userBeforeUpdate, setUserBeforeUpdate] = useState<UserDTO | undefined>(undefined);

    const roleOptions = Object.values(Role)
        .map((role: Role, index: number) => {
            return {
                key: `role-${index}`,
                text: t(`enums.Role.${role}`),
                value: role,
            };
        })
        .filter(
            (role) =>
                role.value !== Role.GESTIONNAIRE &&
                role.value !== Role.APPORTEUR &&
                !(
                    role.value === Role.SUPER_ADMIN &&
                    !loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN")
                )
        );

    const onUserUpdateSuccess = () => {
        if (
            loggedUser !== undefined &&
            userBeforeUpdate !== undefined && 
            logout !== undefined &&
            loggedUser.username === userBeforeUpdate.username
        ) {
            logout();
        }
        toggleEditMode();
    };

    const updateUserMutation = useUpdateUserMutation(companyId, String(user.id));

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<any>({mode: "onBlur"});
    const submitForm = async (formData: any) => {
        setUserBeforeUpdate(user);
        updateUserMutation.mutate({...formData});
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("User")}
            </Header>

            <Form>
                <Grid verticalAlign="middle" className="page-card-padding">
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong className="required">{t("Username")}</strong> :
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={user.username}
                                name={"username"}
                                rules={{required: true, maxLength: 50}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        name={name}
                                        placeholder={t("Username")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(
                                            t,
                                            errors,
                                            name,
                                            undefined,
                                            undefined,
                                            undefined,
                                            undefined,
                                            50
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong className="required">{t("Email")}</strong> :
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={user.email}
                                name={"email"}
                                rules={{
                                    required: true,
                                    pattern: emailValidation(),
                                }}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        name={name}
                                        placeholder={t("Email")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        error={getErrorMessage(t, errors, name)}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong className="required">{t("Role")}</strong> :
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={user.role}
                                name={"role"}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        name={name}
                                        required
                                        placeholder={t("Role")}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={(_, {value}) => onChange(value)}
                                        error={getErrorMessage(t, errors, name)}
                                        options={roleOptions}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong className="required">{t("Blocked")}</strong> :
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={user.blocked}
                                name={"blocked"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Checkbox
                                        fluid
                                        toggle
                                        style={{verticalAlign: "middle"}}
                                        name={name}
                                        checked={value}
                                        onBlur={onBlur}
                                        onChange={(_, {checked}) => {
                                            onChange(checked);
                                        }}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <strong className="required">{t("Credentials Expired")}</strong> :
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={user.credentialsExpired}
                                name={"credentialsExpired"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Checkbox
                                        fluid
                                        toggle
                                        style={{verticalAlign: "middle"}}
                                        name={name}
                                        checked={value}
                                        onBlur={onBlur}
                                        onChange={(_, {checked}) => {
                                            onChange(checked);
                                        }}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider />

                <div className="bottom-button-card">
                    <Button name="cancel" secondary onClick={toggleEditMode}>
                        {t("Cancel")}
                    </Button>

                    <ModalUpdate
                        isModalDisabled={Object.keys(errors).length !== 0}
                        customContent={t(
                            "You are about to change user informations, if this user is yourself you will be disconnected"
                        )}
                        onValidate={handleSubmit(submitForm)}
                        onSuccess={onUserUpdateSuccess}
                        isPending={updateUserMutation.isPending}
                        isError={updateUserMutation.isError}
                        isSuccess={updateUserMutation.isSuccess}
                        resetMutation={updateUserMutation.reset}
                        error={updateUserMutation.error}
                    />
                </div>
            </Form>
        </Segment>
    );
};

export default UserDetails;
