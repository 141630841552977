import axios, {AxiosResponse} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {
    BasicGridType,
    DimensionGridDTO,
    DimensionGridDTOWithId,
    DimensionGridValueDTO,
    DimensionGridValueDTOWithId,
    PricingGridAddType,
    PricingGridViewType,
} from "./Types";

export const getPricingGrids = async (versionId: string): Promise<Array<BasicGridType>> => {
    try {
        const response = await axiosInstance.get<BasicGridType[]>(`/versions/${versionId}/grids`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getPricingGrid = async (versionId: string, pricingGridId: string) => {
    try {
        const response = await axiosInstance.get(`/versions/${versionId}/grids/${pricingGridId}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deletePricingGrid = async (versionId: string, pricingGridId: string) => {
    try {
        await axiosInstance.delete(`/versions/${versionId}/grids/${pricingGridId}`);
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updatePricingGrid = async (
    versionId: string,
    pricingGridId: string,
    label: string
): Promise<BasicGridType> => {
    try {
        const response = await axiosInstance.patch(`/versions/${versionId}/grids/${pricingGridId}?label=${label}`);

        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicatePricingGrid = async (
    versionId: string,
    gridId: string,
    code: string,
    label: string
): Promise<BasicGridType> => {
    try {
        const response = await axiosInstance.post<BasicGridType>(
            `/versions/${versionId}/grids/${gridId}/_duplicate?code=${code}&label=${label}`
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const exportPricingGrid = async (versionId: string, pricingGridId: string) => {
    try {
        const response = await axiosInstance.get(`/versions/${versionId}/grids/${pricingGridId}/_export`, {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getPricingGridView = async (versionId: string, pricingGridId: string): Promise<PricingGridViewType> => {
    try {
        const response = await axiosInstance.get(`/versions/${versionId}/grids/${pricingGridId}/_view`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const importPricingGrid = async (versionId: string, pricingGridId: string, formData: FormData) => {
    try {
        return await axiosInstance.put(`/versions/${versionId}/grids/${pricingGridId}/_import`, formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getPricingGridDimensions = async (pricingGridId: string): Promise<Array<DimensionGridDTO>> => {
    try {
        const response = await axiosInstance.get(`/grids/${pricingGridId}/dimensionGrids`);

        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getPricingGridDimension = async (gridId: string, dimensionId: string): Promise<DimensionGridDTOWithId> => {
    try {
        const response = await axiosInstance.get(`/grids/${gridId}/dimensionGrids/${dimensionId}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updatePricingGridDimensionValue = async (
    dimensionGridId: string,
    dimensionGridValueId: string,
    updatedDimensionValue: DimensionGridValueDTOWithId
): Promise<DimensionGridDTO> => {
    try {
        const response = await axiosInstance.put(
            `/dimensionGrids/${dimensionGridId}/dimensionGridValues/${dimensionGridValueId}`,
            updatedDimensionValue
        );

        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addPricingGridDimensionValue = async (dimensionGridId: string, dimensionValue: DimensionGridValueDTO) => {
    try {
        const response = await axiosInstance.post(
            `/dimensionGrids/${dimensionGridId}/dimensionGridValues`,
            dimensionValue
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deletePricingGridDimensionValue = async (
    dimensionGridId: string,
    dimensionGridValueId: string
): Promise<void> => {
    try {
        const response = await axiosInstance.delete(
            `/dimensionGrids/${dimensionGridId}/dimensionGridValues/${dimensionGridValueId}`
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deletePricingGridDimension = async (dimensionGridId: string, gridId: string) => {
    try {
        const response = await axiosInstance.delete(`/grids/${gridId}/dimensionGrids/${dimensionGridId}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addPricingGridDimension = async (gridId: string, dimension: DimensionGridDTO) => {
    try {
        const response = await axiosInstance.post<DimensionGridDTO>(`/grids/${gridId}/dimensionGrids`, dimension);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updatePricingGridDimension = async (dimension: DimensionGridDTO, gridId: string): Promise<DimensionGridDTO> => {
    try {
        const response = await axiosInstance.put<DimensionGridDTO>(
            `/grids/${gridId}/dimensionGrids/${dimension.id}`,
            dimension
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const reorderPricingGridDimension = async (
    gridId: string,
    dimensionId: number,
    rank: number
): Promise<DimensionGridDTO> => {
    try {
        const response = await axiosInstance.put<DimensionGridDTO>(
            `/grids/${gridId}/dimensionGrids/${dimensionId}/_reorder?order=${rank}`
        );
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addPricingGrid = async (versionId: string, pricingGrid: PricingGridAddType): Promise<BasicGridType> => {
    try {
        const response = await axiosInstance.post<BasicGridType>(`/versions/${versionId}/grids`, pricingGrid);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const downloadDimensionValues = async (gridId: string, dimensionGridId: string) => {
    try {
        const response = await axiosInstance.get(`/grids/${gridId}/dimensionGrids/${dimensionGridId}/_export`, {
            responseType: "arraybuffer",
        });

        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const uploadDimensionValues = async (
    formData: FormData,
    gridId: string,
    dimensionGridId: string
): Promise<AxiosResponse> => {
    try {
        const response = await axiosInstance.post(
            `/grids/${gridId}/dimensionGrids/${dimensionGridId}/_import`,
            formData,
            {
                headers: {"Content-Type": "multipart/form-data"},
            }
        );

        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const reorderDimensionValues = async (dimensionId: string, dimensionValueId: string, order: string): Promise<DimensionGridValueDTOWithId> => {
    try {
        const response = await axiosInstance.patch(`dimensionGrids/${dimensionId}/dimensionGridValues/${dimensionValueId}/_reorder?order=${order}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}

export const importValuesFromQuestionTypeListToDimension = async (gridId: string, dimensionGridId: string, questionName: string) => {
    try {
        const response = await axiosInstance.post(`/grids/${gridId}/dimensionGrids/${dimensionGridId}/_import_from_question?questionName=${questionName}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}

