import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addProductRegistryTax,
    deleteProductRegistryTax,
    getProductBehaviour,
    getProductBehaviourByRegistryId,
    getProductBehaviours,
    reorderProductRegistryTaxJoinConditionMutation,
    updateProductBehaviour,
    updateProductRegistryTax,
} from "./AxiosRequests";
import {
    ProductBehaviorDTO,
    ProductBehaviorWithRegistryDTO,
    ProductRegistryJoinDTO,
    ProductRegistryTaxJoin,
    ProductRegistryTaxJoinDTO,
} from "./Types";

export const useGetProductBehaviourQuery = (
    productId: string,
    registryJoinId: string,
    enabled: boolean = true
): UseQueryResult<ProductBehaviorWithRegistryDTO, Error> => {
    return useQuery({
        queryKey: ["productBehaviour", {productId, registryJoinId}],
        queryFn: () => getProductBehaviour(productId, registryJoinId),
        enabled,
    });
};

export const useGetProductBehavioursQuery = (
    productId: string,
    enabled: boolean = true
): UseQueryResult<Array<ProductRegistryJoinDTO>, Error> => {
    return useQuery({
        queryKey: ["productBehaviours", productId],
        queryFn: () => getProductBehaviours(productId),
        enabled,
    });
};

export const useGetProductBehaviourByRegistryIdQuery = (
    productId: string,
    registryId: number | undefined
): UseQueryResult<ProductBehaviorDTO, Error> => {
    return useQuery({
        queryKey: ["getProductBehaviorDTO", {productId: productId, registryId: registryId}],
        queryFn: () => getProductBehaviourByRegistryId(productId, String(registryId)),
        enabled: registryId !== undefined,
    });
};

export const useUpdateBehaviourDetailsMutation = (
    productId: string,
    registryJoinId: string
): UseMutationResult<ProductBehaviorDTO, CustomError, ProductBehaviorDTO, Error> => {
    return useMutation<ProductBehaviorDTO, CustomError, ProductBehaviorDTO, Error>({
        mutationFn: (editedData: ProductBehaviorDTO) => updateProductBehaviour(productId, registryJoinId, editedData),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviour", {productId, registryJoinId}]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries"]});
            await queryClient.invalidateQueries({queryKey: ["getProductBehaviorDTO"]});
        },
    });
};

/* ----------- ProductRegistryTaxJoin ----------- */
export const useUpdateProductRegistryTaxJoinMutation = (
    productId: string,
    registryJoinId: string,
    registryTaxJoinId: string,
    registryId: string
): UseMutationResult<ProductRegistryTaxJoin, CustomError, ProductRegistryTaxJoin, Error> => {
    return useMutation<ProductRegistryTaxJoin, CustomError, ProductRegistryTaxJoin, Error>({
        mutationFn: (productRegistryTaxJoin: ProductRegistryTaxJoin) =>
            updateProductRegistryTax(productId, registryJoinId, registryTaxJoinId, productRegistryTaxJoin),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviour", {productId, registryJoinId}]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries"]});
            await queryClient.invalidateQueries({queryKey: ["getProductBehaviorDTO", {productId, registryId}]});
        },
    });
};

export const useAddProductRegistryTaxJoinMutation = (
    productId: string,
    registryJoinId: string,
    registryId: string
): UseMutationResult<ProductRegistryTaxJoin, CustomError, ProductRegistryTaxJoinDTO, Error> => {
    return useMutation<ProductRegistryTaxJoin, CustomError, ProductRegistryTaxJoinDTO, Error>({
        mutationFn: (productRegistryTaxJoin: ProductRegistryTaxJoinDTO) =>
            addProductRegistryTax(productId, registryJoinId, productRegistryTaxJoin),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviour", {productId, registryJoinId}]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries"]});
            await queryClient.invalidateQueries({queryKey: ["getProductBehaviorDTO", {productId, registryId}]});
        },
    });
};

export const useDeleteProductRegistryTaxJoinMutation = (
    productId: string,
    registryJoinId: string,
    registryId: string
): UseMutationResult<void, CustomError, string, Error> => {
    return useMutation<void, CustomError, string, Error>({
        mutationFn: (registryTaxJoinId: string) =>
            deleteProductRegistryTax(productId, registryJoinId, registryTaxJoinId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviour", {productId, registryJoinId}]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries"]});
            await queryClient.invalidateQueries({queryKey: ["getProductBehaviorDTO", {productId, registryId}]});
        },
    });
};

export const useReorderProductRegistryTaxJoinCondtionMutation = (
    productId: string,
    registryJoinId: string,
    registryTaxJoinId: string,
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (order: number) => reorderProductRegistryTaxJoinConditionMutation(productId, registryJoinId, registryTaxJoinId, order),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviour", {productId, registryJoinId}]});
        },
    });
};
