import { useEffect } from "react";
import { Control, Controller, DeepMap, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckboxProps, Form, Grid, Label } from "semantic-ui-react";
import { CommissionsStateType } from "../../../../../Services/InsurancePackage/Types";
import { ProductBehaviorDTO } from "../../../../../Services/ProductBehaviour/Types";

type UpdateDerogatoryCommissionRatePropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;

    productBehaviourDTO: ProductBehaviorDTO | undefined;
    derogatoryCommissionRate: CommissionsStateType;
    setDerogatoryCommissionRate: Function;
};

const UpdateDerogatoryCommissionRate = ({
    control,
    errors,
    productBehaviourDTO,
    derogatoryCommissionRate,
    setDerogatoryCommissionRate,
}: UpdateDerogatoryCommissionRatePropsType) => {
    const {t} = useTranslation();

    const isDerogatory = (currentValue: number | null, initialValue: number | null) => {
        if (currentValue === null || currentValue === initialValue) return false;
        else return true;
    };

    const handleCommissionsInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name.substring(0, event.target.name.indexOf("-")) as "contribution" | "management";
        const value = event.target.value === "" ? null : Number(event.target.value);

        setDerogatoryCommissionRate({
            ...derogatoryCommissionRate,
            [name]: {
                ...derogatoryCommissionRate[name],
                currentValue: value,
                derogatoryChecked: isDerogatory(value, derogatoryCommissionRate[name].initialRate),
            },
        });
    };

    const handleCommissionsCheckboxChange = (
        event: React.MouseEvent<HTMLInputElement, MouseEvent>,
        data: CheckboxProps
    ) => {
        if (data.name !== undefined) {
            const name = data.name.substring(0, data.name.indexOf("-")) as "contribution" | "management";
            let isDerogatory = !derogatoryCommissionRate[name].derogatoryChecked;
            let commission;
            if (isDerogatory) {
                commission =
                    name === "contribution"
                        ? derogatoryCommissionRate.contribution.initialRate === 0
                            ? 100
                            : 0
                        : derogatoryCommissionRate.management.initialRate === 0
                        ? 100
                        : 0;
            } else {
                commission = derogatoryCommissionRate[name].initialRate;
            }
            setDerogatoryCommissionRate({
                ...derogatoryCommissionRate,
                [name]: {
                    ...derogatoryCommissionRate[name],
                    currentValue: commission,
                    derogatoryChecked: isDerogatory,
                },
            });
        }
    };

    useEffect(() => {
        const contributionRate =
            productBehaviourDTO !== undefined
                ? productBehaviourDTO.productRegistryJoin.contributionCommissionRate !== null
                    ? productBehaviourDTO.productRegistryJoin.contributionCommissionRate
                    : 0
                : 0;
        const managementRate =
            productBehaviourDTO !== undefined
                ? productBehaviourDTO.productRegistryJoin.managementCommissionRate !== null
                    ? productBehaviourDTO.productRegistryJoin.managementCommissionRate
                    : 0
                : 0;

        setDerogatoryCommissionRate({
            contribution: {
                currentValue:
                    derogatoryCommissionRate.contribution.currentValue !== null
                        ? derogatoryCommissionRate.contribution.currentValue
                        : contributionRate,
                initialRate: contributionRate,
                derogatoryChecked: isDerogatory(
                    derogatoryCommissionRate.contribution.currentValue !== null
                        ? derogatoryCommissionRate.contribution.currentValue
                        : contributionRate,
                    contributionRate
                ),
            },
            management: {
                currentValue:
                    derogatoryCommissionRate.management.currentValue !== null
                        ? derogatoryCommissionRate.management.currentValue
                        : managementRate,
                initialRate: managementRate,
                derogatoryChecked: isDerogatory(
                    derogatoryCommissionRate.management.currentValue !== null
                        ? derogatoryCommissionRate.management.currentValue
                        : managementRate,
                    managementRate
                ),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productBehaviourDTO]);

    return (
        <>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Controller
                        control={control}
                        name={"contribution-input"}
                        defaultValue={derogatoryCommissionRate.contribution.initialRate}
                        render={({field: {name, value, onChange, onBlur}}) => (
                            <Form.Input
                                fluid
                                name={name}
                                type={"number"}
                                step={"0.1"}
                                min={0}
                                max={100}
                                placeholder={t("Contribution")}
                                onChange={(e, {value}) => {
                                    onChange(value);
                                    handleCommissionsInputChange(e);
                                }}
                                onBlur={onBlur}
                                labelPosition={"right"}
                                value={derogatoryCommissionRate.contribution.currentValue}
                                maxLength={"50"}>
                                <Label basic>{t("Contribution")}</Label>
                                <input />
                                <Label>%</Label>
                            </Form.Input>
                        )}
                    />
                </Grid.Column>
                <Grid.Column width={6} verticalAlign="middle">
                    <Controller
                        control={control}
                        defaultValue={derogatoryCommissionRate.contribution.derogatoryChecked}
                        name={"contribution-switch"}
                        render={({field: {name, value}}) => (
                            <Form.Checkbox
                                toggle
                                aria-label={"contributionCommissionRadioButton"}
                                onClick={handleCommissionsCheckboxChange}
                                name={name}
                                label={t("Derogate")}
                                checked={derogatoryCommissionRate.contribution.derogatoryChecked}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={10}>
                    <Controller
                        control={control}
                        name={"management-input"}
                        defaultValue={derogatoryCommissionRate.management.initialRate}
                        render={({field: {name, value, onChange, onBlur}}) => (
                            <Form.Input
                                fluid
                                type={"number"}
                                step={"0.1"}
                                min={0}
                                max={100}
                                name={name}
                                placeholder={t("Management")}
                                onChange={(e, {value}) => {
                                    onChange(value);
                                    handleCommissionsInputChange(e);
                                }}
                                onBlur={onBlur}
                                labelPosition={"right"}
                                value={derogatoryCommissionRate.management.currentValue}
                                maxLength={"50"}>
                                <Label basic>{t("Management")}</Label>
                                <input />
                                <Label>%</Label>
                            </Form.Input>
                        )}
                    />
                </Grid.Column>
                <Grid.Column width={6} verticalAlign="middle">
                    <Controller
                        control={control}
                        defaultValue={derogatoryCommissionRate.management.derogatoryChecked}
                        name={"management-switch"}
                        render={({field: {name, value, onChange}}) => (
                            <Form.Checkbox
                                toggle
                                aria-label={"managementCommissionRadioButton"}
                                onClick={handleCommissionsCheckboxChange}
                                name={name}
                                label={t("Derogate")}
                                checked={derogatoryCommissionRate.management.derogatoryChecked}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
        </>
    );
};

export default UpdateDerogatoryCommissionRate;
