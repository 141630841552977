import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Button, Container } from "semantic-ui-react";
import Description from "../../../Components/Description/Description";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../Components/Sidebar/Sidebar";
import { useGetVariable, useGetVariablesList, useUpdateVariable } from "../../../Services/Variables/Queries";
import { Variable, VariableType } from "../../../Services/Variables/Types";
import { useGetVersionQuery } from "../../../Services/Version/Queries";
import BreadcrumbVariables from "./Fragments/BreadcrumbVariables";
import ModalAddVariable from "./Fragments/ModalAddVariable";
import VariableConditionsList from "./Fragments/VariableConditions/VariableConditionsList";

interface IParams extends Params {
    id: string;
    versionId: string;
    variableId: string;
}

const VariablePage = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [sidebarMenus, setSidebarMenus] = useState<Array<SidebarMenu>>([]);

    // Get
    const variableRequest = useGetVariable(params.versionId, params.variableId);
    const variablesListRequest = useGetVariablesList(
        params.versionId,
        variableRequest.data !== undefined ? variableRequest.data.variableType : "",
        variableRequest.data !== undefined
    );
    const versionRequest = useGetVersionQuery(params.id, params.versionId);

    // Update
    const updateVariable = useUpdateVariable(
        params.versionId,
        params.variableId,
        variableRequest.data !== undefined ? variableRequest.data.variableType : ""
    );
    const updateVariableForm = useForm<Variable>();
    const handleUpdateVariableDescription = (formData: {description: string}) => {
        const updatedVariable = {
            ...variableRequest.data,
            description: formData.description,
            variableConditions: [],
        };
        updateVariable.mutate(updatedVariable);
    };

    useEffect(() => {
        if (variablesListRequest.isSuccess) {
            const sidebarMenus = new SidebarMenu(
                "",
                variablesListRequest.data.map(
                    (variable) =>
                        new SidebarItem(
                            variable.name,
                            variable.name,
                            `/products/${params.id}/versions/${params.versionId}/variables/${variable.id}`,
                            false,
                            () => {},
                            variable.valid ? {} : {color: "red"}
                        )
                )
            );
            setSidebarMenus([sidebarMenus]);
        }

        //eslint-disable-next-line
    }, [variablesListRequest.isSuccess, variablesListRequest.data, versionRequest.data, props.sections]);

    return (
        <Container fluid>
            <Sidebar
                title={
                    variableRequest.data?.variableType === VariableType.R
                        ? t("Computing rule variables", {count: 2})
                        : variableRequest.data?.variableType === VariableType.V
                        ? t("Data variable", {count: 2})
                        : ""
                }
                menus={sidebarMenus}
                renderAdditionnalButton={
                    versionRequest.isSuccess && versionRequest.data.pipDate === null
                        ? () => (
                              <ModalAddVariable
                                  alternateOpenButton={() => (
                                      <Button primary name="new_variable" className="menu-button">
                                          {t("New entity", {
                                              entity: t("Variable").toLowerCase(),
                                              context: "female",
                                          })}
                                      </Button>
                                  )}
                                  variableType={
                                      variableRequest.isSuccess ? variableRequest.data.variableType : VariableType.V
                                  }
                              />
                          )
                        : undefined
                }
            />
            {versionRequest.data !== undefined && variableRequest.data !== undefined && variableRequest.isSuccess && (
                <BreadcrumbVariables
                    variable={variableRequest.data}
                    version={versionRequest.data}
                    sections={props.sections}
                />
            )}

            <div className="main-container">
                {variableRequest.data !== undefined && variableRequest.isSuccess && (
                    <>
                        <Description
                            entityName="Variable"
                            entityType="female"
                            description={variableRequest.data.description}
                            version={versionRequest.data}
                            form={updateVariableForm}
                            entityMutation={updateVariable}
                            handleEntityUpdate={handleUpdateVariableDescription}
                        />

                        <VariableConditionsList variable={variableRequest.data} version={versionRequest.data} />
                    </>
                )}
            </div>
        </Container>
    );
};

export default VariablePage;
