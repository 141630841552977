import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Card, Container, Form, Grid, Header, Icon, Input, Menu, Message, Segment} from "semantic-ui-react";
import {AuthContext} from "../../../Components/AuthProvider/AuthProvider";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import CardListPlaceholder from "../../../Components/Placeholder/CardListPlaceholder";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import TextOverflow from "../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../Hooks/useContainerDimensions";
import useTransverseFeatureSidebarMenus from "../../../Hooks/useTransverseFeatureSidebarMenus";
import {
    useAddCompanyProductInfo,
    useDeleteCompanyProductInfo,
    useGetCompanyProductInfos,
    useUpdateCompanyProductInfo,
} from "../../../Services/Company/Queries";
import {CompanyProductInfosType} from "../../../Services/Company/Types";
import getErrorMessage from "../../Global/Form/ErrorMessage";
import FormCompanyProductInfo from "./FormCompanyProductInfo";

const CompanyProductInfoPage: React.FC = () => {
    const {t} = useTranslation();

    const companyProductInfos = useGetCompanyProductInfos();

    const [activeItem, setActiveItem] = useState<CompanyProductInfosType | undefined>();
    const [editMode, setEditMode] = useState<Boolean>(false);
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");

    const updateCompanyProductInfoMutation = useUpdateCompanyProductInfo(String(activeItem?.id));
    const updateCompanyProductInfoForm = useForm<any>({mode: "onBlur"});
    const submitUpdateCompanyProductInfoForm = (form: any) => {
        updateCompanyProductInfoMutation.mutate({
            ...form,
        });
    };

    const addCompanyProductInfoMutation = useAddCompanyProductInfo();
    const addCompanyProductInfoForm = useForm<any>();
    const sumbitAddCompanyProductInfoForm = (form: any) => {
        addCompanyProductInfoMutation.mutate({
            ...form,
        });
    };

    const deleteCompanyProductInfoMutation = useDeleteCompanyProductInfo();

    const {user: loggedUser} = React.useContext(AuthContext);
    const menus = useTransverseFeatureSidebarMenus(
        loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN" || authority === "ADMINISTRATEUR") ||
            false
    );

    const [search, setSearch] = useState("");
    const hiddenBySearch = (label: string) => {
        return label.toLowerCase().includes(search.toLowerCase()) || search === "";
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Container fluid>
            <Sidebar title={t("transversal_settings")} menus={menus} />
            <div className="main-container">
                <Grid.Column width={12}>
                    <Segment>
                        <Menu borderless style={{border: "0", boxShadow: "none"}}>
                            <Menu.Item>
                                <Header size="large">{t("Information")}</Header>
                            </Menu.Item>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Input
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("company product infos").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                <Menu.Item name="addCompanyProductInfo">
                                    <ModalAdd
                                        isValidationDisabled={
                                            Object.keys(addCompanyProductInfoForm.formState.errors).length !== 0
                                        }
                                        isPending={addCompanyProductInfoMutation.isPending}
                                        isSuccess={addCompanyProductInfoMutation.isSuccess}
                                        isError={addCompanyProductInfoMutation.isError}
                                        error={addCompanyProductInfoMutation.error}
                                        resetMutation={addCompanyProductInfoMutation.reset}
                                        objectToAdd="Company product info"
                                        objectToAddContext="female"
                                        onValidate={addCompanyProductInfoForm.handleSubmit(
                                            sumbitAddCompanyProductInfoForm
                                        )}
                                        onCancel={() => {
                                            setDefaultValueLabel("");
                                        }}
                                        onSuccess={() => {
                                            setDefaultValueLabel("");
                                        }}
                                        iconOnOpenButton
                                        openButtonIcon="plus"
                                        renderModalContent={() => (
                                            <FormCompanyProductInfo
                                                control={addCompanyProductInfoForm.control}
                                                errors={addCompanyProductInfoForm.formState.errors}
                                                defaultValueLabel={defaultValueLabel}
                                                setDefaultValueLabel={setDefaultValueLabel}
                                            />
                                        )}
                                    />
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>

                        {companyProductInfos.status === "success" &&
                            companyProductInfos.data !== undefined &&
                            companyProductInfos.data.length !== 0 && (
                                <div
                                    className={"ui cards " + columnNumberAsText}
                                    ref={ref}
                                    data-cy="companyProductInfo">
                                    {companyProductInfos.data
                                        .slice()
                                        .filter((companyProductInfo: CompanyProductInfosType) =>
                                            hiddenBySearch(companyProductInfo.label)
                                        )
                                        .map((companyProductInfo: CompanyProductInfosType) => (
                                            <Card key={companyProductInfo.id}>
                                                {editMode && companyProductInfo.id === activeItem?.id ? (
                                                    <Card.Content>
                                                        <Form>
                                                            <Grid>
                                                                <Grid.Column width={10}>
                                                                    <Controller
                                                                        control={updateCompanyProductInfoForm.control}
                                                                        defaultValue={companyProductInfo.label}
                                                                        rules={{required: true, maxLength: 50}}
                                                                        name={"label"}
                                                                        render={({
                                                                            field: {name, value, onChange, onBlur},
                                                                        }) => (
                                                                            <Form.Input
                                                                                fluid
                                                                                required
                                                                                maxLength={50}
                                                                                type={"text"}
                                                                                name={name}
                                                                                placeholder={t("label")}
                                                                                onChange={(_, {value}) => {
                                                                                    setDefaultValueLabel(value);
                                                                                    onChange(value);
                                                                                }}
                                                                                onBlur={onBlur}
                                                                                value={value}
                                                                                error={getErrorMessage(
                                                                                    t,
                                                                                    updateCompanyProductInfoForm
                                                                                        .formState.errors,
                                                                                    "label",
                                                                                    undefined,
                                                                                    50
                                                                                )}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column width={6}>
                                                                    <Button.Group floated="right">
                                                                        <Button
                                                                            icon="cancel"
                                                                            color="black"
                                                                            onClick={() => setEditMode(false)}></Button>
                                                                        <ModalUpdate
                                                                            isPending={
                                                                                updateCompanyProductInfoMutation.isPending
                                                                            }
                                                                            isSuccess={
                                                                                updateCompanyProductInfoMutation.isSuccess
                                                                            }
                                                                            isError={
                                                                                updateCompanyProductInfoMutation.isError
                                                                            }
                                                                            error={
                                                                                updateCompanyProductInfoMutation.error
                                                                            }
                                                                            resetMutation={
                                                                                updateCompanyProductInfoMutation.reset
                                                                            }
                                                                            onSuccess={() => {
                                                                                setEditMode(false);
                                                                                setDefaultValueLabel("");
                                                                            }}
                                                                            onValidate={updateCompanyProductInfoForm.handleSubmit(
                                                                                submitUpdateCompanyProductInfoForm
                                                                            )}
                                                                            objectToUpdate="Company product info"
                                                                            objectType="nonbinary"
                                                                            iconOnOpenButton={true}
                                                                            openButtonIcon="check"
                                                                        />
                                                                    </Button.Group>
                                                                </Grid.Column>
                                                                <Grid.Column width={9}>
                                                                    <label htmlFor="saveQuote">
                                                                        <strong>{t("Save quote")} : </strong>
                                                                    </label>
                                                                </Grid.Column>
                                                                <Grid.Column width={1}>
                                                                    <Controller
                                                                        control={updateCompanyProductInfoForm.control}
                                                                        name="saveQuote"
                                                                        defaultValue={companyProductInfo?.saveQuote}
                                                                        render={({
                                                                            field: {onChange, value, name, onBlur},
                                                                        }) => (
                                                                            <Form.Checkbox
                                                                                toggle
                                                                                name={name}
                                                                                checked={value}
                                                                                onBlur={onBlur}
                                                                                onChange={(_, {checked}) => {
                                                                                    onChange(checked);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Form>
                                                    </Card.Content>
                                                ) : (
                                                    <Card.Content>
                                                        <Grid>
                                                            <Grid.Column width={10} verticalAlign="middle">
                                                                <Header as={"h4"}>
                                                                    <TextOverflow value={companyProductInfo.label} />
                                                                </Header>
                                                            </Grid.Column>
                                                            <Grid.Column
                                                                width={6}
                                                                textAlign="right"
                                                                verticalAlign="middle">
                                                                <Button.Group basic floated="right">
                                                                    <Button
                                                                        basic
                                                                        icon
                                                                        name="edit"
                                                                        onClick={() => {
                                                                            setActiveItem(companyProductInfo);
                                                                            setEditMode(true);
                                                                        }}>
                                                                        <Icon name={"edit"} color="black" />
                                                                    </Button>
                                                                    <ModalDelete
                                                                        isPending={
                                                                            deleteCompanyProductInfoMutation.isPending
                                                                        }
                                                                        isSuccess={
                                                                            deleteCompanyProductInfoMutation.isSuccess
                                                                        }
                                                                        isError={
                                                                            deleteCompanyProductInfoMutation.isError
                                                                        }
                                                                        resetMutation={
                                                                            deleteCompanyProductInfoMutation.reset
                                                                        }
                                                                        onValidate={() =>
                                                                            deleteCompanyProductInfoMutation.mutate(
                                                                                String(companyProductInfo.id)
                                                                            )
                                                                        }
                                                                        objectToDelete="Company product info"
                                                                        objectType="nonbinary"
                                                                        renderOpenButton={() => {
                                                                            return (
                                                                                <Button basic icon name="delete">
                                                                                    <Icon
                                                                                        name={"trash alternate"}
                                                                                        color="red"
                                                                                    />
                                                                                </Button>
                                                                            );
                                                                        }}
                                                                    />
                                                                </Button.Group>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Card.Description>
                                                            {t("Save quote")} :{" "}
                                                            {companyProductInfo.saveQuote ? t("yes") : t("no")}
                                                        </Card.Description>
                                                    </Card.Content>
                                                )}
                                            </Card>
                                        ))}
                                </div>
                            )}
                        {companyProductInfos.status === "success" && companyProductInfos.data?.length === 0 && (
                            <Segment style={{border: "0", boxShadow: "none"}}>
                                <Message
                                    warning
                                    header={t("No company product infos")}
                                    content={t("There is no company product info")}
                                />
                            </Segment>
                        )}
                        {companyProductInfos.isLoading && <CardListPlaceholder />}
                    </Segment>
                </Grid.Column>
            </div>
        </Container>
    );
};

export default CompanyProductInfoPage;
