import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Container, Form, Grid, Header, Icon, Menu, Segment} from "semantic-ui-react";
import {AuthContext} from "../../../Components/AuthProvider/AuthProvider";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import useTransverseFeatureSidebarMenus from "../../../Hooks/useTransverseFeatureSidebarMenus";
import {
    useAddRiskTypeMutation,
    useDeleteRiskTypeMutation,
    useRiskTypesQuery,
    useUpdateRiskTypeMutation,
} from "../../../Services/Company/Queries";
import {RiskTypeItemType} from "../../../Services/Company/Types";
import getErrorMessage from "../../Global/Form/ErrorMessage";
import {RangesList} from "../Range/RangesList";
import FormRiskType from "./FormRiskType";

const RiskTypesPage: React.FC = () => {
    const {t} = useTranslation();
    const getRiskTypesQuery = useRiskTypesQuery();

    const [activeItem, setActiveItem] = useState<RiskTypeItemType | undefined>();
    const [editMode, setEditMode] = useState<boolean>(false);

    // Add risk type
    const addRiskTypeMutation = useAddRiskTypeMutation();
    const addRiskTypeForm = useForm<{label: string}>();
    const sumbitAddRiskTypeForm = (form: {label: string}) => {
        addRiskTypeMutation.mutate({
            ...form,
        });
    };

    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");

    // Update risk type
    const updateRiskTypeMutation = useUpdateRiskTypeMutation();
    const updateRiskTypeForm = useForm<{label: string}>({mode: "onBlur"});
    const submitUpdateRiskTypeForm = (form: {label: string}) => {
        if (activeItem) {
            updateRiskTypeMutation.mutate({
                id: activeItem.id,
                label: form.label,
            });
        }
    };

    // Delete risk type
    const deleteRiskTypeMutation = useDeleteRiskTypeMutation();

    useEffect(() => {
        setEditMode(false);
        if (getRiskTypesQuery.isSuccess && getRiskTypesQuery.data) {
            if (activeItem != null) {
                setActiveItem(getRiskTypesQuery.data.find((riskType) => riskType.id === activeItem.id));
            } else {
                setActiveItem(getRiskTypesQuery.data[0]);
            }
        }
        // eslint-disable-next-line
    }, [getRiskTypesQuery.isSuccess, getRiskTypesQuery.data]);

    const {user: loggedUser} = React.useContext(AuthContext);

    const menus = useTransverseFeatureSidebarMenus(
        loggedUser?.authorities?.some((authority) => authority === "SUPER_ADMIN" || authority === "ADMINISTRATEUR") ||
            false
    );

    return (
        <Container fluid>
            <Sidebar title={t("transversal_settings")} menus={menus} />
            <div className="main-container">
                <Segment>
                    <Grid style={{margin: "1px"}}>
                        <Grid.Column width={4}>
                            <Menu fluid vertical pointing name="riskTypeMenu">
                                <Menu.Item>
                                    <Header size="medium">{t("risk_type")}</Header>
                                </Menu.Item>
                                {getRiskTypesQuery.isSuccess &&
                                    getRiskTypesQuery.data.map((item) => {
                                        if (editMode && activeItem?.id === item.id) {
                                            return (
                                                <Menu.Item
                                                    name={`riskType-${item.id}`}
                                                    key={`riskType-${item.id}`}
                                                    active={activeItem?.label === item.label}
                                                    onClick={() => setActiveItem(item)}>
                                                    <Grid>
                                                        <Grid.Column width={10} verticalAlign="middle">
                                                            <Controller
                                                                control={updateRiskTypeForm.control}
                                                                defaultValue={item.label}
                                                                rules={{required: true}}
                                                                name={"label"}
                                                                render={({field: {name, value, onChange, onBlur}}) => (
                                                                    <Form.Input
                                                                        fluid
                                                                        required
                                                                        type={"text"}
                                                                        name={name}
                                                                        placeholder={t("label")}
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        value={value}
                                                                        error={getErrorMessage(
                                                                            t,
                                                                            updateRiskTypeForm.formState.errors,
                                                                            "label"
                                                                        )}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={6} verticalAlign="middle">
                                                            <Button.Group floated="right">
                                                                <Button
                                                                    icon="cancel"
                                                                    color="black"
                                                                    name="cancel"
                                                                    onClick={() => setEditMode(false)}></Button>
                                                                <ModalUpdate
                                                                    isPending={updateRiskTypeMutation.isPending}
                                                                    isSuccess={updateRiskTypeMutation.isSuccess}
                                                                    isError={updateRiskTypeMutation.isError}
                                                                    error={updateRiskTypeMutation.error}
                                                                    resetMutation={updateRiskTypeMutation.reset}
                                                                    onValidate={updateRiskTypeForm.handleSubmit(
                                                                        submitUpdateRiskTypeForm
                                                                    )}
                                                                    objectToUpdate="risk_type"
                                                                    objectType="male"
                                                                    iconOnOpenButton={true}
                                                                    openButtonIcon="check"
                                                                />
                                                            </Button.Group>
                                                        </Grid.Column>
                                                    </Grid>
                                                </Menu.Item>
                                            );
                                        } else {
                                            return (
                                                <Menu.Item
                                                    name={`riskType-${item.id}`}
                                                    key={`riskType-${item.id}`}
                                                    active={activeItem?.label === item.label}
                                                    onClick={() => setActiveItem(item)}>
                                                    <Grid>
                                                        <Grid.Column width={10} verticalAlign="middle">
                                                            <Header as="h5" style={{wordBreak: "break-word"}}>
                                                                {item.label}
                                                            </Header>
                                                        </Grid.Column>
                                                        <Grid.Column width={6} verticalAlign="middle">
                                                            <Button.Group basic floated="right">
                                                                <Button
                                                                    name="edit"
                                                                    basic
                                                                    icon
                                                                    onClick={() => setEditMode(true)}>
                                                                    <Icon name={"edit"} color="black" />
                                                                </Button>
                                                                <ModalDelete
                                                                    isPending={deleteRiskTypeMutation.isPending}
                                                                    isSuccess={deleteRiskTypeMutation.isSuccess}
                                                                    isError={deleteRiskTypeMutation.isError}
                                                                    error={deleteRiskTypeMutation.error}
                                                                    resetMutation={deleteRiskTypeMutation.reset}
                                                                    onValidate={() =>
                                                                        deleteRiskTypeMutation.mutate(item.id)
                                                                    }
                                                                    objectToDelete="risk_type"
                                                                    objectType="male"
                                                                    renderOpenButton={() => {
                                                                        return (
                                                                            <Button basic icon name="delete">
                                                                                <Icon
                                                                                    name={"trash alternate"}
                                                                                    color="red"
                                                                                />
                                                                            </Button>
                                                                        );
                                                                    }}
                                                                />
                                                            </Button.Group>
                                                        </Grid.Column>
                                                    </Grid>
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                                <ModalAdd
                                    isValidationDisabled={Object.keys(addRiskTypeForm.formState.errors).length !== 0}
                                    isPending={addRiskTypeMutation.isPending}
                                    isSuccess={addRiskTypeMutation.isSuccess}
                                    isError={addRiskTypeMutation.isError}
                                    error={addRiskTypeMutation.error}
                                    resetMutation={addRiskTypeMutation.reset}
                                    objectToAdd="risk_type"
                                    objectToAddContext="male"
                                    onValidate={addRiskTypeForm.handleSubmit(sumbitAddRiskTypeForm)}
                                    onSuccess={() => {
                                        setDefaultValueLabel("");
                                        setActiveItem(addRiskTypeMutation.data);
                                    }}
                                    onCancel={() => setDefaultValueLabel("")}
                                    renderModalContent={() => (
                                        <FormRiskType
                                            control={addRiskTypeForm.control}
                                            errors={addRiskTypeForm.formState.errors}
                                            defaultValueLabel={defaultValueLabel}
                                            setDefaultValueLabel={setDefaultValueLabel}
                                        />
                                    )}
                                    renderOpenButton={() => {
                                        return (
                                            <Menu.Item>
                                                <Icon name="add" color="blue" />
                                                {t("Add risk type")}
                                            </Menu.Item>
                                        );
                                    }}
                                />
                            </Menu>
                        </Grid.Column>

                        {activeItem != null && <RangesList activeItem={activeItem} />}
                    </Grid>
                </Segment>
            </div>
        </Container>
    );
};

export default RiskTypesPage;
