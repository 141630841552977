export type Splitting = SplittingDTO & {
    id: number;
};

export type SplittingDTO = {
    code: string;
    label: string;
    splittingType: SplittingType;
    unit: SplittingUnit | null;
    duration: number | null;
    increaseCoef: number;
    defaultSplitting: boolean;
};

export enum SplittingType {
    A = "A",
    S = "S",
    T = "T",
    M = "M",
    X = "X",
}

export enum SplittingUnit {
    A = "A",
    M = "M",
    J = "J",
}
