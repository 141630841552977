import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {PromotionalCampaign} from "./Types";

export const getPromotionalCampaigns = async (productId: string): Promise<Array<PromotionalCampaign>> => {
    try {
        const response = await axiosInstance.get<PromotionalCampaign[]>(`/products/${productId}/promotional_campaigns`);
        return response.data;
    } catch (e: any) {
        throw buildCustomError(e);
    }
};

export const getPromotionalCampaign = async (
    productId: string,
    promotionalCampaignId: string
): Promise<PromotionalCampaign> => {
    try {
        const response = await axiosInstance.get<PromotionalCampaign>(
            `/products/${productId}/promotional_campaigns/${promotionalCampaignId}`
        );
        return response.data;
    } catch (e: any) {
        throw buildCustomError(e);
    }
};

export const addPromotionalCampaign = async (
    productId: string,
    promotionalCampagin: PromotionalCampaign
): Promise<PromotionalCampaign> => {
    try {
        const response = await axiosInstance.post<PromotionalCampaign>(
            `/products/${productId}/promotional_campaigns`,
            promotionalCampagin
        );
        return response.data;
    } catch (e: any) {
        throw buildCustomError(e);
    }
};

export const updatePromotionalCampaign = async (
    productId: string,
    promotionalCampaignId: string,
    promotionalCampagin: PromotionalCampaign
): Promise<PromotionalCampaign> => {
    try {
        const response = await axiosInstance.put<PromotionalCampaign>(
            `/products/${productId}/promotional_campaigns/${promotionalCampaignId}`,
            promotionalCampagin
        );
        return response.data;
    } catch (e: any) {
        throw buildCustomError(e);
    }
};

export const deletePromotionalCampaign = async (productId: string, promotionalCampaignId: string): Promise<void> => {
    try {
        const response = await axiosInstance.delete(
            `/products/${productId}/promotional_campaigns/${promotionalCampaignId}`
        );
        return response.data;
    } catch (e: any) {
        throw buildCustomError(e);
    }
};

