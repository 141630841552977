import React, { useLayoutEffect, useState } from "react";

type PropsType = {
    value?: string | undefined | null;
    element?: JSX.Element;
    inline?: boolean;
    additionalStyle?: object;
};

// Rendering something like : style={{textOverflow: "fade", overflow: "hidden"}}
export default function TextOverflow({value, element, inline = false, additionalStyle = {}}: PropsType) {
    const ref = React.useRef<HTMLSpanElement>(null);
    const [overflowed, setOverflowed] = useState<boolean>(false);

    useLayoutEffect(() => {
        function handleResize() {
            if (
                ref.current?.parentElement?.offsetWidth &&
                ref.current?.offsetWidth &&
                ref.current?.parentElement?.offsetWidth <= ref.current?.offsetWidth
            )
                setOverflowed(true);
            else setOverflowed(false);
        }

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (value === undefined)
        return overflowed ? (
            <div
                style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    ...additionalStyle,
                }}>
                <span ref={ref}>{element}</span>
            </div>
        ) : inline ? (
            <span style={additionalStyle} ref={ref}>
                {element}
            </span>
        ) : (
            <div>
                <span style={additionalStyle} ref={ref}>
                    {element}
                </span>
            </div>
        );

    return overflowed ? (
        <div
            style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                ...additionalStyle,
            }}
            title={value || undefined}>
            <span ref={ref}>{value}</span>
        </div>
    ) : inline ? (
        <span style={additionalStyle} ref={ref}>
            {value}
        </span>
    ) : (
        <div>
            <span style={additionalStyle} ref={ref}>
                {value}
            </span>
        </div>
    );
}
