import {ProductRecap} from "../../../Services/Product/Types";
import {buildCustomError} from "../../../Services/RequestUtils";
import axiosInstance from "../Generation.axios.instance";
import {ESelectionMatrixDTO, ESelectionMatrixExportDTO} from "./Types";

export const getESelectionEligibleProducts = async (): Promise<Array<ProductRecap>> => {
    try {
        const response = await axiosInstance.get<Array<ProductRecap>>("/e_selection/products/_eligible");
        return response.data;
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const getESelectionMatrixDTO = async (productId: string): Promise<ESelectionMatrixDTO> => {
    try {
        const response = await axiosInstance.get<ESelectionMatrixDTO>(`/products/${productId}/e_selection`);
        return response.data;
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const getESelectionMatrixExportDTO = async (productId: string): Promise<ESelectionMatrixExportDTO> => {
    try {
        const response = await axiosInstance.get<ESelectionMatrixExportDTO>(`/products/${productId}/e_selection/_view`);
        return response.data;
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const createESelectionMatrix = async (productId: string, eSelectionMatrixDTO: ESelectionMatrixDTO) => {
    try {
        const response = await axiosInstance.post(`/products/${productId}/e_selection`, eSelectionMatrixDTO);
        return response.data;
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const updateESelectionMatrix = async (productId: string, eSelectionMatrixDTO: ESelectionMatrixDTO) => {
    try {
        const response = await axiosInstance.put(`/products/${productId}/e_selection`, eSelectionMatrixDTO);
        return response.data;
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const deleteESelectionMatrix = async (productId: string): Promise<void> => {
    try {
        await axiosInstance.delete(`/products/${productId}/e_selection`);
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const exportESelectionMatrix = async (productId: string): Promise<unknown> => {
    try {
        const response = await axiosInstance.get(`/products/${productId}/e_selection/_export`, {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

export const importESelectionMatrix = async (productId: string, formData: FormData): Promise<void> => {
    try {
        return await axiosInstance.post(`/products/${productId}/e_selection/_import`, formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    } catch (e) {
        throw buildCustomError(e as any);
    }
};

