import {UseQueryResult, useMutation, UseMutationResult, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addProduct,
    deleteProductApi,
    duplicateProduct,
    editProductApi,
    getProductDetails,
    getProducts,
    getProductsByRiskType,
} from "./AxiosRequests";
import {EditedProductDetailsType, NewProductDTO, ProductDetailsType, ProductRecap, ProductsByRiskType} from "./Types";

export const useGetProductDetailsQuery = (
    productId: string,
    enabled: boolean = true
): UseQueryResult<ProductDetailsType, Error> => {
    return useQuery({
        queryKey: ["productDetails", productId],
        queryFn: () => getProductDetails(productId),
        enabled,
    });
};

export const useGetProductsByRiskTypeQuery = (): UseQueryResult<Array<ProductsByRiskType>, Error> => {
    return useQuery({
        queryKey: ["productsByRiskType"],
        queryFn: async () => getProductsByRiskType(),
    });
};

export const useGetProducts = (): UseQueryResult<ProductRecap[], Error> => {
    return useQuery({
        queryKey: ["getProducts"],
        queryFn: async () => getProducts(),
    });
};

export const useAddProductMutation = (): UseMutationResult<ProductDetailsType, CustomError, NewProductDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<ProductDetailsType, CustomError, NewProductDTO, Error>({
        mutationFn: (product: NewProductDTO) => addProduct(product),
        onSuccess: async (result) => {
            navigate(`/products/${result.id}`);
            await queryClient.invalidateQueries({queryKey: ["productsByRiskType"]});
        },
    });
};

export const useUpdateProductMutation = (
    productId: string
): UseMutationResult<ProductDetailsType, CustomError, EditedProductDetailsType, Error> => {
    return useMutation<ProductDetailsType, CustomError, EditedProductDetailsType, Error>({
        mutationFn: (editedProduct: EditedProductDetailsType) => editProductApi(productId, editedProduct),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productDetails", productId]});
            await queryClient.invalidateQueries({queryKey: ["productsByRiskType"]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionEligibleProducts"]});
        },
    });
};

export const useDeleteProductMutation = (productId: string): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteProductApi(productId),
        onSuccess: async () => {
            queryClient.removeQueries();
            await queryClient.invalidateQueries({queryKey: ["productsByRiskType"]});
        },
    });
};

export const useDuplicateProductMutation = (
    productId: string
): UseMutationResult<
    ProductDetailsType,
    CustomError,
    {code: string; label: string; workingVersion: boolean},
    Error
> => {
    const navigate = useNavigate();
    return useMutation<ProductDetailsType, CustomError, {code: string; label: string; workingVersion: boolean}, Error>({
        mutationFn: (data: {code: string; label: string; workingVersion: boolean}) =>
            duplicateProduct(productId, data.code, data.label, data.workingVersion),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["productsByRiskType"]});
            navigate(`/products/${result.id}`);
        },
    });
};
