import { Container, Image, List, Segment } from "semantic-ui-react";
import AnteniaLogo from "../../Assets/logo-principal/logo-principal-stripped.png";

const Footer = () => (
    <Segment secondary vertical>
        <Container textAlign="center">
            <Image src={AnteniaLogo} size="small" centered />
            <List horizontal divided link size="small">
                <List.Item as="a" href="https://antenia.com">
                    © Antenia 2020. Tout droits réservés
                </List.Item>
            </List>
        </Container>
    </Segment>
);

export default Footer;
