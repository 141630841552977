import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Form, Grid, Header, Segment} from "semantic-ui-react";
import {v4} from "uuid";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import getErrorMessage from "../../../../Pages/Global/Form/ErrorMessage";
import {
    useDeleteApplicationAccessMutation,
    useGetApplicationAccessQuery,
    useGetSelfCompanyQuery,
    useGetUserGroupsQuery,
    useUpdateApplicationAccessMutation,
} from "../../../../Services/Company/Queries";
import {ApplicationAccessAddUpdateDTO, ApplicationAccessDTO} from "../../../../Services/Company/Types";

type ApplicationAccessDetailsPropsType = {
    companyId: string;
    applicationAccessId: string;
};

type ApplicationAccessDetailsViewPropsType = {
    companyId: string;
    applicationAccessId: string;
    applicationAccess: ApplicationAccessDTO;
    t: Function;
    toggleEditMode: () => void;
};

type ApplicationAccessDetailsEditPropsType = {
    companyId: string;
    applicationAccessId: string;
    applicationAccess: ApplicationAccessDTO;
    t: Function;
    toggleEditMode: () => void;
};

const ApplicationAccessDetails = ({companyId, applicationAccessId}: ApplicationAccessDetailsPropsType) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);

    const applicationAccessQueryResult = useGetApplicationAccessQuery(
        companyId,
        applicationAccessId,
        companyId !== undefined
    );

    const toggleEditMode = () => applicationAccessQueryResult.data && setEditMode(!editMode);

    if (applicationAccessQueryResult.data === undefined) return null;

    if (editMode)
        return (
            <ApplicationAccessDetailsEdit
                companyId={companyId}
                applicationAccessId={applicationAccessId}
                applicationAccess={applicationAccessQueryResult.data}
                t={t}
                toggleEditMode={toggleEditMode}
            />
        );

    return (
        <ApplicationAccessDetailsView
            companyId={companyId}
            applicationAccessId={applicationAccessId}
            applicationAccess={applicationAccessQueryResult.data}
            t={t}
            toggleEditMode={toggleEditMode}
        />
    );
};

const ApplicationAccessDetailsView = ({
    companyId,
    applicationAccessId,
    applicationAccess,
    t,
    toggleEditMode,
}: ApplicationAccessDetailsViewPropsType) => {
    const navigate = useNavigate();

    const deleteApplicationAccessMutation = useDeleteApplicationAccessMutation(companyId, applicationAccessId);

    const handleDelete = () => {
        deleteApplicationAccessMutation.mutate();
    };

    const onApplicationAccessDeleteSuccess = () => {
        navigate(`/transversal_settings/rest_api_access`, {state: {activeTabIndex: 0}});
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("Partner")}
            </Header>

            <Grid columns="equal" className="grid-padding">
                <Grid.Row role="row">
                    <Grid.Column width={6} role="cell">
                        <strong data-cy="name">{t("Name")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell">
                        {applicationAccess.username}
                    </Grid.Column>
                    {applicationAccess.identifier ? (
                        <>
                            <Grid.Column width={6} role="cell">
                                <strong data-cy="identifier">{t("Identifier")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10} role="cell">
                                {applicationAccess.identifier}
                            </Grid.Column>
                        </>
                    ) : (
                        <>
                            <Grid.Column width={6} role="cell">
                                <strong data-cy="token">{t("Token")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={10} role="cell">
                                {applicationAccess.token}
                            </Grid.Column>
                        </>
                    )}

                    <Grid.Column width={6} role="cell">
                        <strong data-cy="role">{t("Role")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell">
                        {applicationAccess.role}
                    </Grid.Column>
                    <Grid.Column width={6} role="cell">
                        <strong data-cy="group">{t("Group")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell">
                        {applicationAccess.groupName}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="bottom-button-card">
                <Divider />
                <Button primary name="editApplicationAccess" onClick={toggleEditMode}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteApplicationAccess"
                    isSuccess={deleteApplicationAccessMutation.isSuccess}
                    isPending={deleteApplicationAccessMutation.isPending}
                    isError={deleteApplicationAccessMutation.isError}
                    resetMutation={deleteApplicationAccessMutation.reset}
                    onSuccess={onApplicationAccessDeleteSuccess}
                    onValidate={handleDelete}
                    objectToDelete="Partner"
                    objectType="male"
                />
            </div>
        </Segment>
    );
};

const ApplicationAccessDetailsEdit = ({
    companyId,
    applicationAccessId,
    applicationAccess,
    t,
    toggleEditMode,
}: ApplicationAccessDetailsEditPropsType) => {
    const {
        control,
        handleSubmit,
        formState: {errors},
        setValue,
    } = useForm<any>({mode: "onBlur"});

    const companyQueryResult = useGetSelfCompanyQuery();
    const {data, status} = useGetUserGroupsQuery(
        String(companyQueryResult?.data?.id),
        companyQueryResult?.data !== undefined
    );

    const handleChange = (onchange: (param: string) => void, value: any) => {
        value = formatToken(value);
        setValue("token", value);
        onchange(value);
    };

    const [selectedGroups, setSelectedGroups] = useState<any>([]);
    const [groupSelected, setGroupSelected] = useState<any>(0);
    const roleOptions = [
        {key: 0, value: "GESTIONNAIRE", text: t("Manager")},
        {key: 1, value: "APPORTEUR", text: t("BusinessProvider")},
    ];

    const formatToken = (value: string) => {
        let valueInit = value.replaceAll("-", "").substring(0, 32);

        let valueReturn = valueInit.substring(0, 8);
        if (valueInit.length > 8) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(8, 12);
        if (valueInit.length > 12) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(12, 16);
        if (valueInit.length > 16) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(16, 20);
        if (valueInit.length > 20) {
            valueReturn += "-";
        }
        valueReturn += valueInit.substring(20, 32);

        setValue("token", valueReturn);
        return valueReturn;
    };

    const handleSelect = (value: any, onChange: any) => {
        const foundItem = data?.find((item: any) => item.id === value);
        setGroupSelected(foundItem?.id);
        onChange(foundItem?.id);
    };

    const onApplicationAccessUpdateSuccess = () => {
        toggleEditMode();
    };

    useEffect(() => {
        if (status === "success" && data !== undefined) {
            const foundOptions = data.map((item: any) => ({
                text: item.name,
                value: item.id,
                key: item.id,
            }));
            setSelectedGroups([{text: "Selectionner...", value: null}, ...foundOptions]);

            const foundItem = data?.find((item: any) => item.name === applicationAccess.groupName);
            if (foundItem !== undefined) {
                setGroupSelected(foundItem.id);
            }
        }
    }, [data, status, applicationAccess]);

    const updateApplicationAccessMutation = useUpdateApplicationAccessMutation(companyId, applicationAccessId);

    const submitForm = async (formData: any) => {
        const applicationAccessUpdate: ApplicationAccessAddUpdateDTO = {
            username: formData.name,
            identifier: formData.authentMode === "bearerToken" ? null : formData.identifier,
            token: formData.authentMode === "bearerToken" ? formData.token : null,
            role: formData.role,
            groupId: groupSelected,
        };

        updateApplicationAccessMutation.mutate(applicationAccessUpdate);
    };

    const [authentMode, setAutentMode] = useState<"oAuth2" | "bearerToken">(
        applicationAccess.identifier?.length > 0 ? "oAuth2" : "bearerToken"
    );
    const [currentIdentifier, setCurrentIdentifier] = useState<any>(
        applicationAccess.identifier !== null ? applicationAccess.identifier : applicationAccess.username.slice()
    );
    const AuthentModeOptions = [
        {key: 1, value: "bearerToken", text: "Bearer token"},
        {key: 2, value: "oAuth2", text: "oAuth 2"},
    ];

    const changeAuthentMode = (value: any, onChange: any) => {
        if (
            value === "oAuth2" &&
            currentIdentifier.length === 0 &&
            authentMode === "bearerToken" &&
            applicationAccess.username &&
            applicationAccess.username.length > 0
        ) {
            const userNameSliced = applicationAccess.username.slice();
            setCurrentIdentifier(userNameSliced);
        }

        setAutentMode(value);
        onChange(value);
    };
    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("Partner")}
            </Header>
            <Form>
                <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <strong data-cy="nameEdit">{t("Name")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={control}
                                defaultValue={applicationAccess.username}
                                name={"name"}
                                rules={{required: true}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        name={name}
                                        placeholder={t("Username")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={errors.username && {content: t("this field is required")}}
                                    />
                                )}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <strong data-cy="authentModeEdit">{t("RestApiAccessAuthentMode")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={authentMode}
                                name={"authentMode"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        name={name}
                                        required
                                        onBlur={onBlur}
                                        placeholder={t("RestApiAccessAuthentMode")}
                                        onChange={(_, {value}) => {
                                            changeAuthentMode(value, onChange);
                                        }}
                                        value={authentMode}
                                        error={getErrorMessage(t, errors, "authentMode")}
                                        options={AuthentModeOptions}
                                    />
                                )}
                            />
                        </Grid.Column>
                        {authentMode === "oAuth2" ? (
                            <>
                                <Grid.Column width={6}>
                                    <strong data-cy="identifierEdit">{t("Identifier")} * : </strong>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Controller
                                        control={control}
                                        defaultValue={currentIdentifier}
                                        rules={{required: true}}
                                        name={"identifier"}
                                        render={({field: {name, value, onChange, onBlur}}) => (
                                            <Form.Input
                                                fluid
                                                required
                                                type={"text"}
                                                name={name}
                                                onChange={(_, {value}) => onChange(value)}
                                                onBlur={onBlur}
                                                value={value}
                                                error={getErrorMessage(t, errors, "identifier")}
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </>
                        ) : (
                            <>
                                <Grid.Column width={6}>
                                    <strong data-cy="tokenEdit">{t("Token")} * : </strong>
                                </Grid.Column>
                                <Grid.Column width={10}>
                                    <Controller
                                        control={control}
                                        defaultValue={
                                            applicationAccess.token !== null && applicationAccess.token
                                                ? applicationAccess.token
                                                : formatToken(v4())
                                        }
                                        rules={{required: true, maxLength: 36, minLength: 36}}
                                        name={"token"}
                                        render={({field: {name, value, onChange, onBlur}}) => (
                                            <Form.Input
                                                fluid
                                                required
                                                type={"text"}
                                                name={name}
                                                placeholder="00000000-0000-0000-0000-00000000000"
                                                onChange={(_, {value}) => {
                                                    handleChange(onChange, value);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                maxLength={36}
                                                error={getErrorMessage(t, errors, "token", 36, 36)}
                                            />
                                        )}
                                    />
                                </Grid.Column>
                            </>
                        )}

                        <Grid.Column width={6}>
                            <strong data-cy="roleEdit">{t("Role")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={applicationAccess.role}
                                name={"role"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        name={name}
                                        required
                                        onBlur={onBlur}
                                        placeholder={t("Role")}
                                        onChange={(_, {value}) => onChange(value)}
                                        value={value}
                                        error={errors.role && {content: t("this field is required")}}
                                        options={roleOptions}
                                    />
                                )}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <strong data-cy="groupEdit">{t("Group")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Controller
                                control={control}
                                rules={{required: true}}
                                defaultValue={groupSelected}
                                name={"selectedItem"}
                                render={({field: {name, onChange, onBlur}}) => (
                                    <Form.Select
                                        required
                                        name={name}
                                        value={groupSelected}
                                        onBlur={onBlur}
                                        placeholder={t("Group")}
                                        onChange={(_, {value}) => handleSelect(value, onChange)}
                                        error={errors.selectedItem && {content: t("this field is required")}}
                                        options={selectedGroups}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className="bottom-button-card">
                    <Divider />
                    <Button name="cancel" secondary onClick={toggleEditMode}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        isModalDisabled={Object.keys(errors).length !== 0}
                        customContent={t("You are about to change partner informations")}
                        onValidate={handleSubmit(submitForm)}
                        onSuccess={onApplicationAccessUpdateSuccess}
                        isPending={updateApplicationAccessMutation.isPending}
                        isError={updateApplicationAccessMutation.isError}
                        isSuccess={updateApplicationAccessMutation.isSuccess}
                        resetMutation={updateApplicationAccessMutation.reset}
                        error={updateApplicationAccessMutation.error}
                    />
                </div>
            </Form>
        </Segment>
    );
};

export default ApplicationAccessDetails;
