/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Breadcrumb} from "semantic-ui-react";
import {useGetInsurancePackageQuery} from "../../../Services/InsurancePackage/Queries";
import {useGetPricingGridViewQuery} from "../../../Services/PricingGrid/Queries";
import {useGetProductDetailsQuery} from "../../../Services/Product/Queries";
import {useGetQuestionQuery} from "../../../Services/Question/Queries";
import {useGetVariable} from "../../../Services/Variables/Queries";
import {VariableType} from "../../../Services/Variables/Types";
import {useGetVersionQuery} from "../../../Services/Version/Queries";

type SectionsType = {
    sections: Array<{name: string; path: string}>;
    variableType?: VariableType;
};

const Sections = ({sections, variableType}: SectionsType) => {
    const {t} = useTranslation();

    const preparedSections = getValuesFromSection(sections);

    return (
        <Breadcrumb>
            {preparedSections &&
                preparedSections.map((section, key: number) => {
                    let tabIndex;
                    if (key + 1 < sections.length) {
                        const nextSection = sections[key + 1];

                        if (nextSection.name === "insurance-package") tabIndex = 6;
                        else if (nextSection.name === "variables") tabIndex = variableType === VariableType.R ? 5 : 4;
                        else if (nextSection.name === "pricing-criteria") tabIndex = 2;
                        else if (nextSection.name === "question") tabIndex = 1;
                        else tabIndex = 0;
                    }

                    return sections.length === key + 1 ? (
                        <React.Fragment key={key}>
                            <Breadcrumb.Section>{t("sections." + section.name)}</Breadcrumb.Section>
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={key}>
                            <Breadcrumb.Section
                                as={Link}
                                to={{
                                    pathname: section.path,
                                    state: {activeTabIndex: tabIndex},
                                }}
                                className="ariane-link"
                                data-tooltip={t(`sections.${sections[key].name}`)}
                                data-position="bottom center"
                            >
                                {t(section.name)}
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider icon="right chevron" />
                        </React.Fragment>
                    );
                })}
        </Breadcrumb>
    );
};

export default Sections;

const getValuesFromSection = (sections: Array<{name: string; path: string}>) => {
    return [
        ...sections.map((section: {name: string; path: string}, key: number) => {
            let sectionName;
            let request;
            const ids = section.name !== "/" ? section.path.split("/").filter(Number) : "0";

            if (key !== sections.length - 1) {
                switch (section.name) {
                    case "product":
                        request = useGetProductDetailsQuery(ids[0]);
                        sectionName = request.data?.label;
                        break;
                    case "version":
                        request = useGetVersionQuery(ids[0], ids[1]);
                        sectionName = request.data?.label;
                        break;
                    case "pricing-grid":
                        request = useGetPricingGridViewQuery(ids[1], ids[2]);
                        sectionName = request.data?.label;
                        break;
                    case "question":
                        request = useGetQuestionQuery(ids[1], ids[2]);
                        sectionName = request.data?.name;
                        break;
                    case "variable":
                        request = useGetVariable(ids[1], ids[2]);
                        sectionName = request.data?.name;
                        break;
                    case "insurance-package":
                        request = useGetInsurancePackageQuery(ids[1], ids[2]);
                        sectionName = request.data?.label;
                        break;
                    default:
                        sectionName = section.name;
                }
            }

            return {...section, name: sectionName || section.name};
        }),
    ];
};
