import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    Container,
    Divider,
    Form,
    Grid,
    Header,
    Icon,
    Menu,
    Message,
    Modal,
    Table,
    Transition
} from "semantic-ui-react";
import ModalDelete from "../../Components/Modals/ModalDelete";
import { HealthProductFormContext } from "./HealthProductFormContext";
import { FormValidationType, INSURANCE_PACKAGES_STEP_NAME } from "./InputsByStepHealthProductForm";

type InsurancePackageType = {
    index: number;
    code: string;
    label: string;
    startDate: string;
    endDate: string | null;
    coveragesCode: string[];
    options: string[];
};

export const coveragesOptions = [
    {label: "Insurance", value: "ASSE", ageGroupsPropertyName: "assuranceSlices"},
    {label: "Assistance", value: "ASS", ageGroupsPropertyName: "assistanceSlices"},
    {label: "Legal protection", value: "PJ", ageGroupsPropertyName: "protectionJuridiqueSlices"},
    {label: "Santéclair", value: "SC", ageGroupsPropertyName: "santeClairSlices"},
    {label: "Association fees", value: "FRAISASSOC", ageGroupsPropertyName: "fraisAssociationSlices"},
    {label: "Adhesion fees", value: "FRAISADH", ageGroupsPropertyName: ""},
    {label: "Alternative medicine option", value: "MEDDOUCE", ageGroupsPropertyName: "medecineDouceSlices"},
];

const InsurancePackageForm: React.FC = () => {
    const {t} = useTranslation();
    const {state, validate, displayError, resetErrors, stateChange} = useContext(HealthProductFormContext);

    const options: string[] = state.options;
    const initialInsurancePackage = {
        index: 0,
        code: "",
        label: "",
        startDate: state.effectiveDate || "",
        endDate: null,
        coveragesCode: [],
        options: [],
    };
    const [insurancePackages, setInsurancePackages] = useState<InsurancePackageType[]>(state.insurancePackages);
    const [selectedInsurancePackage, setSelectedInsurancePackage] = useState<InsurancePackageType>(
        initialInsurancePackage
    );
    const [tmpSelectedInsurancePackage, setTmpSelectedInsurancePackage] = useState<{
        open: boolean;
        tmpInsurancePackage: InsurancePackageType | undefined;
    }>({
        open: false,
        tmpInsurancePackage: undefined,
    });
    const [insurancePackageChanged, setInsurancePackageChanged] = useState(false);

    const handleAddInsurancePackage = (e: any) => {
        e.preventDefault();

        if (validate(INSURANCE_PACKAGES_STEP_NAME, selectedInsurancePackage, FormValidationType.IN_STEP_VALIDATION)) {
            const modifiedCurrentInsurancePackage = selectedInsurancePackage;
            let modifiedInsurancePackages;

            if (modifiedCurrentInsurancePackage.index === 0) {
                modifiedCurrentInsurancePackage.index = insurancePackages.length + 1;

                modifiedInsurancePackages = [...insurancePackages, modifiedCurrentInsurancePackage];
            } else {
                modifiedInsurancePackages = insurancePackages.map((insurancePackage) => {
                    if (insurancePackage.index === modifiedCurrentInsurancePackage.index) {
                        return modifiedCurrentInsurancePackage;
                    } else {
                        return insurancePackage;
                    }
                });
            }

            setInsurancePackages(modifiedInsurancePackages);
            setInsurancePackageChanged(false);
            setSelectedInsurancePackage(initialInsurancePackage);
            stateChange("insurancePackages", modifiedInsurancePackages);
        }
    };

    const handleChangeInsurancePackage = (_: React.ChangeEvent<HTMLInputElement>, data: any) => {
        if (!insurancePackageChanged) setInsurancePackageChanged(true);
        setSelectedInsurancePackage({...selectedInsurancePackage, [data.name]: data.value});
    };

    const handleChangeInsurancePackageCoverages = (_: React.MouseEvent<HTMLInputElement>, data: any) => {
        if (!insurancePackageChanged) setInsurancePackageChanged(true);
        let coveragesCode = Array.from(selectedInsurancePackage.coveragesCode);
        if (data.checked) {
            coveragesCode.push(data.value);
        } else coveragesCode = coveragesCode.filter((code: string) => code !== data.value);
        setSelectedInsurancePackage({...selectedInsurancePackage, coveragesCode: coveragesCode});
    };

    const handleChangeInsurancePackageOptions = (_: React.MouseEvent<HTMLInputElement>, data: any) => {
        if (!insurancePackageChanged) setInsurancePackageChanged(true);
        let options = Array.from(selectedInsurancePackage.options);
        if (data.checked) {
            options.push(data.value);
        } else options = options.filter((code: string) => code !== data.value);
        setSelectedInsurancePackage({...selectedInsurancePackage, options: options});
    };

    const handleChangeCurrentInsurancePackage = (index: number | undefined, forceToSwitch: boolean = false) => {
        if (insurancePackageChanged && !forceToSwitch) {
            setTmpSelectedInsurancePackage({
                open: true,
                tmpInsurancePackage:
                    index === undefined
                        ? initialInsurancePackage
                        : insurancePackages.find((insurancePackage) => insurancePackage.index === index),
            });
        } else {
            resetErrors();
            const insurancePackage = insurancePackages.find((insurancePackage) => insurancePackage.index === index);
            setSelectedInsurancePackage(insurancePackage || initialInsurancePackage);
            setInsurancePackageChanged(false);
            setTmpSelectedInsurancePackage({open: false, tmpInsurancePackage: undefined});
        }
    };

    const handleRemoveInsurancePackage = () => {
        let modifiedInsurancePackages = insurancePackages
            .filter((insurancePackage) => insurancePackage.index !== selectedInsurancePackage.index)
            .map((insurancePackage, key) => {
                return {...insurancePackage, index: (insurancePackage.index = key + 1)};
            });

        setInsurancePackages(modifiedInsurancePackages);
        stateChange("insurancePackages", modifiedInsurancePackages);
        setSelectedInsurancePackage(initialInsurancePackage);
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Menu pointing vertical>
                        {insurancePackages.map((insurancePackage) => (
                            <Menu.Item
                                key={insurancePackage.index}
                                onClick={() => handleChangeCurrentInsurancePackage(insurancePackage.index)}
                                active={selectedInsurancePackage.index === insurancePackage.index}>
                                {/*TODO: Handle too long text with css or semantic ui solution*/}
                                <h4>{`${insurancePackage.code} - ${insurancePackage.label}`}</h4>
                            </Menu.Item>
                        ))}
                        <Menu.Item
                            onClick={() => handleChangeCurrentInsurancePackage(undefined)}
                            active={selectedInsurancePackage.index === 0}>
                            <h4>{t("New insurance package")}</h4>
                        </Menu.Item>
                        <Modal
                            size={"tiny"}
                            open={tmpSelectedInsurancePackage.open}
                            onClose={() =>
                                setTmpSelectedInsurancePackage({open: false, tmpInsurancePackage: undefined})
                            }>
                            <Modal.Header>{t("Would you like to continue ?")}</Modal.Header>
                            <Modal.Content>
                                {t(
                                    "In order not to lose the changes please validate your changes before changing the insurance package"
                                )}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    secondary
                                    onClick={() =>
                                        setTmpSelectedInsurancePackage({open: false, tmpInsurancePackage: undefined})
                                    }>
                                    {t("Go back to changes")}
                                </Button>
                                <Button
                                    negative
                                    onClick={() =>
                                        handleChangeCurrentInsurancePackage(
                                            tmpSelectedInsurancePackage.tmpInsurancePackage?.index,
                                            true
                                        )
                                    }>
                                    {t("Continue without validation")}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Menu>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            width={4}
                            type="text"
                            maxLength="10"
                            name="code"
                            label={t("Insurance package code")}
                            placeholder={t("Insurance package code")}
                            value={selectedInsurancePackage.code}
                            onChange={handleChangeInsurancePackage}
                            error={displayError("code")}
                        />
                        <Form.Input
                            fluid
                            required
                            width={12}
                            type="text"
                            maxLength="50"
                            name="label"
                            label={t("Insurance package label")}
                            placeholder={t("Insurance package label")}
                            value={selectedInsurancePackage.label}
                            onChange={handleChangeInsurancePackage}
                            error={displayError("label")}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Input
                            fluid
                            required
                            type="date"
                            label={t("Commercialization start date")}
                            name="startDate"
                            onChange={handleChangeInsurancePackage}
                            value={selectedInsurancePackage.startDate}
                            error={displayError("startDate")}
                        />
                        <Form.Input
                            fluid
                            type="date"
                            label={t("Commercialization end date")}
                            name="endDate"
                            onChange={handleChangeInsurancePackage}
                            value={selectedInsurancePackage.endDate === null ? "" : selectedInsurancePackage.endDate}
                            error={displayError("endDate")}
                        />
                    </Form.Group>

                    <Header as="h2">{t("Coverage choices")}</Header>

                    <Transition visible={displayError("coveragesCode") !== undefined} animation="zoom" duration={250}>
                        <Message negative>
                            <Message.Header>{t("No coverage selected")}</Message.Header>
                            <p>{t("Please select at least one coverage")}</p>
                        </Message>
                    </Transition>

                    <Table compact celled definition width={"5em"}>
                        <Table.Body>
                            {coveragesOptions.map((coverage) => (
                                <Table.Row key={coverage.label} className="no-hover">
                                    <Table.Cell textAlign="center">{t(coverage.label)}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <Checkbox
                                            value={coverage.value}
                                            name={coverage.value}
                                            slider
                                            onClick={handleChangeInsurancePackageCoverages}
                                            checked={
                                                selectedInsurancePackage.coveragesCode.find(
                                                    (code) => coverage.value === code
                                                ) !== undefined
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    {options.length !== 0 && (
                        <>
                            <Header as="h2">{t("Renforts choices")}</Header>

                            <Table compact celled definition width={"5em"}>
                                <Table.Body>
                                    {options.map((option) => (
                                        <Table.Row key={option} className="no-hover">
                                            <Table.Cell textAlign="center">{option}</Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Checkbox
                                                    value={option}
                                                    name={option}
                                                    slider
                                                    onClick={handleChangeInsurancePackageOptions}
                                                    checked={
                                                        selectedInsurancePackage.options.find(
                                                            (code) => option === code
                                                        ) !== undefined
                                                    }
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </>
                    )}
                    <Divider />
                    <Container textAlign="center">
                        {selectedInsurancePackage.index > 0 ? (
                            <>
                                <Button primary onClick={handleAddInsurancePackage}>
                                    <Icon name="redo" />
                                    {t("Update", {entity: t("Insurance package").toLowerCase(), context: "female"})}
                                </Button>
                                <ModalDelete
                                    objectToDelete={t("Insurance package")}
                                    openModalButtonText={t("Remove insurance package")}
                                    isError={false}
                                    isSuccess={true}
                                    isPending={false}
                                    onValidate={handleRemoveInsurancePackage}
                                    objectType={"female"}
                                />
                            </>
                        ) : (
                            <Button primary onClick={handleAddInsurancePackage}>
                                <Icon name="plus" /> {t("Validate and add insurance package")}
                            </Button>
                        )}
                    </Container>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default InsurancePackageForm;
