import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Accordion, Grid, Message, Segment} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import useDragAndDropProperties from "../../../../../Hooks/useDragAndDropProperties";
import {useAddDeductibleCapitalConditions} from "../../../../../Services/DeductibleCapital/Queries";
import {DeductibleCapital, DeductibleCapitalCondition} from "../../../../../Services/DeductibleCapital/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import DeductibleCapitalConditionAccordion from "./DeductibleCapitalCondition/DeductibleCapitalConditionAccordion";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

type DeductibleCapitalListPropsType = {
    deductibleCapital: DeductibleCapital;
    version: VersionDTO | undefined;
};

const DeductibleCapitalList = ({deductibleCapital, version}: DeductibleCapitalListPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);
    const addDeductibleCapitalCondition = useAddDeductibleCapitalConditions(
        params.insurancePackageId,
        params.deductibleCapitalId
    );

    const onAddConditionValidation = () => {
        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            conditionPartList: null,
            deductibleCapitalConditionElementDTOs: [
                {
                    order: 1,
                    text: deductibleCapital.label,
                    format: null,
                    questionName: null,
                    pricingCriteriaName: null,
                    variableName: null,
                },
            ],
        };

        addDeductibleCapitalCondition.mutate(condition);
    };

    const onAddConditionSuccess = () => {
        if (deductibleCapital !== undefined) {
            setActiveConditionIndex(deductibleCapital.deductibleCapitalConditions.length - 2);
        }
    };

    const dndProperties = useDragAndDropProperties<DeductibleCapitalCondition>(
        deductibleCapital.deductibleCapitalConditions
    );

    const renderConditions = () => {
        return dndProperties.renderedItems.map((condition, index) => {
            return (
                <DeductibleCapitalConditionAccordion
                    key={condition.id}
                    condition={condition}
                    version={version}
                    index={index}
                    activeIndex={activeConditionIndex}
                    setActiveIndex={setActiveConditionIndex}
                    deductibleCapital={deductibleCapital}
                    numberOfConditions={deductibleCapital.deductibleCapitalConditions.length}
                    dndProperties={dndProperties}
                />
            );
        });
    };

    return (
        <Segment className="card-container" textAlign="center">
            <Accordion styled fluid onMouseLeave={() => dndProperties.refreshData()}>
                {renderConditions()}
            </Accordion>

            {version !== undefined && version.pipDate === null && (
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isPending={addDeductibleCapitalCondition.isPending}
                                isSuccess={addDeductibleCapitalCondition.isSuccess}
                                isError={addDeductibleCapitalCondition.isError}
                                resetMutation={addDeductibleCapitalCondition.reset}
                                error={addDeductibleCapitalCondition.error}
                                onValidate={() => onAddConditionValidation()}
                                onSuccess={() => onAddConditionSuccess()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="addCondition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Segment>
    );
};

export default DeductibleCapitalList;
