import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Form, Grid, Header, Loader, Message, Segment} from "semantic-ui-react";
import {AuthContext} from "../../../Components/AuthProvider/AuthProvider";
import CustomModal from "../../../Components/Modals/CustomModal";
import {useResetSelfPasswordRequestMutation} from "../../../Services/Company/Queries";
import {EmailDTO} from "../../../Services/Company/Types";
import axiosInstance from "../../../Services/ProductEngine.instance";
import getErrorMessage from "../Form/ErrorMessage";
import {emailValidation} from "../Form/Validation";
import {FormResetUserPassword} from "./Fragments/FormResetUserPassword";

export type AuthenticationDTO = {
    email: string;
    password: string;
    societeUser: string | null;
};

const LoginPage = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {login} = React.useContext(AuthContext);

    const authentificationForm = useForm<AuthenticationDTO>({
        mode: "onBlur",
        defaultValues: {email: "", password: "", societeUser: null},
    });
    const [loginError, setLoginError] = useState<{reason: string; explanation: string}>();
    const [loginRequestInProgress, setLoginRequestInProgress] = useState<boolean>(false);

    const resetSelfPasswordRequestMutation = useResetSelfPasswordRequestMutation();
    const resetSelfPasswordRequestForm = useForm<EmailDTO>({mode: "onBlur", defaultValues: {email: ""}});
    const submitResetUserPasswordRequest = (formData: EmailDTO) => {
        resetSelfPasswordRequestMutation.mutate(formData);
    };

    const submitForm = async (formData: AuthenticationDTO): Promise<void> => {
        setLoginRequestInProgress(true);

        axiosInstance
            .post("/auth/sign-in", {
                ...formData,
            })
            .then((res) => {
                //Stockage du token dans un cookie avec durée de vie de une journée
                login(res.data.token);
                //Redirection vers l'accueil après login
                navigate("/");
            })
            .catch(function (error) {
                setLoginRequestInProgress(false);

                if (error.response) {
                    if (error.response.status === 500) {
                        setLoginError({
                            reason: t("An error occurred") + "...",
                            explanation: t("An error occurred, please try again later"),
                        });
                    } else {
                        setLoginError({
                            reason: t("request_errors." + error.response.data.message),
                            explanation: t("request_errors." + error.response.data.message + "_explanation"),
                        });
                    }
                } else {
                    setLoginError({
                        reason: t("An error occurred, please try again"),
                        explanation: "",
                    });
                }
            });
    };

    return (
        <Grid textAlign="center" style={{height: "90vh"}} verticalAlign="middle">
            <Grid.Column style={{maxWidth: 450}} textAlign="center">
                <Header as="h2" textAlign="center">
                    {t("login")}
                </Header>
                <Form data-cy="form" size="large" onSubmit={authentificationForm.handleSubmit(submitForm)}>
                    <Segment stacked style={{padding: "2em"}} textAlign="left">
                        <Controller
                            control={authentificationForm.control}
                            name="email"
                            rules={{
                                required: true,
                                pattern: emailValidation(),
                            }}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Input
                                    data-cy="input-email-field"
                                    name={name}
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    placeholder={t("Please enter your email address")}
                                    onChange={onChange}
                                    value={value}
                                    error={getErrorMessage(t, authentificationForm.formState.errors, name)}
                                />
                            )}
                        />
                        <Controller
                            control={authentificationForm.control}
                            name="password"
                            rules={{required: true}}
                            render={({field: {onChange, value, name}}) => (
                                <Form.Input
                                    data-cy="input-password-field"
                                    name={name}
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    type="password"
                                    placeholder={t("Please enter your password")}
                                    onChange={onChange}
                                    value={value}
                                    error={getErrorMessage(t, authentificationForm.formState.errors, name)}
                                />
                            )}
                        />

                        <Button
                            data-cy="loginButton"
                            primary
                            fluid
                            size="large"
                            disabled={loginRequestInProgress}
                            type="submit"
                        >
                            {loginRequestInProgress ? (
                                <Loader active inline="centered" size="tiny" inverted />
                            ) : (
                                t("Log in")
                            )}
                        </Button>

                        {!loginRequestInProgress && loginError && (
                            <Message negative>
                                <Message.Header>{loginError.reason}</Message.Header>
                                <Message.Content style={{textAlign: "justify"}}>
                                    {loginError.explanation}
                                </Message.Content>
                            </Message>
                        )}
                    </Segment>
                </Form>

                <CustomModal
                    openModalButtonWithoutBackground
                    openModalButtonText={t("Forgot your password ?") + " / " + t("Reset")}
                    openModalButtonName="reset_password"
                    loadingText={t("Password resetting")}
                    errorText={t("An error occurred please contact support")}
                    cancelButtonColor="black"
                    validateButtonColor="green"
                    displayModalSuccess
                    iconAndTextOnOpenButton
                    openButtonIcon="lock"
                    modalSuccessHeaderText={t("Password resetting")}
                    modalSuccessContentText={t("You will shortly receive a link by email to reset your password")}
                    isPending={resetSelfPasswordRequestMutation.isPending}
                    isSuccess={resetSelfPasswordRequestMutation.isSuccess}
                    isError={resetSelfPasswordRequestMutation.isError}
                    resetMutation={resetSelfPasswordRequestMutation.reset}
                    error={resetSelfPasswordRequestMutation.error}
                    modalHeader={t("Reset your password")}
                    onValidate={resetSelfPasswordRequestForm.handleSubmit(submitResetUserPasswordRequest)}
                    renderModalContent={() => (
                        <FormResetUserPassword
                            errors={resetSelfPasswordRequestForm.formState.errors}
                            control={resetSelfPasswordRequestForm.control}
                        />
                    )}
                />
            </Grid.Column>
        </Grid>
    );
};

export default LoginPage;
