import {UseQueryResult, UseMutationResult, useMutation, useQuery} from "@tanstack/react-query";
import {
    createESelectionMatrix,
    deleteESelectionMatrix,
    exportESelectionMatrix,
    getESelectionEligibleProducts,
    getESelectionMatrixDTO,
    getESelectionMatrixExportDTO,
    importESelectionMatrix,
    updateESelectionMatrix,
} from "./AxiosRequests";
import {ESelectionMatrixDTO, ESelectionMatrixExportDTO} from "./Types";
import {CustomError} from "../../../Services/RequestUtils";
import queryClient from "../../../Config/queryClient";
import {AxiosResponse} from "axios";
import {ProductRecap} from "../../../Services/Product/Types";

export const useGetESelectionEligibleProductsQuery = (): UseQueryResult<Array<ProductRecap>, Error> => {
    return useQuery({
        queryKey: ["getESelectionEligibleProducts"],
        queryFn: () => getESelectionEligibleProducts(),
    });
};

export const useGetESelectionMatrixDTOQuery = (productId: string): UseQueryResult<ESelectionMatrixDTO, Error> => {
    return useQuery({
        queryKey: ["getESelectionMatrixDTO", {productId}],
        queryFn: () => getESelectionMatrixDTO(productId),
    });
};

export const useGetESelectionMatrixExportDTOQuery = (
    productId: string
): UseQueryResult<ESelectionMatrixExportDTO, Error> => {
    return useQuery({
        queryKey: ["getESelectionMatrixExportDTO", {productId}],
        queryFn: () => getESelectionMatrixExportDTO(productId),
    });
};

export const useCreateESelectionMatrixMutation = (
    productId: string
): UseMutationResult<void, CustomError, ESelectionMatrixDTO, Error> => {
    return useMutation<void, CustomError, ESelectionMatrixDTO, Error>({
        mutationFn: (eSelectionMatrixDTO: ESelectionMatrixDTO) =>
            createESelectionMatrix(productId, eSelectionMatrixDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["exportESelectionMatrix", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixDTO", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixExportDTO", {productId}]});
        },
    });
};

export const useUpdateESelectionMatrixMutation = (
    productId: string
): UseMutationResult<void, CustomError, ESelectionMatrixDTO, Error> => {
    return useMutation<void, CustomError, ESelectionMatrixDTO, Error>({
        mutationFn: (eSelectionMatrixDTO: ESelectionMatrixDTO) =>
            updateESelectionMatrix(productId, eSelectionMatrixDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["exportESelectionMatrix", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixDTO", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixExportDTO", {productId}]});
        },
    });
};

export const useDeleteESelectionMatrixMutation = (
    productId: string
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteESelectionMatrix(productId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["exportESelectionMatrix", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixDTO", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixExportDTO", {productId}]});
        },
    });
};

export const useExportESelectionMatrixQuery = (
    productId: string,
    enabled: boolean = false
): UseQueryResult<unknown, Error> => {
    return useQuery({
        queryKey: ["exportESelectionMatrix", {productId}],
        queryFn: () => exportESelectionMatrix(productId),
        enabled,
    });
};

export const useImportESelectionMatrixMutation = (
    productId: string
): UseMutationResult<void, CustomError, any, Error> => {
    return useMutation<void, CustomError, any, Error>({
        mutationFn: (files: any) => {
            const formData = new FormData();
            formData.append(
                "file",
                new Blob([files], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );

            return importESelectionMatrix(productId, formData);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["exportESelectionMatrix", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixDTO", {productId}]});
            await queryClient.invalidateQueries({queryKey: ["getESelectionMatrixExportDTO", {productId}]});
        },
    });
};

