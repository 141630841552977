import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import {useAddQuestionMutation} from "../../../../Services/Question/Queries";
import {QuestionValue, QuestionValueTypeEnum} from "../../../../Services/Question/Types";
import FormQuestion from "./FormQuestion";

interface IParams extends Params {
    id: string;
    versionId: string;
    questionId: string;
}

type PropsType = {
    alternateOpenButton?: () => JSX.Element;
};

const ModalAddQuestion = ({...props}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const addQuestionMutation = useAddQuestionMutation(params.id, params.versionId);
    const addQuestionForm = useForm<any>({mode: "onBlur", shouldUnregister: true});
    const submitAddQuestion = (form: any) => {
        var questionValues: Array<QuestionValue> | null = null;
        if (form.mandatory && form.valueType === QuestionValueTypeEnum.LISTE) {
            questionValues = [
                {
                    value: t("Default"),
                    label: t("Default value"),
                },
            ];
        } else if (!form.mandatory && form.valueType === QuestionValueTypeEnum.LISTE) {
            questionValues = [
                {
                    value: form.defaultValue,
                    label: null,
                },
            ];
        }

        addQuestionMutation.mutate({
            ...form,
            questionValueDTOs: questionValues,
        });
    };

    const [defaultValueName, setDefaultValueName] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<QuestionValueTypeEnum | undefined>();
    const [defaultValueMandatory, setDefaultValueMandatory] = useState<boolean>(false);
    const [defaultValueDefaultValue, setDefaultValueDefaultValue] = useState<String>("");

    const resetForm = () => {
        setDefaultValueName("");
        setDefaultValueType(undefined);
        setDefaultValueMandatory(false);
        setDefaultValueDefaultValue("");
    };

    return (
        <ModalAdd
            isValidationDisabled={Object.keys(addQuestionForm.formState.errors).length !== 0}
            isPending={addQuestionMutation.isPending}
            isSuccess={addQuestionMutation.isSuccess}
            isError={addQuestionMutation.isError}
            resetMutation={addQuestionMutation.reset}
            error={addQuestionMutation.error}
            onValidate={addQuestionForm.handleSubmit(submitAddQuestion)}
            onCancel={resetForm}
            onSuccess={resetForm}
            iconOnOpenButton
            openButtonIcon="add"
            objectToAdd="Question"
            objectToAddContext="female"
            renderModalContent={() => (
                <FormQuestion
                    errors={addQuestionForm.formState.errors}
                    control={addQuestionForm.control}
                    defaultValueName={defaultValueName}
                    setDefaultValueName={setDefaultValueName}
                    defaultValueType={defaultValueType}
                    setDefaultValueType={setDefaultValueType}
                    defaultValueMandatory={defaultValueMandatory}
                    setDefaultValueMandatory={setDefaultValueMandatory}
                    defaultValueDefaultValue={defaultValueDefaultValue}
                    setDefaultValueDefaultValue={setDefaultValueDefaultValue}
                />
            )}
            renderOpenButton={props.alternateOpenButton}
        />
    );
};

export default ModalAddQuestion;
