import {UserPayload} from "../Components/AuthProvider/AuthProvider";

export type AuditingType = {
    entityContext: "male" | "female";
    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number | null;
};

export const displayAuditLog = (auditedEntity: AuditingType, currentUser: UserPayload, versionId: string, t: any) => {
    if (Number.parseInt(versionId) === auditedEntity.createdOnVersionId) {
        if (auditedEntity.createdDate === auditedEntity.modifiedDate) {
            if (currentUser.username === auditedEntity.createdBy) {
                return t("Created at", {context: auditedEntity.entityContext, value: auditedEntity.createdDate});
            } else {
                return t("Created by at", {
                    context: auditedEntity.entityContext,
                    username: auditedEntity.createdBy,
                    value: auditedEntity.createdDate,
                });
            }
        } else if (new Date(auditedEntity.createdDate).getTime() < new Date(auditedEntity.modifiedDate).getTime()) {
            if (currentUser.username === auditedEntity.modifiedBy) {
                return t("Modified at", {context: auditedEntity.entityContext, value: auditedEntity.modifiedDate});
            } else {
                return t("Modified by at", {
                    context: auditedEntity.entityContext,
                    username: auditedEntity.modifiedBy,
                    value: auditedEntity.modifiedDate,
                });
            }
        }
    } else if (Number.parseInt(versionId) !== auditedEntity.createdOnVersionId) {
        if (auditedEntity.createdDate === auditedEntity.modifiedDate) {
            return t("Not modified", {context: auditedEntity.entityContext});
        } else if (new Date(auditedEntity.createdDate).getTime() < new Date(auditedEntity.modifiedDate).getTime()) {
            if (currentUser.username === auditedEntity.modifiedBy) {
                return t("Modified at", {context: auditedEntity.entityContext, value: auditedEntity.modifiedDate});
            } else {
                return t("Modified by at", {
                    context: auditedEntity.entityContext,
                    username: auditedEntity.modifiedBy,
                    value: auditedEntity.modifiedDate,
                });
            }
        }
    }
};
