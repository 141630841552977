import {useTranslation} from "react-i18next";
import {SemanticICONS} from "semantic-ui-react";
import {CustomError} from "../../Services/RequestUtils";
import CustomModal from "./CustomModal";

type ModalUpdateType = {
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    resetMutation?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    onSuccess?: () => void;
    onValidate: () => void;
    isModalDisabled?: boolean;
    objectToUpdate?: string;
    objectType?: "male" | "female" | "nonbinary";
    openModalButtonName?: string;
    openModalButtonText?: string;
    error?: CustomError | null;
    iconOnOpenButton?: boolean;
    openButtonIcon?: SemanticICONS;
    isModalOpenAtStart?: boolean;
    hidden?: boolean;
    customContent?: string | undefined;
};

const ModalUpdate: React.FC<ModalUpdateType> = ({
    isPending,
    isSuccess,
    isError,
    resetMutation,
    onCancel,
    onClose,
    onSuccess,
    onValidate,
    isModalDisabled,
    objectToUpdate,
    objectType,
    openModalButtonName = "update",
    openModalButtonText = "Validate",
    error = null,
    iconOnOpenButton = false,
    openButtonIcon,
    isModalOpenAtStart = false,
    hidden = false,
    customContent,
}) => {
    const {t} = useTranslation();

    const renderModalContent = () => {
        return (
            <p>
                {objectToUpdate !== undefined && customContent === undefined
                    ? t("You are about to change informations", {
                          name: t(objectToUpdate, {count: 1}).toLowerCase(),
                          context: objectType,
                      })
                    : customContent}
            </p>
        );
    };

    return (
        <CustomModal
            openModalButtonName={openModalButtonName}
            openModalButtonText={t(openModalButtonText)}
            openModalButtonColor="green"
            cancelButtonColor="black"
            cancelButtonText={t("Go back to changes")}
            iconOnCancelButton
            iconOnOpenButton={iconOnOpenButton}
            openButtonIcon={openButtonIcon}
            cancelButtonIcon="arrow left"
            validateButtonColor="green"
            iconOnValidateButton
            validateButtonIcon="check"
            validateButtonText={t("validate modifications")}
            loadingText={t("Update in progress")}
            errorText={t("An error occurred during the update")}
            modalHeader={t("Would you like to continue ?")}
            renderModalContent={renderModalContent}
            isModalDisabled={isModalDisabled}
            isPending={isPending}
            isSuccess={isSuccess}
            isError={isError}
            resetMutation={resetMutation}
            onCancel={onCancel}
            onClose={onClose}
            onSuccess={onSuccess}
            onValidate={onValidate}
            error={error}
            isModalOpenAtStart={isModalOpenAtStart}
            hidden={hidden}
        />
    );
};

export default ModalUpdate;
