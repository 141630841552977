import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Card, Form, Grid, Header, Icon, Menu, Message, Segment} from "semantic-ui-react";
import ModalAdd from "../../../Components/Modals/ModalAdd";
import ModalDelete from "../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../Components/Modals/ModalUpdate";
import useContainerDimensions from "../../../Hooks/useContainerDimensions";
import {useAddRangeMutation, useDeleteRangeMutation, useUpdateRangeMutation} from "../../../Services/Company/Queries";
import {RangeType, RiskTypeItemType} from "../../../Services/Company/Types";
import getErrorMessage from "../../Global/Form/ErrorMessage";
import FormRange from "./FormRange";

export const RangesList: React.FC<{activeItem: RiskTypeItemType}> = ({...props}) => {
    const {t} = useTranslation();

    const [editMode, setEditMode] = useState<boolean>(false);
    const [rangeToEdit, setRangeToEdit] = useState<RangeType | undefined>();

    // Add range
    const addRangeMutation = useAddRangeMutation(props.activeItem?.id);
    const addRangeForm = useForm<any>();
    const sumbitAddRangeForm = (form: any) => {
        addRangeMutation.mutate({
            ...form,
        });
    };
    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");

    // Update
    const updateRangeMutation = useUpdateRangeMutation(props.activeItem?.id);
    const updateRangeForm = useForm<{label: string}>({mode: "onBlur"});
    const submitUpdateRangeForm = (form: {label: string}) => {
        if (rangeToEdit) {
            updateRangeMutation.mutate({
                id: rangeToEdit.id,
                label: form.label,
            });
        }
    };

    // Delete
    const deleteRangeMutation = useDeleteRangeMutation(props.activeItem?.id);

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Grid.Column width={12}>
            <Segment>
                <Menu borderless style={{border: "0", boxShadow: "none"}}>
                    <Menu.Item>
                        <Header size="large">
                            {t("Ranges")} - {props.activeItem?.label}
                        </Header>
                    </Menu.Item>
                    <Menu.Menu position="right">
                        <Menu.Item name="addRange">
                            <ModalAdd
                                isValidationDisabled={Object.keys(addRangeForm.formState.errors).length !== 0}
                                isPending={addRangeMutation.isPending}
                                isSuccess={addRangeMutation.isSuccess}
                                isError={addRangeMutation.isError}
                                error={addRangeMutation.error}
                                resetMutation={addRangeMutation.reset}
                                objectToAdd="Range"
                                objectToAddContext="female"
                                onValidate={addRangeForm.handleSubmit(sumbitAddRangeForm)}
                                onCancel={() => {
                                    setDefaultValueLabel("");
                                }}
                                onSuccess={() => {
                                    setDefaultValueLabel("");
                                }}
                                iconOnOpenButton
                                openButtonIcon="plus"
                                renderModalContent={() => (
                                    <FormRange
                                        control={addRangeForm.control}
                                        errors={addRangeForm.formState.errors}
                                        defaultValueLabel={defaultValueLabel}
                                        setDefaultValueLabel={setDefaultValueLabel}
                                    />
                                )}
                            />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>

                {props.activeItem.ranges != null && props.activeItem.ranges.length !== 0 ? (
                    <div className={"ui cards " + columnNumberAsText} ref={ref} data-cy="rangesGroup">
                        {props.activeItem.ranges.map((range) => (
                            <Card key={range.id}>
                                {editMode && range.id === rangeToEdit?.id ? (
                                    <Card.Content>
                                        <Form>
                                            <Grid>
                                                <Grid.Column width={10}>
                                                    <Controller
                                                        control={updateRangeForm.control}
                                                        defaultValue={range.label}
                                                        rules={{required: true}}
                                                        name={"label"}
                                                        render={({field: {name, value, onChange, onBlur}}) => (
                                                            <Form.Input
                                                                fluid
                                                                required
                                                                type={"text"}
                                                                name={name}
                                                                placeholder={t("label")}
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                value={value}
                                                                error={getErrorMessage(
                                                                    t,
                                                                    updateRangeForm.formState.errors,
                                                                    "label"
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={6}>
                                                    <Button.Group floated="right">
                                                        <Button
                                                            icon="cancel"
                                                            color="black"
                                                            onClick={() => setEditMode(false)}></Button>
                                                        <ModalUpdate
                                                            isPending={updateRangeMutation.isPending}
                                                            isSuccess={updateRangeMutation.isSuccess}
                                                            isError={updateRangeMutation.isError}
                                                            error={updateRangeMutation.error}
                                                            resetMutation={updateRangeMutation.reset}
                                                            onSuccess={() => setEditMode(false)}
                                                            onValidate={updateRangeForm.handleSubmit(
                                                                submitUpdateRangeForm
                                                            )}
                                                            objectToUpdate="Range"
                                                            objectType="female"
                                                            iconOnOpenButton={true}
                                                            openButtonIcon="check"
                                                        />
                                                    </Button.Group>
                                                </Grid.Column>
                                            </Grid>
                                        </Form>
                                    </Card.Content>
                                ) : (
                                    <Card.Content>
                                        <Grid>
                                            <Grid.Column width={10} verticalAlign="middle">
                                                <Header as={"h4"}>{range.label} </Header>
                                            </Grid.Column>
                                            <Grid.Column width={6} textAlign="right" verticalAlign="middle">
                                                <Button.Group basic floated="right">
                                                    <Button
                                                        basic
                                                        icon
                                                        name="edit"
                                                        onClick={() => {
                                                            setRangeToEdit(range);
                                                            setEditMode(true);
                                                        }}>
                                                        <Icon name={"edit"} color="black" />
                                                    </Button>
                                                    <ModalDelete
                                                        isPending={deleteRangeMutation.isPending}
                                                        isSuccess={deleteRangeMutation.isSuccess}
                                                        isError={deleteRangeMutation.isError}
                                                        error={deleteRangeMutation.error}
                                                        resetMutation={deleteRangeMutation.reset}
                                                        onValidate={() => deleteRangeMutation.mutate(range.id)}
                                                        objectToDelete="Range"
                                                        objectType="female"
                                                        renderOpenButton={() => {
                                                            return (
                                                                <Button basic icon name="delete">
                                                                    <Icon name={"trash alternate"} color="red" />
                                                                </Button>
                                                            );
                                                        }}
                                                    />
                                                </Button.Group>
                                            </Grid.Column>
                                        </Grid>
                                    </Card.Content>
                                )}
                            </Card>
                        ))}
                    </div>
                ) : (
                    <Segment style={{border: "0", boxShadow: "none"}}>
                        <Message warning header={t("No ranges")} content={t("There is no ranges for this risk type")} />
                    </Segment>
                )}
            </Segment>
        </Grid.Column>
    );
};
