import { useEffect, useRef, useState } from "react";

function getWindowDimensions(ref: React.MutableRefObject<any>) {
    const {innerWidth: windowWidth, innerHeight: windowHeight} = window;
    const {clientWidth: refWidth, clientHeight: refHeight} =
        ref.current !== null ? ref.current : {clientWidth: 0, clientHeight: 0};

    const columnNumber = getColumnNumberInGroups(refWidth);
    const columnNumberAsText = getColumnNumberAsText(columnNumber);

    return {
        windowWidth,
        windowHeight,
        refWidth,
        refHeight,
        columnNumber: ref.current !== null ? columnNumber : 4,
        columnNumberAsText: ref.current !== null ? columnNumberAsText : "four",
        ref,
    };
}

function getColumnNumberInGroups(innerWidth: number): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 {
    if (innerWidth > 2800) return 8;
    else if (innerWidth <= 2800 && innerWidth > 2400) return 7;
    else if (innerWidth <= 2400 && innerWidth > 1910) return 6;
    else if (innerWidth <= 1910 && innerWidth > 1450) return 5;
    else if (innerWidth <= 1450 && innerWidth > 1020) return 4;
    else if (innerWidth <= 1020 && innerWidth > 800) return 3;
    else if (innerWidth <= 800 && innerWidth > 500) return 2;
    else return 1;
}

function getColumnNumberAsText(columnNumber: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8): string {
    switch (columnNumber) {
        case 1:
            return "one";
        case 2:
            return "two";
        case 3:
            return "three";
        case 4:
            return "four";
        case 5:
            return "five";
        case 6:
            return "six";
        case 7:
            return "seven";
        case 8:
            return "height";
        default:
            return "height";
    }
}

export default function useContainerDimensions() {
    const ref = useRef(null);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(ref));

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions(ref));
        }

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}
