import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {CustomError} from "../RequestUtils";
import {
    addVersion,
    deleteVersion,
    executeVersionMassTest,
    getVersion,
    getVersionMassTestFileStructure,
    getVersions,
    updateVersion,
} from "./AxiosRequests";
import {VersionAddDTO, VersionDTO, VersionUpdateDTO} from "./Types";

export const useGetVersionQuery = (
    productId: string,
    versionId: string,
    enabled: boolean = true
): UseQueryResult<VersionDTO, CustomError> => {
    return useQuery({
        queryKey: ["getVersion", productId, versionId],
        queryFn: () => getVersion(productId, versionId),
        enabled,
    });
};

export const useGetVersionsQuery = (
    productId: string,
    enabled: boolean = true
): UseQueryResult<Array<VersionDTO>, CustomError> => {
    return useQuery({
        queryKey: ["getVersions", productId],
        queryFn: () => getVersions(productId),
        enabled,
    });
};

export const useAddVersionMutation = (
    productId: string
): UseMutationResult<VersionDTO, CustomError, VersionAddDTO, Error> => {
    return useMutation<VersionDTO, CustomError, VersionAddDTO, Error>({
        mutationFn: (versionAddDTO: VersionAddDTO) => addVersion(productId, versionAddDTO),
        onSuccess: async () => await queryClient.invalidateQueries({queryKey: ["getVersions", productId]}),
    });
};

export const useUpdateVersionMutation = (
    productId: string,
    versionId: string
): UseMutationResult<VersionDTO, CustomError, VersionUpdateDTO, Error> => {
    return useMutation<VersionDTO, CustomError, VersionUpdateDTO, Error>({
        mutationFn: (versionUpdateDTO: VersionUpdateDTO) => updateVersion(productId, versionId, versionUpdateDTO),
        onSuccess: async (data) => {
            await queryClient.setQueryData(["getVersion", productId, versionId], data);
            await queryClient.invalidateQueries({queryKey: ["getVersions", productId]});
        },
    });
};

export const useDeleteVersionMutation = (
    productId: string,
    versionId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();

    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteVersion(productId, versionId),
        onSuccess: async () => {
            queryClient.removeQueries({queryKey: ["getVersion", productId, versionId]});
            await queryClient.invalidateQueries({queryKey: ["getVersions", productId]});
            navigate(`/products/${productId}`);
        },
    });
};

export const useGetVersionMassTestFileStructureQuery = (
    productId: string,
    versionId: string,
    enabled: boolean = true
): UseQueryResult<unknown, CustomError> => {
    return useQuery({
        queryKey: ["exportVersionMassTest", {productId, versionId}],
        queryFn: () => getVersionMassTestFileStructure(productId, versionId),
        enabled,
    });
};

export const useExecuteVersionMassTestMutation = (
    productId: string,
    versionId: string
): UseMutationResult<void, CustomError, any, Error> => {
    return useMutation<void, CustomError, any, Error>({
        mutationFn: async (file: any) => {
            const formData = new FormData();

            formData.append(
                "file",
                new Blob([file], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );

            return executeVersionMassTest(productId, versionId, formData);
        },
    });
};

