import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useLocation, useParams} from "react-router-dom";
import {Button, Container, Grid, Segment, Tab} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetInsurancePackageQuery, useGetInsurancePackagesQuery} from "../../../Services/InsurancePackage/Queries";
import {useGetVersionQuery} from "../../../Services/Version/Queries";
import BreadcrumbInsurancePackage from "./Fragments/BreadcrumbInsurancePackage";
import CoverageGroupList from "./Fragments/Coverages/CoverageGroupList";
import DeductibleCapitalsList from "./Fragments/DeductibleCapitals/DeductibleCapitalsList";
import FeeChargeList from "./Fragments/FeeCharges/FeeChargeList";
import {InsurancePackageConditionsList} from "./Fragments/InsurancePackageExclusionConditions/InsurancePackageExclusionConditionsList";
import ModalAddInsurancePackage from "./Fragments/ModalAddInsurancePackage";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

const InsurancePackagePage = ({...props}) => {
    const location = useLocation();
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [sidebarMenus, setSidebarMenus] = useState<Array<SidebarMenu>>([]);

    const insurancePackageListQuery = useGetInsurancePackagesQuery(params.versionId);

    const {
        data: insurancePackage,
        isSuccess,
        isLoading,
    } = useGetInsurancePackageQuery(params.versionId, params.insurancePackageId);

    const {data: version} = useGetVersionQuery(params.id, params.versionId);

    const [activeTabIndex, setActiveIndex] = useState(location.state ? location.state.activeTabIndex : 0);

    useEffect(() => {
        if (insurancePackageListQuery.isSuccess && insurancePackageListQuery.data) {
            setSidebarMenus([
                new SidebarMenu(
                    "",
                    insurancePackageListQuery.data.map(
                        (insurancePackage) =>
                            new SidebarItem(
                                insurancePackage.code,
                                insurancePackage.code + " - " + insurancePackage.label,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${insurancePackage.id}`
                            )
                    )
                ),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insurancePackageListQuery.isSuccess, insurancePackageListQuery.data]);

    const getVersionQueryResult = useGetVersionQuery(params.id, params.versionId);

    const panes = [
        {
            menuItem: {key: "coverages", content: t("Coverages")},
            render: () => (
                <CoverageGroupList
                    insurancePackageId={params.insurancePackageId}
                    version={getVersionQueryResult.data}
                />
            ),
        },
        {
            menuItem: {key: "feeCharges", content: t("Fees and charges")},
            render: () => <FeeChargeList version={getVersionQueryResult.data} />,
        },
        {
            menuItem: {key: "conditions", content: t("Exclusion conditions")},
            render: () => (
                <InsurancePackageConditionsList insurancePackageId={params.insurancePackageId} version={version} />
            ),
        },
        {
            menuItem: {
                key: "deductibleCapitals",
                content: t("Deductible capitals"),
                "data-cy": "deductibleCapitalTab",
            },
            render: () => <DeductibleCapitalsList insurancePackageId={params.insurancePackageId} />,
        },
    ];

    return (
        <Container fluid>
            <Sidebar
                title={t("Insurance packages")}
                menus={sidebarMenus}
                renderAdditionnalButton={
                    version !== undefined && version.pipDate === null
                        ? () => (
                              <ModalAddInsurancePackage
                                  alternateOpenButton={() => (
                                      <Button primary name="new_insurance_package" className="menu-button">
                                          {t("New entity", {
                                              entity: t("Insurance package").toLowerCase(),
                                              context: "female",
                                          })}
                                      </Button>
                                  )}
                              />
                          )
                        : undefined
                }
            />
            {isLoading && <CustomBreadcrumb sections={props.sections} />}
            {version !== undefined && insurancePackage !== undefined && isSuccess && (
                <BreadcrumbInsurancePackage
                    insurancePackage={insurancePackage}
                    version={version}
                    sections={props.sections}
                />
            )}
            <div className="main-container">
                <Grid>
                    <Grid.Column width={16}>
                        <Segment className="card-container">
                            <Tab
                                panes={panes}
                                activeIndex={activeTabIndex}
                                onTabChange={(_, data) => setActiveIndex(data.activeIndex as number)}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>
        </Container>
    );
};

export default InsurancePackagePage;
