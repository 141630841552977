import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import {Cookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {AuthenticationDTO} from "../../Pages/Global/Login/LoginPage";
import {CustomError} from "../RequestUtils";
import {
    activateAccount,
    addApplicationAccess,
    addCompany,
    addCompanyProductInfo,
    addRange,
    addRiskType,
    addUser,
    addUserGroup,
    deleteApplicationAccess,
    deleteCompany,
    deleteCompanyProductInfo,
    deleteRange,
    deleteRiskType,
    deleteUser,
    deleteUserGroup,
    editRange,
    getApplicationAccess,
    getApplicationAccesses,
    getCompanies,
    getCompany,
    getCompanyProductInfo,
    getCompanyProductInfos,
    getNotAssociatedRegistries,
    getRangesApi,
    getRiskTypesApi,
    getSelfCompany,
    getSelfUser,
    getUser,
    getUserGroup,
    getUserGroups,
    getUsers,
    resetSelfPassword,
    resetSelfPasswordRequest,
    resetUserPassword,
    signIn,
    updateApplicationAccess,
    updateCompany,
    updateCompanyProductInfo,
    updateRiskType,
    updateSelfUser,
    updateUser,
    updateUserGroup,
    verifyAuthToken,
} from "./AxiosRequests";
import {
    ApplicationAccessAddUpdateDTO,
    ApplicationAccessDTO,
    Company,
    CompanyProductInfosType,
    EmailDTO,
    PasswordDTO,
    RangeType,
    RegistryNotAssociatedDTO,
    RiskTypeItemType,
    UserAddDTO,
    UserDTO,
    UserGroupDTO,
    UserUpdateDTO,
    UserUpdateSelfDTO,
    VerifyTokenDTO,
} from "./Types";

export const useSignInMutation = (): UseMutationResult<{token: string}, CustomError, AuthenticationDTO, Error> => {
    return useMutation<{token: string}, CustomError, AuthenticationDTO, Error>({
        mutationFn: (authenticationDTO: AuthenticationDTO) => signIn(authenticationDTO),
    });
};

export const useGetNotAssociatedRegistriesQuery = (
    insurancePackageId: string
): UseQueryResult<Array<RegistryNotAssociatedDTO>, Error> => {
    return useQuery({
        queryKey: ["getNotAssociatedRegistries", insurancePackageId],
        queryFn: () => getNotAssociatedRegistries(insurancePackageId),
    });
};

export const useRiskTypesQuery = (): UseQueryResult<Array<RiskTypeItemType>, Error> => {
    return useQuery({
        queryKey: ["riskTypes"],
        queryFn: getRiskTypesApi,
    });
};

export const useAddRiskTypeMutation = (): UseMutationResult<RiskTypeItemType, CustomError, {label: string}, Error> => {
    return useMutation<RiskTypeItemType, CustomError, {label: string}, Error>({
        mutationFn: (riskType: {label: string}) => addRiskType(riskType),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["riskTypes"]});
        },
    });
};

export const useUpdateRiskTypeMutation = (): UseMutationResult<
    RiskTypeItemType,
    CustomError,
    {id: number, label: string},
    Error
> => {
    return useMutation<RiskTypeItemType, CustomError, {id: number, label: string}, Error>({
        mutationFn: (riskType: {id: number, label: string}) => updateRiskType(riskType.id, riskType.label),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["riskTypes"]});
            await queryClient.invalidateQueries({queryKey: ["productsByRiskType"]});
        },
    });
};

export const useDeleteRiskTypeMutation = (): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (riskTypeId: number) => deleteRiskType(riskTypeId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["riskTypes"]});
        },
    });
};

export const useGetRangesQuery = (
    riskTypeId: string,
    enabled: boolean = true
): UseQueryResult<Array<RiskTypeItemType>, Error> => {
    return useQuery({
        queryKey: ["getRangesApi", riskTypeId],
        queryFn: () => getRangesApi(riskTypeId),
        enabled,
    });
};

export const useAddRangeMutation = (
    riskTypeId: number
): UseMutationResult<RangeType, CustomError, RangeType, Error> => {
    return useMutation<RangeType, CustomError, RangeType, Error>({
        mutationFn: (range: RangeType) => addRange(riskTypeId, range),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["riskTypes"]});
            await queryClient.invalidateQueries({queryKey: ["getRangesApi", riskTypeId]});
        },
    });
};

export const useUpdateRangeMutation = (
    riskTypeId: number
): UseMutationResult<RangeType, CustomError, RangeType, Error> => {
    return useMutation<RangeType, CustomError, RangeType, Error>({
        mutationFn: (range: RangeType) => editRange(riskTypeId, range.id, range.label),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRangesApi", riskTypeId]});
            await queryClient.invalidateQueries({queryKey: ["riskTypes"]});
        },
    });
};

export const useDeleteRangeMutation = (riskTypeId: number): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (rangeId: number) => deleteRange(riskTypeId, rangeId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getRangesApi", riskTypeId]});
            await queryClient.invalidateQueries({queryKey: ["riskTypes"]});
        },
    });
};

export const useGetSelfCompanyQuery = (): UseQueryResult<Company, Error> => {
    return useQuery({
        queryKey: ["getSelfCompany", new Cookies().get("token")],
        queryFn: () => getSelfCompany(),
    });
};

export const useGetCompanyQuery = (companyId: string): UseQueryResult<Company, Error> => {
    return useQuery({
        queryKey: ["getCompany", companyId],
        queryFn: () => getCompany(companyId),
    });
};

export const useGetCompaniesQuery = (): UseQueryResult<Array<Company>, Error> => {
    return useQuery({
        queryKey: ["getCompanies"],
        queryFn: () => getCompanies(),
    });
};

export const useAddCompanyMutation = (): UseMutationResult<Company, CustomError, Company, Error> => {
    const navigate = useNavigate();
    return useMutation<Company, CustomError, Company, Error>({
        mutationFn: (company: Company) => addCompany(company),
        onSuccess: async (result: Company) => {
            await queryClient.invalidateQueries({queryKey: ["getCompanies"]});
            navigate(`/companies/${result.id}`);
        },
    });
};

export const useUpdateCompanyMutation = (
    companyId: string
): UseMutationResult<Company, CustomError, Company, Error> => {
    return useMutation<Company, CustomError, Company, Error>({
        mutationFn: (company: Company) => updateCompany(companyId, company),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCompanies"]});
            await queryClient.invalidateQueries({queryKey: ["getCompany", companyId]});
        },
    });
};

export const useDeleteCompanyMutation = (companyId: string): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteCompany(companyId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCompanies"]});
            queryClient.removeQueries({queryKey: ["getCompany", companyId]});
        },
    });
};

export const useGetUsersQuery = (companyId: string, enabled: boolean = true) => {
    return useQuery({
        queryKey: ["getUsers", companyId],
        queryFn: () => getUsers(companyId),
        enabled,
    });
};

export const useGetUserQuery = (companyId: string, userId: string) => {
    return useQuery({
        queryKey: ["getUser", {companyId, userId}],
        queryFn: () => getUser(companyId, userId),
    });
};

export const useGetSelfUserQuery = () => {
    return useQuery({
        queryKey: ["getSelfUser", new Cookies().get("token")],
        queryFn: () => getSelfUser(),
    });
};

export const useUpdateSelfUserMutation = (
    companyId: string
): UseMutationResult<UserDTO, CustomError, UserUpdateSelfDTO, Error> => {
    return useMutation<UserDTO, CustomError, UserUpdateSelfDTO, Error>({
        mutationFn: (userUpdateSelfDTO: UserUpdateSelfDTO) => updateSelfUser(userUpdateSelfDTO),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getUsers", companyId]});
            await queryClient.invalidateQueries({queryKey: ["getUser", {companyId, userId: result.id}]});
            await queryClient.invalidateQueries({queryKey: ["getSelfUser", new Cookies().get("token")]});
            await queryClient.invalidateQueries({queryKey: ["getUserByUsername"]});
        },
    });
};

export const useAddUserMutation = (companyId: string): UseMutationResult<UserDTO, CustomError, UserAddDTO, Error> => {
    return useMutation<UserDTO, CustomError, UserAddDTO, Error>({
        mutationFn: (userAddDTO: UserAddDTO) => addUser(companyId, userAddDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getUsers", companyId]});
        },
    });
};

export const useUpdateUserMutation = (
    companyId: string,
    userId: string
): UseMutationResult<void, CustomError, UserUpdateDTO, Error> => {
    return useMutation<void, CustomError, UserUpdateDTO, Error>({
        mutationFn: (userUpdateDTO: UserUpdateDTO) => updateUser(companyId, userId, userUpdateDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getUsers", companyId]});
            await queryClient.invalidateQueries({queryKey: ["getUser", {companyId, userId}]});
            await queryClient.invalidateQueries({queryKey: ["getUserByUsername"]});
        },
    });
};

export const useDeleteUserMutation = (
    companyId: string,
    userId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteUser(companyId, userId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getUsers", companyId]});
            queryClient.removeQueries({queryKey: ["getUser", {companyId, userId}]});
            navigate(`/transversal_settings/users`, {state: {activeTabIndex: 2}});
        },
    });
};

export const useGetApplicationAccessesQuery = (companyId: string, enabled: boolean = true) => {
    return useQuery({
        queryKey: ["getApplicationAccesses", companyId],
        queryFn: () => getApplicationAccesses(companyId),
        enabled,
    });
};

export const useGetApplicationAccessQuery = (
    companyId: string,
    applicationAccessId: string,
    enabled: boolean = true
) => {
    return useQuery({
        queryKey: ["getApplicationAccess", {companyId: companyId, applicationAccessId: applicationAccessId}],
        queryFn: () => getApplicationAccess(companyId, applicationAccessId),
        enabled,
    });
};

export const useAddApplicationAccessMutation = (
    companyId: string
): UseMutationResult<ApplicationAccessDTO, CustomError, ApplicationAccessAddUpdateDTO, Error> => {
    return useMutation<ApplicationAccessDTO, CustomError, ApplicationAccessAddUpdateDTO, Error>({
        mutationFn: (applicationAccessAddDTO: ApplicationAccessAddUpdateDTO) =>
            addApplicationAccess(companyId, applicationAccessAddDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getApplicationAccesses", companyId]});
        },
    });
};

export const useUpdateApplicationAccessMutation = (
    companyId: string,
    applicationAccessId: string
): UseMutationResult<ApplicationAccessDTO, CustomError, ApplicationAccessAddUpdateDTO, Error> => {
    return useMutation<ApplicationAccessDTO, CustomError, ApplicationAccessAddUpdateDTO, Error>({
        mutationFn: (applicationAccessUpdateDTO: ApplicationAccessAddUpdateDTO) =>
            updateApplicationAccess(companyId, applicationAccessId, applicationAccessUpdateDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getApplicationAccesses", companyId]});
            await queryClient.invalidateQueries({
                queryKey: ["getApplicationAccess", {companyId, applicationAccessId}],
            });
        },
    });
};

export const useDeleteApplicationAccessMutation = (
    companyId: string,
    applicationAccessId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteApplicationAccess(companyId, applicationAccessId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getApplicationAccesses", companyId]});
            queryClient.removeQueries({queryKey: ["getApplicationAccess", {companyId, applicationAccessId}]});
            navigate(`/transversal_settings/rest_api_access`, {state: {activeTabIndex: 0}});
        },
    });
};

export const useResetUserPasswordMutation = (
    companyId: string,
    userId: string
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => resetUserPassword(companyId, userId),
    });
};

export const useResetSelfPasswordRequestMutation = (): UseMutationResult<void, CustomError, EmailDTO, Error> => {
    return useMutation<void, CustomError, EmailDTO, Error>({
        mutationFn: (email: EmailDTO) => resetSelfPasswordRequest(email),
    });
};

export const useResetSelfPasswordMutation = (
    token: string
): UseMutationResult<void, CustomError, PasswordDTO, Error> => {
    return useMutation({mutationFn: (password: PasswordDTO) => resetSelfPassword(password, token)});
};

export const useActivateAccountMutation = (token: string): UseMutationResult<void, CustomError, PasswordDTO, Error> => {
    return useMutation({mutationFn: (password: PasswordDTO) => activateAccount(password, token)});
};

export const useVerifyAuthTokenMutation = (): UseMutationResult<void, CustomError, VerifyTokenDTO, Error> => {
    return useMutation({mutationFn: (verifyTokenDTO: VerifyTokenDTO) => verifyAuthToken(verifyTokenDTO)});
};

export const useGetCompanyProductInfos = (): UseQueryResult<Array<CompanyProductInfosType>, Error> => {
    return useQuery({
        queryKey: ["getCompanyProductInfos"],
        queryFn: () => getCompanyProductInfos(),
    });
};

export const useGetCompanyProductInfo = (
    companyProductInfoId: string
): UseQueryResult<CompanyProductInfosType, Error> => {
    return useQuery({
        queryKey: ["getCompanyProductInfo", companyProductInfoId],
        queryFn: () => getCompanyProductInfo(companyProductInfoId),
    });
};

export const useAddCompanyProductInfo = (): UseMutationResult<
    CompanyProductInfosType,
    CustomError,
    CompanyProductInfosType,
    Error
> => {
    return useMutation<CompanyProductInfosType, CustomError, CompanyProductInfosType, Error>({
        mutationFn: (companyProductInfo: CompanyProductInfosType) => addCompanyProductInfo(companyProductInfo),
    });
};

export const useUpdateCompanyProductInfo = (
    companyProductInfoId: string
): UseMutationResult<CompanyProductInfosType, CustomError, CompanyProductInfosType, Error> => {
    return useMutation<CompanyProductInfosType, CustomError, CompanyProductInfosType, Error>({
        mutationFn: (companyProductInfo: CompanyProductInfosType) =>
            updateCompanyProductInfo(companyProductInfoId, companyProductInfo),
    });
};

export const useDeleteCompanyProductInfo = () => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: (companyProductInfoId: string) => deleteCompanyProductInfo(companyProductInfoId),
        onSuccess: async () => {
            navigate(`/transversal_settings/company_product_infos`);
        },
    });
};

export const useGetUserGroupsQuery = (companyId: string, enabled: boolean = true) => {
    return useQuery({
        queryKey: ["getUserGroups", companyId],
        queryFn: () => getUserGroups(companyId),
        enabled,
    });
};

export const useGetUserGroupQuery = (companyId: string, userGroupId: string, enabled: boolean = true) => {
    return useQuery({
        queryKey: ["getUserGroup", {companyId, userGroupId}],
        queryFn: () => getUserGroup(companyId, userGroupId),
        enabled,
    });
};

export const useAddUserGroupMutation = (companyId: string): UseMutationResult<void, CustomError, any, Error> => {
    return useMutation<void, CustomError, any, Error>({
        mutationFn: (userGroupDTO: UserGroupDTO) => addUserGroup(companyId, userGroupDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getUserGroups", companyId]});
        },
    });
};

export const useDeleteUserGroupMutation = (companyId: string, groupId: string) => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: () => deleteUserGroup(companyId, groupId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getUserGroups", companyId]});
            queryClient.removeQueries({queryKey: ["getUserGroup", {companyId, groupId}]});
            navigate(`/transversal_settings/rest_api_access`, {state: {activeTabIndex: 1}});
        },
    });
};

export const useUpdateUserGroupMutation = (
    companyId: string,
    userGroupId: string
): UseMutationResult<UserGroupDTO, CustomError, any, Error> => {
    return useMutation<UserGroupDTO, CustomError, any, Error>({
        mutationFn: (groupUpdateDTO: UserGroupDTO) => updateUserGroup(companyId, userGroupId, groupUpdateDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getUserGroups", companyId]});
            await queryClient.invalidateQueries({queryKey: ["getUserGroup", {companyId, userGroupId}]});
        },
    });
};

