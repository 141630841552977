import {Control, Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import getErrorMessage from "../../Pages/Global/Form/ErrorMessage";
import {emailValidation} from "../../Pages/Global/Form/Validation";
import {Role, UserAddDTO} from "../../Services/Company/Types";
import React from "react";
import {AuthContext} from "../AuthProvider/AuthProvider";

type FormUserCampaignPropsType = {
    control: Control<UserAddDTO>;
    errors: any;
    defaultValueAddUser: UserAddDTO;
    setDefaultValueAddUser: React.Dispatch<React.SetStateAction<UserAddDTO>>;
};

const FormUser = ({control, errors, defaultValueAddUser, setDefaultValueAddUser}: FormUserCampaignPropsType) => {
    const {t} = useTranslation();
    const {user} = React.useContext(AuthContext);

    const roleOptions = Object.values(Role)
        .map((role: Role, index: number) => {
            return {
                key: `role-${index}`,
                text: t(`enums.Role.${role}`),
                value: role,
            };
        })
        .filter(
            (role) =>
                role.value !== Role.GESTIONNAIRE &&
                role.value !== Role.APPORTEUR &&
                !(
                    role.value === Role.SUPER_ADMIN &&
                    !user?.authorities?.some((authority) => authority === "SUPER_ADMIN")
                )
        );

    const handleChangeDefaultValue = (
        propertyChangedName: string,
        value: string | number | boolean | (string | number | boolean)[] | undefined,
        onChange: Function
    ) => {
        setDefaultValueAddUser({
            ...defaultValueAddUser,
            [propertyChangedName]: value,
        });
        onChange(value);
    };

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueAddUser.username || ""}
                            rules={{required: true}}
                            name={"username"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Username")}
                                    placeholder={t("Username")}
                                    onChange={(_, {value}) => handleChangeDefaultValue(name, value, onChange)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            defaultValue={defaultValueAddUser.email || ""}
                            rules={{
                                required: true,
                                pattern: emailValidation(),
                            }}
                            name={"email"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"email"}
                                    name={name}
                                    label={t("Email")}
                                    placeholder={t("Email")}
                                    onChange={(_, {value}) => handleChangeDefaultValue(name, value, onChange)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueAddUser.role || ""}
                            name={"role"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    label={t("Role")}
                                    name={name}
                                    required
                                    onBlur={onBlur}
                                    placeholder={t("Role")}
                                    onChange={(_, {value}) => handleChangeDefaultValue(name, value, onChange)}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={roleOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormUser;
