import React, {useState, useEffect} from "react";
import {Grid, Header, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useGetPromotionalCampaignQuery} from "../../../../Services/PromotionalCampagins/Queries";
import PromotionalCampaignDetailsRead from "./PromotionalCampaignDetailsRead";
import PromotionalCampaignDetailsEdit from "./PromotionalCampaignDetailsEdit";

type PromotionalCampaignDetailsProps = {
    productId: string;
    promotionalCampaignId: string;
};

const PromotionalCampaignDetails: React.FC<PromotionalCampaignDetailsProps> = ({productId, promotionalCampaignId}) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);
    const {data: promotionalCampaign} = useGetPromotionalCampaignQuery(productId, promotionalCampaignId);

    useEffect(() => {
        setEditMode(false);
    }, [productId, promotionalCampaignId, promotionalCampaign]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {promotionalCampaign !== undefined && (
                        <Segment className="card-container">
                            <Header as="h2" dividing>
                                {promotionalCampaign.code}
                            </Header>
                            {editMode ? (
                                <PromotionalCampaignDetailsEdit
                                    productId={productId}
                                    promotionalCampaignId={promotionalCampaignId}
                                    promotionalCampaign={promotionalCampaign}
                                    setEdit={setEditMode}
                                />
                            ) : (
                                <PromotionalCampaignDetailsRead
                                    productId={productId}
                                    promotionalCampaignId={promotionalCampaignId}
                                    promotionalCampaign={promotionalCampaign}
                                    setEdit={setEditMode}
                                />
                            )}
                        </Segment>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default PromotionalCampaignDetails;
