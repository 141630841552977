import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { getSortMethod, SortOption } from "../../Utils/SortUtils";

type SortPropsType = {
    sortOptions: SortOption[];
    setSortMethod: React.Dispatch<React.SetStateAction<(a: any, b: any) => number>>;
};

const Sort = ({sortOptions, setSortMethod}: SortPropsType) => {
    const {t} = useTranslation();

    return (
        <Dropdown
            icon="sort"
            labeled
            button
            scrolling
            className="icon"
            name="sort"
            selection
            options={sortOptions.map((sortOption) => {
                return {
                    text: t(sortOption.text),
                    key: sortOption.text + "_" + sortOption.field + "_" + sortOption.fieldType + "_" + sortOption.order,
                    value:
                        sortOption.text + "_" + sortOption.field + "_" + sortOption.fieldType + "_" + sortOption.order,
                };
            })}
            placeholder={t("Sort")}
            onChange={(_, {value}) => {
                if (value !== undefined) {
                    setSortMethod(() =>
                        getSortMethod(
                            sortOptions.find(
                                (sortOption) =>
                                    sortOption.text +
                                        "_" +
                                        sortOption.field +
                                        "_" +
                                        sortOption.fieldType +
                                        "_" +
                                        sortOption.order ===
                                    value
                            )
                        )
                    );
                }
            }}
        />
    );
};

export default Sort;
