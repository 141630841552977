import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useNavigate, useParams} from "react-router-dom";
import {Grid, Input, Menu, Message, Segment, Tab} from "semantic-ui-react";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetQuestionsQuery} from "../../../../../Services/Question/Queries";
import {QuestionDTO, QuestionValueTypeEnum} from "../../../../../Services/Question/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {
    sortOptionsId,
    sortOptionsModificationDate,
    sortOptionsName,
    sortOptionsQuestionType,
} from "../../../../../Utils/SortUtils";
import ModalAddQuestion from "../../../Question/Fragments/ModalAddQuestion";
import QuestionCard from "./QuestionCard";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PropsType = {
    version: VersionDTO | undefined;
};

const QuestionsList: React.FC<PropsType> = ({version}: PropsType) => {
    const {t} = useTranslation();
    const {id: productId, versionId} = useParams() as IParams;

    const getQuestionsQuery = useGetQuestionsQuery(versionId);
    const [sortMethod, setSortMethod] = useState<(a: QuestionDTO, b: QuestionDTO) => number>(() => (a: QuestionDTO, b: QuestionDTO) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    const sortOptions = [
        ...sortOptionsName,
        ...sortOptionsId,
        ...sortOptionsModificationDate,
        ...sortOptionsQuestionType,
    ];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (name: string, valueType: QuestionValueTypeEnum) => {
        return (
            name.toLowerCase().includes(search.toLowerCase()) ||
            valueType.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    const navigate = useNavigate();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>

                                <Menu.Item>
                                    <Input
                                        name="searchQuestion"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("Question").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(_, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>

                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddQuestion />
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getQuestionsQuery.isSuccess &&
                            getQuestionsQuery.data !== undefined &&
                            getQuestionsQuery.data.length === 0 && (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No question")}
                                        content={t("There is no question for this version")}
                                    />
                                </Segment>
                            )}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getQuestionsQuery.isSuccess &&
                                getQuestionsQuery.data !== undefined &&
                                getQuestionsQuery.data.length !== 0 &&
                                getQuestionsQuery.data
                                    .slice()
                                    .filter((question: QuestionDTO) =>
                                        hiddenBySearch(question.name, question.valueType)
                                    )
                                    .sort(sortMethod)
                                    .map((question: QuestionDTO) => (
                                        <QuestionCard
                                            key={"card_question_" + question.id}
                                            onClick={() => navigate(`/products/${productId}/versions/${versionId}/questions/${question.id}`)}
                                            versionId={versionId}
                                            question={question}
                                        />
                                    ))}
                        </div>

                        {getQuestionsQuery.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default QuestionsList;
