import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {RegistryEntryDTO, RegistryEntryType} from "./Types";

export const getRegistries = async (
    registryEntryType: RegistryEntryType | null
): Promise<Array<RegistryEntryDTO>> => {
    try {
        const response = await axiosInstance.get<Array<RegistryEntryDTO>>(`/registries`, {
            params: registryEntryType == null ? {} : {type: registryEntryType},
        });
        return response.data;
    } catch (error: unknown | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw buildCustomError(error);
        } else {
            return Promise.reject(error);
        }
    }
};

export const getRegistry = async (registryEntryId: string): Promise<RegistryEntryDTO> => {
    try {
        const response = await axiosInstance.get<RegistryEntryDTO>(`/registries/${registryEntryId}`);
        return response.data;
    } catch (error: unknown | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw buildCustomError(error);
        } else {
            return Promise.reject(error);
        }
    }
};

export const addRegistry = async (registry: RegistryEntryDTO): Promise<RegistryEntryDTO> => {
    try {
        const response = await axiosInstance.post<RegistryEntryDTO>(`/registries`, registry);
        return response.data;
    } catch (error: unknown | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw buildCustomError(error);
        } else {
            return Promise.reject(error);
        }
    }
};

export const updateRegistry = async (
    registryEntryId: string,
    registryEntryDetails: RegistryEntryDTO
): Promise<RegistryEntryDTO> => {
    try {
        const response = await axiosInstance.put<RegistryEntryDTO>(
            `/registries/${registryEntryId}`,
            registryEntryDetails
        );
        return response.data;
    } catch (error: unknown | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw buildCustomError(error);
        } else {
            return Promise.reject(error);
        }
    }
};

export const deleteRegistry = async (registryEntryId: string): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`/registries/${registryEntryId}`);
        return response.data;
    } catch (error: unknown | AxiosError) {
        if (axios.isAxiosError(error)) {
            throw buildCustomError(error);
        } else {
            return Promise.reject(error);
        }
    }
};

