import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Container, Grid} from "semantic-ui-react";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetSelfCompanyQuery, useGetUserGroupsQuery} from "../../../Services/Company/Queries";
import UserGroupDetails from "./Fragments/UserGroupDetails";

interface IParams extends Params {
    userGroupId: string;
}

const UserGroupPage = () => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);
    const companyQueryResult = useGetSelfCompanyQuery();

    const userGroupsQueryResult = useGetUserGroupsQuery(
        String(companyQueryResult?.data?.id),
        companyQueryResult?.data !== undefined
    );

    useEffect(() => {
        if (userGroupsQueryResult.isSuccess && userGroupsQueryResult.data !== undefined) {
            const sidebarItems = userGroupsQueryResult.data.map(
                (group) =>
                    new SidebarItem(
                        group.name,
                        group.name,
                        `/transversal_settings/rest_api_access/user_groups/${group.id}`
                    )
            );
            setMenus([new SidebarMenu("", sidebarItems)]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGroupsQueryResult.data]);

    return (
        <Container fluid>
            <Sidebar title={`${t("User groups")}`} menus={menus} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            {companyQueryResult.data !== undefined && (
                                <UserGroupDetails
                                    companyId={String(companyQueryResult.data.id)}
                                    userGroupId={params.userGroupId}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default UserGroupPage;
