import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Button, Divider, Form, Grid, Header, Segment } from "semantic-ui-react";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import { useUpdateBehaviourDetailsMutation } from "../../../../Services/ProductBehaviour/Queries";
import { ProductBehaviorWithRegistryDTO, ProductRegistryTaxJoin } from "../../../../Services/ProductBehaviour/Types";
import "../../Product/Fragments/ProductDetails/Products.css";

type PropsType = {
    behaviourDetails: ProductBehaviorWithRegistryDTO;
    toggleEditBehaviourDetailsModal: () => void;
};

type BehaviourFormDataType = {
    applyProrata: boolean;
    chargingMode: string;
    code: string;
    companyCode: string;
    contributionCommissionRate: number;
    emitOnDeadline: boolean;
    excludeVATFree: boolean;
    label: string;
    managementCommissionRate: number;
    refundMode: string;
    renewOnDeadline: boolean;
};

interface IParams extends Params {
    id: string;
    behaviourId: string;
}

const BehaviourDetailsEdit = ({behaviourDetails, toggleEditBehaviourDetailsModal}: PropsType) => {
    const {t} = useTranslation();
    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<any>({mode: "onBlur"});
    const params = useParams() as IParams;

    const chargingModeOptions = [
        {text: t("enums.HTANNUEL"), value: "HTANNUEL"},
        {text: t("enums.TTCANNUEL"), value: "TTCANNUEL"},
        {text: t("enums.HTMENSUEL"), value: "HTMENSUEL"},
        {text: t("enums.TTCMENSUEL"), value: "TTCMENSUEL"},
    ];

    const refundModeOptions = [
        {text: t("always"), value: "O"},
        {text: t("Only in no effect"), value: "N"},
        {text: t("never"), value: "J"},
    ];

    const updateBehaviourMutation = useUpdateBehaviourDetailsMutation(params.id, params.behaviourId);

    const onBehaviourUpdateSuccess = () => {
        toggleEditBehaviourDetailsModal();
    };

    const submitForm = async (formData: BehaviourFormDataType) => {
        const newBehaviourData = {
            productRegistryJoin: {
                ...formData,
            },
            productRegistryTaxJoins: behaviourDetails.productRegistryTaxJoins.map((item: ProductRegistryTaxJoin) => ({
                taxCategoryCode: item.taxCategoryCode,
                ifCondition: item.ifCondition,
                conditionOrder: item.conditionOrder,
            })),
        };

        updateBehaviourMutation.mutate(newBehaviourData);
    };

    return (
        <>
            <Segment style={{padding: "25px"}}>
                <Header as="h2" dividing>
                    {behaviourDetails.registry.type === "G" ? t("Coverage") : t("Fee")}
                </Header>
                <Form>
                    <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <strong>{t("Code")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.registry.code}
                                    name={"code"}
                                    render={({field: {value}}) => <Grid.Column width={9}>{value}</Grid.Column>}
                                />
                            </Grid.Column>

                            <Grid.Column width={7}>
                                <strong>{t("Label")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.registry.label}
                                    name={"label"}
                                    render={({field: {value}}) => <Grid.Column width={9}>{value}</Grid.Column>}
                                />
                            </Grid.Column>

                            <Grid.Column width={7}>
                                <strong>{t("Charging mode")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    name={"chargingMode"}
                                    defaultValue={behaviourDetails.productRegistryJoin.chargingMode}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name, ref, onBlur}}) => (
                                        <Form.Select
                                            fluid
                                            name={name}
                                            maxLength="50"
                                            placeholder={t("Charging mode")}
                                            onChange={(e, {value}) => onChange(value)}
                                            value={value}
                                            options={chargingModeOptions}
                                            onBlur={onBlur}
                                            error={
                                                errors.chargingMode
                                                    ? {
                                                          content: t("this field is required"),
                                                      }
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Header as="h2" dividing>
                        {t("company")}
                    </Header>

                    <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <strong>{t("Company code")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.companyCode || ""}
                                    name={"companyCode"}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Form.Input
                                            name={name}
                                            fluid
                                            maxLength={"5"}
                                            placeholder={t("Company code")}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("contribution commission rate")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.contributionCommissionRate}
                                    name={"contributionCommissionRate"}
                                    rules={{required: true}}
                                    render={({field: {name, value, onChange, ref, onBlur}}) => (
                                        <Form.Input
                                            name={name}
                                            type="number"
                                            fluid
                                            maxLength="50"
                                            placeholder={t("contribution commission rate")}
                                            onChange={(e: any, {value}: {value: string}) => onChange(Number(value))}
                                            value={value}
                                            onBlur={onBlur}
                                            error={
                                                errors.contributionCommissionRate
                                                    ? {
                                                          content: t("this field is required"),
                                                      }
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("management commission rate")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.managementCommissionRate}
                                    name={"managementCommissionRate"}
                                    rules={{required: true}}
                                    render={({field: {name, value, onChange, ref, onBlur}}) => (
                                        <Form.Input
                                            name={name}
                                            type="number"
                                            fluid
                                            maxLength="50"
                                            placeholder={t("management commission rate")}
                                            onChange={(e: any, {value}: {value: string}) => onChange(Number(value))}
                                            value={value}
                                            onBlur={onBlur}
                                            error={
                                                errors.managementCommissionRate
                                                    ? {
                                                          content: t("this field is required"),
                                                      }
                                                    : false
                                            }
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Header as="h2" dividing>
                        {t("behaviour")}
                    </Header>

                    <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <strong>{t("emit on deadline")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.emitOnDeadline}
                                    name={"emitOnDeadline"}
                                    render={({field: {value, name, onChange, ref}}) => (
                                        <Form.Radio
                                            toggle
                                            aria-label={"deadline"}
                                            onChange={(e: any, {checked}) => onChange(checked)}
                                            name={name}
                                            checked={value}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("renew on deadline")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.renewOnDeadline}
                                    name={"renewOnDeadline"}
                                    render={({field: {value, name, onChange, ref}}) => (
                                        <Form.Radio
                                            toggle
                                            aria-label={"renew on deadline"}
                                            onChange={(e: any, {checked}) => onChange(checked)}
                                            name={name}
                                            checked={value}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("exclude VAT free")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.excludeVATFree}
                                    name={"excludeVATFree"}
                                    render={({field: {value, name, onChange, ref}}) => (
                                        <Form.Radio
                                            toggle
                                            aria-label={"excludeVATFree"}
                                            onChange={(e, {checked}) => onChange(checked)}
                                            name={name}
                                            checked={value}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("apply prorata")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.applyProrata}
                                    name={"applyProrata"}
                                    render={({field: {value, name, onChange, ref}}) => (
                                        <Form.Radio
                                            toggle
                                            aria-label={"applyProrata"}
                                            onChange={(e, {checked}) => onChange(checked)}
                                            name={name}
                                            checked={value}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("Refund mode")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    name={"refundMode"}
                                    rules={{required: true}}
                                    defaultValue={behaviourDetails.productRegistryJoin.refundMode}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Form.Select
                                            fluid
                                            name={name}
                                            maxLength="50"
                                            placeholder={t("Refund mode")}
                                            onChange={(e, {value}) => onChange(value)}
                                            value={value}
                                            options={refundModeOptions}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("Apply discount")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.applyDiscount}
                                    name={"applyDiscount"}
                                    render={({field: {value, name, onChange, ref}}) => (
                                        <Form.Radio
                                            toggle
                                            aria-label={"applyDiscount"}
                                            onChange={(e, {checked}) => onChange(checked)}
                                            name={name}
                                            checked={value}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <strong>{t("Exoneration active")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={9}>
                                <Controller
                                    control={control}
                                    defaultValue={behaviourDetails.productRegistryJoin.exonerationActive}
                                    name={"exonerationActive"}
                                    render={({field: {value, name, onChange, ref}}) => (
                                        <Form.Radio
                                            toggle
                                            aria-label={"exonerationActive"}
                                            onChange={(e, {checked}) => onChange(checked)}
                                            name={name}
                                            checked={value}
                                        />
                                    )}
                                />
                            </Grid.Column>

                            {behaviourDetails.registry.type === "G" && (
                                <>
                                    <Grid.Column width={7}>
                                        <strong>{t("Apply terrorism tax")} : </strong>
                                    </Grid.Column>
                                    <Grid.Column width={9}>
                                        <Controller
                                            control={control}
                                            defaultValue={behaviourDetails.productRegistryJoin.applyTerrorismTax}
                                            name={"applyTerrorismTax"}
                                            render={({field: {value, name, onChange, ref}}) => (
                                                <Form.Radio
                                                    toggle
                                                    aria-label={"Apply terrorism tax"}
                                                    onChange={(e, {checked}) => onChange(checked)}
                                                    name={name}
                                                    checked={value}
                                                />
                                            )}
                                        />
                                    </Grid.Column>
                                </>
                            )}
                        </Grid.Row>
                    </Grid>

                    <div className={"bottom-button-card"} style={{bottom: 0}}>
                        <Divider />
                        <Button name="cancel" secondary onClick={toggleEditBehaviourDetailsModal}>
                            {t("Cancel")}
                        </Button>
                        <ModalUpdate
                            isPending={updateBehaviourMutation.isPending}
                            isSuccess={updateBehaviourMutation.isSuccess}
                            isError={updateBehaviourMutation.isError}
                            resetMutation={updateBehaviourMutation.reset}
                            error={updateBehaviourMutation.error}
                            onSuccess={onBehaviourUpdateSuccess}
                            onValidate={handleSubmit(submitForm)}
                            objectToUpdate={t("Product behaviour")}
                            objectType="male"
                        />
                    </div>
                </Form>
            </Segment>
        </>
    );
};

export default BehaviourDetailsEdit;
