import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Form, Grid, Header, Input, Segment} from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {
    useDeleteUserGroupMutation,
    useGetUserGroupQuery,
    useUpdateUserGroupMutation,
} from "../../../../Services/Company/Queries";
import {UserGroupAuthorizationsDTO, UserGroupDTO} from "../../../../Services/Company/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";

type UserGroupDetailsPropsType = {
    companyId: string;
    userGroupId: string;
};

type UserGroupDetailsViewPropsType = {
    companyId: string;
    userGroupId: string;
    userGroup: UserGroupDTO;
    t: Function;
    toggleEditMode: () => void;
};

type UserGroupDetailsEditPropsType = {
    companyId: string;
    userGroupId: string;
    userGroup: UserGroupDTO;
    t: Function;
    toggleEditMode: () => void;
};

const UserGroupDetails = ({companyId, userGroupId}: UserGroupDetailsPropsType) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);

    const userGroupQueryResult = useGetUserGroupQuery(companyId, userGroupId, companyId !== undefined);

    const toggleEditMode = () => userGroupQueryResult.data && setEditMode(!editMode);

    if (userGroupQueryResult.data === undefined) return null;
    if (editMode)
        return (
            <UserGroupDetailsEdit
                companyId={companyId}
                userGroupId={userGroupId}
                userGroup={userGroupQueryResult.data}
                t={t}
                toggleEditMode={toggleEditMode}
            />
        );

    return (
        <UserGroupDetailsView
            companyId={companyId}
            userGroupId={userGroupId}
            userGroup={userGroupQueryResult.data}
            t={t}
            toggleEditMode={toggleEditMode}
        />
    );
};

const UserGroupDetailsView = ({
    companyId,
    userGroupId,
    userGroup,
    t,
    toggleEditMode,
}: UserGroupDetailsViewPropsType) => {
    const navigate = useNavigate();

    const deleteUserGroupMutation = useDeleteUserGroupMutation(companyId, userGroupId);

    const handleDelete = () => {
        deleteUserGroupMutation.mutate();
    };

    const onUserGroupDeleteSuccess = () => {
        navigate(`/transversal_settings/rest_api_access`, {
            state: {activeTabIndex: 1},
        });
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {userGroup.name}
            </Header>

            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Name")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{userGroup.name}</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("nbDaysAuthorizedBeforePricingDate")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {userGroup.userGroupAuthorizations !== null
                            ? userGroup.userGroupAuthorizations.nbDaysAuthorizedBeforePricingDate
                            : 0}{" "}
                        {t("day(s)")}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="bottom-button-card">
                <Divider />
                <Button primary name="editGroup" onClick={toggleEditMode}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteGroup"
                    isSuccess={deleteUserGroupMutation.isSuccess}
                    isPending={deleteUserGroupMutation.isPending}
                    isError={deleteUserGroupMutation.isError}
                    resetMutation={deleteUserGroupMutation.reset}
                    onSuccess={onUserGroupDeleteSuccess}
                    onValidate={handleDelete}
                    objectToDelete="User group"
                    objectType="male"
                />
            </div>
        </Segment>
    );
};

const UserGroupDetailsEdit = ({
    companyId,
    userGroupId,
    userGroup,
    t,
    toggleEditMode,
}: UserGroupDetailsEditPropsType) => {
    const {control, handleSubmit, formState} = useForm<any>({mode: "onBlur"});

    const onUserGroupUpdateSuccess = () => {
        toggleEditMode();
    };

    const updateUserGroupMutation = useUpdateUserGroupMutation(companyId, userGroupId);

    const submitForm = async (formData: any) => {
        const userGroupAuthorizations: UserGroupAuthorizationsDTO = {
            id: userGroup.userGroupAuthorizations !== null ? userGroup.userGroupAuthorizations.id : null,
            nbDaysAuthorizedBeforePricingDate: Number(formData.nbDaysAuthorizedBeforePricingDate),
        };

        const userGroupUpdate: UserGroupDTO = {
            id: Number(userGroupId),
            name: formData.name,
            userGroupAuthorizations: userGroupAuthorizations,
        };
        updateUserGroupMutation.mutate(userGroupUpdate);
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {userGroup.name}
            </Header>
            <Form>
                <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                    <Grid.Row style={{paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("Name")} * : </strong>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Controller
                                control={control}
                                defaultValue={userGroup.name}
                                name={"name"}
                                rules={{required: true}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        required
                                        name={name}
                                        placeholder={t("name")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(t, formState.errors, "name")}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                        <Grid.Column width={8}>
                            <strong>{t("nbDaysAuthorizedBeforePricingDate")} * : </strong>
                        </Grid.Column>
                        <Grid.Column>
                            <Controller
                                control={control}
                                defaultValue={
                                    userGroup.userGroupAuthorizations !== null
                                        ? userGroup.userGroupAuthorizations.nbDaysAuthorizedBeforePricingDate
                                        : 0
                                }
                                name={"nbDaysAuthorizedBeforePricingDate"}
                                rules={{required: true, min: 0}}
                                render={({field: {value, name, onChange, onBlur}}) => (
                                    <Input
                                        label={{basic: true, content: t("day(s)")}}
                                        labelPosition="right"
                                        fluid
                                        name={name}
                                        placeholder={t("nbDaysAuthorizedBeforePricingDate")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        type="number"
                                        min="0"
                                        error={getErrorMessage(
                                            t,
                                            formState.errors,
                                            name,
                                            undefined,
                                            undefined,
                                            undefined,
                                            0
                                        )}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div className="bottom-button-card">
                    <Divider />
                    <Button name="cancel" secondary onClick={toggleEditMode}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        onSuccess={onUserGroupUpdateSuccess}
                        resetMutation={updateUserGroupMutation.reset}
                        isSuccess={updateUserGroupMutation.isSuccess}
                        isPending={updateUserGroupMutation.isPending}
                        isError={updateUserGroupMutation.isError}
                        error={updateUserGroupMutation.error}
                        onValidate={handleSubmit(submitForm)}
                        objectToUpdate="User group"
                        objectType="male"
                        openModalButtonName="updateGroup"
                        isModalDisabled={Object.keys(formState.errors).length !== 0}
                    />
                </div>
            </Form>
        </Segment>
    );
};

export default UserGroupDetails;
