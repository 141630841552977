import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {UseMutationResult} from "@tanstack/react-query";
import {Button, Divider, Grid, GridColumn, GridRow, Input, Label, Message, Segment, Step} from "semantic-ui-react";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {ProductRegistryTaxJoin} from "../../../../Services/ProductBehaviour/Types";
import {CustomError} from "../../../../Services/RequestUtils";
import {
    useGetCategories,
    useGetCategoryDetails,
    useGetDomains,
    useGetSubDomains,
} from "../../../../Services/Taxes/Queries";
import {Category, Domain, SubDomain} from "../../../../Services/Taxes/Types";

type BehaviourTaxesAssignementPropsType = {
    condition: ProductRegistryTaxJoin;
    onValidateMutate: Function;
    updateMutaion: UseMutationResult<unknown, CustomError, any, Error>;
};

const BehaviourTaxesAssignement = ({
    condition,
    onValidateMutate,
    updateMutaion,
}: BehaviourTaxesAssignementPropsType) => {
    const {t} = useTranslation();

    const [editCondition, setEditCondition] = useState<boolean>(false);

    const [editDomain, setEditDomain] = useState<Domain>();
    const [editSubDomain, setEditSubDomain] = useState<SubDomain>();
    const [editCategory, setEditCategory] = useState<Category>();

    const [editActiveStep, setEditActiveStep] = useState<number>(0);
    const [editSearchInput, setEditSearchInput] = useState<string>("");
    const [editOptionsList, setEditOptionsList] = useState<Array<any>>();

    const {data: categoryDetails, status: getCategoryDetailsStatus} = useGetCategoryDetails(condition.taxCategoryCode);

    const {data: domains, refetch: refetchDomains} = useGetDomains();
    const {data: subDomains, refetch: refetchSubDomains} = useGetSubDomains(
        editDomain !== undefined ? editDomain.id : 0,
        editDomain !== undefined
    );
    const {data: categories, refetch: refetchCategories} = useGetCategories(
        editSubDomain !== undefined ? editSubDomain.id : 0,
        editSubDomain !== undefined
    );

    const handleClickOnStepper = (stepperIndex: number) => {
        switch (stepperIndex) {
            case 1:
                setEditDomain(undefined);
                setEditSubDomain(undefined);
                setEditCategory(undefined);
                setEditSearchInput("");
                setEditActiveStep(stepperIndex);
                break;
            case 2:
                if (editActiveStep !== 1) {
                    setEditSubDomain(undefined);
                    setEditCategory(undefined);
                    setEditSearchInput("");
                    setEditActiveStep(stepperIndex);
                }
                break;
            case 3:
                if (editActiveStep !== 1 && editActiveStep !== 2) {
                    setEditCategory(undefined);
                    setEditSearchInput("");
                    setEditActiveStep(stepperIndex);
                }
                break;
        }
    };

    const handleClickOnStepperOption = (value: any) => {
        switch (editActiveStep) {
            case 1:
                setEditDomain(value as Domain);
                setEditActiveStep(2);
                break;
            case 2:
                setEditSubDomain(value as SubDomain);
                setEditActiveStep(3);
                break;
            case 3:
                setEditCategory(value as Category);
                setEditActiveStep(0);
                break;
        }
        setEditSearchInput("");
    };

    const getEditSearchInputPlaceholderContext = () => {
        switch (editActiveStep) {
            case 1:
                return {
                    context: "male",
                    entity: t("Domain").toLowerCase(),
                };
            case 2:
                return {
                    context: "male",
                    entity: t("Sub-domain").toLowerCase(),
                };
            case 3:
                return {
                    context: "female",
                    entity: t("Category").toLowerCase(),
                };
        }
    };

    useEffect(() => {
        switch (editActiveStep) {
            case 1:
                refetchDomains();
                setEditOptionsList(domains);
                break;
            case 2:
                refetchSubDomains();
                setEditOptionsList(subDomains);
                break;
            case 3:
                refetchCategories();
                setEditOptionsList(categories);
                break;
        }
    }, [editActiveStep, domains, refetchDomains, refetchSubDomains, subDomains, refetchCategories, categories]);

    useEffect(() => {
        if (getCategoryDetailsStatus === "success" && categoryDetails !== undefined) {
            setEditDomain(categoryDetails.domain);
            setEditSubDomain(categoryDetails.subDomain);
            setEditCategory(categoryDetails.category);
            setEditActiveStep(0);
        }
    }, [categoryDetails, getCategoryDetailsStatus, editCondition]);

    return (
        <>
            {editCondition ? (
                <>
                    {editActiveStep === 0 &&
                    editDomain === undefined &&
                    editSubDomain === undefined &&
                    editCategory === undefined ? (
                        <Message
                            warning
                            header="Aucunes taxes"
                            content="Aucunes taxes ne sera appliqué sur la garantie"></Message>
                    ) : (
                        <>
                            <Step.Group fluid widths={3} style={{marginBottom: "0"}} size="big">
                                <Step
                                    name="step-domain"
                                    active={editActiveStep === 1}
                                    onClick={() => handleClickOnStepper(1)}
                                    style={{display: "block"}}>
                                    <Step.Title style={{border: "none", textAlign: "center"}}>{t("Domain")}</Step.Title>
                                    {editDomain && (
                                        <Step.Description style={{padding: "0.25em 0.25em", textAlign: "center"}}>
                                            {editDomain.name}
                                        </Step.Description>
                                    )}
                                </Step>
                                <Step
                                    name="step-sub-domain"
                                    active={editActiveStep === 2}
                                    onClick={() => handleClickOnStepper(2)}
                                    style={{display: "block"}}>
                                    <Step.Title style={{border: "none", textAlign: "center"}}>
                                        {t("Sub-domain")}
                                    </Step.Title>
                                    {editSubDomain && (
                                        <Step.Description style={{padding: "0.25em 0.25em", textAlign: "center"}}>
                                            {editSubDomain.name}
                                        </Step.Description>
                                    )}
                                </Step>
                                <Step
                                    name="step-category"
                                    active={editActiveStep === 3}
                                    onClick={() => handleClickOnStepper(3)}
                                    style={{display: "block"}}>
                                    <Step.Title style={{border: "none", textAlign: "center"}}>
                                        {t("Category")}
                                    </Step.Title>
                                    {editCategory && (
                                        <Step.Description style={{padding: "0.25em 0.25em", textAlign: "center"}}>
                                            {editCategory.categoryCode}
                                        </Step.Description>
                                    )}
                                </Step>
                            </Step.Group>
                            <Segment attached="top" style={{marginTop: "0", border: "none"}}>
                                {editActiveStep === 0 && editCategory ? (
                                    <>
                                        <Message>{editCategory.description}</Message>

                                        <Grid
                                            columns={editCategory.changes.length > 1 ? 2 : 1}
                                            divided
                                            style={{margin: "0"}}>
                                            {editCategory.changes
                                                .sort((a, b) => {
                                                    return new Date(a.effectiveDate) > new Date(b.effectiveDate)
                                                        ? 1
                                                        : -1;
                                                })
                                                .slice(editCategory.changes.length - 2)
                                                .map((change, index) => {
                                                    return (
                                                        <Grid.Column
                                                            key={"change-" + index}
                                                            textAlign="center"
                                                            style={{paddingBottom: "0px"}}>
                                                            <Label
                                                                name={"effective-date-" + (index + 1)}
                                                                basic
                                                                size="big"
                                                                style={{border: "none", marginBottom: "0px"}}>
                                                                <b>
                                                                    {t("format_date", {
                                                                        value: change.effectiveDate,
                                                                    })}
                                                                </b>
                                                            </Label>
                                                            <Label
                                                                name={"taxes-rate-" + (index + 1)}
                                                                basic
                                                                size="big"
                                                                style={{
                                                                    border: "none",
                                                                    marginBottom: "0px",
                                                                    display: "block",
                                                                }}>
                                                                <b>
                                                                    {change.taxes
                                                                        .reduce((accumulator, current) => {
                                                                            return accumulator + current.taxRate;
                                                                        }, 0)
                                                                        .toFixed(2) + " %"}
                                                                </b>
                                                            </Label>
                                                        </Grid.Column>
                                                    );
                                                })}
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            fluid
                                            required
                                            value={editSearchInput}
                                            name="edit-search"
                                            placeholder={t("Find entity", getEditSearchInputPlaceholderContext())}
                                            type="string"
                                            onChange={(e, {value}) => {
                                                setEditSearchInput(value);
                                            }}
                                            action>
                                            <input />
                                            <Button
                                                attached="right"
                                                name="edit-search-clear"
                                                icon="delete"
                                                onClick={(e) => {
                                                    setEditSearchInput("");
                                                }}
                                            />
                                        </Input>

                                        <div style={{height: "200px"}}>
                                            <div className="ui dropdown" style={{width: "100%", height: "100%"}}>
                                                <div
                                                    className="visible menu transition"
                                                    style={{
                                                        width: "100%",
                                                        maxHeight: "100%",
                                                        overflowY: "scroll",
                                                        top: "0",
                                                        borderTop: "none",
                                                    }}>
                                                    {editActiveStep === 1
                                                        ? editOptionsList
                                                              ?.filter((domain: Domain) => {
                                                                  return (
                                                                      editSearchInput === "" ||
                                                                      domain.name
                                                                          .toLowerCase()
                                                                          .includes(editSearchInput.toLowerCase())
                                                                  );
                                                              })
                                                              .map((domain: Domain, index) => {
                                                                  return (
                                                                      <div
                                                                          key={"domain-" + index}
                                                                          role="option"
                                                                          aria-checked="false"
                                                                          aria-selected="false"
                                                                          className="item"
                                                                          onClick={() =>
                                                                              handleClickOnStepperOption(domain)
                                                                          }>
                                                                          <span className="text">{domain.name}</span>
                                                                      </div>
                                                                  );
                                                              })
                                                        : editActiveStep === 2
                                                        ? editOptionsList
                                                              ?.filter((subDomain: SubDomain) => {
                                                                  return (
                                                                      editSearchInput === "" ||
                                                                      subDomain.name
                                                                          .toLowerCase()
                                                                          .includes(editSearchInput.toLowerCase())
                                                                  );
                                                              })
                                                              .map((subDomain: SubDomain, index) => {
                                                                  return (
                                                                      <div
                                                                          key={"subDomain-" + index}
                                                                          role="option"
                                                                          aria-checked="false"
                                                                          aria-selected="false"
                                                                          className="item"
                                                                          onClick={() =>
                                                                              handleClickOnStepperOption(subDomain)
                                                                          }>
                                                                          <span className="text">{subDomain.name}</span>
                                                                      </div>
                                                                  );
                                                              })
                                                        : editActiveStep === 3
                                                        ? editOptionsList
                                                              ?.filter((category: Category) => {
                                                                  return (
                                                                      editSearchInput === "" ||
                                                                      (category.categoryCode !== null &&
                                                                          category.categoryCode
                                                                              .toLowerCase()
                                                                              .includes(
                                                                                  editSearchInput.toLowerCase()
                                                                              )) ||
                                                                      (category.categoryCode !== null &&
                                                                          category.description
                                                                              .toLowerCase()
                                                                              .includes(editSearchInput.toLowerCase()))
                                                                  );
                                                              })
                                                              .map((category: Category, index) => {
                                                                  return (
                                                                      <div
                                                                          key={"category-" + index}
                                                                          role="option"
                                                                          aria-checked="false"
                                                                          aria-selected="false"
                                                                          className="item"
                                                                          onClick={() =>
                                                                              handleClickOnStepperOption(category)
                                                                          }>
                                                                          <span className="text">
                                                                              {category.categoryCode}
                                                                          </span>
                                                                      </div>
                                                                  );
                                                              })
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Segment>
                        </>
                    )}

                    <Divider />
                    <Grid>
                        <GridRow>
                            <GridColumn textAlign={"right"}>
                                <Button name="cancel" secondary onClick={() => setEditCondition(false)}>
                                    {t("Cancel")}
                                </Button>
                                {editActiveStep === 0 && editCategory === undefined ? (
                                    <Button
                                        color="blue"
                                        name="add-taxe"
                                        onClick={() => {
                                            setEditCondition(true);
                                            setEditActiveStep(1);
                                        }}>
                                        {t("Add taxe")}
                                    </Button>
                                ) : (
                                    <Button
                                        color="red"
                                        name="remove-taxe"
                                        onClick={() => {
                                            setEditDomain(undefined);
                                            setEditSubDomain(undefined);
                                            setEditCategory(undefined);
                                            setEditActiveStep(0);
                                        }}>
                                        {t("Remove taxe")}
                                    </Button>
                                )}
                                <ModalUpdate
                                    isModalDisabled={editActiveStep !== 0 && editCategory === undefined}
                                    isPending={updateMutaion.isPending}
                                    isSuccess={updateMutaion.isSuccess}
                                    isError={updateMutaion.isError}
                                    resetMutation={updateMutaion.reset}
                                    error={updateMutaion.error}
                                    onSuccess={() => setEditCondition(false)}
                                    onValidate={() => onValidateMutate(editCategory ? editCategory.categoryCode : "")}
                                    objectToUpdate={t("Assignment")}
                                    objectType="nonbinary"
                                />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </>
            ) : (
                <>
                    {categoryDetails !== undefined && getCategoryDetailsStatus === "success" ? (
                        <>
                            <Grid columns={3} divided style={{margin: "0"}}>
                                <Grid.Column textAlign="center" style={{paddingBottom: "0px"}}>
                                    <Label basic style={{border: "none", fontSize: "1.15rem"}}>
                                        {t("Domain")}
                                    </Label>
                                    <Segment size="big" style={{boxShadow: "none", border: "none", margin: "0"}}>
                                        <b>{categoryDetails.domain.name}</b>
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column textAlign="center" style={{paddingBottom: "0px"}}>
                                    <Label basic style={{border: "none", fontSize: "1.15rem"}}>
                                        {t("Sub-domain")}
                                    </Label>
                                    <Segment size="big" style={{boxShadow: "none", border: "none", margin: "0"}}>
                                        <b>{categoryDetails.subDomain.name}</b>
                                    </Segment>
                                </Grid.Column>

                                <Grid.Column textAlign="center" style={{paddingBottom: "0px"}}>
                                    <Label basic style={{border: "none", fontSize: "1.15rem"}}>
                                        {t("Category")}
                                    </Label>
                                    <Segment size="big" style={{boxShadow: "none", border: "none", margin: "0"}}>
                                        <b>{categoryDetails.category.categoryCode}</b>
                                    </Segment>
                                    <Button
                                        style={{position: "absolute", top: "25%", right: "5%"}}
                                        name={"edit-assignement-" + condition.id}
                                        color="grey"
                                        icon="edit"
                                        compact
                                        basic
                                        onClick={() => {
                                            setEditCondition(true);
                                        }}
                                    />
                                </Grid.Column>
                            </Grid>

                            <Message name="category-description">{categoryDetails.category.description}</Message>

                            <Grid
                                columns={categoryDetails.category.changes.length > 1 ? 2 : 1}
                                divided
                                style={{margin: "0"}}>
                                {categoryDetails.category.changes
                                    .sort((a, b) => {
                                        return new Date(a.effectiveDate) > new Date(b.effectiveDate) ? 1 : -1;
                                    })
                                    .slice(categoryDetails.category.changes.length - 2)
                                    .map((change, index) => {
                                        return (
                                            <Grid.Column
                                                key={"change-" + index}
                                                textAlign="center"
                                                style={{paddingBottom: "0px"}}>
                                                <Label
                                                    name={"effective-date-" + (index + 1)}
                                                    basic
                                                    size="big"
                                                    style={{border: "none", marginBottom: "0px"}}>
                                                    <b>
                                                        {t("format_date", {
                                                            value: change.effectiveDate,
                                                        })}
                                                    </b>
                                                </Label>
                                                <Label
                                                    name={"taxes-rate-" + (index + 1)}
                                                    basic
                                                    size="big"
                                                    style={{border: "none", marginBottom: "0px", display: "block"}}>
                                                    <b>
                                                        {change.taxes
                                                            .reduce((accumulator, current) => {
                                                                return accumulator + current.taxRate;
                                                            }, 0)
                                                            .toFixed(2) + " %"}
                                                    </b>
                                                </Label>
                                            </Grid.Column>
                                        );
                                    })}
                            </Grid>
                        </>
                    ) : (
                        condition.taxCategoryCode === "" && (
                            <div style={{position: "relative"}}>
                                <Message
                                    warning
                                    header={t("No taxes")}
                                    content={t("No taxes will be applied to this coverage")}></Message>
                                <Button
                                    style={{position: "absolute", top: "30%", right: "2%"}}
                                    name={"edit-assignement-" + condition.id}
                                    color="grey"
                                    icon="edit"
                                    compact
                                    basic
                                    onClick={() => {
                                        setEditCondition(true);
                                    }}
                                />
                            </div>
                        )
                    )}
                </>
            )}
        </>
    );
};

export default BehaviourTaxesAssignement;
