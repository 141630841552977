import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link, Params, useParams} from "react-router-dom";
import {Card, Grid, Header, Menu, Message, Tab} from "semantic-ui-react";
import FormFeeCharge from "../../../../../Components/Forms/FormFeeCharge";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useAddFeeChargeMutation, useGetFeeChargesQuery} from "../../../../../Services/InsurancePackage/Queries";
import {FeeChargeType, FeeType} from "../../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type FeeChargeListPropsType = {
    version: VersionDTO | undefined;
};

const FeeChargeList: React.FC<FeeChargeListPropsType> = ({version}) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const userContext = React.useContext(AuthContext);

    const feeChargesQueryResult = useGetFeeChargesQuery(params.insurancePackageId);

    // Add fee charge
    const [defaultFeeChargeRegistryId, setDefaultFeeChargeRegistryId] = useState<number | undefined>();
    const [defaultFeeChargeFeeType, setDefaultFeeChargeFeeType] = useState<FeeType | undefined>();

    const addFeeChargeMutation = useAddFeeChargeMutation(params.id, params.versionId, params.insurancePackageId);
    const addFeeChargeForm = useForm<any>();
    const submitAddFeeChargeForm = (form: any) => {
        addFeeChargeMutation.mutate({
            registryId: form.form_fee_charge_fee_registry,
            feeType: form.form_fee_charge_fee_type,
            type: FeeChargeType.FEE,
            rule: null,
        });
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column>
                        <Menu borderless secondary style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Item>
                                <Header as="h2">{t("Fees and charges")}</Header>
                            </Menu.Item>
                            {version?.pipDate === null && (
                                <Menu.Menu position="right">
                                    <Menu.Item>
                                        <ModalAdd
                                            isValidationDisabled={
                                                Object.keys(addFeeChargeForm.formState.errors).length !== 0
                                            }
                                            isPending={addFeeChargeMutation.isPending}
                                            isSuccess={addFeeChargeMutation.isSuccess}
                                            isError={addFeeChargeMutation.isError}
                                            error={addFeeChargeMutation.error}
                                            resetMutation={addFeeChargeMutation.reset}
                                            onValidate={addFeeChargeForm.handleSubmit(submitAddFeeChargeForm)}
                                            onCancel={() => {
                                                setDefaultFeeChargeRegistryId(undefined);
                                                setDefaultFeeChargeFeeType(undefined);
                                            }}
                                            onSuccess={() => {
                                                setDefaultFeeChargeRegistryId(undefined);
                                                setDefaultFeeChargeFeeType(undefined);
                                            }}
                                            objectToAdd={"Fee"}
                                            objectToAddContext={"male"}
                                            iconOnOpenButton
                                            openButtonIcon="plus"
                                            renderModalContent={() => (
                                                <FormFeeCharge
                                                    control={addFeeChargeForm.control}
                                                    errors={addFeeChargeForm.formState.errors}
                                                    defaultFeeChargeRegistryId={defaultFeeChargeRegistryId}
                                                    setDefaultFeeChargeRegistryId={setDefaultFeeChargeRegistryId}
                                                    defaultFeeChargeFeeType={defaultFeeChargeFeeType}
                                                    setDefaultFeeChargeFeeType={setDefaultFeeChargeFeeType}
                                                />
                                            )}
                                        />
                                    </Menu.Item>
                                </Menu.Menu>
                            )}
                        </Menu>
                        {feeChargesQueryResult.isSuccess &&
                            feeChargesQueryResult.data !== undefined &&
                            (feeChargesQueryResult.data.length === 0 ? (
                                <Message
                                    warning
                                    header={t("No fee")}
                                    content={t("There is no fee declared for this insurance package")}
                                />
                            ) : (
                                <div className={"ui cards " + columnNumberAsText} ref={ref}>
                                    {feeChargesQueryResult.data.map((feeCharge) => (
                                        <Card
                                            key={feeCharge.id}
                                            as={Link}
                                            to={`/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/fee-charges/${feeCharge.id}`}
                                        >
                                            <Card.Content>
                                                <Card.Header>
                                                    <TextOverflow value={feeCharge.label} />
                                                </Card.Header>
                                                <Card.Meta>
                                                    {t("Code")} : {feeCharge.code}
                                                </Card.Meta>
                                                <Card.Description>
                                                    <strong>{t("Fee type")} : </strong>
                                                    {feeCharge.feeType + " - "}
                                                    {t("enums.FeeType." + feeCharge.feeType)}
                                                </Card.Description>
                                            </Card.Content>

                                            {userContext.user !== undefined && (
                                                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                                                    <span>
                                                        {displayAuditLog(
                                                            {
                                                                entityContext: "male",
                                                                createdDate: feeCharge.createdDate,
                                                                modifiedDate: feeCharge.modifiedDate,
                                                                createdBy: feeCharge.createdBy,
                                                                modifiedBy: feeCharge.modifiedBy,
                                                                createdOnVersionId: feeCharge.createdOnVersionId,
                                                            },
                                                            userContext.user,
                                                            params.versionId,
                                                            t
                                                        )}
                                                    </span>
                                                </Card.Content>
                                            )}
                                        </Card>
                                    ))}
                                </div>
                            ))}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default FeeChargeList;
