import {
    DeductibleCapitalCondition,
    DeductibleCapitalConditionDTO,
    DeductibleCapitalConditionElementDTO
} from "../../../../../../Services/DeductibleCapital/Types";
import { GenericReducerActionType } from "../../../../../../Utils/ReducerUtils";

export type DeductibleCapitalConditionActionType = {
    type: GenericReducerActionType;
    assignmentType: AssignmentType;
    assignmentDimensionType?: AssignmentDimensionType;
    payload: any;
};

export enum AssignmentType {
    NOTHING = "",
    QUESTION = "QUESTION",
    FIXED_VALUE = "FIXED_VALUE",
    PRICING_CRITERIA = "PRICING_CRITERIA",
    VARIABLE = "VARIABLE",
    VARIABLE_COMPUTING_RULE = "VARIABLE_COMPUTING_RULE",
}

export enum AssignmentDimensionType {
    NOTHING = "NOTHING",
    QUESTION = "QUESTION",
    FIXED_VALUE = "FIXED_VALUE",
    PRICING_CRITERIA = "PRICING_CRITERIA",
    VARIABLE = "VARIABLE",
}

const deductibleCapitalConditionAssignementReducer = (
    conditionState: DeductibleCapitalCondition,
    action: DeductibleCapitalConditionActionType
) => {
    switch (action.type) {
        case GenericReducerActionType.EDIT: {
            const elementList: DeductibleCapitalConditionElementDTO[] = [
                ...action.payload.deductibleCapitalConditionElementList,
            ];

            const condition: DeductibleCapitalConditionDTO = {
                ifCondition: conditionState.ifCondition,
                conditionPartList: conditionState.conditionPartList,
                conditionOrder: conditionState.conditionOrder,
                deductibleCapitalConditionElementDTOs: [...elementList],
            };

            return condition;
        }
        case GenericReducerActionType.REMOVE: {
            return {
                ...conditionState,
                deductibleCapitalConditionElementDTOs: [
                    ...conditionState.deductibleCapitalConditionElements.filter(
                        (el) => el.order === action.payload.value
                    ),
                ],
            };
        }
        case GenericReducerActionType.UNDO:
            return action.payload;
        default:
            return conditionState;
    }
};

export default deductibleCapitalConditionAssignementReducer;
