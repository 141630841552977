import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalDuplicate from "../../../../Components/Modals/ModalDuplicate";
import {
    useDeletePricingCriteria,
    useDuplicatePricingCriteriaMutation,
} from "../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItem} from "../../../../Services/PricingCriterias/Types";
import FormDuplicatePricingCriterias from "../PricingCriteriasDetails/FormDuplicatePricingCriterias";
import ModalUpdatePricingCriteria from "./ModalUpdatePricingCriteria";

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
}

type PricingCriteriasActionsType = {
    pricingCriteria: PricingCriteriaItem;
    isVersionValid: boolean;
};

const PricingCriteriasActions = ({pricingCriteria, isVersionValid}: PricingCriteriasActionsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const deletePricingCriteriaMutation = useDeletePricingCriteria(params.id, params.versionId, pricingCriteria.id);

    const handleDelete = (pricingCriteriaId: number) => {
        deletePricingCriteriaMutation.mutate(pricingCriteriaId);
    };

    const [duplicatePricingCriteriaName, setDuplicatePricingCriteriaName] = useState("");
    const [duplicatePricingCriteriaDescription, setDuplicatePricingCriteriaDescription] = useState("");
    const duplicatePricingCriteriasForm = useForm<any>();
    const resetFormDuplicatePricingCriterias = () => {
        setDuplicatePricingCriteriaName("");
        setDuplicatePricingCriteriaDescription("");
    };
    const duplicatePricingCriteriasMutation = useDuplicatePricingCriteriaMutation(
        params.id,
        params.versionId,
        params.pricingCriteriaId
    );

    const submitDuplicate = (form: any) => {
        duplicatePricingCriteriasMutation.mutate({
            ...form,
        });
    };

    return (
        <Grid.Column textAlign={"right"} className={"breadcrumb-actions"}>
            <div className={"breadcrumb-actions-button-list"}>
                {!isVersionValid && (
                    <>
                        <ModalUpdatePricingCriteria
                            pricingCriteriaName={pricingCriteria.name}
                            pricingCriteriaType={pricingCriteria.type}
                            pricingCriteriaSaveQuote={pricingCriteria.saveQuote}
                        />
                        <ModalDuplicate
                            isValidationDisabled={
                                Object.keys(duplicatePricingCriteriasForm.formState.errors).length !== 0
                            }
                            onValidate={duplicatePricingCriteriasForm.handleSubmit(submitDuplicate)}
                            onCancel={resetFormDuplicatePricingCriterias}
                            onSuccess={resetFormDuplicatePricingCriterias}
                            objectToDuplicate={t("Pricing criteria")}
                            openModalButtonName="duplicate"
                            isSuccess={duplicatePricingCriteriasMutation.isSuccess}
                            isPending={duplicatePricingCriteriasMutation.isPending}
                            isError={duplicatePricingCriteriasMutation.isError}
                            resetMutation={duplicatePricingCriteriasMutation.reset}
                            error={duplicatePricingCriteriasMutation.error}
                            objectToDuplicateContext="male"
                            objectToDuplicateCodeOrLabel={pricingCriteria.name}
                            renderModalContent={() => (
                                <FormDuplicatePricingCriterias
                                    errors={duplicatePricingCriteriasForm.formState.errors}
                                    control={duplicatePricingCriteriasForm.control}
                                    defaultValueName={duplicatePricingCriteriaName}
                                    setDefaultValueName={setDuplicatePricingCriteriaName}
                                    defaultValueDescription={duplicatePricingCriteriaDescription}
                                    setDefaultValueDescription={setDuplicatePricingCriteriaDescription}
                                />
                            )}
                            renderOpenButton={() => {
                                return (
                                    <Button name="duplicate" color="green">
                                        {t("Duplicate")}
                                    </Button>
                                );
                            }}
                        />
                        <ModalDelete
                            isSuccess={deletePricingCriteriaMutation.isSuccess}
                            isPending={deletePricingCriteriaMutation.isPending}
                            isError={deletePricingCriteriaMutation.isError}
                            error={deletePricingCriteriaMutation.error}
                            resetMutation={deletePricingCriteriaMutation.reset}
                            objectToDelete="Pricing criteria"
                            objectType="male"
                            onValidate={() => handleDelete(pricingCriteria.id)}
                        />
                    </>
                )}
            </div>
        </Grid.Column>
    );
};

export default PricingCriteriasActions;
