import {useTranslation} from "react-i18next";
import {Control, Controller} from "react-hook-form";
import {Button, Checkbox, Divider, Form, Grid, Table} from "semantic-ui-react";
import {PromotionalCampaign} from "../../../../../Services/PromotionalCampagins/Types";
import {useParams} from "react-router-dom";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import {useGetProductBehavioursQuery} from "../../../../../Services/ProductBehaviour/Queries";

type PromotionalCampaignFreeMonthDetailsEditProps = {
    productId: string;
    promotionalCampaignId: string;
    promotionalCampaign: PromotionalCampaign;
    control: Control<PromotionalCampaign>;
    errors: any;
};

const PromotionalCampaignFreeMonthDetailsEdit: React.FC<PromotionalCampaignFreeMonthDetailsEditProps> = ({
    productId,
    promotionalCampaignId,
    promotionalCampaign,
    control,
    errors,
}) => {
    const {t} = useTranslation();
    const params = useParams<{id: string}>();
    const getProductBehavioursQuery = useGetProductBehavioursQuery(productId);

    return (
        <>
            <Divider />

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Free month") + " (" + t("nth") + ")"} * : </strong>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Controller
                        control={control}
                        name={"processParams.0"}
                        defaultValue={promotionalCampaign.processParams[0]}
                        rules={{required: true}}
                        render={({field: {name, value, onChange, onBlur, ref}}) => (
                            <Form.Input
                                required
                                fluid
                                name={name}
                                type="number"
                                min={1}
                                placeholder={t("Free month") + " (" + t("nth") + ")"}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={getErrorMessage(t, errors, name)}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Authorized effective date start date")} * : </strong>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Controller
                        control={control}
                        name={"processParams.1"}
                        defaultValue={promotionalCampaign.processParams[1]}
                        rules={{required: true}}
                        render={({field: {name, value, onChange, onBlur, ref}}) => (
                            <Form.Input
                                required
                                fluid
                                name={name}
                                type="date"
                                placeholder={t("Authorized effective date start date")}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={getErrorMessage(t, errors, name)}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Authorized effective date end date")} * : </strong>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Controller
                        control={control}
                        name={"processParams.2"}
                        defaultValue={promotionalCampaign.processParams[2]}
                        rules={{required: true}}
                        render={({field: {name, value, onChange, onBlur, ref}}) => (
                            <Form.Input
                                required
                                fluid
                                name={name}
                                type="date"
                                placeholder={t("Authorized effective date end date")}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                error={getErrorMessage(t, errors, name)}
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                <Grid.Column width={8}>
                    <strong>{t("Coverage choices")} * : </strong>
                </Grid.Column>
            </Grid.Row>

            {getProductBehavioursQuery.isSuccess && getProductBehavioursQuery.data !== undefined && (
                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column>
                        <Controller
                            control={control}
                            name={"processParams.3"}
                            defaultValue={promotionalCampaign.processParams[3]}
                            rules={{validate: (value) => value.length > 0 || "At least one coverage must be selected"}}
                            render={({field: {onChange, onBlur, value, name, ref}}) => {
                                var includedCoverage: string[] = value
                                    .split(",")
                                    .filter((item: string) => item.length > 0);

                                return (
                                    <div className="required field">
                                        <Table basic celled style={{marginTop: "0"}}>
                                            <Table.Body>
                                                {getProductBehavioursQuery.data.map((productBehaviour) => (
                                                    <Table.Row
                                                        key={productBehaviour.registryLabel}
                                                        className="no-hover"
                                                    >
                                                        <Table.Cell textAlign="center">
                                                            {t(productBehaviour.registryLabel)}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign="center">
                                                            <Checkbox
                                                                name={name + "_" + productBehaviour.registryCode}
                                                                style={{verticalAlign: "middle"}}
                                                                toggle
                                                                onChange={(e, {checked}) => {
                                                                    if (checked) {
                                                                        includedCoverage.push(
                                                                            productBehaviour.registryCode
                                                                        );
                                                                    } else {
                                                                        includedCoverage = includedCoverage.filter(
                                                                            (item) =>
                                                                                item !== productBehaviour.registryCode
                                                                        );
                                                                    }

                                                                    var includedCoverageAsString =
                                                                        includedCoverage.join(",");
                                                                    onChange(includedCoverageAsString);
                                                                }}
                                                                onBlur={onBlur}
                                                                checked={includedCoverage.includes(
                                                                    productBehaviour.registryCode
                                                                )}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                ))}
                                            </Table.Body>
                                        </Table>

                                        {getErrorMessage(t, errors, name) && (
                                            <div
                                                className="ui pointing above prompt label"
                                                role="alert"
                                                aria-atomic="true"
                                                style={{marginTop: "0"}}
                                            >
                                                {t("At least one coverage must be selected")}
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
        </>
    );
};

export default PromotionalCampaignFreeMonthDetailsEdit;
