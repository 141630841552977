import FileSaver from "file-saver";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Dropdown, Grid, Header, Menu, Segment} from "semantic-ui-react";
import FormDimensionValue from "../../../../../Components/Forms/FormDimensionValue";
import EntityPicker from "../../../../../Components/Modals/EntityPicker";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import useDragAndDropProperties from "../../../../../Hooks/useDragAndDropProperties";
import {downloadDimensionValues} from "../../../../../Services/PricingGrid/AxiosRequests";
import {
    useAddPricingGridDimensionValueMutation,
    useGetPricingGridDimensionQuery,
    useImportValuesFromQuestionTypeListToDimensionMutation,
    useUploadDimensionValues,
} from "../../../../../Services/PricingGrid/Queries";
import {
    DimensionGridValueDTO,
    DimensionGridValueDTOWithId,
    DimensionOrderType,
} from "../../../../../Services/PricingGrid/Types";
import {useGetQuestionsQuery} from "../../../../../Services/Question/Queries";
import {QuestionDTO} from "../../../../../Services/Question/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {sortOptionsId, sortOptionsModificationDate, sortOptionsName} from "../../../../../Utils/SortUtils";
import QuestionCard from "../../../Version/Fragments/Questions/QuestionCard";
import PricingGridDimensionValueCard from "./PricingGridDimensionValueCard";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
}

type PricingGridDimensionValuesViewPropsType = {
    version: VersionDTO;
    gridCode: string;
    dimensionId: number;
};

const PricingGridDimensionValuesView = ({version, gridCode, dimensionId}: PricingGridDimensionValuesViewPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    // Get dimension
    const {data: dimension, status: getPricingGridDimensionQueryStatus} = useGetPricingGridDimensionQuery(
        params.gridId,
        dimensionId.toString()
    );

    // Add dimension value
    const addDimensionValueMutation = useAddPricingGridDimensionValueMutation(
        params.versionId,
        params.gridId,
        dimensionId.toString()
    );

    const addDimensionValueForm = useForm<DimensionGridValueDTO>();
    const submitDimensionValue = (form: DimensionGridValueDTO) => {
        addDimensionValueMutation.mutate({...form});
    };

    const [defaultValueDimensionValueCode, setDefaultValueDimensionValueCode] = useState<string>("");
    const [defaultValueDimensionValueLabel, setDefaultValueDimensionValueLabel] = useState<string>("");
    const [defaultValueDimensionValueMin, setDefaultValueDimensionValueMin] = useState<string>("");
    const [defaultValueDimensionValueMax, setDefaultValueDimensionValueMax] = useState<string>("");

    const resetForm = () => {
        addDimensionValueForm.reset();
        setDefaultValueDimensionValueCode("");
        setDefaultValueDimensionValueLabel("");
        setDefaultValueDimensionValueMin("");
        setDefaultValueDimensionValueMax("");
    };

    const uploadMutation = useUploadDimensionValues(params.versionId, params.gridId, dimensionId.toString());

    const handleExportDimensionValues = async () => {
        if (getPricingGridDimensionQueryStatus === "success" && dimension) {
            const blob = new Blob([await downloadDimensionValues(params.gridId, dimensionId.toString())], {
                type: "text/csv",
            });
            FileSaver.saveAs(blob, gridCode + "_" + dimension.code + ".csv");
        }
    };

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const clearSelectedFile = () => {
        setSelectedFile(null);
        if (hiddenFileInput.current !== null) {
            hiddenFileInput.current.value = "";
        }
    };

    const {columnNumberAsText, ref} = useContainerDimensions();

    const determinateReorderMethod = () => {
        if (dimension) {
            if (dimension.dimensionOrderType === DimensionOrderType.ORDRE) {
                return [...dimension.dimensionGridValueDTOs].sort((a, b) => a.order - b.order);
            } else {
                return [...dimension.dimensionGridValueDTOs].sort((a, b) => {
                    if (a.value) {
                        return a.value < b.value ? -1 : 1;
                    } else if (a.max) {
                        return a.max - b.max;
                    } else {
                        return a.maxDate < b.maxDate ? -1 : 1;
                    }
                });
            }
        } else {
            return [];
        }
    };

    const dndProperties = useDragAndDropProperties<DimensionGridValueDTOWithId>(determinateReorderMethod());

    const renderDimensionValuesCard = () => {
        return (
            getPricingGridDimensionQueryStatus === "success" &&
            dndProperties.renderedItems.map((dimensionGridValueDTO: DimensionGridValueDTOWithId, index: number) => {
                return (
                    <PricingGridDimensionValueCard
                        key={dimensionGridValueDTO.id.toString()}
                        dimension={dimension}
                        dimensionGridValue={dimensionGridValueDTO}
                        type={dimension.dimensionType}
                        valueType={dimension.dimensionValueType}
                        index={index}
                        dndProperties={dndProperties}
                    />
                );
            })
        );
    };

    const [openEntityPicker, setOpenEntityPicker] = useState<boolean>(false);

    const uploadFromExcel = () => {
        hiddenFileInput.current?.click();
    };
    const uploadFromQuestion = () => {
        setOpenEntityPicker(true);
    };

    const [selectedQuestionName, setSelectedQuestionName] = useState<string | undefined>(undefined);
    const importValues = useImportValuesFromQuestionTypeListToDimensionMutation(
        params.gridId,
        dimensionId.toString(),
        version.id.toString()
    );
    const clearSelectedQuestionName = () => {
        setSelectedQuestionName(undefined);
        setOpenEntityPicker(false);
    };

    return (
        <Grid.Column width={12}>
            {getPricingGridDimensionQueryStatus === "success" && dimension && (
                <Segment>
                    <Menu secondary>
                        <Menu.Item position="left">
                            <Header size="medium">{`${dimension.code} - ${t("Dimension_values")}`}</Header>
                        </Menu.Item>
                        <Menu.Menu position="right">
                            <Menu.Item>
                                {version.pipDate === null && (
                                    <ModalAdd
                                        isValidationDisabled={
                                            Object.keys(addDimensionValueForm.formState.errors).length !== 0
                                        }
                                        isPending={addDimensionValueMutation.isPending}
                                        isSuccess={addDimensionValueMutation.isSuccess}
                                        isError={addDimensionValueMutation.isError}
                                        resetMutation={addDimensionValueMutation.reset}
                                        error={addDimensionValueMutation.error}
                                        onValidate={addDimensionValueForm.handleSubmit(submitDimensionValue)}
                                        onSuccess={() => {
                                            resetForm();
                                        }}
                                        onClose={() => {
                                            resetForm();
                                        }}
                                        onCancel={() => {
                                            resetForm();
                                        }}
                                        iconOnOpenButton
                                        openButtonIcon="add"
                                        objectToAdd="Dimension_value"
                                        objectToAddContext="female"
                                        renderModalContent={() => {
                                            return (
                                                <FormDimensionValue
                                                    errors={addDimensionValueForm.formState.errors}
                                                    control={addDimensionValueForm.control}
                                                    dimension={dimension}
                                                    defaultValueCode={defaultValueDimensionValueCode}
                                                    setDefaultValueCode={setDefaultValueDimensionValueCode}
                                                    defaultValueLabel={defaultValueDimensionValueLabel}
                                                    setDefaultValueLabel={setDefaultValueDimensionValueLabel}
                                                    defaultValueMin={defaultValueDimensionValueMin}
                                                    setDefaultValueMin={setDefaultValueDimensionValueMin}
                                                    defaultValueMax={defaultValueDimensionValueMax}
                                                    setDefaultValueMax={setDefaultValueDimensionValueMax}
                                                />
                                            );
                                        }}
                                    />
                                )}

                                <Button
                                    name="download_dimension_values"
                                    positive
                                    onClick={handleExportDimensionValues}
                                    icon="download"
                                    style={{margin: "0 0.25em 0 0.25em"}}
                                />

                                {version.pipDate === null && (
                                    <>
                                        {selectedFile !== null && (
                                            <ModalUpdate
                                                hidden
                                                isModalOpenAtStart
                                                customContent={t("You are about to replace all dimensions values")}
                                                onSuccess={clearSelectedFile}
                                                onCancel={clearSelectedFile}
                                                onClose={clearSelectedFile}
                                                resetMutation={uploadMutation.reset}
                                                isSuccess={uploadMutation.isSuccess}
                                                isPending={uploadMutation.isPending}
                                                isError={uploadMutation.isError}
                                                error={uploadMutation.error}
                                                onValidate={() => uploadMutation.mutate(selectedFile)}
                                            />
                                        )}

                                        <Dropdown
                                            name="upload_dimension_values"
                                            style={{backgroundColor: "#21ba45", color: "white"}}
                                            icon={"upload"}
                                            className="icon"
                                            direction="left"
                                            button
                                        >
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => uploadFromExcel()}>
                                                    {t("importFromExcel")}
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => uploadFromQuestion()}>
                                                    {t("importFromQuestion")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <input
                                            data-cy="importExcelInput"
                                            ref={hiddenFileInput}
                                            type={"file"}
                                            hidden
                                            onChange={({target: {files}}: React.ChangeEvent<HTMLInputElement>) =>
                                                setSelectedFile(files !== null ? files[0] : null)
                                            }
                                        />
                                    </>
                                )}
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>

                    {openEntityPicker && (
                        <EntityPicker
                            object="Question"
                            objectContext={"female"}
                            entityListGetParameters={[version.id.toString(), version.id !== undefined]}
                            entityListGetMethod={useGetQuestionsQuery}
                            renderCardContent={(question: QuestionDTO) => (
                                <QuestionCard
                                    versionId={params.versionId}
                                    question={question}
                                    onClick={() => setSelectedQuestionName(question.name)}
                                />
                            )}
                            filterEntity={(question: QuestionDTO) => {
                                return question.valueType === "LISTE";
                            }}
                            onCancel={() => setOpenEntityPicker(false)}
                            defaultSortMethod={(a: QuestionDTO, b: QuestionDTO) =>
                                a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
                            }
                            filterBySearch={(question: QuestionDTO, search: string): boolean => {
                                return (
                                    question.name.toLowerCase().includes(search.toLowerCase()) ||
                                    question.valueType.toLowerCase().includes(search.toLowerCase()) ||
                                    search === ""
                                );
                            }}
                            sortOptions={[...sortOptionsName, ...sortOptionsId, ...sortOptionsModificationDate]}
                        />
                    )}

                    {selectedQuestionName !== undefined && (
                        <ModalUpdate
                            hidden
                            isModalOpenAtStart
                            customContent={t("You are about to modify the dimension values")}
                            onSuccess={clearSelectedQuestionName}
                            onCancel={clearSelectedQuestionName}
                            onClose={clearSelectedQuestionName}
                            resetMutation={importValues.reset}
                            isSuccess={importValues.isSuccess}
                            isPending={importValues.isPending}
                            isError={importValues.isError}
                            error={importValues.error}
                            onValidate={() => importValues.mutate(selectedQuestionName)}
                        />
                    )}

                    <div
                        className={"ui cards " + columnNumberAsText}
                        ref={ref}
                        onMouseLeave={() => dndProperties.refreshData()}
                    >
                        {renderDimensionValuesCard()}
                    </div>
                </Segment>
            )}
        </Grid.Column>
    );
};

export default PricingGridDimensionValuesView;
