import React from "react";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid, GridColumn} from "semantic-ui-react";
import {CommissionMode, Company, ProrateDaysCountingMode, ProrateMode} from "../../../Services/Company/Types";
import getErrorMessage from "../../Global/Form/ErrorMessage";

type FormCompanyProps = {
    errors: FieldErrors<Company>;
    control: Control<Company>;
    defaultValueCompany: Company;
    setDefaultValueCompany: React.Dispatch<React.SetStateAction<Company>>;
};

const FormCompany = ({control, errors, defaultValueCompany, setDefaultValueCompany}: FormCompanyProps) => {
    const {t} = useTranslation();

    // Options Mode de prorate
    const prorateModeOptions = Object.values(ProrateMode).map((prorateMode: ProrateMode) => {
        return {
            key: prorateMode.toString(),
            text: t("enums.ProrateMode." + prorateMode),
            value: prorateMode,
        };
    });

    // Options Mode de comptage des jours de prorate
    const prorateDaysCountingModeOptions = Object.values(ProrateDaysCountingMode).map(
        (prorateDaysCountingMode: ProrateDaysCountingMode) => {
            return {
                key: prorateDaysCountingMode.toString(),
                text: t("enums.ProrateDaysCountingMode." + prorateDaysCountingMode),
                value: prorateDaysCountingMode,
            };
        }
    );

    // Options Mode de commissionnement
    const commissionModeOptions = Object.values(CommissionMode).map((commissionMode: CommissionMode) => {
        return {
            key: commissionMode.toString(),
            text: t("enums.CommissionMode." + commissionMode),
            value: commissionMode,
        };
    });

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <GridColumn>
                        <Controller
                            name="label"
                            defaultValue={defaultValueCompany.label}
                            rules={{required: true, maxLength: 50}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            label: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={50}
                                    error={getErrorMessage(t, errors, name, undefined, 50)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="prorateMode"
                            defaultValue={defaultValueCompany.prorateMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    fluid
                                    required
                                    name={name}
                                    label={t("prorateMode")}
                                    placeholder={t("prorateMode")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            prorateMode: value as ProrateMode,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={prorateModeOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="prorateDaysCountingMode"
                            defaultValue={defaultValueCompany.prorateDaysCountingMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    required
                                    name={name}
                                    fluid
                                    label={t("prorateDaysCountingMode")}
                                    placeholder={t("prorateDaysCountingMode")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            prorateDaysCountingMode: value as ProrateDaysCountingMode,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={prorateDaysCountingModeOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="internalNovanetUrl"
                            rules={{maxLength: 120}}
                            defaultValue={defaultValueCompany.internalNovanetUrl}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("internalNovanetUrl")}
                                    placeholder={t("internalNovanetUrl")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            internalNovanetUrl: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={120}
                                    error={getErrorMessage(t, errors, name, undefined, 120)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="externalNovanetUrl"
                            rules={{maxLength: 120}}
                            defaultValue={defaultValueCompany.externalNovanetUrl}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("externalNovanetUrl")}
                                    placeholder={t("externalNovanetUrl")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            externalNovanetUrl: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={120}
                                    error={getErrorMessage(t, errors, name, undefined, 120)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="webserviceLogin"
                            rules={{maxLength: 50}}
                            defaultValue={defaultValueCompany.webserviceLogin}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("webserviceLogin")}
                                    placeholder={t("webserviceLogin")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            webserviceLogin: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={50}
                                    error={getErrorMessage(t, errors, name, undefined, 50)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="webservicePassword"
                            defaultValue={defaultValueCompany.webservicePassword}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("webservicePassword")}
                                    placeholder={t("webservicePassword")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            webservicePassword: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="commissionMode"
                            defaultValue={defaultValueCompany.commissionMode}
                            rules={{required: true}}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Select
                                    required
                                    name={name}
                                    fluid
                                    label={t("commissionMode")}
                                    placeholder={t("commissionMode")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            commissionMode: value as CommissionMode,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                    options={commissionModeOptions}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="consoleURL"
                            defaultValue={defaultValueCompany.consoleURL}
                            rules={{required: true, maxLength: 255}}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    fluid
                                    name={name}
                                    type="text"
                                    label={t("consoleURL")}
                                    placeholder={t("consoleURL")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            consoleURL: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={255}
                                    error={getErrorMessage(t, errors, name, undefined, 255)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="environmentName"
                            defaultValue={defaultValueCompany.environmentName}
                            rules={{required: true, maxLength: 50}}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <>
                                    <Form.Input
                                        inputref={ref}
                                        required
                                        fluid
                                        name={name}
                                        type="text"
                                        label={t("environmentName")}
                                        placeholder={t("environmentName")}
                                        onChange={(_, {value}) => {
                                            setDefaultValueCompany({
                                                ...defaultValueCompany,
                                                environmentName: value,
                                            });
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength={50}
                                        error={getErrorMessage(t, errors, name, undefined, 50)}
                                        list="environment-names"
                                    />
                                    <datalist id="environment-names">
                                        <option value="LOCAL">LOCAL</option>
                                        <option value="RDO">RDO</option>
                                        <option value="TEST">TEST</option>
                                        <option value="RECETTE">RECETTE</option>
                                        <option value="PREPRODUCTION">PREPRODUCTION</option>
                                        <option value="PRODUCTION">PRODUCTION</option>
                                    </datalist>
                                </>
                            )}
                        />

                        <Controller
                            control={control}
                            name="retrocessionSettingsActive"
                            defaultValue={defaultValueCompany.retrocessionSettingsActive}
                            render={({field: {onChange, onBlur, value, name, ref}}) => (
                                <Form.Checkbox
                                    toggle
                                    inputref={ref}
                                    name={name}
                                    label={t("retrocessionSettingsActive")}
                                    onChange={(_, {checked}) => {
                                        setDefaultValueCompany({
                                            ...defaultValueCompany,
                                            retrocessionSettingsActive: checked ?? false,
                                        });
                                        onChange(checked);
                                    }}
                                    onBlur={onBlur}
                                    checked={value}
                                />
                            )}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormCompany;
