import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, Grid, Icon, Segment, Step } from "semantic-ui-react";
import { HealthProductFormContext } from "./HealthProductFormContext";
import HealthProductSubmissionModal from "./HealthProductSubmissionModal";
import HealthSpecificQuestionsForm from "./HealthSpecificQuestionsForm";
import {
    INSURANCE_PACKAGES_STEP_NAME,
    PRODUCT_INFO_STEP_NAME,
    RENFORTS_STEP_NAME,
    SPECIFIC_INFORMATION_STEP_NAME
} from "./InputsByStepHealthProductForm";
import InsurancePackageForm from "./InsurancePackagesForm";
import ProductInformationForm from "./ProductInformationForm";
import RenfortForm from "./RenfortForm";

const HealthProductCreationFormStepper: React.FC = () => {
    const {t} = useTranslation();
    const steps = [
        {
            order: 1,
            name: PRODUCT_INFO_STEP_NAME,
            title: t("New Product"),
            description: t("Information related to the new product"),
        },
        {
            order: 2,
            name: RENFORTS_STEP_NAME,
            title: t("Renfort", {count: 2}),
            description: t("Renforts creation"),
        },
        {
            order: 3,
            name: INSURANCE_PACKAGES_STEP_NAME,
            title: t("Insurance package", {count: 2}),
            description: t("Insurance packages underwriting creation"),
        },
        {
            order: 4,
            name: SPECIFIC_INFORMATION_STEP_NAME,
            title: t("Specific information"),
            description: t("Specific health questions"),
        },
    ];
    const [currentStep, setCurrentStep] = useState(steps[0]);
    const {validate} = useContext(HealthProductFormContext);

    function handlePreviousStep(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setCurrentStep(steps[steps.findIndex((step) => step.order === currentStep.order - 1)]);
    }

    function handleNextStep(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (validate(currentStep.name))
            setCurrentStep(steps[steps.findIndex((step) => step.order === currentStep.order + 1)]);
    }

    return (
        <Grid>
            <Grid.Column>
                <Segment.Group>
                    <Segment>
                        <Step.Group widths={5}>
                            {steps.map((step) => (
                                <Step
                                    key={step.name}
                                    active={currentStep.name === step.name}
                                    disabled={currentStep.order < step.order}
                                    onClick={() => setCurrentStep(step)}>
                                    <Icon name="box" />
                                    <Step.Content>
                                        <Step.Title>{step.title}</Step.Title>
                                        <Step.Description>{step.description}</Step.Description>
                                    </Step.Content>
                                </Step>
                            ))}
                        </Step.Group>
                        <Container style={{minHeight: "50vh", margin: "1em"}}>
                            <StepsContent stepName={currentStep.name} />
                        </Container>
                    </Segment>
                    <Segment align="center">
                        {currentStep.order >= 2 && (
                            <Button onClick={(event) => handlePreviousStep(event)}>
                                <Icon name="arrow left" /> {t("Return to previous step")}
                            </Button>
                        )}
                        {currentStep.order < steps.length && (
                            <Button onClick={(event) => handleNextStep(event)}>
                                {t("Move to next step")} <Icon name="arrow right" />
                            </Button>
                        )}
                        {currentStep.order === steps.length && <HealthProductSubmissionModal />}
                    </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid>
    );
};

const StepsContent = ({...props}) => {
    switch (props.stepName) {
        case PRODUCT_INFO_STEP_NAME:
            return <ProductInformationForm />;
        case RENFORTS_STEP_NAME:
            return <RenfortForm />;
        case INSURANCE_PACKAGES_STEP_NAME:
            return <InsurancePackageForm />;
        case SPECIFIC_INFORMATION_STEP_NAME:
            return <HealthSpecificQuestionsForm />;
        default:
            return null;
    }
};

export default HealthProductCreationFormStepper;
