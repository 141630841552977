import axios, {AxiosError} from "axios";
import {buildCustomError} from "../../../Services/RequestUtils";
import axiosInstance from "../Generation.axios.instance";
import {CommissionPackages} from "./Types";

export const getCommissionPackages = async (productCode: string): Promise<Array<CommissionPackages>> => {
    try {
        const response = await axiosInstance.get<CommissionPackages[]>(`/products/${productCode}/commissions`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const exportCommissionPackages = async (productCode: string): Promise<unknown> => {
    try {
        const response = await axiosInstance.get(`/products/${productCode}/commissions/_export`, {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const importCommissionPackages = async (productCode: string, formData: FormData): Promise<void> => {
    try {
        return await axiosInstance.post(`/products/${productCode}/commissions/_import`, formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
