import { useTranslation } from "react-i18next";
import { SidebarMenu } from "../Components/Sidebar/Sidebar";

export default function (isAdmin: boolean) {
    const {t} = useTranslation();
    const menus = isAdmin
        ? [
              {
                  key: "users",
                  name: t("users"),
                  link: "/transversal_settings/users",
              },
              {
                  key: "rest_api_access",
                  name: t("rest_api_access"),
                  link: "/transversal_settings/rest_api_access",
              },
              {
                  key: "registry",
                  name: t("registry"),
                  link: "/transversal_settings/registry",
              },
              {
                  key: "risk_types",
                  name: t("risk_types"),
                  link: "/transversal_settings/risk_types",
              },
              {
                  key: "company_product_infos",
                  name: t("company product infos"),
                  link: "/transversal_settings/company_product_infos",
              },
          ]
        : [
              {
                  key: "registry",
                  name: t("registry"),
                  link: "/transversal_settings/registry",
              },
              {
                  key: "risk_types",
                  name: t("risk_types"),
                  link: "/transversal_settings/risk_types",
              },
          ];

    return [new SidebarMenu("", menus)];
}
