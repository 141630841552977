import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar, {SidebarItem, SidebarMenu} from "../../../Components/Sidebar/Sidebar";
import {useGetProductDetailsQuery} from "../../../Services/Product/Queries";
import {useGetPromotionalCampaignsQuery} from "../../../Services/PromotionalCampagins/Queries";
import PromotionalCampaignDetails from "./Fragments/PromotionalCampaignDetails";

export interface IParams extends Params {
    id: string;
    promotionalCampaignId: string;
}

const PromotionalCampaignPage = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [menus, setMenus] = useState<any>([]);

    const getPromotionalCampaignsQuery = useGetPromotionalCampaignsQuery(params.id);
    const getProductDetails = useGetProductDetailsQuery(params.id);

    useEffect(() => {
        if (getPromotionalCampaignsQuery.isSuccess && getPromotionalCampaignsQuery.data !== undefined) {
            const sideBarItems = new SidebarMenu(
                "",
                getPromotionalCampaignsQuery.data.map(
                    (promotionalCampaign) =>
                        new SidebarItem(
                            String(promotionalCampaign.code),
                            String(promotionalCampaign.code),
                            `/products/${params.id}/promotional_campaigns/${promotionalCampaign.id}`
                        )
                )
            );
            setMenus(sideBarItems);
        }
    }, [params.id, getPromotionalCampaignsQuery.isSuccess, getPromotionalCampaignsQuery.data]);

    return (
        <Container fluid>
            <Sidebar title={t("Promotional campaigns")} menus={[menus]} />
            <CustomBreadcrumb sections={props.sections} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <PromotionalCampaignDetails
                                productId={params.id}
                                promotionalCampaignId={params.promotionalCampaignId}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default PromotionalCampaignPage;
