import {useContext, useMemo} from "react";
import {AuthContext, UserPayload} from "../Components/AuthProvider/AuthProvider";
import routes, {RouteProps} from "../Config/routes";

export const useGetFilteredRoutes = () => {
    const {user} = useContext(AuthContext);

    const filterRoutes = (routes: Array<RouteProps>, user: UserPayload | null): Array<RouteProps> => {
        const routeToKeep = routes.filter((route) => route.name === "home" || route.societeUser === user?.societeUser);
        routes.forEach((route) => {
            if (route.societeUser === undefined && !routeToKeep.find((route1) => route.name === route1.name)) {
                if (
                    user?.authorities.some(
                        (authority) => route.requiredRole === undefined || route.requiredRole.includes(authority)
                    )
                ) {
                    routeToKeep.push(route);
                }
            }
        });
        return routeToKeep;
    };

    return useMemo(() => filterRoutes(routes, user), [user]);
};
