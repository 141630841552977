import React, {useMemo} from "react";
import {useCookies} from "react-cookie";

export type UserPayload = {
    sub: string;
    scope: string;
    environmentName: string;
    exp: number;
    societeUser: string;
    username: string;
    authorities: string[];
};

type AuthState = {
    isAuthenticated: boolean;
    token: string | null;
    user: UserPayload | null;
};

type AuthContextType = AuthState & {
    login: (token: string) => void;
    logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    const auth = useMemo((): AuthContextType => {
        const login = (token: string) => {
            const environmentName = parseJwt(token)?.environmentName;
            setCookie("token", token, {
                sameSite: true,
                secure: !!environmentName && environmentName.toLocaleLowerCase().includes("prod"),
                path: "/",
                maxAge: 60 * 60 * 24,
            });
        };
        const logout = () => removeCookie("token");

        const authInformations: AuthState = {
            isAuthenticated: false,
            token: null,
            user: null,
        };

        if (cookies.token) {
            authInformations.isAuthenticated = true;
            authInformations.token = cookies.token;
            authInformations.user = parseJwt(cookies.token);
        }

        return {...authInformations, login, logout};
    }, [cookies.token, setCookie, removeCookie]);

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const parseJwt = (token: string): UserPayload | null => {
    try {
        if (token) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            const jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                    .join("")
            );

            return JSON.parse(jsonPayload);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Failed to parse token :", token, error);
    }

    return null;
};
