import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import queryClient from "../../Config/queryClient";
import {ProductRegistryTaxJoin} from "../ProductBehaviour/Types";
import {CustomError} from "../RequestUtils";
import {
    addCoverage,
    addCoverageGroups,
    addCoverageTax,
    addFeeCharge,
    addInsurancePackage,
    addInsurancePackageCondition,
    addOption,
    deleteCoverage,
    deleteCoverageGroups,
    deleteDerogatoryTax,
    deleteFeeCharge,
    deleteInsurancePackage,
    deleteInsurancePackageCondition,
    deleteOption,
    duplicateInsurancePackage,
    duplicateOption,
    getCoverage,
    getCoverageGroup,
    getCoverageGroups,
    getCoverages,
    getDerogatoriesTaxes,
    getFeeCharge,
    getFeeCharges,
    getInsurancePackage,
    getInsurancePackageConditions,
    getInsurancePackages,
    getOption,
    getOptionsByGroup,
    reorderDerogatoryTaxConditionMutation,
    reorderInsurancePackageExclusionConditionMutation,
    updateCoverage,
    updateCoverageGroups,
    updateDerogatoryTax,
    updateFeeCharge,
    updateInsurancePackage,
    updateInsurancePackageCondition,
    updateOption,
} from "./AxiosRequests";
import {
    Coverage,
    CoverageAddDTO,
    CoverageDTO,
    CoverageGroup,
    CoverageRuleDTO,
    CoverageUpdateDTO,
    DerogatoryTaxDTO,
    FeeCharge,
    FeeChargeAddDTO,
    FeeChargeDTO,
    InsurancePackageExclusion,
    InsurancePackageExclusionDTO,
    InsurancePackageItem,
    Option,
    OptionAddDTO,
    OptionUpdateDTO,
} from "./Types";

/* --------- FORMULES --------- */
export const useGetInsurancePackagesQuery = (versionId: string): UseQueryResult<Array<InsurancePackageItem>, Error> => {
    return useQuery({
        queryKey: ["getInsurancePackages", versionId],
        queryFn: () => getInsurancePackages(versionId),
    });
};

export const useGetInsurancePackageQuery = (
    versionId: string,
    insurancePackageId: string
): UseQueryResult<InsurancePackageItem, Error> => {
    return useQuery({
        queryKey: ["getInsurancePackage", {versionId, insurancePackageId}],
        queryFn: () => getInsurancePackage(versionId, insurancePackageId),
    });
};

export const useUpdateInsurancePackageMutation = (
    versionId: string,
    insurancePackageId: string
): UseMutationResult<InsurancePackageItem, CustomError, InsurancePackageItem, Error> => {
    return useMutation<InsurancePackageItem, CustomError, InsurancePackageItem, Error>({
        mutationFn: (insurancePackage: InsurancePackageItem) => updateInsurancePackage(versionId, insurancePackage),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getInsurancePackages", versionId]});
            await queryClient.invalidateQueries({
                queryKey: ["getInsurancePackage", {versionId: versionId, insurancePackageId: insurancePackageId}],
            });
        },
    });
};

export const useDeleteInsurancePackageMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteInsurancePackage(versionId, insurancePackageId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getInsurancePackages", versionId]});
            queryClient.removeQueries({
                queryKey: ["getInsurancePackage", {versionId: versionId, insurancePackageId: insurancePackageId}],
            });
            navigate(`/products/${productId}/versions/${versionId}`, {state: {activeTabIndex: 6}});
        },
    });
};

export const useAddInsurancePackageMutation = (
    productId: string,
    versionId: string
): UseMutationResult<InsurancePackageItem, CustomError, InsurancePackageItem, Error> => {
    const navigate = useNavigate();
    return useMutation<InsurancePackageItem, CustomError, InsurancePackageItem, Error>({
        mutationFn: (insurancePackage: InsurancePackageItem) => addInsurancePackage(versionId, insurancePackage),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getInsurancePackages", versionId]});
            navigate(`/products/${productId}/versions/${versionId}/insurance-packages/${result.id}`);
        },
    });
};

export const useDuplicateInsurancePackageMutation = (
    versionId: string,
    insurancePackageId: string,
    productId: string
): UseMutationResult<InsurancePackageItem, CustomError, {code: string; label: string}, Error> => {
    const navigate = useNavigate();
    return useMutation<InsurancePackageItem, CustomError, {code: string; label: string}, Error>({
        mutationFn: (data: {code: string; label: string}) =>
            duplicateInsurancePackage(versionId, insurancePackageId, data.code, data.label),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getInsurancePackages", versionId]});
            navigate(`/products/${productId}/versions/${versionId}/insurance-packages/${result.id}`);
        },
    });
};

/* --------- GROUPE DE GARANTIES --------- */
export const useGetCoverageGroupsQuery = (insurancePackageId: string): UseQueryResult<Array<CoverageGroup>, Error> => {
    return useQuery({
        queryKey: ["getCoverageGroups", insurancePackageId],
        queryFn: () => getCoverageGroups(insurancePackageId),
    });
};

export const useGetCoverageGroupQuery = (
    insurancePackageId: string,
    coverageGroupId: string
): UseQueryResult<CoverageGroup, Error> => {
    return useQuery({
        queryKey: ["getCoverageGroup", {insurancePackageId, coverageGroupId}],
        queryFn: () => getCoverageGroup(insurancePackageId, coverageGroupId),
    });
};

export const useAddCoveragesGroupMutation = (
    insurancePackageId: string
): UseMutationResult<CoverageGroup, CustomError, CoverageGroup, Error> => {
    return useMutation<CoverageGroup, CustomError, CoverageGroup, Error>({
        mutationFn: (coverageGroup: CoverageGroup) => addCoverageGroups(insurancePackageId, coverageGroup),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCoverageGroups", insurancePackageId]});
        },
    });
};

export const useUpdateCoveragesGroupMutation = (
    insurancePackageId: string
): UseMutationResult<CoverageGroup, CustomError, CoverageGroup, Error> => {
    return useMutation<CoverageGroup, CustomError, CoverageGroup, Error>({
        mutationFn: (coverageGroup: CoverageGroup) =>
            updateCoverageGroups(insurancePackageId, String(coverageGroup.id), coverageGroup),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCoverageGroups", insurancePackageId]});
        },
    });
};

export const useDeleteCoveragesGroupMutation = (
    insurancePackageId: string
): UseMutationResult<void, CustomError, string, Error> => {
    return useMutation<void, CustomError, string, Error>({
        mutationFn: (coverageGroupId: string) => deleteCoverageGroups(insurancePackageId, coverageGroupId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCoverageGroups", insurancePackageId]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries", insurancePackageId]});
        },
    });
};

/* --------- GARANTIES --------- */
export const useGetCoverageQuery = (
    coverageGroupId: string,
    coverageId: string
): UseQueryResult<CoverageRuleDTO, Error> => {
    return useQuery({
        queryKey: ["getCoverage", {coverageGroupId, coverageId}],
        queryFn: () => getCoverage(coverageGroupId, coverageId),
    });
};

export const useGetCoveragesQuery = (coverageGroupId: string): UseQueryResult<Array<CoverageDTO>, Error> => {
    return useQuery({
        queryKey: ["getCoverages", coverageGroupId],
        queryFn: () => getCoverages(coverageGroupId),
    });
};

export const useUpdateCoverageMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    coverageGroupId: string,
    coverageId: string
): UseMutationResult<Coverage, CustomError, CoverageUpdateDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<Coverage, CustomError, CoverageUpdateDTO, Error>({
        mutationFn: (coverageUpdateDTO: CoverageUpdateDTO) =>
            updateCoverage(String(coverageGroupId), String(coverageId), coverageUpdateDTO),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getCoverages", coverageGroupId]});
            await queryClient.invalidateQueries({queryKey: ["getCoverage", {coverageGroupId, coverageId}]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/coverages-groups/${result.group.id}/coverages/${coverageId}`
            );
        },
    });
};

export const useAddCoverageMutation = (
    productId: string,
    coverageGroupId: string,
    insurancePackageId: string,
    versionId: string
): UseMutationResult<CoverageDTO, CustomError, CoverageAddDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<CoverageDTO, CustomError, CoverageAddDTO, Error>({
        mutationFn: (coverageAddDTO: CoverageAddDTO) => addCoverage(coverageGroupId, coverageAddDTO),
        onSuccess: async (result: CoverageDTO) => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getCoverages", coverageGroupId]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries", insurancePackageId]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/coverages-groups/${coverageGroupId}/coverages/${result.id}`
            );
        },
    });
};

export const useDeleteCoverageMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    coverageGroupId: string
): UseMutationResult<void, CustomError, number, Error> => {
    const navigate = useNavigate();
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (coverageId: number) => deleteCoverage(String(coverageGroupId), String(coverageId)),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getCoverages", coverageGroupId]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries", insurancePackageId]});
            navigate(`/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}`, {
                state: {activeGroupIndex: coverageGroupId},
            });
        },
    });
};

/* --------- FRAIS --------- */
export const useGetFeeChargesQuery = (insurancePackageId: string): UseQueryResult<Array<FeeCharge>, Error> => {
    return useQuery({
        queryKey: ["getFeeCharges", insurancePackageId],
        queryFn: () => getFeeCharges(insurancePackageId),
    });
};

export const useGetFeeChargeQuery = (
    insurancePackageId: string,
    feeChargeId: string
): UseQueryResult<FeeCharge, Error> => {
    return useQuery({
        queryKey: ["getFeeCharge", {insurancePackageId, feeChargeId}],
        queryFn: () => getFeeCharge(insurancePackageId, feeChargeId),
    });
};

export const useDeleteFeeChargeMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    feeChargeId: string
): UseMutationResult<void, CustomError, void, Error> => {
    const navigate = useNavigate();
    return useMutation({
        mutationFn: () => deleteFeeCharge(insurancePackageId, String(feeChargeId)),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getFeeCharges", insurancePackageId]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries", insurancePackageId]});
            queryClient.removeQueries({queryKey: ["getFeeCharge", {insurancePackageId, feeChargeId}]});
            navigate(`/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}`, {
                state: {activeTabIndex: 1},
            });
        },
    });
};

export const useUpdateFeeChargeMutation = (
    insurancePackageId: string,
    feeChargeId: string
): UseMutationResult<FeeCharge, CustomError, FeeChargeDTO, Error> => {
    return useMutation<FeeCharge, CustomError, FeeChargeDTO, Error>({
        mutationFn: (feeChargeUpdateDTO: FeeChargeDTO) =>
            updateFeeCharge(insurancePackageId, feeChargeId, feeChargeUpdateDTO),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getFeeCharges", insurancePackageId]});
            await queryClient.invalidateQueries({queryKey: ["getFeeCharge", {insurancePackageId, feeChargeId}]});
        },
    });
};

export const useAddFeeChargeMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string
): UseMutationResult<FeeCharge, CustomError, FeeChargeAddDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<FeeCharge, CustomError, FeeChargeAddDTO, Error>({
        mutationFn: (feeChargeAddDTO: FeeChargeAddDTO) => addFeeCharge(insurancePackageId, feeChargeAddDTO),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getFeeCharges", insurancePackageId]});
            await queryClient.invalidateQueries({queryKey: ["getNotAssociatedRegistries", insurancePackageId]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/fee-charges/${result.id}`
            );
        },
    });
};

/* --------- OPTIONS --------- */
export const useGetOptionQuery = (coverageGroupId: string, optionId: string): UseQueryResult<Option, Error> => {
    return useQuery({
        queryKey: ["getOption", {coverageGroupId, optionId}],
        queryFn: () => getOption(coverageGroupId, optionId),
    });
};

export const useGetOptionsByGroupQuery = (coverageGroupId: string): UseQueryResult<Array<Option>, Error> => {
    return useQuery({
        queryKey: ["getOptionsByGroup", coverageGroupId],
        queryFn: () => getOptionsByGroup(coverageGroupId),
    });
};

export const useAddOptionMutation = (
    productId: string,
    coverageGroupId: string,
    insurancePackageId: string,
    versionId: string
) => {
    const navigate = useNavigate();
    return useMutation<Option, CustomError, OptionAddDTO, Error>({
        mutationFn: (optionAddDTO: OptionAddDTO) => addOption(coverageGroupId, optionAddDTO),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getOptionsByGroup", coverageGroupId]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/coverages-groups/${coverageGroupId}/options/${result.id}`
            );
        },
    });
};

export const useDeleteOptionMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    coverageGroupId: string
): UseMutationResult<void, CustomError, string, Error> => {
    const navigate = useNavigate();
    let optionIdCopy: string;
    return useMutation<void, CustomError, string, Error>({
        mutationFn: (optionId: string) => {
            optionIdCopy = optionId;
            return deleteOption(coverageGroupId, optionId);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getOptionsByGroup", coverageGroupId]});
            queryClient.removeQueries({queryKey: ["getOption", {coverageGroupId, optionIdCopy}]});
            navigate(`/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}`, {
                state: {activeGroupIndex: coverageGroupId},
            });
        },
    });
};

export const useUpdateOptionMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    coverageGroupId: string,
    optionId: string
): UseMutationResult<Option, CustomError, OptionUpdateDTO, Error> => {
    const navigate = useNavigate();
    return useMutation<Option, CustomError, OptionUpdateDTO, Error>({
        mutationFn: (optionUpdateDTO: OptionUpdateDTO) => updateOption(coverageGroupId, optionId, optionUpdateDTO),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["getOptionsByGroup", coverageGroupId]});
            if (String(result.coverageGroup.id) !== coverageGroupId) {
                await queryClient.invalidateQueries({
                    queryKey: ["getOptionsByGroup", String(result.coverageGroup.id)],
                });
            }
            await queryClient.invalidateQueries({
                queryKey: ["getProductBehaviorDTO", productId],
            });
            queryClient.removeQueries({queryKey: ["getOption", {coverageGroupId, optionId}]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/coverages-groups/${result.coverageGroup.id}/options/${optionId}`
            );
        },
    });
};

export const useDuplicateOptionMutation = (
    productId: string,
    versionId: string,
    insurancePackageId: string,
    coverageGroupId: string,
    optionId: string
): UseMutationResult<Option, CustomError, {code: string; label: string}, Error> => {
    const navigate = useNavigate();
    return useMutation<Option, CustomError, {code: string; label: string}, Error>({
        mutationFn: (data: {code: string; label: string}) =>
            duplicateOption(coverageGroupId, optionId, data.code, data.label),
        onSuccess: async (result) => {
            await queryClient.invalidateQueries({queryKey: ["productBehaviours", productId]});
            await queryClient.invalidateQueries({queryKey: ["getOptionsByGroup", coverageGroupId]});
            navigate(
                `/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackageId}/coverages-groups/${coverageGroupId}/options/${result.id}`
            );
        },
    });
};

/* --------- Conditions --------- */
export const useAddInsurancePackageExclusionConditionMutation = (
    insurancePackageId: string
): UseMutationResult<InsurancePackageExclusion, CustomError, InsurancePackageExclusionDTO, Error> => {
    return useMutation<InsurancePackageExclusion, CustomError, InsurancePackageExclusionDTO, Error>({
        mutationFn: (insurancePackageExclusionConditionId: InsurancePackageExclusionDTO) =>
            addInsurancePackageCondition(insurancePackageId, insurancePackageExclusionConditionId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["getInsurancePackageExclusionConditions", {insurancePackageId}],
            });
        },
    });
};

export const useGetInsurancePackageExclusionConditionQuery = (
    insurancePackageId: string
): UseQueryResult<Array<InsurancePackageExclusion>, Error> => {
    return useQuery({
        queryKey: ["getInsurancePackageExclusionConditions", {insurancePackageId}],
        queryFn: () => getInsurancePackageConditions(insurancePackageId),
    });
};

export const useUpdateInsurancePackageExclusionConditionMutation = (
    insurancePackageId: string
): UseMutationResult<InsurancePackageExclusion, CustomError, InsurancePackageExclusion, Error> => {
    return useMutation<InsurancePackageExclusion, CustomError, InsurancePackageExclusion, Error>({
        mutationFn: (insurancePackageExclusionCondition: InsurancePackageExclusion) =>
            updateInsurancePackageCondition(
                insurancePackageId,
                insurancePackageExclusionCondition.id,
                insurancePackageExclusionCondition
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["getInsurancePackageExclusionConditions", {insurancePackageId}],
            });
        },
    });
};

export const useDeleteInsurancePackageExclusionConditionMutation = (
    insurancePackageId: string
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (insurancePackageCondition: number) =>
            deleteInsurancePackageCondition(insurancePackageId, insurancePackageCondition),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["getInsurancePackageExclusionConditions", {insurancePackageId}],
            });
        },
    });
};

/* --------- DEROGATION TAXES --------- */
export const useGetDegatoriesTaxesQuery = (
    insurancePackageId: string,
    registryCode: string
): UseQueryResult<Array<ProductRegistryTaxJoin>, Error> =>
    useQuery({
        queryKey: ["getDerogatoriesTaxes", {insurancePackageId, registryCode}],
        queryFn: () => getDerogatoriesTaxes(insurancePackageId, registryCode),
    });

export const useAddDerogationTaxMutation = (
    insurancePackageId: string,
    registryCode: string
): UseMutationResult<DerogatoryTaxDTO, CustomError, DerogatoryTaxDTO, Error> => {
    return useMutation<DerogatoryTaxDTO, CustomError, DerogatoryTaxDTO, Error>({
        mutationFn: (derogatoryTax: DerogatoryTaxDTO) => addCoverageTax(insurancePackageId, derogatoryTax),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["getDerogatoriesTaxes", {insurancePackageId, registryCode}],
            });
        },
    });
};

export const useUpdateDerogatoryTaxMutation = (
    insurancePackageId: string,
    productRegistryTaxJoinId: string,
    registryCode: string
): UseMutationResult<DerogatoryTaxDTO, CustomError, DerogatoryTaxDTO, Error> => {
    return useMutation<DerogatoryTaxDTO, CustomError, DerogatoryTaxDTO, Error>({
        mutationFn: (derogatoryTax: DerogatoryTaxDTO) =>
            updateDerogatoryTax(insurancePackageId, productRegistryTaxJoinId, derogatoryTax),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["getDerogatoriesTaxes", {insurancePackageId, registryCode}],
            });
        },
    });
};

export const useDeleteDerogatoryTaxMutation = (
    insurancePackageId: string,
    productRegistryTaxJoinId: string,
    registryCode: string
): UseMutationResult<void, CustomError, void, Error> => {
    return useMutation<void, CustomError, void, Error>({
        mutationFn: () => deleteDerogatoryTax(insurancePackageId, productRegistryTaxJoinId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["getDerogatoriesTaxes", {insurancePackageId, registryCode}],
            });
        },
    });
};

export const useReorderDerogatoryTaxCondtionMutation = (
    insurancePackageId: string,
    productRegistryTaxJoinId: string,
    registryCode: string
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (order: number) => reorderDerogatoryTaxConditionMutation(insurancePackageId, productRegistryTaxJoinId, order),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getDerogatoriesTaxes", {insurancePackageId, registryCode}]});
        },
    });
};

export const useReorderInsurancePackageExclusionCondtionMutation = (
    insurancePackageId: string,
    insurancePackageExclusionId: string,
): UseMutationResult<void, CustomError, number, Error> => {
    return useMutation<void, CustomError, number, Error>({
        mutationFn: (order: number) => reorderInsurancePackageExclusionConditionMutation(insurancePackageId, insurancePackageExclusionId, order),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getInsurancePackageExclusionConditions", {insurancePackageId}]});
        },
    });
};
