import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    Divider,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    Label,
    Menu,
    Message,
    Radio,
    Table,
    Transition
} from "semantic-ui-react";
import { HealthProductFormContext } from "./HealthProductFormContext";

const regimesOptions = [
    {value: "healthPlanRG", label: "RG"},
    {value: "healthPlanAM", label: "AM"},
    {value: "healthPlanTNS", label: "TNS"},
    {value: "healthPlanEXP", label: "Farm operator"},
    {value: "healthPlanETU", label: "Student"},
];

type AgeRange = {minAge: number; maxAge: number; type: "NO_INCREMENT"};

const coveragesOptions = [
    {label: "Insurance", value: "ASSE", ageGroupsPropertyName: "assuranceSlices"},
    {label: "Assistance", value: "ASS", ageGroupsPropertyName: "assistanceSlices"},
    {label: "Reinforcement", value: "RENFORT", ageGroupsPropertyName: "renfortSlices"},
    {label: "Legal protection", value: "PJ", ageGroupsPropertyName: "protectionJuridiqueSlices"},
    {label: "Santéclair", value: "SC", ageGroupsPropertyName: "santeClairSlices"},
    {label: "Association fees", value: "FRAISASSOC", ageGroupsPropertyName: "fraisAssociationSlices"},
    {label: "Adhesion fees", value: "FRAISADH", ageGroupsPropertyName: ""},
    {label: "Alternative medicine option", value: "MEDDOUCE", ageGroupsPropertyName: "medecineDouceSlices"},
];

const HealthSpecificQuestionsForm: React.FC = () => {
    const {t} = useTranslation();
    const {state, displayError, stepStateChange} = useContext(HealthProductFormContext);
    const {numberZones} = state.questions;
    const [selectedCoverage, setSelectedCoverage] = useState(coveragesOptions[0]);
    const [ageRange, setAgeRange] = useState<AgeRange>({minAge: 0, maxAge: 0, type: "NO_INCREMENT"});
    const [ageRangeMode, setAgeRangeMode] = useState<"STEP_BY_STEP" | "RANGE">("RANGE");
    const [ageRangeError, setAgeRangeError] = useState(false);

    const messageItems = [
        t("A single addition corresponding to the min max limits, example from 0 to 17 years old"),
        t(
            "A step-by-step addition, from the min value to the max value, example min = 18, max = 19 add range from 18 to 18 years old, and range from 19 to 19 years old"
        ),
    ];

    const handleQuestionsChange = (_: React.ChangeEvent | React.MouseEvent, data: any) => {
        if (data.type === "checkbox") stepStateChange("questions", data.name, data.checked);
        else stepStateChange("questions", data.name, data.value);
    };

    const addAgeRange = () => {
        let ageGroupToAdd: AgeRange[] = [];
        let existingAgeRange = undefined;
        if (
            ageRange.minAge <= ageRange.maxAge &&
            ageRange.minAge >= 0 &&
            ageRange.minAge <= 999 &&
            ageRange.minAge >= 0 &&
            ageRange.maxAge <= 999
        ) {
            existingAgeRange = state.questions[selectedCoverage.ageGroupsPropertyName].find((range: AgeRange) => {
                if (ageRange.minAge === range.minAge || ageRange.maxAge === range.minAge) return true;
                return ageRange.minAge >= range.minAge && ageRange.minAge <= range.maxAge;
            });
            if (existingAgeRange === undefined) {
                if (ageRangeMode === "RANGE") {
                    ageGroupToAdd.push(ageRange);
                } else if (ageRangeMode === "STEP_BY_STEP") {
                    for (let minMax = ageRange.minAge; minMax <= ageRange.maxAge; minMax++) {
                        ageGroupToAdd.push({minAge: minMax, maxAge: minMax, type: ageRange.type});
                    }
                }
                setAgeRange({...ageRange, minAge: 0, maxAge: 0});
                stepStateChange("questions", selectedCoverage.ageGroupsPropertyName, [
                    ...state.questions[selectedCoverage.ageGroupsPropertyName],
                    ...ageGroupToAdd,
                ]);
            } else setAgeRangeError(true);
        }
    };

    const deleteAgeRange = (min: number | undefined = undefined) => {
        let modifiedAgeRanges = state.questions[selectedCoverage.ageGroupsPropertyName];
        if (min === undefined) modifiedAgeRanges = [];
        else modifiedAgeRanges = modifiedAgeRanges.filter((range: AgeRange) => range.minAge !== min);
        stepStateChange("questions", selectedCoverage.ageGroupsPropertyName, modifiedAgeRanges);
    };

    function handleChangeAgeRange(e: React.ChangeEvent<HTMLInputElement>) {
        setAgeRange({...ageRange, [e.target.name]: parseInt(e.target.value)});
        setAgeRangeError(false);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={4}>
                    <Form.Field>
                        <Input
                            fluid
                            required
                            type="number"
                            name={"numberZones"}
                            maxLength="1"
                            label={t("Number of zone")}
                            max={9}
                            min={0}
                            value={numberZones}
                            onChange={handleQuestionsChange}
                        />
                        {displayError("numberZones") && (
                            <Label pointing prompt>
                                {displayError("numberZones")}
                            </Label>
                        )}
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Header as="h2">{t("Plans")}</Header>
                    <Transition visible={displayError("healthPlan") !== undefined} animation="zoom" duration={250}>
                        <Message negative>
                            <Message.Header>{t("No plan selected")}</Message.Header>
                            <p>{t("Please select at least one plan")}</p>
                        </Message>
                    </Transition>
                    <Table compact celled>
                        <Table.Header fullWidth>
                            <Table.Row>
                                {regimesOptions.map((regime) => (
                                    <Table.HeaderCell key={regime.value} textAlign="center">
                                        {t(regime.label)}
                                    </Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row className="no-hover">
                                {regimesOptions.map((regime) => (
                                    <Table.Cell key={regime.value} textAlign="center">
                                        <Checkbox
                                            slider
                                            checked={state.questions[regime.value]}
                                            onClick={handleQuestionsChange}
                                            name={regime.value}
                                        />
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16} style={{marginBottom: "1rem"}}>
                    <Header as="h2">{t("Age ranges by coverage")}</Header>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Menu vertical pointing>
                        {coveragesOptions.map(
                            (coverage) =>
                                state.questions[coverage.ageGroupsPropertyName] !== undefined && (
                                    <Menu.Item
                                        key={coverage.value}
                                        active={coverage.value === selectedCoverage.value}
                                        onClick={() => setSelectedCoverage(coverage)}>
                                        <Label
                                            basic
                                            color={
                                                state.questions[coverage.ageGroupsPropertyName].length > 0
                                                    ? "teal"
                                                    : "grey"
                                            }>
                                            {state.questions[coverage.ageGroupsPropertyName].length}
                                        </Label>
                                        <strong>{t(coverage.label)}</strong>
                                    </Menu.Item>
                                )
                        )}
                    </Menu>
                </Grid.Column>
                <Grid.Column width={12}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Form.Field>
                                    <Input
                                        fluid
                                        label="Min"
                                        type="number"
                                        name="minAge"
                                        maxLength="3"
                                        min={0}
                                        max={999}
                                        value={ageRange.minAge}
                                        onChange={handleChangeAgeRange}
                                    />
                                    {ageRangeError && (
                                        <Label pointing prompt>
                                            {t("Age min already added")}
                                        </Label>
                                    )}
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Input
                                    fluid
                                    label="Max"
                                    type="number"
                                    name="maxAge"
                                    maxLength="3"
                                    min={0}
                                    max={999}
                                    value={ageRange.maxAge}
                                    onChange={handleChangeAgeRange}
                                />
                            </Grid.Column>
                            <Grid.Column width={7} textAlign="center">
                                <Radio
                                    toggle
                                    name="radioRange"
                                    label={t("Single addition")}
                                    style={{padding: "0.5em"}}
                                    onClick={() => setAgeRangeMode("RANGE")}
                                    checked={ageRangeMode === "RANGE"}
                                />
                                <Radio
                                    toggle
                                    name="radioStepByStep"
                                    label={t("Step by step addition")}
                                    style={{padding: "0.5em"}}
                                    onClick={() => setAgeRangeMode("STEP_BY_STEP")}
                                    checked={ageRangeMode === "STEP_BY_STEP"}
                                />
                            </Grid.Column>
                            <Grid.Column width={3} textAlign="center">
                                <Button icon="plus" primary onClick={() => addAgeRange()} />
                                {state.questions[selectedCoverage.ageGroupsPropertyName].length > 0 && (
                                    <Button icon="trash" color="red" basic onClick={() => deleteAgeRange()} />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider />
                    {state.questions[selectedCoverage.ageGroupsPropertyName].length > 0 ? (
                        <Label.Group>
                            {state.questions[selectedCoverage.ageGroupsPropertyName]
                                .sort((range: AgeRange, nextRange: AgeRange) => {
                                    if (range.minAge < nextRange.minAge) return -1;
                                    if (range.minAge > nextRange.minAge) return 1;
                                    return 0;
                                })
                                .map((ageGroup: AgeRange) => (
                                    <Label key={ageGroup.minAge + ageGroup.maxAge}>
                                        {`${ageGroup.minAge} - ${ageGroup.maxAge}`}
                                        <Icon name="delete" onClick={() => deleteAgeRange(ageGroup.minAge)} />
                                    </Label>
                                ))}
                        </Label.Group>
                    ) : (
                        <Message warning style={{display: "block"}}>
                            <Message.Header>{t("No age range has been added")}</Message.Header>
                            <p>{t("You can add age range in two ways")} :</p>
                            <Message.List items={messageItems} />
                        </Message>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default HealthSpecificQuestionsForm;
