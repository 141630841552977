import {ConditionPart} from "../Condition/Types";

// ----------------- Entités -----------------
export type DeductibleCapital = {
    id: number;
    label: string;
    type: DeductibleCapitalType;
    description: string | null;

    coverageId: number | null;
    optionId: number | null;
    coverageGroupId: number | null;
    insurancePackageId: number;
    deductibleCapitalConditions: Array<DeductibleCapitalCondition>;

    createdDate: string;
    modifiedDate: string;
    createdBy: string;
    modifiedBy: string;
    createdOnVersionId: number;
};

export type DeductibleCapitalCondition = {
    id: number;
    ifCondition: string | null;
    conditionPartList: Array<ConditionPart>;
    conditionOrder: number;
    deductibleCapitalId: number;
    deductibleCapitalConditionElements: Array<DeductibleCapitalConditionElement>;
};

export type DeductibleCapitalConditionElement = {
    id: number;
    order: number;
    text: string | null;
    format: DeductibleCapitalConditionElementFormat | null;

    questionName: string | null;
    pricingCriteriaName: string | null;
    variableName: string | null;
    deductibleCapitalConditionId: number;
};

// ----------------- DTOs -----------------
export type DeductibleCapitalDTO = {
    label: string;
    type: DeductibleCapitalType;
    description: string | null;

    coverageId: number | null;
    optionId: number | null;
    coverageGroupId: number | null;

    deductibleCapitalConditionDTOs: Array<DeductibleCapitalConditionDTO>;
};

export type DeductibleCapitalConditionDTO = {
    ifCondition: string | null;
    conditionPartList: Array<ConditionPart> | null;
    conditionOrder?: number;

    deductibleCapitalConditionElementDTOs: Array<DeductibleCapitalConditionElementDTO>;
};

export type DeductibleCapitalConditionElementDTO = {
    order: number;
    text: string | null;
    format: DeductibleCapitalConditionElementFormat | null;

    questionName: string | null;
    pricingCriteriaName: string | null;
    variableName: string | null;
};

// ----------------- Enums -----------------
export enum DeductibleCapitalType {
    F = "F",
    C = "C",
}

export enum DeductibleCapitalConditionElementFormat {
    INTEGER = "INTEGER",
    DECIMAL = "DECIMAL",
    INTEGER_WITH_SEPARATOR = "INTEGER_WITH_SEPARATOR",
    DECIMAL_WITH_SEPARATOR = "DECIMAL_WITH_SEPARATOR",
}
