import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { AccordionContent, Button, Divider, Grid, GridColumn, Header } from "semantic-ui-react";
import ConditionAccordionTitle from "../../../../../Components/Condition/ConditionAccordionTitle";
import { ConditionWarningEditForbidden } from "../../../../../Components/Condition/ConditionWarningEditForbidden";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { DndPropertiesType } from "../../../../../Hooks/useDragAndDropProperties";

import { ConditionPart, OperandEnum } from "../../../../../Services/Condition/Types";
import {
    useDeleteDerogatoryTaxMutation,
    useReorderDerogatoryTaxCondtionMutation,
    useUpdateDerogatoryTaxMutation
} from "../../../../../Services/InsurancePackage/Queries";
import { ProductRegistryTaxJoin } from "../../../../../Services/ProductBehaviour/Types";
import { VersionDTO } from "../../../../../Services/Version/Types";
import ConditionHandling from "../../../Condition/ConditionHandling";
import ConditionView from "../../../Condition/Fragments/ConditionView";
import BehaviourTaxesAssignement from "../../../ProductBehaviour/Fragments/BehaviourTaxesAssignement";
import { isEditingConditionForbidden } from "../../../../../Utils/ConditionPartUtils";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type DerogatoriesTaxesAccordionPropsType = {
    version: VersionDTO
    insurancePackageId: string;
    condition: ProductRegistryTaxJoin;
    index: number;
    activeIndex: number;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    registryCode: string;
    numberOfConditions: number;
    dndProperties: DndPropertiesType<ProductRegistryTaxJoin>
};

const DerogatoriesTaxesAccordion = ({
    version,
    insurancePackageId,
    condition,
    index,
    activeIndex,
    setActiveIndex,
    registryCode,
    numberOfConditions,
    dndProperties,
}: DerogatoriesTaxesAccordionPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [editCondition, setEditCondition] = useState<boolean>(false);

    const conditionKindList = [
        {key: 1, text: "System variable", value: OperandEnum.SOFTWARE_CRITERIA, type: "female"},
        {key: 2, text: "Question", value: OperandEnum.QUESTION, type: "female"},
        {key: 3, text: "Pricing criteria", value: OperandEnum.CRITERE, type: "male"},
        {key: 4, text: "Variable", value: OperandEnum.VARIABLE, type: "female"},
        {key: 5, text: "Fixed value", value: OperandEnum.FIXED_VALUE, type: "female"},
    ];

    const updateDerogatoryTaxMutation = useUpdateDerogatoryTaxMutation(
        insurancePackageId,
        condition.id.toString(),
        registryCode
    );
    const deleteDerogatoryTaxMutation = useDeleteDerogatoryTaxMutation(
        insurancePackageId,
        condition.id.toString(),
        registryCode
    );

    const reorderDerogatoryTaxCondtionMutation = useReorderDerogatoryTaxCondtionMutation(
        insurancePackageId,
        condition.id.toString(),
        registryCode
    );

    return (
        <div key={index}>
            <ConditionAccordionTitle<ProductRegistryTaxJoin>
                dndProperties={dndProperties}
                version={version}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                condition={condition}
                numberOfConditions={numberOfConditions}
                reorderFunction={reorderDerogatoryTaxCondtionMutation}
                index={index}
            />
            <AccordionContent active={activeIndex === index}>
                <Divider horizontal>
                    <Header as="h4">{t("Condition")}</Header>
                </Divider>

                {editCondition ? (
                    <ConditionHandling
                        conditionKindList={conditionKindList}
                        conditionPartList={condition.conditionPartList}
                        setConditionPartList={(conditionPartListUpdate: ConditionPart[]) => {
                            const conditionUpdate: ProductRegistryTaxJoin = {
                                ...condition,
                                ifCondition: null,
                                conditionPartList: conditionPartListUpdate,
                            };
                            updateDerogatoryTaxMutation.mutate(conditionUpdate);
                        }}
                        setEditCondition={setEditCondition}
                        updateMutation={updateDerogatoryTaxMutation}
                        versionId={params.versionId}
                    />
                ) : (
                    <>
                        <Grid>
                            <>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {t("If")} :
                                </GridColumn>
                                <GridColumn width={14} textAlign="center" className="condition">
                                    <ConditionView conditionPartList={condition.conditionPartList} />
                                </GridColumn>
                                <GridColumn width={1} textAlign="center" verticalAlign="middle">
                                    {isEditingConditionForbidden(condition.conditionPartList) ? (
                                        <ConditionWarningEditForbidden />
                                    ) : (
                                        <Button
                                            name={"editCondition" + Number(index + 1)}
                                            color="grey"
                                            icon="edit"
                                            compact
                                            basic
                                            onClick={() => {
                                                setEditCondition(true);
                                            }}
                                        />
                                    )}
                                </GridColumn>
                            </>
                        </Grid>
                        <Divider horizontal>
                            <Header as="h4">{t("Assignment")}</Header>
                        </Divider>
                        <BehaviourTaxesAssignement
                            condition={condition}
                            onValidateMutate={(categoryTaxe: string) => {
                                updateDerogatoryTaxMutation.mutate({
                                    ...condition,
                                    taxCategoryCode: categoryTaxe,
                                });
                            }}
                            updateMutaion={updateDerogatoryTaxMutation}
                        />
                        <Grid>
                            <GridColumn floated={"right"} textAlign={"right"} style={{paddingRight: "15px"}}>
                                <ModalDelete
                                    isSuccess={deleteDerogatoryTaxMutation.isSuccess}
                                    isPending={deleteDerogatoryTaxMutation.isPending}
                                    isError={deleteDerogatoryTaxMutation.isError}
                                    error={deleteDerogatoryTaxMutation.error}
                                    resetMutation={deleteDerogatoryTaxMutation.reset}
                                    objectToDelete="Condition"
                                    objectType="female"
                                    openModalButtonName={"delete-condition-" + Number(index + 1)}
                                    onValidate={() => deleteDerogatoryTaxMutation.mutate()}
                                    onSuccess={() => setActiveIndex(-1)}
                                />
                            </GridColumn>
                        </Grid>
                    </>
                )}
            </AccordionContent>
        </div>
    );
};

export default DerogatoriesTaxesAccordion;
