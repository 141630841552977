import {Dispatch, SetStateAction, useState} from "react";

export type DndPropertiesType<T> = {
    initialTargetItemId: number | undefined;
    setInitialTargetItemId: Dispatch<SetStateAction<number | undefined>>;
    temporaryItems: T[] | undefined;
    setTemporaryItems: Dispatch<SetStateAction<T[] | undefined>>;
    draggedItem: T | undefined;
    setDraggedItem: Dispatch<SetStateAction<T | undefined>>;
    renderedItems: T[];
    refreshData: () => void;
};

export default function useDragAndDropProperties<T>(data: T[]): DndPropertiesType<T> {
    const [initialTargetItemId, setInitialTargetItemId] = useState<number | undefined>(undefined);

    const [temporaryItems, setTemporaryItems] = useState<T[]>();
    const [draggedItem, setDraggedItem] = useState<T>();

    const renderedItems = temporaryItems ?? data;

    const refreshData = () => {
        setTemporaryItems(undefined);
        setInitialTargetItemId(undefined);
        setDraggedItem(undefined);
    };

    return {
        initialTargetItemId,
        setInitialTargetItemId,
        temporaryItems,
        setTemporaryItems,
        draggedItem,
        setDraggedItem,
        renderedItems,
        refreshData,
    };
}
