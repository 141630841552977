import {useReducer, useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid, Message} from "semantic-ui-react";
import getErrorMessage from "../../../../Pages/Global/Form/ErrorMessage";
import {ESelectionDimensionDTO, ESelectionDimensionType} from "../../../Services/ESelection/Types";

type FormESelectionPropsType = {
    control: any;
    errors: any;
    getValues: any;
    trigger: any;
    defaultValues: {
        dimensions: Array<ESelectionDimensionDTO>;
        age_slices_size: number | undefined;
        amount_slices_size: number | undefined;
        dc_is_active: boolean;
        ij_is_active: boolean;
        fictive_is_active: boolean;
    };
};

type ReducerActionType =
    | {type: "ADD_DIM"; payload: ESelectionDimensionType}
    | {type: "REMOVE_DIM"; payload: ESelectionDimensionType}
    | {type: "UPDATE_DIM"; payload: {dimension: ESelectionDimensionType; size: number}}
    | {
          type: "UPDATE_DVAL";
          payload: {dimension: ESelectionDimensionType; index: number; min: number | string; max: number | string};
      };

const FormESelection = ({control, errors, getValues, trigger, defaultValues}: FormESelectionPropsType) => {
    const {t} = useTranslation();
    const [ageSlicesSize, setAgeSlicesSize] = useState<number>(defaultValues.age_slices_size || 0);
    const [amountSlicesSize, setAmountSlicesSize] = useState<number>(defaultValues.amount_slices_size || 0);

    const reducer = (
        state: Array<ESelectionDimensionDTO>,
        action: ReducerActionType
    ): Array<ESelectionDimensionDTO> => {
        switch (action.type) {
            case "ADD_DIM":
                return [
                    ...state,
                    {
                        type: action.payload,
                        eSelectionDimensionValueDTOs: [],
                    },
                ];
            case "REMOVE_DIM":
                return state.filter((dimension) => dimension.type !== action.payload);
            case "UPDATE_DIM":
                return state.map((dimension) => {
                    if (dimension.type === action.payload.dimension) {
                        const diff = action.payload.size - dimension.eSelectionDimensionValueDTOs.length;

                        if (diff > 0) {
                            return {
                                ...dimension,
                                eSelectionDimensionValueDTOs: [
                                    ...dimension.eSelectionDimensionValueDTOs,
                                    ...Array(diff).fill({
                                        min: "",
                                        max: "",
                                    }),
                                ],
                            };
                        } else if (diff < 0) {
                            return {
                                ...dimension,
                                eSelectionDimensionValueDTOs: dimension.eSelectionDimensionValueDTOs.slice(0, diff),
                            };
                        }
                    }
                    return dimension;
                });
            case "UPDATE_DVAL":
                return state.map((dimension) => {
                    if (dimension.type === action.payload.dimension) {
                        return {
                            ...dimension,
                            eSelectionDimensionValueDTOs: dimension.eSelectionDimensionValueDTOs.map((dval, index) => {
                                if (index === action.payload.index) {
                                    return {
                                        min: action.payload.min,
                                        max: action.payload.max,
                                    };
                                }
                                return dval;
                            }),
                        };
                    }
                    return dimension;
                });
            default:
                return state;
        }
    };

    const [gridStructure, dispatchGridStructure] = useReducer(reducer, defaultValues.dimensions);

    return (
        <Form id="modal-form">
            <Grid centered columns={2} data-cy="e-selection-grid-structure">
                <Grid.Column>
                    <Controller
                        control={control}
                        defaultValue={defaultValues.age_slices_size || ""}
                        name={"age-slices-size"}
                        rules={{required: true}}
                        render={({field: {value, name, onChange}}) => (
                            <Form.Input
                                value={value}
                                type="number"
                                label={t("Age slices size")}
                                placeholder={t("Age slices size")}
                                name={name}
                                onChange={(_, {value}) => {
                                    if (parseInt(value) > 20) value = "20";

                                    onChange(value);
                                    setAgeSlicesSize(parseInt(value));
                                    dispatchGridStructure({
                                        type: "UPDATE_DIM",
                                        payload: {
                                            dimension: ESelectionDimensionType.AGE,
                                            size: parseInt(value),
                                        },
                                    });
                                }}
                                error={getErrorMessage(t, errors, name)}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue={defaultValues.dc_is_active}
                        name={"dc-is-active"}
                        rules={{
                            validate: (checked) =>
                                checked || getValues("ij-is-active") || getValues("fictive-is-active"),
                        }}
                        render={({field: {value, name, onChange}}) => (
                            <Form.Checkbox
                                toggle
                                checked={value}
                                label={t("Include amount x", {value: ESelectionDimensionType.DC})}
                                name={name}
                                onChange={(_, {checked}) => {
                                    onChange(checked);
                                    trigger("ij-is-active");
                                    trigger("fictive_is_active");

                                    if (checked) {
                                        dispatchGridStructure({type: "ADD_DIM", payload: ESelectionDimensionType.DC});

                                        dispatchGridStructure({
                                            type: "UPDATE_DIM",
                                            payload: {
                                                dimension: ESelectionDimensionType.DC,
                                                size: amountSlicesSize,
                                            },
                                        });
                                    } else {
                                        dispatchGridStructure({
                                            type: "REMOVE_DIM",
                                            payload: ESelectionDimensionType.DC,
                                        });
                                    }
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue={defaultValues.ij_is_active}
                        name={"ij-is-active"}
                        rules={{
                            validate: (checked) =>
                                checked || getValues("dc-is-active") || getValues("fictive-is-active"),
                        }}
                        render={({field: {value, name, onChange}}) => (
                            <Form.Checkbox
                                toggle
                                checked={value}
                                label={t("Include amount x", {value: ESelectionDimensionType.IJ})}
                                name={name}
                                onChange={(_, {checked}) => {
                                    onChange(checked);
                                    trigger("dc-is-active");
                                    trigger("fictive_is_active");

                                    if (checked) {
                                        dispatchGridStructure({type: "ADD_DIM", payload: ESelectionDimensionType.IJ});

                                        dispatchGridStructure({
                                            type: "UPDATE_DIM",
                                            payload: {
                                                dimension: ESelectionDimensionType.IJ,
                                                size: amountSlicesSize,
                                            },
                                        });
                                    } else {
                                        dispatchGridStructure({
                                            type: "REMOVE_DIM",
                                            payload: ESelectionDimensionType.IJ,
                                        });
                                    }
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue={defaultValues.fictive_is_active}
                        name={"fictive-is-active"}
                        rules={{
                            validate: (checked) => checked || getValues("dc-is-active") || getValues("ij-is-active"),
                        }}
                        render={({field: {value, name, onChange}}) => (
                            <Form.Checkbox
                                toggle
                                checked={value}
                                label={t("Include amount x", {value: ESelectionDimensionType.FICTIVE})}
                                name={name}
                                onChange={(_, {checked}) => {
                                    onChange(checked);
                                    trigger("dc-is-active");
                                    trigger("ij-is-active");

                                    if (checked) {
                                        dispatchGridStructure({
                                            type: "ADD_DIM",
                                            payload: ESelectionDimensionType.FICTIVE,
                                        });

                                        dispatchGridStructure({
                                            type: "UPDATE_DIM",
                                            payload: {
                                                dimension: ESelectionDimensionType.FICTIVE,
                                                size: amountSlicesSize,
                                            },
                                        });
                                    } else {
                                        dispatchGridStructure({
                                            type: "REMOVE_DIM",
                                            payload: ESelectionDimensionType.FICTIVE,
                                        });
                                    }
                                }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        defaultValue={defaultValues.amount_slices_size || ""}
                        name={"amount-slices-size"}
                        rules={{required: true}}
                        render={({field: {value, name, onChange}}) => (
                            <Form.Input
                                value={value}
                                type="number"
                                label={t("Amount slices size")}
                                placeholder={t("Amount slices size")}
                                name={name}
                                onChange={(_, {value}) => {
                                    if (parseInt(value) > 20) value = "20";

                                    onChange(value);
                                    setAmountSlicesSize(parseInt(value));
                                    dispatchGridStructure({
                                        type: "UPDATE_DIM",
                                        payload: {
                                            dimension: ESelectionDimensionType.DC,
                                            size: parseInt(value),
                                        },
                                    });
                                    dispatchGridStructure({
                                        type: "UPDATE_DIM",
                                        payload: {
                                            dimension: ESelectionDimensionType.IJ,
                                            size: parseInt(value),
                                        },
                                    });
                                    dispatchGridStructure({
                                        type: "UPDATE_DIM",
                                        payload: {
                                            dimension: ESelectionDimensionType.FICTIVE,
                                            size: parseInt(value),
                                        },
                                    });
                                }}
                                error={getErrorMessage(t, errors, name)}
                            />
                        )}
                    />

                    {errors["ij-is-active"] && errors["dc-is-active"] && errors["fictive-is-active"] && (
                        <Message negative>{t("At least on amount type must be selected")}</Message>
                    )}
                </Grid.Column>
            </Grid>

            {ageSlicesSize > 0 &&
                amountSlicesSize > 0 &&
                !errors["ij-is-active"] &&
                !errors["dc-is-active"] &&
                !errors["fictive-is-active"] && (
                    <Grid centered divided data-cy="e-selection-dimensions-values">
                        {gridStructure
                            .sort((a, b) => (a.type > b.type ? 1 : -1))
                            .map((dimension, index) => (
                                <Grid.Column key={index} width={4} style={{textAlign: "center", paddingTop: "0"}}>
                                    <label>{t("x slices", {value: dimension.type})}</label>
                                    <Grid style={{marginTop: "0.5rem"}}>
                                        {dimension.eSelectionDimensionValueDTOs.map((dval, index) => (
                                            <Grid.Row centered key={index} style={{padding: "0.2rem 0 0.2rem 0"}}>
                                                <Grid.Column width={8} style={{paddingRight: "0.1rem"}}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue={
                                                            gridStructure.find((d) => d.type === dimension.type)
                                                                ?.eSelectionDimensionValueDTOs[index].min ?? ""
                                                        }
                                                        name={`${dimension.type}.${index}.min`}
                                                        rules={{required: true, min: 0}}
                                                        render={({field: {value, name, onChange}}) => (
                                                            <Form.Input
                                                                fluid
                                                                value={value}
                                                                type="number"
                                                                placeholder={t("min")}
                                                                name={name}
                                                                min="0"
                                                                onChange={(_, {value}) => {
                                                                    onChange(value);
                                                                    dispatchGridStructure({
                                                                        type: "UPDATE_DVAL",
                                                                        payload: {
                                                                            dimension: dimension.type,
                                                                            index: index,
                                                                            min: parseInt(value),
                                                                            max: dval.max,
                                                                        },
                                                                    });
                                                                }}
                                                                error={
                                                                    errors[dimension.type] &&
                                                                    errors[dimension.type][index] &&
                                                                    errors[dimension.type][index].min
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column width={8} style={{paddingLeft: "0.1rem"}}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue={
                                                            gridStructure.find((d) => d.type === dimension.type)
                                                                ?.eSelectionDimensionValueDTOs[index].max ?? ""
                                                        }
                                                        name={`${dimension.type}.${index}.max`}
                                                        rules={{required: true, min: 0}}
                                                        render={({field: {value, name, onChange}}) => (
                                                            <Form.Input
                                                                fluid
                                                                value={value}
                                                                type="number"
                                                                placeholder={t("max")}
                                                                name={name}
                                                                min="0"
                                                                onChange={(_, {value}) => {
                                                                    onChange(value);
                                                                    dispatchGridStructure({
                                                                        type: "UPDATE_DVAL",
                                                                        payload: {
                                                                            dimension: dimension.type,
                                                                            index: index,
                                                                            min: parseInt(value),
                                                                            max: dval.max,
                                                                        },
                                                                    });
                                                                }}
                                                                error={
                                                                    errors[dimension.type] &&
                                                                    errors[dimension.type][index] &&
                                                                    errors[dimension.type][index].max
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}
                                    </Grid>
                                </Grid.Column>
                            ))}
                    </Grid>
                )}
        </Form>
    );
};

export default FormESelection;
