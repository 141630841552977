import {UseQueryResult, useQuery} from "@tanstack/react-query";
import React from "react";
import {AuthContext} from "../../Components/AuthProvider/AuthProvider";
import {getCategories, getCategoryDetails, getDomains, getSubDomains} from "./AxiosRequests";
import {Category, CategoryDetails, Domain, SubDomain} from "./Types";

export const useGetDomains = (): UseQueryResult<Array<Domain>, Error> => {
    const societeUser = React.useContext(AuthContext).user?.societeUser;
    return useQuery({
        queryKey: ["getDomains"],
        queryFn: () => getDomains(societeUser),
    });
};

export const useGetSubDomains = (domainId: number, enabled: boolean): UseQueryResult<Array<SubDomain>, Error> => {
    return useQuery({
        queryKey: ["getSubDomains", {domainId}],
        queryFn: () => getSubDomains(domainId),
        enabled,
    });
};

export const useGetCategories = (subDomainId: number, enabled: boolean): UseQueryResult<Array<Category>, Error> => {
    return useQuery({
        queryKey: ["getCategories", {subDomainId}],
        queryFn: () => getCategories(subDomainId),
        enabled,
    });
};

export const useGetCategoryDetails = (categoryCode: string): UseQueryResult<CategoryDetails, Error> => {
    return useQuery({
        queryKey: ["getCategoryDetails", {categoryCode}],
        queryFn: () => getCategoryDetails(categoryCode),
        enabled: categoryCode !== undefined && categoryCode !== null && categoryCode !== "",
    });
};

