import { useState } from "react";
import { Control, DeepMap, FieldError } from "react-hook-form";
import FormDimensionValue from "../../../../../Components/Forms/FormDimensionValue";
import { DimensionGridValueDTO } from "../../../../../Services/PricingGrid/Types";

type PropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldError>;
    dimensionItem: DimensionGridValueDTO & {dimensionType: "F" | "T"; dimensionValueType: string};
};

const UpdateFormDimensionValue = ({control, errors, dimensionItem}: PropsType) => {
    const [value, setValue] = useState<string | number>(dimensionItem.value);
    const [label, setLabel] = useState<string>(dimensionItem.label);
    const [valueMin, setValueMin] = useState<null | string>(
        dimensionItem.min !== null
            ? dimensionItem.min.toString()
            : dimensionItem.dimensionValueType === "DATE"
            ? dimensionItem.minDate
            : "0"
    );
    const [valueMax, setValueMax] = useState<null | string>(
        dimensionItem.max !== null
            ? dimensionItem.max.toString()
            : dimensionItem.dimensionValueType === "DATE"
            ? dimensionItem.maxDate
            : "0"
    );
    const [defaultValueOrder, setDefaultValueOrder] = useState<null | number>(dimensionItem.order);

    return (
        <FormDimensionValue
            control={control}
            errors={errors}
            dimension={dimensionItem}
            defaultValueCode={value}
            setDefaultValueCode={setValue}
            defaultValueLabel={label}
            setDefaultValueLabel={setLabel}
            defaultValueMin={valueMin}
            setDefaultValueMin={setValueMin}
            defaultValueMax={valueMax}
            setDefaultValueMax={setValueMax}
            defaultValueOrder={defaultValueOrder}
            setDefaultValueOrder={setDefaultValueOrder}
        />
    );
};

export default UpdateFormDimensionValue;
