import axios, {AxiosError} from "axios";
import {buildCustomError} from "../../../Services/RequestUtils";
import axiosInstance from "../Coverlife.axios.instance";
import {PremiumsPricingRights} from "./Types";

export const getPremiumsPricingRights = async (): Promise<PremiumsPricingRights[]> => {
    try {
        const response = await axiosInstance.get<PremiumsPricingRights[]>("/premiums/pricing_rights");

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const downloadPremiumsPricingRights = async (): Promise<unknown> => {
    try {
        const response = await axiosInstance.get("/premiums/pricing_rights/_export", {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const uploadPremiumsPricingRights = async (formData: FormData): Promise<void> => {
    try {
        const response = await axiosInstance.post("/premiums/pricing_rights/_import", formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

