import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router";
import { Grid, Message, Tab } from "semantic-ui-react";
import { useGetDeductibleCapitals } from "../../../../../Services/DeductibleCapital/Queries";
import { DeductibleCapital } from "../../../../../Services/DeductibleCapital/Types";
import { useGetCoverageGroupsQuery } from "../../../../../Services/InsurancePackage/Queries";
import { useGetVersionQuery } from "../../../../../Services/Version/Queries";
import DeductibleCapitalCardList from "./DeductibleCapitalCardList";
import DeductibleCapitalMenu from "./DeductibleCapitalMenu";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

type DeductibleCapitalListPropsType = {
    insurancePackageId: string;
};

export enum DeductibleCapitalSelectedTypeEnum {
    OPTION,
    COVERAGE,
    COVERAGE_GROUP,
}

export type DeductibleCapitalActiveItemType = {
    type: DeductibleCapitalSelectedTypeEnum;
    id: number;
};

const DeductibleCapitalsList = ({insurancePackageId: insurancePackageId}: DeductibleCapitalListPropsType) => {
    const [haveCoverageGroup, setHaveCoverageGroup] = useState<boolean>(true);
    const [activeItem, setActiveItem] = React.useState<DeductibleCapitalActiveItemType | null>(null);
    const getDeductibleCapitals = useGetDeductibleCapitals(insurancePackageId);

    const params = useParams() as IParams;
    const {data: coverageGroups} = useGetCoverageGroupsQuery(params.insurancePackageId);
    useEffect(() => {
        setHaveCoverageGroup(coverageGroups !== undefined && coverageGroups.length > 0);
    }, [coverageGroups]);
    const getVersion = useGetVersionQuery(params.id, params.versionId);
    const {t} = useTranslation();
    return (
        <Tab.Pane>
            {haveCoverageGroup ? (
                <Grid>
                    <Grid.Column width={3}>
                        <DeductibleCapitalMenu
                            insurancePackageId={insurancePackageId}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                        />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        {activeItem &&
                            getVersion.isSuccess &&
                            getVersion.data &&
                            getDeductibleCapitals.isSuccess &&
                            getDeductibleCapitals.data !== undefined && (
                                <DeductibleCapitalCardList
                                    version={getVersion.data}
                                    insurancePackageId={insurancePackageId}
                                    activeItem={activeItem}
                                    deductibleCapitalsList={getDeductibleCapitals.data.filter(
                                        (deductibleCapital: DeductibleCapital) => {
                                            return (
                                                (deductibleCapital.coverageGroupId === activeItem?.id &&
                                                    activeItem?.type ===
                                                        DeductibleCapitalSelectedTypeEnum.COVERAGE_GROUP) ||
                                                (deductibleCapital.coverageId === activeItem?.id &&
                                                    activeItem?.type === DeductibleCapitalSelectedTypeEnum.COVERAGE) ||
                                                (deductibleCapital.optionId === activeItem?.id &&
                                                    activeItem?.type === DeductibleCapitalSelectedTypeEnum.OPTION)
                                            );
                                        }
                                    )}
                                />
                            )}
                    </Grid.Column>
                </Grid>
            ) : (
                <Message
                    warning
                    header={t("No coverage group")}
                    content={t("There is no coverage group declared for this insurance package")}
                />
            )}
        </Tab.Pane>
    );
};

export default DeductibleCapitalsList;
