import { Rules, TypeCheckingRule } from "validatorjs";

export type InputDataStepType = {
    type: string;
    field: string;
    name: string;
    rules: string | Array<string | TypeCheckingRule> | Rules;
    validationType: FormValidationType;
};

export enum FormValidationType {
    GLOBAL_VALIDATION,
    IN_STEP_VALIDATION,
}

type FormStepsType = {stepName: string; inputs: Array<InputDataStepType>};

export const PRODUCT_INFO_STEP_NAME = "product_infos";
export const RENFORTS_STEP_NAME = "renforts";
export const INSURANCE_PACKAGES_STEP_NAME = "insurance_packages";
export const SPECIFIC_INFORMATION_STEP_NAME = "specific_information";

const inputsByStepHealthProductForm: Array<FormStepsType> = [
    {
        stepName: PRODUCT_INFO_STEP_NAME,
        inputs: [
            {
                type: "text",
                field: "productCode",
                name: "Product code",
                rules: ["required", "max:10"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "text",
                field: "productLabel",
                name: "Label",
                rules: ["required", "max:50"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "text",
                field: "principalCompanyCode",
                name: "Company code",
                rules: ["required", "max:5"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "select",
                field: "rangeId",
                name: "Range",
                rules: "numeric",
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "text",
                field: "brand",
                name: "Brand",
                rules: ["required", "max:50"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "date",
                field: "effectiveDate",
                name: "Commercialization date",
                //TODO:: verify date
                rules: ["required", "max:10"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "checkbox",
                field: "monthlySplitting",
                name: "Authorize monthly",
                rules: ["required", "boolean"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "checkbox",
                field: "quarterlySplitting",
                name: "Authorize quarterly",
                rules: ["required", "boolean"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "checkbox",
                field: "halfYearlySplitting",
                name: "Authorize halfYearly",
                rules: ["required", "boolean"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "checkbox",
                field: "annualSplitting",
                name: "Authorize annual",
                rules: ["required", "boolean"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
            {
                type: "radio",
                field: "defaultSplitting",
                name: "Default splitting",
                rules: ["required", "in:M,S,T,A"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
        ],
    },
    {
        stepName: RENFORTS_STEP_NAME,
        inputs: [],
    },
    {
        stepName: INSURANCE_PACKAGES_STEP_NAME,
        inputs: [
            {
                type: "text",
                field: "code",
                name: "Insurance package code",
                rules: ["required", "max:10"],
                validationType: FormValidationType.IN_STEP_VALIDATION,
            },
            {
                type: "text",
                field: "label",
                name: "Insurance package label",
                rules: ["required", "max:50"],
                validationType: FormValidationType.IN_STEP_VALIDATION,
            },
            {
                type: "text",
                field: "startDate",
                name: "Commercialization start date",
                rules: ["required", "date"],
                validationType: FormValidationType.IN_STEP_VALIDATION,
            },
            {
                type: "text",
                field: "endDate",
                name: "Commercialization end date",
                rules: ["date"],
                validationType: FormValidationType.IN_STEP_VALIDATION,
            },
            {
                type: "text",
                field: "coveragesCode",
                name: "Coverage choices",
                rules: ["array", "min:1", "max:8"],
                validationType: FormValidationType.IN_STEP_VALIDATION,
            },
            {
                type: "text",
                field: "insurancePackages",
                name: "Insurance packages",
                rules: ["array", "min:1"],
                validationType: FormValidationType.GLOBAL_VALIDATION,
            },
        ],
    },
    {
        stepName: SPECIFIC_INFORMATION_STEP_NAME,
        inputs: [
            {
                type: "number",
                field: "numberZones",
                name: "Number of zone",
                rules: ["required", "numeric", "min:0", "max:9"],
                validationType: FormValidationType.IN_STEP_VALIDATION,
            },
        ],
    },
];

export default inputsByStepHealthProductForm;
