import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router";
import { Form, Grid, Label } from "semantic-ui-react";
import { useGetNotAssociatedRegistriesQuery } from "../../../../../Services/Company/Queries";
import { RegistryNotAssociatedDTO } from "../../../../../Services/Company/Types";
import { CoverageOrOption } from "../../../../../Services/InsurancePackage/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import { codeValidation } from "../../../../Global/Form/Validation";

interface IParams extends Params {
    id: string;
    versionId: string;
    gridId: string;
    insurancePackageId: string;
}

const FormCoverage = ({
    control,
    errors,
    setValue,
    coverageOrOption,
    selectCoverageOptions,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
    defaultValueSelectedItem,
    setDefaultValueSelectedItem,
    defaultValueContribCom,
    setDefaultValueContribCom,
    defaultValueContribComCheck,
    setDefaultValueContribComCheck,
    defaultValueManagementCom,
    setDefaultValueManagementCom,
    defaultValueManagementCheck,
    setDefaultValueManagementCheck,
}: any) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [selectedCoverage, setSelectedCoverage] = useState<RegistryNotAssociatedDTO | null>(null);
    const getNotAssociatedRegistriesQuery = useGetNotAssociatedRegistriesQuery(params.insurancePackageId);

    const handleRadioChange = (type: string, onChange: any, checked: any) => {
        onChange(checked);

        if (selectedCoverage !== null) {
            if (type === "contributionCommission") {
                const coverageContributionCommissionRate =
                    selectedCoverage.contributionCommissionRate !== null
                        ? selectedCoverage.contributionCommissionRate
                        : 0;
                if (checked) {
                    const coverageContributionCommissionRateToApply =
                        coverageContributionCommissionRate === 0 ? 100 : 0;
                    setDefaultValueContribCom(coverageContributionCommissionRateToApply);
                    setValue("contributionCommissionInput", coverageContributionCommissionRateToApply);
                } else {
                    setDefaultValueContribCom(coverageContributionCommissionRate);
                    setValue("contributionCommissionInput", coverageContributionCommissionRate);
                }
            } else if (type === "managementCommission") {
                const coverageManagementCommissionRate =
                    selectedCoverage.managementCommissionRate !== null ? selectedCoverage.managementCommissionRate : 0;
                if (checked) {
                    const coverageManagementCommissionRateToApply = coverageManagementCommissionRate === 0 ? 100 : 0;
                    setDefaultValueManagementCom(coverageManagementCommissionRateToApply);
                    setValue("managementCommissionInput", coverageManagementCommissionRateToApply);
                } else {
                    setDefaultValueManagementCom(coverageManagementCommissionRate);
                    setValue("managementCommissionInput", coverageManagementCommissionRate);
                }
            }
        }
    };

    const handleInputChange = (type: string, value: any, onChange: any) => {
        onChange(value);
        if (selectedCoverage !== null) {
            if (type === "contributionCommission") {
                const coverageContributionCommissionRate =
                    selectedCoverage.contributionCommissionRate !== null
                        ? selectedCoverage.contributionCommissionRate
                        : 0;
                setDefaultValueContribComCheck(coverageContributionCommissionRate !== Number(value));
                setValue("contributionCommissionRadioButton", coverageContributionCommissionRate !== Number(value));
            } else if (type === "managementCommission") {
                const coverageManagementCommissionRate =
                    selectedCoverage.managementCommissionRate !== null ? selectedCoverage.managementCommissionRate : 0;
                setDefaultValueManagementCheck(coverageManagementCommissionRate !== Number(value));
                setValue("managementCommissionRadioButton", coverageManagementCommissionRate !== Number(value));
            }
        }
    };

    const handleSelect = (value: any, onChange: any) => {
        const foundItem = getNotAssociatedRegistriesQuery.data?.find((item: any) => item.registryId === value);
        if (foundItem !== undefined) {
            setDefaultValueSelectedItem(foundItem);
            setDefaultValueContribCom(
                foundItem.contributionCommissionRate === null ? 0 : foundItem.contributionCommissionRate
            );
            setDefaultValueContribComCheck(false);
            setDefaultValueManagementCom(
                foundItem.managementCommissionRate === null ? 0 : foundItem.managementCommissionRate
            );
            setDefaultValueManagementCheck(false);
            setSelectedCoverage(foundItem);
            onChange(foundItem);
        }
    };

    return (
        <Form id="modal-form">
            <Grid columns={"equal"} verticalAlign={"middle"} className={"grid-padding"}>
                <Grid.Row>
                    {coverageOrOption === CoverageOrOption.OPTION && (
                        <>
                            <Grid.Column width={16}>
                                <Controller
                                    control={control}
                                    rules={{required: true, maxLength: 20, pattern: codeValidation()}}
                                    defaultValue={defaultValueCode}
                                    name={"code"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            autoFocus
                                            fluid
                                            required
                                            type={"text"}
                                            name={name}
                                            label={t("Option code")}
                                            placeholder={t("Option code")}
                                            onChange={(_, {value}) => {
                                                setDefaultValueCode(value);
                                                onChange(value);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            maxLength="20"
                                            error={getErrorMessage(t, errors, name)}
                                        />
                                    )}
                                />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Controller
                                    control={control}
                                    rules={{required: true}}
                                    defaultValue={defaultValueLabel}
                                    name={"label"}
                                    render={({field: {name, value, onChange, onBlur}}) => (
                                        <Form.Input
                                            fluid
                                            required
                                            type={"text"}
                                            name={name}
                                            label={t("Option label")}
                                            placeholder={t("Option label")}
                                            onChange={(_, {value}) => {
                                                setDefaultValueLabel(value);
                                                onChange(value);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            maxLength="90"
                                            error={getErrorMessage(t, errors, name)}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </>
                    )}
                    <Grid.Column width={16}>
                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueSelectedItem}
                            name={"selectedItem"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Select
                                    label={t("Choose a coverage")}
                                    name={name}
                                    onBlur={onBlur}
                                    placeholder={t("Coverage")}
                                    onChange={(_, {value}) => {
                                        handleSelect(value, onChange);
                                    }}
                                    value={
                                        defaultValueSelectedItem !== null ? defaultValueSelectedItem.registryId : null
                                    }
                                    error={errors.selectedItem && {content: t("this field is required")}}
                                    options={selectCoverageOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            rules={{required: defaultValueContribComCheck}}
                            name={"contributionCommissionInput"}
                            defaultValue={defaultValueContribCom}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    name={name}
                                    type={"number"}
                                    step={"0.1"}
                                    min={0}
                                    max={100}
                                    disabled={selectedCoverage === null && defaultValueSelectedItem === null}
                                    placeholder={t("Contribution")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueContribCom(value);
                                        handleInputChange("contributionCommission", value, onChange);
                                    }}
                                    onBlur={onBlur}
                                    labelPosition={"right"}
                                    value={defaultValueContribCom}
                                    maxLength={"50"}
                                    error={
                                        defaultValueContribComCheck &&
                                        errors[name] && {
                                            content: t("this field is required"),
                                        }
                                    }>
                                    <Label basic>{t("Contribution")}</Label>
                                    <input />
                                    <Label>%</Label>
                                </Form.Input>
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            defaultValue={defaultValueContribComCheck}
                            name={"contributionCommissionRadioButton"}
                            render={({field: {name, onChange}}) => (
                                <Form.Radio
                                    toggle
                                    disabled={selectedCoverage === null && defaultValueSelectedItem === null}
                                    aria-label={"contributionCommissionRadioButton"}
                                    onChange={(_, {checked}) => {
                                        setDefaultValueContribComCheck(checked);
                                        handleRadioChange("contributionCommission", onChange, checked);
                                    }}
                                    name={name}
                                    label={t("Derogate")}
                                    checked={defaultValueContribComCheck}
                                />
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            rules={{required: defaultValueManagementCheck}}
                            name={"managementCommissionInput"}
                            defaultValue={defaultValueManagementCom}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    type={"number"}
                                    step={"0.1"}
                                    min={0}
                                    max={100}
                                    disabled={selectedCoverage === null && defaultValueSelectedItem === null}
                                    name={name}
                                    placeholder={t("Management")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueManagementCom(value);
                                        handleInputChange("managementCommission", value, onChange);
                                    }}
                                    onBlur={onBlur}
                                    labelPosition={"right"}
                                    value={defaultValueManagementCom}
                                    maxLength={"50"}
                                    error={
                                        defaultValueManagementCheck &&
                                        errors[name] && {
                                            content: t("this field is required"),
                                        }
                                    }>
                                    <Label basic>{t("Management")}</Label>
                                    <input />
                                    <Label>%</Label>
                                </Form.Input>
                            )}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            defaultValue={defaultValueManagementCheck}
                            name={"managementCommissionRadioButton"}
                            render={({field: {name, onChange}}) => (
                                <Form.Radio
                                    toggle
                                    disabled={selectedCoverage === null && defaultValueSelectedItem === null}
                                    aria-label={"managementCommissionRadioButton"}
                                    onChange={(_, {checked}) => {
                                        setDefaultValueManagementCheck(checked);
                                        handleRadioChange("managementCommission", onChange, checked);
                                    }}
                                    name={name}
                                    label={t("Derogate")}
                                    checked={defaultValueManagementCheck}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormCoverage;
