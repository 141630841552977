import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Accordion, Divider, Grid, GridColumn, GridRow, Message, Tab} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import useDragAndDropProperties from "../../../../../Hooks/useDragAndDropProperties";
import {
    useAddInsurancePackageExclusionConditionMutation,
    useGetInsurancePackageExclusionConditionQuery,
} from "../../../../../Services/InsurancePackage/Queries";
import {InsurancePackageExclusion} from "../../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {InsurancePackageExclusionConditionAccordion} from "./InsurancePackageExclusionConditionAccordion";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type InsurancePackageConditionsListPropsType = {
    insurancePackageId: string;
    version: VersionDTO | undefined;
};

export const InsurancePackageConditionsList = ({
    insurancePackageId,
    version,
}: InsurancePackageConditionsListPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);
    const addInsurancePackageExclusionConditionAddMutation = useAddInsurancePackageExclusionConditionMutation(
        params.insurancePackageId
    );

    const {data: exclusionConditions, status: exclusionConditionsStatus} =
        useGetInsurancePackageExclusionConditionQuery(params.insurancePackageId);

    const onAddConditionSuccess = () => {
        if (insurancePackageId !== undefined) {
            setActiveConditionIndex(exclusionConditions === undefined ? 0 : exclusionConditions.length - 1);
        }
    };

    const dndProperties = useDragAndDropProperties<InsurancePackageExclusion>(exclusionConditions ?? []);

    const renderConditions = () => {
        return (
            exclusionConditions !== undefined &&
            exclusionConditionsStatus === "success" &&
            dndProperties.renderedItems.map((condition, index) => {
                return (
                    <InsurancePackageExclusionConditionAccordion
                        key={condition.id}
                        condition={condition}
                        version={version}
                        index={index}
                        activeIndex={activeConditionIndex}
                        setActiveIndex={setActiveConditionIndex}
                        insurancePackageExclusionConditions={exclusionConditions}
                        dndProperties={dndProperties}
                    />
                );
            })
        );
    };

    return (
        <Tab.Pane>
            <Message
                info
                header={t("Insurance package exclusion conditions")}
                content={t(
                    "If one of these insurance package exclusion conditions is evaluted like true, the insurance will be exclude from the pricing"
                )}
            />

            <Divider />

            {insurancePackageId !== undefined && exclusionConditions !== undefined && exclusionConditions.length > 0 ? (
                <Accordion styled fluid onMouseLeave={() => dndProperties.refreshData()}>
                    {renderConditions()}
                </Accordion>
            ) : (
                <Message
                    warning
                    header={t("No exclusion condition")}
                    content={t("There is no condition declared for this insurance package")}
                />
            )}

            {version !== undefined && version.pipDate === null && (
                <Grid style={{marginTop: "0"}}>
                    <GridRow>
                        <GridColumn textAlign="center">
                            <ModalAdd
                                isPending={addInsurancePackageExclusionConditionAddMutation.isPending}
                                isSuccess={addInsurancePackageExclusionConditionAddMutation.isSuccess}
                                isError={addInsurancePackageExclusionConditionAddMutation.isError}
                                resetMutation={addInsurancePackageExclusionConditionAddMutation.reset}
                                error={addInsurancePackageExclusionConditionAddMutation.error}
                                onValidate={() =>
                                    addInsurancePackageExclusionConditionAddMutation.mutate({
                                        ifCondition: "( ( 1 == 1 ) )",
                                    })
                                }
                                onSuccess={() => onAddConditionSuccess()}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="addCondition"
                                renderModalContent={() => (
                                    <Message info>
                                        {t(
                                            "You are about to add a new condition, this condition will be added second to last with default values which will be convenient to update"
                                        )}
                                    </Message>
                                )}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>
            )}
        </Tab.Pane>
    );
};
