import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationsFr from "../Config/translations.fr.json";
import translationsCoverlifeFr from "../Config/translations-coverlife.fr.json";
import translationsGenerationFr from "../Config/translations-generation.fr.json";

const resources = {
    en: {
        common: {},
    },
    fr: {
        common: translationsFr,
        coverlife: translationsCoverlifeFr,
        generation: translationsGenerationFr
    },
};

i18n.use(initReactI18next).init({
    resources,
    ns: ['common', 'coverlife', 'generation'],
    defaultNS: "common",
    fallbackNS: ['coverlife', 'generation'],
    lng: "fr",

    interpolation: {
        escapeValue: false, // react already safes from xss
        format: (value, format, lng) => {

            if (format === "date") {
                return new Date(value).toLocaleDateString(lng);
            } else if (format === "datetime") {
                return new Date(value).toLocaleDateString(lng) + " " + new Date(value).toLocaleTimeString(lng);
            }

            return value;
        },
    },
});

export default i18n;
