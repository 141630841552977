import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {Splitting, SplittingDTO} from "./Types";

export const getSplitting = async (productId: string, splittingId: string): Promise<Splitting> => {
    try {
        const response = await axiosInstance.get<Splitting>(`/products/${productId}/splittings/${splittingId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getSplittings = async (productId: string): Promise<Array<Splitting>> => {
    try {
        const response = await axiosInstance.get<Array<Splitting>>(`/products/${productId}/splittings`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addSplitting = async (productId: string, splitting: SplittingDTO): Promise<Splitting> => {
    try {
        const response = await axiosInstance.post<Splitting>(`products/${productId}/splittings`, splitting);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateSplitting = async (
    productId: string,
    splittingId: string,
    splitting: Splitting
): Promise<Splitting> => {
    try {
        const response = await axiosInstance.put<Splitting>(
            `/products/${productId}/splittings/${splittingId}`,
            splitting
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteSplitting = async (productId: string, splittingId: string): Promise<void> => {
    try {
        const response = await axiosInstance.delete<void>(`products/${productId}/splittings/${splittingId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
