import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import {Container, Grid, Segment, Tab} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import {Breadcrumb} from "../../../Components/Routes/BreadcrumbsInjectorWrapper";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SimpleList from "../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../Components/SimpleList/SimpleListTypes";
import {useProductsSidebarMenu} from "../../../Hooks/useProductsSidebarMenu";
import {useGetProductDetailsQuery} from "../../../Services/Product/Queries";
import {useGetProductBehavioursQuery} from "../../../Services/ProductBehaviour/Queries";
import {useGetVersionsQuery} from "../../../Services/Version/Queries";
import {VersionDTO} from "../../../Services/Version/Types";
import ProductDetails from "./Fragments/ProductDetails/ProductDetails";
import "./Fragments/ProductDetails/Products.css";
import PromotionalCampaignTabPane from "./Fragments/PromotionalCampaignTabPane";
import RetrocessionTabPane from "./Fragments/RetrocessionTabPane";
import SplittingTabPane from "./Fragments/SplittingTabPane";
import DraftVersionSegment from "./Fragments/Version/DraftVersionSegment";
import ValidatedVersionsSegment from "./Fragments/Version/ValidatedVersionsSegment";

const ProductPage = (props: {sections: Array<Breadcrumb>}) => {
    const {t} = useTranslation();
    const params = useParams() as {id: string};
    const location = useLocation();

    const [activeTabIndex, setActiveIndex] = useState(location.state ? location.state.activeTabIndex : 0);

    const {data: versions, status} = useGetVersionsQuery(params.id);
    const {data: registries} = useGetProductBehavioursQuery(params.id);
    const {data: productData, isSuccess, isLoading} = useGetProductDetailsQuery(params.id);

    const [workingVersion, setWorkingVersion] = useState<VersionDTO | undefined>();
    const [validVersions, setValidVersions] = useState<VersionDTO[] | undefined>();

    const menus = useProductsSidebarMenu();

    useEffect(() => {
        const findWorkVersion = () => {
            if (versions !== undefined) {
                const version = versions.find((version) => version.pipDate === null || version.pipDate === "");
                setWorkingVersion(version);
            }
        };

        const findValidVersions = () => {
            if (versions !== undefined) {
                setValidVersions(versions.filter((version) => version.pipDate !== null && version.pipDate !== ""));
            }
        };

        findValidVersions();
        findWorkVersion();
    }, [versions]);

    const haveWorkingVersion = () => {
        return workingVersion !== undefined && status !== "pending";
    };

    const isRemovableVersion = () => {
        return validVersions !== undefined && validVersions.length !== 0 && status !== "pending";
    };

    const isOnlyInvalidVersion = () => versions?.length === 1 && versions[0].pipDate === null;

    const haveWorkingAndValidatedVersion = () =>
        status === "success" &&
        versions !== undefined &&
        versions?.length > 1 &&
        versions.some((version) => version.pipDate === null);

    const panes = [
        {
            menuItem: {key: "behaviours", content: t("behaviours")},
            render: () => (
                <Tab.Pane style={{overflowX: "auto"}}>
                    <SimpleList
                        tableName="productBehaviours"
                        columns={registryTableColumns}
                        globalData={[{key: "productId", value: params.id}]}
                        rows={registries !== undefined ? registries : []}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: {key: "splittings", content: t("Splittings")},
            render: () => <SplittingTabPane productId={params.id} />,
        },
        {
            menuItem: {key: "retrocession", content: t("Retrocession")},
            render: () => <RetrocessionTabPane productId={params.id} />,
        },
        {
            menuItem: {key: "promotional_campaigns", content: t("Promotional campaigns")},
            render: () => <PromotionalCampaignTabPane productId={params.id} />,
        },
    ];

    return (
        <Container fluid>
            <Sidebar title={t("Product", {count: 2})} menus={menus} />
            {isLoading && <CustomBreadcrumb sections={props.sections} />}
            {productData !== undefined && isSuccess && (
                <CustomBreadcrumb sections={props.sections} title={productData.label} />
            )}
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <ProductDetails
                                isOnlyInvalidVersion={isOnlyInvalidVersion()}
                                haveWorkingAndValidatedVersion={haveWorkingAndValidatedVersion()}
                            />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Segment className="card-container">
                                <Tab
                                    panes={panes}
                                    activeIndex={activeTabIndex}
                                    onTabChange={(_, data) => setActiveIndex(data.activeIndex as number)}
                                />
                            </Segment>

                            <Grid columns={2} stackable>
                                <Grid.Row>
                                    <Grid.Column>
                                        {workingVersion !== undefined && (
                                            <DraftVersionSegment
                                                version={workingVersion}
                                                isRemovableVersion={isRemovableVersion()}
                                            />
                                        )}
                                    </Grid.Column>
                                    <Grid.Column>
                                        {validVersions !== undefined && validVersions.length !== 0 && (
                                            <ValidatedVersionsSegment
                                                productId={params.id}
                                                validatedVersions={validVersions}
                                                haveWorkingVersion={haveWorkingVersion}
                                            />
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default ProductPage;

const registryTableColumns = [
    {
        text: "Code",
        dataField: "registryCode",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Name",
        dataField: "registryLabel",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Company code",
        dataField: "companyCode",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Type",
        dataField: "registryType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "Charging mode",
        dataField: "chargingMode",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "renew on deadline",
        dataField: "renewOnDeadline",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "Prorata",
        dataField: "applyProrata",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "",
        dataField: "/products/:productId/behaviours/:id",
        type: DataTypeColumnEnum.LINK,
    },
];
