import axios, {AxiosError} from "axios";
import axiosInstanceTaxes from "../InsuranceTaxes.instance";
import {buildCustomError} from "../RequestUtils";
import {Category, CategoryDetails, Domain, SubDomain} from "./Types";

export const getDomains = async (societeUser: string | undefined): Promise<Array<Domain>> => {
    try {
        const response = await axiosInstanceTaxes.get<Array<Domain>>(`/domains?labelCompany=${societeUser}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getSubDomains = async (domainId: number): Promise<Array<SubDomain>> => {
    try {
        const response = await axiosInstanceTaxes.get<Array<SubDomain>>(`/domains/${String(domainId)}/sub_domains`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCategories = async (subDomainId: number): Promise<Array<Category>> => {
    try {
        const response = await axiosInstanceTaxes.get<Array<Category>>(
            `/sub_domains/${String(subDomainId)}/categories`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCategoryDetails = async (categoryCode: string): Promise<CategoryDetails> => {
    try {
        const response = await axiosInstanceTaxes.get<CategoryDetails>(`/categories/${categoryCode}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
