import { Control, Controller, DeepMap, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid } from "semantic-ui-react";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import { codeValidation } from "../../../Global/Form/Validation";

type FormDuplicateQuestionPropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    defaultValueName: string;
    setDefaultValueName: React.Dispatch<React.SetStateAction<string>>;
    defaultValueDescription: string;
    setDefaultValueDescription: React.Dispatch<React.SetStateAction<string>>;
};

const FormDuplicatePricingCriterias = ({
    control,
    errors,
    defaultValueName,
    setDefaultValueName,
    defaultValueDescription,
    setDefaultValueDescription,
}: FormDuplicateQuestionPropsType) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <Grid.Column>
                        <Controller
                            control={control}
                            defaultValue={defaultValueName}
                            rules={{required: true, maxLength: 50, pattern: codeValidation()}}
                            name={"name"}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Name")}
                                    placeholder={t("Name")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueName(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueName}
                                    maxLength="50"
                                    error={getErrorMessage(t, errors, "name", undefined, 10)}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            rules={{required: false, maxLength: 255}}
                            name="description"
                            defaultValue={defaultValueDescription}
                            render={({field: {name, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    type={"text"}
                                    name={name}
                                    label={t("Description")}
                                    placeholder={t("Description")}
                                    onChange={(_, {value}) => {
                                        setDefaultValueDescription(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueDescription}
                                    maxLength="255"
                                    error={getErrorMessage(t, errors, "description")}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDuplicatePricingCriterias;
