import React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {Button, Grid, Header, Loader, Message, Segment} from "semantic-ui-react";
import BackToLoginButton from "../../../Components/Buttons/BackToLoginButton";
import FormPassword from "../../../Components/Forms/FormPassword";
import {useResetSelfPasswordMutation, useVerifyAuthTokenMutation} from "../../../Services/Company/Queries";
import {PasswordDTO} from "../../../Services/Company/Types";
import {displayResponseError} from "../../../Services/RequestUtils";

const ResetPasswordPage = () => {
    const {t} = useTranslation();

    const verifyAuthTokenMutation = useVerifyAuthTokenMutation();

    const {search} = useLocation();
    const resetSelfPasswordMutation = useResetSelfPasswordMutation(search.replace("?token=", ""));

    const {
        control,
        formState: {errors},
        handleSubmit,
        watch,
    } = useForm<any>({mode: "onChange"});
    const watchPassword = watch("password", "");
    const watchRepeatedPassword = watch("repeat-password", "");
    const submitResetSelfPasswordForm = (formData: PasswordDTO) => {
        resetSelfPasswordMutation.mutate({
            password: formData.password,
        });
    };

    React.useEffect(() => {
        if (verifyAuthTokenMutation.isIdle) {
            verifyAuthTokenMutation.mutate({
                token: search.replace("?token=", ""),
                requestedScopes: ["reset_password"],
            });
        }
    }, [verifyAuthTokenMutation, search]);

    return (
        <Grid centered textAlign="center" style={{height: "90vh"}} verticalAlign="middle">
            <Grid.Row>
                <Grid.Column width={8}>
                    {verifyAuthTokenMutation.isPending ? (
                        <Loader active inline="centered" size="massive">
                            {t("Verification of authentication token")}
                        </Loader>
                    ) : verifyAuthTokenMutation.isError && verifyAuthTokenMutation.error ? (
                        <Segment stacked style={{padding: "2em"}} textAlign="left">
                            <Message negative>
                                <Message.Header>{t("An error occurred") + "..."}</Message.Header>
                                <Message.Content>
                                    {t(displayResponseError(verifyAuthTokenMutation.error))}
                                </Message.Content>
                            </Message>

                            <BackToLoginButton />
                        </Segment>
                    ) : (
                        <>
                            <Header as="h2" textAlign="center">
                                {t("Reset of your password")}
                            </Header>
                            <Segment stacked style={{padding: "2em"}} textAlign="left">
                                {!resetSelfPasswordMutation.isSuccess && (
                                    <FormPassword
                                        control={control}
                                        password={watchPassword}
                                        repeatedPassword={watchRepeatedPassword}
                                    />
                                )}

                                {resetSelfPasswordMutation.isError && resetSelfPasswordMutation.error && (
                                    <Message negative>
                                        <Message.Header>{t("An error occurred") + "..."}</Message.Header>
                                        <Message.Content>
                                            {t(displayResponseError(resetSelfPasswordMutation.error))}
                                        </Message.Content>
                                    </Message>
                                )}

                                {resetSelfPasswordMutation.isSuccess && (
                                    <Message positive>
                                        <Message.Content>
                                            {t("Your password modification is successful")}
                                        </Message.Content>
                                    </Message>
                                )}

                                <Grid columns="equal">
                                    <Grid.Column>
                                        <BackToLoginButton />
                                    </Grid.Column>

                                    {!resetSelfPasswordMutation.isSuccess && (
                                        <Grid.Column>
                                            <Button
                                                name="validate-password"
                                                primary
                                                fluid
                                                size="large"
                                                onClick={handleSubmit(submitResetSelfPasswordForm)}
                                                type="button"
                                                disabled={
                                                    resetSelfPasswordMutation.isPending ||
                                                    watchPassword === "" ||
                                                    watchRepeatedPassword === "" ||
                                                    Object.keys(errors).length !== 0
                                                }
                                            >
                                                {resetSelfPasswordMutation.isPending ? (
                                                    <Loader active inline="centered" size="tiny" inverted />
                                                ) : (
                                                    t("Validate password")
                                                )}
                                            </Button>
                                        </Grid.Column>
                                    )}
                                </Grid>
                            </Segment>
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ResetPasswordPage;
