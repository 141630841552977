import axios from "axios";

const axiosInstanceTaxes = axios.create({
    // http://webaction-test-neo.leaderinfo.com:8020/taxes/api
    // http://localhost:8081/taxes/api
    // /taxes/api
    baseURL: `/taxes/api`,
});

export default axiosInstanceTaxes;
