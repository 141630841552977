import {useEffect, useReducer, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {UseMutationResult} from "@tanstack/react-query";
import {Button, Grid, Label, Segment} from "semantic-ui-react";
import ModalUpdate from "../../../../../../Components/Modals/ModalUpdate";
import {
    DeductibleCapital,
    DeductibleCapitalCondition,
    DeductibleCapitalConditionDTO,
    DeductibleCapitalConditionElement,
    DeductibleCapitalConditionElementDTO,
} from "../../../../../../Services/DeductibleCapital/Types";
import {CustomError} from "../../../../../../Services/RequestUtils";
import {VersionDTO} from "../../../../../../Services/Version/Types";
import {GenericReducerActionType} from "../../../../../../Utils/ReducerUtils";
import deductibleCapitalConditionAssignementReducer, {
    AssignmentType,
} from "./DeductibleCapitalConditionAssignementReducer";
import DeductibleCapitalConditionAssignementUpdateEdit from "./DeductibleCapitalConditionAssignementUpdateEdit";

type DeductibleCapitalConditionAssignementPropsType = {
    deductibleCapital: DeductibleCapital;
    deductibleCapitalCondition: DeductibleCapitalCondition;
    version?: VersionDTO;
    updateDeductibleCapitalConditionMutation: UseMutationResult<any, CustomError, any, Error>;
    activeIndex: number;
    index: number;
    numberOfConditions: number | undefined;
};

const DeductibleCapitalConditionAssignement = ({
    deductibleCapital,
    deductibleCapitalCondition,
    version,
    updateDeductibleCapitalConditionMutation,
    activeIndex,
    index,
    numberOfConditions = 0,
}: DeductibleCapitalConditionAssignementPropsType) => {
    const {t} = useTranslation();

    const [editAssignment, setEditAssignment] = useState<boolean>(false);
    const [assignmentType, setAssignmentType] = useState<AssignmentType>(AssignmentType.FIXED_VALUE);

    const [conditionState, dispatchCondition] = useReducer(
        deductibleCapitalConditionAssignementReducer,
        deductibleCapitalCondition
    );

    const {handleSubmit} = useForm<any>({mode: "onBlur"});

    const labelStyle = {
        border: "none",
        margin: 0,
        padding: "0.1em",
        minWidth: "2em",
        background: "none #fff0",
    };

    const determineAssignmentType = () => {
        if (conditionState.deductibleCapitalConditionElements?.length === 1) {
            if (conditionState.deductibleCapitalConditionElements[0].questionName !== null) {
                return AssignmentType.QUESTION;
            } else if (conditionState.deductibleCapitalConditionElements[0].pricingCriteriaName !== null) {
                return AssignmentType.PRICING_CRITERIA;
            } else if (conditionState.deductibleCapitalConditionElements[0].variableNameName !== null) {
                return AssignmentType.VARIABLE;
            } else if (conditionState.deductibleCapitalConditionElements[0].computingRule !== null) {
                return AssignmentType.VARIABLE_COMPUTING_RULE;
            } else if (conditionState.deductibleCapitalConditionElements[0].text !== null) {
                return AssignmentType.FIXED_VALUE;
            }
        }
        return AssignmentType.NOTHING;
    };

    useEffect(() => {
        if (index === activeIndex) setEditAssignment(false);
    }, [activeIndex, index, deductibleCapital, deductibleCapitalCondition, numberOfConditions]);

    const displayElementData = (conditionElement: DeductibleCapitalConditionElement) => {
        let elementDataToString = "";

        if (conditionElement.questionName !== null) elementDataToString += conditionElement.questionName;
        if (conditionElement.pricingCriteriaName !== null) elementDataToString += conditionElement.pricingCriteriaName;
        if (conditionElement.variableName !== null) elementDataToString += conditionElement.variableName;
        if (conditionElement.text !== null) elementDataToString += conditionElement.text;

        return elementDataToString;
    };

    const updateDeductibleCapitalCondition = async () => {
        const condition: DeductibleCapitalConditionDTO = {
            ifCondition: conditionState.ifCondition,
            conditionPartList: conditionState.conditionPartList,
            conditionOrder: conditionState.conditionOrder,
            deductibleCapitalConditionElementDTOs: [
                ...conditionState.deductibleCapitalConditionElementDTOs.map(
                    (conditionElement: DeductibleCapitalConditionElementDTO, index: number) => {
                        return {
                            ...conditionElement,
                            order: index + 1,
                        };
                    }
                ),
            ],
        };

        updateDeductibleCapitalConditionMutation.mutate(condition);
    };

    const closeAssignmentEdition = () => {
        setAssignmentType(determineAssignmentType());
        dispatchCondition({
            type: GenericReducerActionType.UNDO,
            assignmentType: AssignmentType.NOTHING,
            payload: deductibleCapitalCondition,
        });
        setEditAssignment(false);
    };

    return editAssignment ? (
        <Grid>
            <Grid.Row>
                <Grid.Column width={1} textAlign="center" verticalAlign="middle" style={{whiteSpace: "nowrap"}}>
                    {t("Then")} :
                </Grid.Column>
                <Grid.Column width={15} textAlign="left">
                    <DeductibleCapitalConditionAssignementUpdateEdit
                        conditionState={conditionState}
                        dispatchCondition={dispatchCondition}
                        originalCondition={deductibleCapitalCondition}
                        numberOfConditions={numberOfConditions}
                    />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16} textAlign={"right"}>
                    <Button name="cancel" secondary onClick={() => closeAssignmentEdition()}>
                        {t("Cancel")}
                    </Button>
                    <ModalUpdate
                        isPending={updateDeductibleCapitalConditionMutation.isPending}
                        isSuccess={updateDeductibleCapitalConditionMutation.isSuccess}
                        isError={updateDeductibleCapitalConditionMutation.isError}
                        resetMutation={updateDeductibleCapitalConditionMutation.reset}
                        error={updateDeductibleCapitalConditionMutation.error}
                        onSuccess={() => setEditAssignment(false)}
                        onValidate={handleSubmit(() => updateDeductibleCapitalCondition())}
                        onCancel={() => closeAssignmentEdition()}
                        objectToUpdate={t("Assignment")}
                        objectType="nonbinary"
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    ) : (
        <Grid>
            <Grid.Row>
                <Grid.Column width={1} textAlign="center" verticalAlign="middle" style={{whiteSpace: "nowrap"}}>
                    {t("Then")} :
                </Grid.Column>
                <Grid.Column width={14} textAlign="left" verticalAlign="middle">
                    <Segment secondary>
                        <Label.Group size="large">
                            {deductibleCapitalCondition.deductibleCapitalConditionElements.map(
                                (conditionElement: DeductibleCapitalConditionElement) => (
                                    <Label
                                        key={conditionElement.id}
                                        basic
                                        style={{...labelStyle, minWidth: "2em", marginRight: "5px"}}
                                        className="data-element"
                                        horizontal
                                    >
                                        {displayElementData(conditionElement)}
                                    </Label>
                                )
                            )}
                        </Label.Group>
                    </Segment>
                </Grid.Column>
                {version !== undefined && version.pipDate === null && (
                    <Grid.Column floated="right" textAlign="center" verticalAlign="middle">
                        <Button
                            name={`edit-assignment-${index}`}
                            color="grey"
                            icon="edit"
                            compact
                            basic
                            onClick={async () => {
                                setEditAssignment(true);
                            }}
                        />
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};

export default DeductibleCapitalConditionAssignement;
