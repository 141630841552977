import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Params, useParams } from "react-router-dom";
import { Card, Grid } from "semantic-ui-react";
import { AuthContext } from "../../../../../Components/AuthProvider/AuthProvider";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import { CommissionsStateType, CoverageOrOptionType } from "../../../../../Services/InsurancePackage/Types";
import { useGetProductBehaviourByRegistryIdQuery } from "../../../../../Services/ProductBehaviour/Queries";
import { displayAuditLog } from "../../../../../Utils/AuditUtils";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
}

type CoverageCardPropsType = {
    coverageOrOption: CoverageOrOptionType;
    activeCoverageGroupId: number;
};

const CoverageCard = ({coverageOrOption, activeCoverageGroupId}: CoverageCardPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const userContext = React.useContext(AuthContext);

    const [commissions, setCommissions] = useState<CommissionsStateType>({
        contribution: {
            initialRate: 0,
            currentValue: null,
            derogatoryChecked: false,
        },
        management: {
            initialRate: 0,
            currentValue: null,
            derogatoryChecked: false,
        },
    });

    const productBehaviorQueryResult = useGetProductBehaviourByRegistryIdQuery(params.id, coverageOrOption?.registryId);

    useEffect(() => {
        if (productBehaviorQueryResult.isSuccess && productBehaviorQueryResult.data) {
            setCommissions((actualCommissions: CommissionsStateType) => {
                return {
                    contribution: {
                        ...actualCommissions.contribution,
                        initialRate: productBehaviorQueryResult.data.productRegistryJoin.contributionCommissionRate,
                    },
                    management: {
                        ...actualCommissions.management,
                        initialRate: productBehaviorQueryResult.data.productRegistryJoin.managementCommissionRate,
                    },
                };
            });
        }
    }, [productBehaviorQueryResult.isSuccess, productBehaviorQueryResult.data]);

    return (
        <Card
            key={coverageOrOption.id}
            as={Link}
            to={`/products/${params.id}/versions/${params.versionId}/insurance-packages/${
                params.insurancePackageId
            }/coverages-groups/${activeCoverageGroupId}/${coverageOrOption.code === null ? "coverages" : "options"}/${
                coverageOrOption.id
            }`}
        >
            <Card.Content>
                {coverageOrOption.code === null ? (
                    <>
                        <Card.Header>
                            <TextOverflow value={coverageOrOption.registryLabel} />
                        </Card.Header>
                        <Card.Meta>
                            {t("Code")} : {coverageOrOption.registryCode}
                        </Card.Meta>
                    </>
                ) : (
                    <>
                        <Card.Header>
                            <TextOverflow value={coverageOrOption.label} />
                        </Card.Header>
                        <Card.Meta>
                            {t("Code")} : {coverageOrOption.code}
                        </Card.Meta>
                        <Card.Meta>
                            {t("Coverage")} :{coverageOrOption.registryLabel} - {coverageOrOption.registryCode}
                        </Card.Meta>
                    </>
                )}
                <Card.Meta>{t("Derogatory commission rate")}</Card.Meta>
                <Card.Description>
                    <Grid>
                        <Grid.Column>
                            <CommissionsDisplayMode
                                t={t}
                                name="Contribution"
                                initialRate={commissions.contribution.initialRate}
                                derogatoryRate={coverageOrOption.derogatoryContributionCommissionRate}
                            />
                            <br />
                            <CommissionsDisplayMode
                                t={t}
                                name="Management"
                                initialRate={commissions.management.initialRate}
                                derogatoryRate={coverageOrOption.derogatoryManagementCommissionRate}
                            />
                        </Grid.Column>
                    </Grid>
                </Card.Description>
            </Card.Content>

            {userContext.user !== undefined && (
                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                    <span>
                        {displayAuditLog(
                            {
                                entityContext: "female",
                                createdDate: coverageOrOption.createdDate,
                                modifiedDate: coverageOrOption.modifiedDate,
                                createdBy: coverageOrOption.createdBy,
                                modifiedBy: coverageOrOption.modifiedBy,
                                createdOnVersionId: coverageOrOption.createdOnVersionId,
                            },
                            userContext.user,
                            params.versionId,
                            t
                        )}
                    </span>
                </Card.Content>
            )}
        </Card>
    );
};

export default CoverageCard;

export const CommissionsDisplayMode = (props: {
    t: any;
    name: string;
    initialRate: number | null;
    derogatoryRate: number | null;
}) => (
    <>
        <strong>{props.t(props.name)} : </strong>
        {props.derogatoryRate === null || props.derogatoryRate === props.initialRate
            ? props.t("No derogation")
            : props.derogatoryRate + "%"}
    </>
);
