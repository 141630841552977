import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Grid, Header, Icon, Modal, Segment } from "semantic-ui-react";

export const RiskTypeModal: React.FC<{setSelectedRiskType: Dispatch<SetStateAction<string | undefined>>}> = ({
    ...props
}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);

    const handleSelectedRiskType = (riskType: string) => {
        props.setSelectedRiskType(riskType);
        setOpen(false);
    };

    return (
        <Modal
            size="tiny"
            open={open}
            dimmer="blurring"
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
            onClose={() => setOpen(false)}>
            <Modal.Content>
                <Segment placeholder>
                    <Grid columns={2} stackable textAlign="center">
                        <Divider vertical>Ou</Divider>

                        <Grid.Row verticalAlign="middle">
                            <Grid.Column>
                                <Header icon>
                                    <Icon name="doctor" />
                                    {t("Health")}
                                </Header>
                                {/*TODO: Remove as={Link} (impact button size)*/}
                                <Button
                                    primary
                                    as={Link}
                                    onClick={() => handleSelectedRiskType("Santé")}
                                    to={"/new_product"}>
                                    {t("Create")}
                                </Button>
                            </Grid.Column>
                            <Grid.Column>
                                <Header icon>
                                    <Icon name="box" />
                                    {t("Other")}
                                </Header>
                                <Button primary as={Link} to={"/new_product_generic"}>
                                    {t("Create")}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    secondary
                    onClick={() => {
                        setOpen(false);
                        navigate(-1);
                    }}>
                    {t("Cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
