import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import { useDeleteDeductibleCapital } from "../../../../../Services/DeductibleCapital/Queries";
import { DeductibleCapital, DeductibleCapitalType } from "../../../../../Services/DeductibleCapital/Types";
import { DeductibleCapitalSelectedTypeEnum } from "../DeductibleCapitals/DeductibleCapitalsList";
import ModalUpdateDeductibleCapital from "./ModalUpdateDeductibleCapital";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    deductibleCapitalId: string;
}

interface DeductibleCapitalActionsProps {
    deductibleCapital: DeductibleCapital;
    isVersionValid: boolean;
}

const DeductibleCapitalActions = ({...props}: DeductibleCapitalActionsProps) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();
    const deleteDeductibleCapitalMutation = useDeleteDeductibleCapital(
        params.id,
        params.versionId,
        params.insurancePackageId,
        props.deductibleCapital,
        props.deductibleCapital.coverageGroupId !== null
            ? DeductibleCapitalSelectedTypeEnum.COVERAGE_GROUP
            : props.deductibleCapital.coverageId !== null
            ? DeductibleCapitalSelectedTypeEnum.COVERAGE
            : DeductibleCapitalSelectedTypeEnum.OPTION,
        props.deductibleCapital.coverageGroupId !== null
            ? props.deductibleCapital.coverageGroupId
            : props.deductibleCapital.coverageId !== null
            ? props.deductibleCapital.coverageId
            : props.deductibleCapital.optionId
    );
    const handleDeductibleCapitalDelete = () => {
        deleteDeductibleCapitalMutation.mutate();
    };
    return (
        <>
            <Grid.Column
                width={6}
                className={"compact-buttons"}
                style={{margin: "auto", marginRight: 0, width: "max-content"}}
                floated={"right"}
                textAlign={"right"}>
                {props.isVersionValid && (
                    <div>
                        <ModalUpdateDeductibleCapital deductibleCapital={props.deductibleCapital} />

                        <ModalDelete
                            resetMutation={deleteDeductibleCapitalMutation.reset}
                            isSuccess={deleteDeductibleCapitalMutation.isSuccess}
                            isPending={deleteDeductibleCapitalMutation.isPending}
                            isError={deleteDeductibleCapitalMutation.isError}
                            onValidate={() => handleDeductibleCapitalDelete()}
                            objectToDelete={t(
                                props.deductibleCapital.type === DeductibleCapitalType.F ? "Deductible" : "Capital"
                            )}
                            objectType={props.deductibleCapital.type === DeductibleCapitalType.C ? "male" : "female"}
                        />
                    </div>
                )}
            </Grid.Column>
        </>
    );
};

export default DeductibleCapitalActions;
