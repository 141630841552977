import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import {Button, Container, Grid} from "semantic-ui-react";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb/CustomBreadcrumb";
import ModalAdd from "../../Components/Modals/ModalAdd";
import Sidebar, {SidebarItem, SidebarMenu} from "../../Components/Sidebar/Sidebar";
import {useAddCompanyMutation, useGetCompaniesQuery} from "../../Services/Company/Queries";
import {Company} from "../../Services/Company/Types";
import CompanyDetails from "./Fragments/CompanyDetails";
import FormCompany from "./Fragments/FormCompany";

interface IParams extends Params {
    companyId: string;
}

const CompanyPage = ({...props}) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    // Get companies
    const getCompaniesQuery = useGetCompaniesQuery();

    // UseEffect pour le menu de la sidebar
    useEffect(() => {
        if (getCompaniesQuery.isSuccess && getCompaniesQuery.data !== undefined) {
            const sidebarItems = getCompaniesQuery.data.map(
                (company) =>
                    new SidebarItem(company.label, company.label.toLocaleUpperCase(), `/companies/${company.id}`)
            );
            setMenus([new SidebarMenu("", sidebarItems)]);
        }
    }, [getCompaniesQuery.isSuccess, getCompaniesQuery.data]);

    // Add company
    const addCompanyMutation = useAddCompanyMutation();
    const addCompanyForm = useForm<Company>();
    const [defaultValueCompany, setDefaultValueCompany] = useState<Company>({} as Company);
    const submitCompany = (formData: Company) => {
        addCompanyMutation.mutate({
            ...formData,
            internalNovanetUrl: formData.internalNovanetUrl === "" ? null : formData.internalNovanetUrl,
            webserviceLogin: formData.webserviceLogin === "" ? null : formData.webserviceLogin,
            webservicePassword: formData.webservicePassword === "" ? null : formData.webservicePassword,
            retrocessionSettingsActive: formData.retrocessionSettingsActive ?? false,
        });
    };

    return (
        <Container fluid>
            <Sidebar
                title={t("companies")}
                menus={menus}
                renderAdditionnalButton={() => (
                    <ModalAdd
                        isValidationDisabled={Object.keys(addCompanyForm.formState.errors).length !== 0}
                        isPending={addCompanyMutation.isPending}
                        isSuccess={addCompanyMutation.isSuccess}
                        isError={addCompanyMutation.isError}
                        resetMutation={addCompanyMutation.reset}
                        error={addCompanyMutation.error}
                        onValidate={addCompanyForm.handleSubmit(submitCompany)}
                        onCancel={() => setDefaultValueCompany({} as Company)}
                        onSuccess={() => setDefaultValueCompany({} as Company)}
                        iconOnOpenButton
                        openButtonIcon="add"
                        objectToAdd="Company"
                        objectToAddContext="female"
                        renderModalContent={() => (
                            <FormCompany
                                errors={addCompanyForm.formState.errors}
                                control={addCompanyForm.control}
                                defaultValueCompany={defaultValueCompany}
                                setDefaultValueCompany={setDefaultValueCompany}
                            />
                        )}
                        renderOpenButton={() => (
                            <Button primary name="new-company" className="menu-button">
                                {t("New entity", {
                                    entity: t("Company").toLowerCase(),
                                    context: "female",
                                })}
                            </Button>
                        )}
                    />
                )}
            />
            <CustomBreadcrumb sections={props.sections} />
            <div className="main-container">
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <CompanyDetails companyId={params.companyId} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default CompanyPage;
