export type RegistryEntryDTO = {
    id?: number;
    code: string;
    label: string;
    type: RegistryEntryType;
};

export enum RegistryEntryType {
    G = "G",
    F = "F",
}

export type EntryDetailsViewType = {
    registryEntryId: string;
    registryEntry: RegistryEntryDTO;
    t: Function;
    toggleEditMode: () => void;
};
