import React, {useEffect, useState} from "react";
import {Grid, Header, Segment} from "semantic-ui-react";
import {useGetCompanyQuery} from "../../../Services/Company/Queries";
import CompanyDetailsEdit from "./CompanyDetailsEdit";
import CompanyDetailsRead from "./CompanyDetailsRead";

type CompanyDetailsProps = {
    companyId: string;
};

const CompanyDetails: React.FC<CompanyDetailsProps> = ({companyId}) => {
    const [editMode, setEditMode] = useState(false);

    // Get company
    const getCompanyQuery = useGetCompanyQuery(companyId);

    useEffect(() => {
        setEditMode(false);
    }, [companyId, getCompanyQuery.isSuccess, getCompanyQuery.data]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {getCompanyQuery.isSuccess && getCompanyQuery.data !== undefined && (
                        <Segment className="card-container">
                            <Header as="h2" dividing>
                                {getCompanyQuery.data.label.toLocaleUpperCase()}
                            </Header>
                            {editMode ? (
                                <CompanyDetailsEdit
                                    companyId={companyId}
                                    company={getCompanyQuery.data}
                                    setEdit={setEditMode}
                                />
                            ) : (
                                <CompanyDetailsRead
                                    companyId={companyId}
                                    company={getCompanyQuery.data}
                                    setEdit={setEditMode}
                                />
                            )}
                        </Segment>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CompanyDetails;
