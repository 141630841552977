import {Children, cloneElement, ReactElement} from "react";
import {matchRoutes, useLocation} from "react-router-dom";
import {RouteProps} from "../../Config/routes";

export type Breadcrumb = {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any;
    societeUser?: string;
    requiredRole?: string[];
    customBreadcrumb?: boolean;
    path: string;
};

export const BreadcrumbsInjectorWrapper = ({routes, children}: {routes: RouteProps[]; children: ReactElement}) => {
    const location = useLocation();
    const matchs = matchRoutes(routes, location);
    const crumbs =
        matchs && matchs.length != 0
            ? routes
                  .filter(({path}) => matchs[0].route.path.includes(path))
                  .map(
                      ({path, ...rest}): Breadcrumb => ({
                          path: Object.keys(matchs[0].params).length
                              ? Object.keys(matchs[0].params).reduce(
                                    (path, param) => path.replace(`:${param}`, matchs[0].params[param] as string),
                                    path
                                )
                              : path,
                          ...rest,
                      })
                  )
            : [];

    return (
        <>
            {Children.map(children, (child) => {
                return cloneElement(child, {sections: crumbs});
            })}
        </>
    );
};
