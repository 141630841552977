import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { Params, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader, Container,
    Divider,
    Dropdown,
    DropdownItemProps,
    Form,
    Grid,
    GridColumn,
    GridRow, Segment,
    Tab
} from "semantic-ui-react";
import ModalDelete from "../../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../../Components/Modals/ModalUpdate";
import Sidebar, { SidebarItem, SidebarMenu } from "../../../../../Components/Sidebar/Sidebar";
import {
    useDeleteCoverageMutation,
    useGetCoverageGroupsQuery,
    useGetCoverageQuery,
    useGetCoveragesQuery,
    useGetFeeChargesQuery, useGetOptionsByGroupQuery,
    useUpdateCoverageMutation
} from "../../../../../Services/InsurancePackage/Queries";
import { CommissionsStateType, Coverage, CoverageRuleDTO, CoverageUpdateDTO } from "../../../../../Services/InsurancePackage/Types";
import { useGetProductBehaviourByRegistryIdQuery } from "../../../../../Services/ProductBehaviour/Queries";
import { CustomError } from "../../../../../Services/RequestUtils";
import { useGetVersionQuery } from "../../../../../Services/Version/Queries";
import { VersionDTO } from "../../../../../Services/Version/Types";
import ComputingRuleList from "../../../ComputingRule/ComputingRuleList";
import UpdateDerogatoryCommissionRate from "../DerogatoryCommissionRate/UpdateDerogatoryCommissionRate";
import BreadcrumbCoverage from "./BreadcrumbCoverage";
import { CommissionsDisplayMode } from "./CoverageCard";
import DerogatoriesTaxes from "./DerogatoriesTaxes";

interface IParams extends Params {
    id: string;
    versionId: string;
    insurancePackageId: string;
    coverageGroupId: string;
    coverageId: string;
}

const CoveragePage = ({...props}) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    const getVersionQuery = useGetVersionQuery(params.id, params.versionId);
    const getCoveragesGroupsQuery = useGetCoverageGroupsQuery(params.insurancePackageId);
    const getCoveragesQuery = useGetCoveragesQuery(params.coverageGroupId);
    const getFeeChargesQuery = useGetFeeChargesQuery(params.insurancePackageId);
    const getOptionsByGroupQuery = useGetOptionsByGroupQuery(params.coverageGroupId);
    const {
        data: coverage,
        status: getCoverageQueryStatus,
        refetch: refetchCoverage,
        isFetching: isCoverageFetching,
    } = useGetCoverageQuery(params.coverageGroupId, params.coverageId);

    const updateCoverageMutation = useUpdateCoverageMutation(
        params.id,
        params.versionId,
        params.insurancePackageId,
        params.coverageGroupId,
        params.coverageId
    );
    const deleteCoverageMutation = useDeleteCoverageMutation(
        params.id,
        params.versionId,
        params.insurancePackageId,
        params.coverageGroupId
    );

    useEffect(
        () => {
            let sidebarMenus: Array<SidebarMenu> = [];

            if (getCoveragesQuery.isSuccess && getCoveragesQuery.data !== undefined) {
                const coveragesSidebarMenu = new SidebarMenu(
                    t("Coverages"),
                    getCoveragesQuery.data.map(
                        (coverageItem) =>
                            new SidebarItem(
                                coverageItem.coverageCode,
                                coverageItem.coverageCode,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/coverages-groups/${params.coverageGroupId}/coverages/${coverageItem.id}`
                            )
                    )
                );
                sidebarMenus.push(coveragesSidebarMenu);
            }

            if (getOptionsByGroupQuery.isSuccess && getOptionsByGroupQuery.data !== undefined) {
                const optionsSidebarMenu = new SidebarMenu(
                    t("Options"),
                    getOptionsByGroupQuery.data.map(
                        (optionItem) =>
                            new SidebarItem(
                                optionItem.code,
                                optionItem.code,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/coverages-groups/${params.coverageGroupId}/options/${optionItem.id}`
                            )
                    )
                );
                sidebarMenus.push(optionsSidebarMenu);
            }

            if (getFeeChargesQuery.isSuccess && getFeeChargesQuery.data !== undefined) {
                const feeChargesSidebarMenu = new SidebarMenu(
                    t("Fee"),
                    getFeeChargesQuery.data.map(
                        (feeChargeItem) =>
                            new SidebarItem(
                                feeChargeItem.code,
                                feeChargeItem.code,
                                `/products/${params.id}/versions/${params.versionId}/insurance-packages/${params.insurancePackageId}/fee-charges/${feeChargeItem.id}`
                            )
                    )
                );
                sidebarMenus.push(feeChargesSidebarMenu);
            }

            setMenus(sidebarMenus);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            getCoveragesQuery.isSuccess,
            getCoveragesQuery.data,
            getOptionsByGroupQuery.isSuccess,
            getOptionsByGroupQuery.data,
            getFeeChargesQuery.isSuccess,
            getFeeChargesQuery.data,
        ]
    );

    return (
        <>
            <Sidebar menus={menus} />
            {getCoverageQueryStatus === "success" &&
                coverage !== undefined &&
                getVersionQuery.isSuccess &&
                getVersionQuery.data !== undefined &&
                getCoveragesGroupsQuery.isSuccess &&
                getCoveragesGroupsQuery.data !== undefined && (
                    <>
                        <BreadcrumbCoverage coverage={coverage} sections={props.sections} />
                        <Container fluid>
                            <div className="main-container">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width="16">
                                            <Segment className="card-container">
                                                <Tab
                                                    panes={[
                                                        {
                                                            menuItem: t("Coverage Informations"),
                                                            render: () => (
                                                                <CoveragePageGenericsInfos
                                                                    coverage={coverage}
                                                                    version={getVersionQuery.data}
                                                                    coveragesGroupsOptions={getCoveragesGroupsQuery.data.map(
                                                                        (coverageGroup) => {
                                                                            return {
                                                                                key: coverageGroup.id,
                                                                                value: String(coverageGroup.id),
                                                                                text: coverageGroup.label,
                                                                            };
                                                                        }
                                                                    )}
                                                                    updateCoverageMutation={updateCoverageMutation}
                                                                    deleteCoverageMutation={deleteCoverageMutation}
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            menuItem: t("Computing rule"),
                                                            render: () => (
                                                                <ComputingRuleList
                                                                    computingRule={coverage.computingRule}
                                                                    version={getVersionQuery.data}
                                                                    refetch={refetchCoverage}
                                                                    isQueryStatusFetching={isCoverageFetching}
                                                                    queryToInvalidate={
                                                                        {queryKey: ["getCoverage", {coverageGroupId: params.coverageGroupId, coverageId: params.coverageId}]}
                                                                    }
                                                                />
                                                            ),
                                                        },
                                                        {
                                                            menuItem: t("Derogatories Taxes"),
                                                            render: () => (
                                                                <DerogatoriesTaxes
                                                                    insurancePackageId={params.insurancePackageId}
                                                                    registryCode={coverage.code}
                                                                    version={getVersionQuery.data}
                                                                />
                                                            ),
                                                        },
                                                    ]}
                                                />
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </>
                )}
        </>
    );
};

/* ----- CoveragePageGenericsInfos ----- */
interface CoveragePageGenericsInfosPropsType {
    coverage: CoverageRuleDTO;
    version: VersionDTO;
    coveragesGroupsOptions: Array<DropdownItemProps>;

    updateCoverageMutation: UseMutationResult<Coverage, CustomError, CoverageUpdateDTO, Error>;
    deleteCoverageMutation: UseMutationResult<void, CustomError, number, Error>;
}

const CoveragePageGenericsInfos = ({
    coverage,
    version,
    coveragesGroupsOptions,
    updateCoverageMutation,
    deleteCoverageMutation,
}: CoveragePageGenericsInfosPropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const editCoverageForm = useForm({mode: "onBlur"});

    const productBehaviorQueryResult = useGetProductBehaviourByRegistryIdQuery(params.id, coverage.registryId);

    const [editingMode, setEditingMode] = useState<boolean>(false);
    const [editGroupSelectedId, setEditGroupSelectedId] = useState<number>(Number(params.coverageGroupId));
    const [commissions, setCommissions] = useState<CommissionsStateType>({
        contribution: {
            initialRate: 0,
            currentValue: null,
            derogatoryChecked: false,
        },
        management: {
            initialRate: 0,
            currentValue: null,
            derogatoryChecked: false,
        },
    });

    const handleUpdate = () => {
        updateCoverageMutation.mutate({
            id: coverage.id,
            code: coverage.code,
            label: coverage.label,
            optional: coverage.optional,
            derogatoryManagementCommissionRate: commissions.management.derogatoryChecked
                ? commissions.management.currentValue
                : null,
            derogatoryContributionCommissionRate: commissions.contribution.derogatoryChecked
                ? commissions.contribution.currentValue
                : null,
            groupId: editGroupSelectedId,
            rule: null,
        });
    };

    const isDerogatory = (currentValue: number | null, initialValue: number | null) => {
        if (currentValue === null || currentValue === initialValue) return false;
        else return true;
    };

    const handleModifyClick = () => {
        setEditingMode(true);

        setCommissions({
            contribution: {
                ...commissions.contribution,
                currentValue: coverage.derogatoryContributionCommissionRate,
                derogatoryChecked: isDerogatory(
                    coverage.derogatoryContributionCommissionRate,
                    commissions.contribution.initialRate
                ),
            },
            management: {
                ...commissions.management,
                currentValue: coverage.derogatoryManagementCommissionRate,
                derogatoryChecked: isDerogatory(
                    coverage.derogatoryManagementCommissionRate,
                    commissions.management.initialRate
                ),
            },
        });
    };

    return (
        <Tab.Pane style={{padding: "20px"}}>
            <Grid>
                <GridRow>
                    <GridColumn width={6}>
                        <Card>
                            {editingMode ? (
                                <CardContent style={{padding: "25px"}}>
                                    <CardHeader>{coverage.label}</CardHeader>
                                    <Divider />
                                    <CardDescription>
                                        <Grid columns="equal" className="grid-padding">
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <strong>{t("Code")} : </strong>
                                                </Grid.Column>
                                                <Grid.Column width={8}>{coverage.code}</Grid.Column>
                                            </Grid.Row>

                                            {productBehaviorQueryResult.isSuccess &&
                                                productBehaviorQueryResult.data !== undefined && (
                                                    <UpdateDerogatoryCommissionRate
                                                        control={editCoverageForm.control}
                                                        errors={editCoverageForm.formState.errors}
                                                        productBehaviourDTO={productBehaviorQueryResult.data}
                                                        derogatoryCommissionRate={commissions}
                                                        setDerogatoryCommissionRate={setCommissions}
                                                    ></UpdateDerogatoryCommissionRate>
                                                )}

                                            <Grid.Column width={16}>
                                                <Form.Field>
                                                    <label>{t("Coverages group")}</label>
                                                    <Dropdown
                                                        fluid
                                                        options={coveragesGroupsOptions}
                                                        selection
                                                        name="coverageGroup"
                                                        onChange={(_e, data) =>
                                                            setEditGroupSelectedId(Number(data.value))
                                                        }
                                                        defaultValue={params.coverageGroupId}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid>
                                        <div className="bottom-button-card">
                                            <Divider style={{marginTop: "30px"}} />
                                            <Button
                                                name="cancel-coverage"
                                                secondary
                                                onClick={() => setEditingMode(false)}
                                            >
                                                {t("Cancel")}
                                            </Button>
                                            <ModalUpdate
                                                isSuccess={updateCoverageMutation.isSuccess}
                                                isPending={updateCoverageMutation.isPending}
                                                isError={updateCoverageMutation.isError}
                                                resetMutation={updateCoverageMutation.reset}
                                                error={updateCoverageMutation.error}
                                                onSuccess={() => setEditingMode(false)}
                                                onValidate={handleUpdate}
                                                objectToUpdate={"Coverage"}
                                                objectType={"female"}
                                                openModalButtonName="update-coverage"
                                            />
                                        </div>
                                    </CardDescription>
                                </CardContent>
                            ) : (
                                <CardContent style={{padding: "25px"}}>
                                    <CardHeader>{coverage.label}</CardHeader>
                                    <Divider />
                                    <CardDescription>
                                        <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                                            <Grid.Column width={8}>
                                                <strong>{t("Code")} : </strong>
                                            </Grid.Column>
                                            <Grid.Column width={8}>{coverage.code}</Grid.Column>

                                            <Grid.Column width={8}>
                                                <strong>{t("Derogatory commission rate")} : </strong>
                                            </Grid.Column>

                                            <Grid.Column width={8}>
                                                <div id="contribution">
                                                    <CommissionsDisplayMode
                                                        t={t}
                                                        name="Contribution"
                                                        initialRate={commissions.contribution.initialRate}
                                                        derogatoryRate={coverage.derogatoryContributionCommissionRate}
                                                    />
                                                </div>

                                                <div id="management">
                                                    <CommissionsDisplayMode
                                                        t={t}
                                                        name="Management"
                                                        initialRate={commissions.management.initialRate}
                                                        derogatoryRate={coverage.derogatoryManagementCommissionRate}
                                                    />
                                                </div>
                                            </Grid.Column>
                                        </Grid>
                                        {version.pipDate === null && (
                                            <div className="bottom-button-card">
                                                <Divider style={{marginTop: "30px"}} />
                                                <Button name="edit-coverage" color="blue" onClick={handleModifyClick}>
                                                    {t("edit")}
                                                </Button>
                                                <ModalDelete
                                                    onValidate={() => deleteCoverageMutation.mutate(Number(params.coverageId))}
                                                    objectToDelete="Coverage"
                                                    objectType="male"
                                                    openModalButtonName="delete-coverage"
                                                    isSuccess={deleteCoverageMutation.isSuccess}
                                                    isPending={deleteCoverageMutation.isPending}
                                                    isError={deleteCoverageMutation.isError}
                                                    resetMutation={deleteCoverageMutation.reset}
                                                />
                                            </div>
                                        )}
                                    </CardDescription>
                                </CardContent>
                            )}
                        </Card>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Tab.Pane>
    );
};

export default CoveragePage;
