import {Fragment} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Divider, Form, Grid, Header} from "semantic-ui-react";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {useUpdateSplittingMutation} from "../../../../Services/Splitting/Queries";
import {Splitting, SplittingType, SplittingUnit} from "../../../../Services/Splitting/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {codeValidation} from "../../../Global/Form/Validation";

type SplittingDetailsEditProps = {
    productId: string;
    splittingId: string;
    splitting: Splitting;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const SplittingDetailsEdit = ({productId, splittingId, splitting, setEdit}: SplittingDetailsEditProps) => {
    const {t} = useTranslation();
    const {
        control,
        handleSubmit,
        formState: {errors},
        watch,
        setValue,
    } = useForm<Splitting>({mode: "onBlur", shouldUnregister: true, defaultValues: splitting});
    const {mutate, isError, isPending, isSuccess, reset, error} = useUpdateSplittingMutation(productId, splittingId);

    const updateSplitting = (formData: Splitting) => {
        mutate({
            ...formData,
            unit: formData.splittingType === SplittingType.X ? formData.unit : null,
            duration: formData.splittingType === SplittingType.X ? formData.duration : null,
        });
    };

    const [splittingType] = watch(["splittingType"]);

    const handleSplittingTypeChange = (value: SplittingType) => {
        if (value !== SplittingType.X) {
            setValue("code", value);
            setValue("label", t(`enums.SplittingType.${value}`));
        }
    };

    const splittingTypeOptions = Object.values(SplittingType).map((splittingType: SplittingType) => {
        return {
            key: splittingType.toString(),
            text: t("enums.SplittingType." + splittingType),
            value: splittingType,
        };
    });

    const splittingUnitOptions = Object.values(SplittingUnit).map((splittingUnit: SplittingUnit) => {
        return {
            key: splittingUnit.toString(),
            text: t("enums.SplittingUnit." + splittingUnit),
            value: splittingUnit,
        };
    });

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Splitting type")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            name={"splittingType"}
                            defaultValue={splitting.splittingType}
                            rules={{required: true}}
                            render={({field: {onChange, value, name, ref}}) => (
                                <Form.Select
                                    inputref={ref}
                                    fluid
                                    name={name}
                                    placeholder={t("Splitting type")}
                                    onChange={(_, {value}) => {
                                        handleSplittingTypeChange(value as SplittingType);
                                        onChange(value as SplittingType);
                                    }}
                                    value={value}
                                    options={splittingTypeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Code")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            name="code"
                            control={control}
                            rules={{required: true, maxLength: 5, pattern: codeValidation()}}
                            defaultValue={splitting.code}
                            render={({field: {name, value, onBlur, onChange, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    name={name}
                                    fluid
                                    maxLength={"5"}
                                    placeholder={t("Code")}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, 5)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Label")} * :</strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            name="label"
                            control={control}
                            defaultValue={splitting.label}
                            rules={{required: true, maxLength: 30}}
                            render={({field: {name, value, onBlur, onChange, ref}}) => (
                                <Form.Input
                                    inputref={ref}
                                    required
                                    name={name}
                                    fluid
                                    maxLength={"30"}
                                    placeholder={t("Label")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name, undefined, 30)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                {splittingType === SplittingType.X && (
                    <Fragment>
                        <Grid.Row style={{paddingTop: "1rem", paddingBottom: "0"}}>
                            <Grid.Column>
                                <Header>{t("Duration")}</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Unity")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    control={control}
                                    name={"unit"}
                                    defaultValue={splitting.unit}
                                    rules={{required: true}}
                                    render={({field: {onChange, value, name, ref}}) => (
                                        <Form.Select
                                            inpuref={ref}
                                            fluid
                                            name={name}
                                            placeholder={t("Unity")}
                                            onChange={(_, {value}) => onChange(value)}
                                            value={value as string}
                                            options={splittingUnitOptions}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{paddingTop: "0", paddingBottom: "1rem"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Duration")} * : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Controller
                                    name="duration"
                                    control={control}
                                    defaultValue={splitting.duration}
                                    rules={{
                                        required: true,
                                        min: {value: 0, message: t("The value must be greater than 0")},
                                        max: {value: 999, message: t("The value must be less than 999")},
                                    }}
                                    render={({field: {name, value, onBlur, onChange, ref}}) => (
                                        <Form.Input
                                            inputref={ref}
                                            type="number"
                                            name={name}
                                            fluid
                                            min={0}
                                            max={999}
                                            placeholder={t("Duration")}
                                            onChange={(_, {value}) => onChange(Number.parseInt(value) || "")}
                                            onBlur={onBlur}
                                            value={value}
                                            error={getErrorMessage(t, errors, name)}
                                        />
                                    )}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Fragment>
                )}

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Coefficient increase")} * : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            name="increaseCoef"
                            control={control}
                            defaultValue={splitting.increaseCoef}
                            rules={{required: true, min: {value: 0, message: t("The value must be greater than 0")}}}
                            render={({field: {name, value, onBlur, onChange}}) => (
                                <Form.Input
                                    type={"number"}
                                    name={name}
                                    fluid
                                    min={0}
                                    step={".01"}
                                    placeholder={t("Coefficient increase")}
                                    onChange={(_, {value}) => onChange(Number.parseFloat(value) || "")}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{paddingTop: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Default splitting")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Controller
                            control={control}
                            defaultValue={splitting.defaultSplitting}
                            name={"defaultSplitting"}
                            render={({field: {value, name, onChange}}) => (
                                <Form.Radio
                                    toggle
                                    aria-label={name}
                                    onChange={(_, {checked}) => onChange(checked)}
                                    name={name}
                                    checked={value}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className={"bottom-button-card"} style={{bottom: 0}}>
                <Divider />
                <Button secondary name="cancel" onClick={() => setEdit(false)}>
                    {t("Cancel")}
                </Button>
                <ModalUpdate
                    isModalDisabled={Object.keys(errors).length !== 0}
                    onSuccess={() => setEdit(false)}
                    onValidate={handleSubmit(updateSplitting)}
                    resetMutation={reset}
                    isError={isError}
                    isPending={isPending}
                    isSuccess={isSuccess}
                    error={error}
                    customContent={t("You are about to change splitting information")}
                />
            </div>
        </Form>
    );
};

export default SplittingDetailsEdit;
