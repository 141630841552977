import {useState} from "react";
import {Controller} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {UseMutationResult} from "@tanstack/react-query";
import {Button, Form, Grid, GridColumn, Header, Segment} from "semantic-ui-react";
import {CustomError} from "../../Services/RequestUtils";
import {VersionDTO} from "../../Services/Version/Types";
import ModalUpdate from "../Modals/ModalUpdate";

type DescriptionPropsType = {
    entityName: string;
    entityType: "male" | "female";
    description: string;
    version: VersionDTO | undefined;

    form: any;
    entityMutation: UseMutationResult<any, CustomError, any, Error>;
    handleEntityUpdate: (formData: any) => void;
};

const Description = ({...props}: DescriptionPropsType) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(false);

    return (
        <Segment className="card-container description">
            <Header as="h3" dividing>
                {t("Description")}
            </Header>
            {editMode ? (
                <Form>
                    <Controller
                        control={props.form.control}
                        defaultValue={props.description ? props.description : ""}
                        name="description"
                        rules={{maxLength: 255}}
                        render={({field: {onChange, value, name}}) => (
                            <Form.TextArea
                                name={name}
                                placeholder={t("Description")}
                                maxLength="255"
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <Grid>
                        <GridColumn textAlign="right">
                            <Button
                                secondary
                                name="cancel"
                                onClick={(event) => {
                                    setEditMode(false);
                                    event.preventDefault();
                                }}
                            >
                                {t("Cancel")}
                            </Button>
                            <ModalUpdate
                                isModalDisabled={Object.keys(props.form.formState.errors).length !== 0}
                                isPending={props.entityMutation.isPending}
                                isSuccess={props.entityMutation.isSuccess}
                                isError={props.entityMutation.isError}
                                resetMutation={props.entityMutation.reset}
                                onSuccess={() => setEditMode(false)}
                                onValidate={props.form.handleSubmit(props.handleEntityUpdate)}
                                objectToUpdate={t(props.entityName).toLocaleLowerCase()}
                                objectType={props.entityType}
                            />
                        </GridColumn>
                    </Grid>
                </Form>
            ) : (
                <Grid>
                    <GridColumn width={15}>
                        {props.description !== null &&
                        props.description !== undefined &&
                        props.description.length !== 0 ? (
                            <p>{props.description}</p>
                        ) : (
                            <p>{t("No_description_available")}</p>
                        )}
                    </GridColumn>

                    <GridColumn width={1} textAlign="center" verticalAlign="middle">
                        {props.version !== undefined && props.version.pipDate === null && (
                            <Button
                                name="editDescription"
                                color="grey"
                                icon="edit"
                                compact
                                basic
                                onClick={() => setEditMode(true)}
                            ></Button>
                        )}
                    </GridColumn>
                </Grid>
            )}
        </Segment>
    );
};

export default Description;
