export const handleNullableProperty = (property: string | number | undefined | null, t: Function) => {
    if ((typeof property === "string" && property.length > 0) || typeof property === "number") {
        return property;
    } else {
        return <i>{t("Not specified")}</i>;
    }
};

export const handleNullablePropertyAsDate = (property: string | undefined | null, t: Function) => {
    if (property !== undefined && property !== null) {
        return t("format_date", {value: property});
    } else {
        return <i>{t("Not specified")}</i>;
    }
};

export const handleNullablePropertyAsDateTime = (property: string | undefined | null, t: Function) => {
    if (property !== undefined && property !== null) {
        return t("format_datetime", {value: property});
    } else {
        return <i>{t("Not specified")}</i>;
    }
};
