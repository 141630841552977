import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Tab} from "semantic-ui-react";
import FormSplitting from "../../../../Components/Forms/FormSplitting";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import SimpleList from "../../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../../Components/SimpleList/SimpleListTypes";
import {useAddSplittingMutation, useGetSplittings} from "../../../../Services/Splitting/Queries";
import {SplittingDTO} from "../../../../Services/Splitting/Types";

type SplittingTabPaneProps = {
    productId: string;
};

const splittingTableColumns = [
    {
        text: "Code",
        dataField: "code",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Label",
        dataField: "label",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Type",
        dataField: "splittingType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "By default",
        dataField: "defaultSplitting",
        type: DataTypeColumnEnum.BOOLEAN,
    },
    {
        text: "",
        dataField: "/products/:productId/splittings/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const SplittingTabPane: React.FC<SplittingTabPaneProps> = ({productId}) => {
    const getSplittings = useGetSplittings(productId);

    const addSplittingMutation = useAddSplittingMutation(productId);
    const addSplittingForm = useForm<SplittingDTO>();
    const submitAddSplittingForm = (formData: SplittingDTO) => addSplittingMutation.mutate(formData);

    const [defaultSplitting, setDefaultSplitting] = useState<Partial<SplittingDTO>>({});
    const resetForm = () => {
        addSplittingForm.reset();
        setDefaultSplitting({});
    };

    return (
        <Tab.Pane style={{overflowX: "auto"}}>
            <SimpleList
                tableName="splittings"
                columns={splittingTableColumns}
                globalData={[{key: "productId", value: productId}]}
                rows={getSplittings.isSuccess && getSplittings.data ? getSplittings.data : []}
                isLoading={getSplittings.isLoading}
            />
            <div className="bottom-button-card">
                <ModalAdd
                    isValidationDisabled={Object.keys(addSplittingForm.formState.errors).length !== 0}
                    isPending={addSplittingMutation.isPending}
                    isSuccess={addSplittingMutation.isSuccess}
                    isError={addSplittingMutation.isError}
                    resetMutation={addSplittingMutation.reset}
                    error={addSplittingMutation.error}
                    onValidate={addSplittingForm.handleSubmit(submitAddSplittingForm)}
                    onSuccess={resetForm}
                    onCancel={resetForm}
                    objectToAdd="Splitting"
                    objectToAddContext="male"
                    openModalButtonName="add-splitting"
                    renderModalContent={() => (
                        <FormSplitting
                            form={addSplittingForm}
                            defaultSplitting={defaultSplitting}
                            setDefaultSplitting={setDefaultSplitting}
                        />
                    )}
                />
            </div>
        </Tab.Pane>
    );
};

export default SplittingTabPane;
