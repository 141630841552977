import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Params, useLocation, useParams} from "react-router-dom";
import {Container, Grid, Segment, Tab} from "semantic-ui-react";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb/CustomBreadcrumb";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import {useVersionSidebarMenus} from "../../../Hooks/useVersionSidebarMenus";
import {VariableType} from "../../../Services/Variables/Types";
import {useGetVersionQuery, useGetVersionsQuery} from "../../../Services/Version/Queries";
import BreadcrumbVersion from "./Fragments/BreadcrumbVersion";
import InsurancePackageList from "./Fragments/InsurancePackages/InsurancePackageList";
import PricingCriteriasList from "./Fragments/PricingCriterias/PricingCriteriasList";
import PricingGridList from "./Fragments/PricingGrids/PricingGridList";
import QuestionsList from "./Fragments/Questions/QuestionsList";
import RejectionRules from "./Fragments/RejectionRules/RejectionRules";
import VariablesList from "./Fragments/Variables/VariablesList";

interface IParams extends Params {
    id: string;
    versionId: string;
}

const VersionPage = ({...props}) => {
    const location = useLocation();
    const params = useParams() as IParams;
    const {t} = useTranslation();
    const {data: versionList} = useGetVersionsQuery(params.id);

    const menus = useVersionSidebarMenus(params.id);
    const [activeTabIndex, setActiveIndex] = useState(location.state ? location.state.activeTabIndex : 0);

    const {data: version, isSuccess, isLoading} = useGetVersionQuery(params.id, params.versionId);

    const panes = [
        {
            menuItem: {key: "pricingGrid", content: t("Pricing grid", {count: 2})},
            render: () => <PricingGridList version={version} />,
        },
        {
            menuItem: {key: "questions", content: t("Questions", {count: 2})},
            render: () => <QuestionsList version={version} />,
        },
        {
            menuItem: {key: "pricingCriterias", content: t("Pricing criterias", {count: 2})},
            render: () => <PricingCriteriasList version={version} />,
        },
        {
            menuItem: {key: "rejectRule", content: t("Reject rules", {count: 2})},
            render: () => <RejectionRules versionId={params.versionId} />,
        },
        {
            menuItem: {key: "variables", content: t("Data variable", {count: 2})},
            render: () => <VariablesList version={version} type={VariableType.V} />,
        },
        {
            menuItem: {key: "computingRules", content: t("Computing rule variables", {count: 2})},
            render: () => <VariablesList version={version} type={VariableType.R} />,
        },
        {
            menuItem: {key: "insurancePackages", content: t("Insurance packages", {count: 2})},
            render: () => <InsurancePackageList version={version} />,
        },
    ];

    return (
        <Container fluid>
            <Sidebar title={t("Versions")} menus={menus} />
            {isLoading && <CustomBreadcrumb sections={props.sections} />}
            {version !== undefined && versionList !== undefined && isSuccess && (
                <BreadcrumbVersion version={version} versionList={versionList} sections={props.sections} />
            )}

            <div className="main-container">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Segment className="card-container">
                                <Tab
                                    panes={panes}
                                    activeIndex={activeTabIndex}
                                    onTabChange={(_, data) => setActiveIndex(data.activeIndex as number)}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Container>
    );
};

export default VersionPage;
