import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router";
import {Link} from "react-router-dom";
import {Card} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import {DeductibleCapital} from "../../../../../Services/DeductibleCapital/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";

type DeductibleCapitalCardPropsType = {
    insurancePackageId: string;
    deductibleCapital: DeductibleCapital;
};

interface IParams extends Params {
    id: string;
    versionId: string;
}

const DeductibleCapitalCard = ({insurancePackageId, deductibleCapital}: DeductibleCapitalCardPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);

    return (
        <Card
            as={Link}
            to={`/products/${params.id}/versions/${params.versionId}/insurance-packages/${insurancePackageId}/deductible-capitals/${deductibleCapital.id}`}>
            <Card.Content>
                <Card.Header>
                    <TextOverflow value={deductibleCapital.label} />
                </Card.Header>
                <Card.Meta>
                    <strong>
                        {deductibleCapital.type === "C" ? t("Capital").toUpperCase() : t("Deductible").toUpperCase()}
                    </strong>
                </Card.Meta>
            </Card.Content>
            {user !== undefined && (
                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                    <span>
                        {displayAuditLog(
                            {
                                entityContext: "female",
                                createdDate: deductibleCapital.createdDate,
                                modifiedDate: deductibleCapital.modifiedDate,
                                createdBy: deductibleCapital.createdBy,
                                modifiedBy: deductibleCapital.modifiedBy,
                                createdOnVersionId: deductibleCapital.createdOnVersionId,
                            },
                            user,
                            params.versionId,
                            t
                        )}
                    </span>
                </Card.Content>
            )}
        </Card>
    );
};

export default DeductibleCapitalCard;
