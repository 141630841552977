// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {ComputingRuleCondition, ComputingRuleConditionDTO, RulePart} from "./Types";

export const checkSyntax = async (rulePartList: RulePart[]): Promise<boolean> => {
    try {
        const response = await axiosInstance.post<boolean>(`/computing_rules/_checkSyntax`, rulePartList);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateComputingRuleCondition = async (
    computingRuleId: number,
    conditionId: number,
    condition: ComputingRuleConditionDTO
): Promise<ComputingRuleCondition> => {
    try {
        const response = await axiosInstance.put<ComputingRuleCondition>(
            `computing_rules/${computingRuleId}/conditions/${conditionId}`,
            condition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteComputingRuleCondition = async (computingRuleId: number, conditionId: number) => {
    try {
        const response = await axiosInstance.delete(`computing_rules/${computingRuleId}/conditions/${conditionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addComputingRuleCondition = async (computingRuleId: number, condition: ComputingRuleConditionDTO) => {
    try {
        const response = await axiosInstance.post<ComputingRuleCondition>(
            `computing_rules/${computingRuleId}/conditions`,
            condition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const reorderComputingRuleConditionMutation = async (computingRuleId: string, conditionId: string, order: number): Promise<void> => {
    try {
        const response = await axiosInstance.patch(`/computing_rules/${computingRuleId}/conditions/${conditionId}/_reorder?order=${order}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}
