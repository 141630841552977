import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Card, Label, SemanticCOLORS } from "semantic-ui-react";
import { AuthContext } from "../../../../../Components/AuthProvider/AuthProvider";
import SaveQuoteLabel from "../../../../../Components/Labels/SaveQuoteLabel";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import { PricingCriteriaItem, PricingCriteriaItemType, PricingCriterias } from "../../../../../Services/PricingCriterias/Types";
import { displayAuditLog } from "../../../../../Utils/AuditUtils";

type PricingCriteriaCardType = {
    versionId: string | undefined;
    pricingCriteria: PricingCriteriaItem | PricingCriterias;
    onClick: (() => void) | ((operandItem: PricingCriteriaItem | PricingCriterias) => void);
}

export const PricingCriteriaCard = ({
    versionId,
    pricingCriteria,
    onClick,
}: PricingCriteriaCardType) => {

    const {t} = useTranslation();
    const userContext = useContext(AuthContext);

    const getColorOfPricingCriteriaItemType = (
        pricingCriteriaItemType: PricingCriteriaItemType
    ): SemanticCOLORS | undefined => {
        switch (pricingCriteriaItemType) {
            case PricingCriteriaItemType.STRING:
                return "blue";
            case PricingCriteriaItemType.DATE:
                return "green";
            case PricingCriteriaItemType.NUMERIC:
                return "purple";
            default:
                return undefined;
        }
    };

    return (
        <Card
            onClick={() => onClick(pricingCriteria)}
        >
            <Card.Content>
                <Card.Header>
                    <TextOverflow value={pricingCriteria.name} />
                </Card.Header>
                <Card.Description style={{marginTop: "10px", display: "flex", justifyContent: "space-between"}}>
                    <Label
                        color={getColorOfPricingCriteriaItemType(pricingCriteria.type)}
                        data-cy="pricingCriteria-card-type">
                        {t("enums.PricingCriteriaType." + pricingCriteria.type)}
                    </Label>
                    {pricingCriteria.saveQuote && (
                        <SaveQuoteLabel />
                    )}
                </Card.Description>

                {!pricingCriteria.valid && (
                    <Label corner="right" color="red" size="tiny" icon="warning sign" />
                )}
            </Card.Content>

            {userContext.user !== undefined && (
                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                    <span>
                        {versionId && displayAuditLog(
                            {
                                entityContext: "male",
                                createdDate: pricingCriteria.createdDate,
                                modifiedDate: pricingCriteria.modifiedDate,
                                createdBy: pricingCriteria.createdBy,
                                modifiedBy: pricingCriteria.modifiedBy,
                                createdOnVersionId: pricingCriteria.createdOnVersionId,
                            },
                            userContext.user,
                            versionId,
                            t
                        )}
                    </span>
                </Card.Content>
            )}
        </Card>
    );
}