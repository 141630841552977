import axios from "axios";
import { interceptAxiosRequestAndInjectTokenFromCookie } from "../../Services/RequestUtils";

const axiosInstance = axios.create({
    baseURL: `/moteurproduit/api/coverlife`,
});

axiosInstance.interceptors.request.use(
    interceptAxiosRequestAndInjectTokenFromCookie,
    (error: Error): Promise<never> => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
