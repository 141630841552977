import {UseMutationResult, UseQueryResult, useMutation, useQuery} from "@tanstack/react-query";
import queryClient from "../../../Config/queryClient";
import {CustomError} from "../../../Services/RequestUtils";
import {exportCommissionPackages, getCommissionPackages, importCommissionPackages} from "./AxiosRequests";
import {CommissionPackages} from "./Types";

export const useGetCommissionPackages = (
    productCode: string,
    enabled: boolean = true
): UseQueryResult<Array<CommissionPackages>, Error> => {
    return useQuery({
        queryKey: ["getCommissionPackages", {productCode}],
        queryFn: () => getCommissionPackages(productCode),
        enabled,
    });
};

export const useExportCommissionPackages = (
    productCode: string,
    enabled: boolean = true
): UseQueryResult<unknown, Error> => {
    return useQuery({
        queryKey: ["exportCommissionPackages", {productCode}],
        queryFn: () => exportCommissionPackages(productCode),

        enabled,
    });
};

export const useImportCommissionPackagesMutation = (
    productCode: string
): UseMutationResult<void, CustomError, any, Error> => {
    return useMutation({
        mutationFn: (files: any) => {
            const formData = new FormData();
            formData.append(
                "file",
                new Blob([files], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            );

            return importCommissionPackages(productCode, formData);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["getCommissionPackages", {productCode}]});
        },
    });
};

