import {Fragment, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Button, Divider, Form, Grid, Header, Segment} from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import ModalUpdate from "../../../../Components/Modals/ModalUpdate";
import {useDeleteRegistryMutation, useUpdateRegistryMutation} from "../../../../Services/Registry/Queries";
import {EntryDetailsViewType, RegistryEntryDTO, RegistryEntryType} from "../../../../Services/Registry/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {codeValidation} from "../../../Global/Form/Validation";

type PropsType = {
    registryEntryId: string;
    registryEntry: RegistryEntryDTO;
};

const EntryDetails = ({registryEntryId, registryEntry}: PropsType) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);
    const toggleEditMode = () => setEditMode(!editMode);

    return (
        <Fragment>
            <Segment className="card-container">
                <Header as="h2" dividing>
                    {t("Entry registry")}
                </Header>

                {editMode ? (
                    <EntryDetailsEdit
                        registryEntryId={registryEntryId}
                        registryEntry={registryEntry}
                        t={t}
                        toggleEditMode={toggleEditMode}
                    />
                ) : (
                    <EntryDetailsView
                        registryEntryId={registryEntryId}
                        registryEntry={registryEntry}
                        t={t}
                        toggleEditMode={toggleEditMode}
                    />
                )}
            </Segment>
        </Fragment>
    );
};

const EntryDetailsView = ({registryEntryId, registryEntry, t, toggleEditMode}: EntryDetailsViewType) => {
    const navigate = useNavigate();

    const deleteEntryMutation = useDeleteRegistryMutation(registryEntryId);
    const handleDelete = () => {
        deleteEntryMutation.mutate();
    };

    const onEntryDeleteSuccess = () => {
        navigate(`/transversal_settings/registry`);
    };

    return (
        <Fragment>
            <Grid columns="equal" className="grid-padding">
                <Grid.Row role="row">
                    <Grid.Column width={6} role="cell">
                        <strong>{t("Code")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell" id="code">
                        {registryEntry.code}
                    </Grid.Column>

                    <Grid.Column width={6} role="cell">
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell" id="label">
                        {registryEntry.label}
                    </Grid.Column>

                    <Grid.Column width={6} role="cell">
                        <strong>{t("Type")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={10} role="cell" id="type">
                        {t(`enums.RegistryEntryType.${registryEntry.type}`)}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="bottom-button-card">
                <Divider />
                <Button primary name="editEntry" onClick={toggleEditMode}>
                    {t("edit")}
                </Button>
                <ModalDelete
                    openModalButtonName="deleteEntry"
                    isSuccess={deleteEntryMutation.isSuccess}
                    isPending={deleteEntryMutation.isPending}
                    isError={deleteEntryMutation.isError}
                    error={deleteEntryMutation.error}
                    resetMutation={deleteEntryMutation.reset}
                    onSuccess={onEntryDeleteSuccess}
                    onValidate={handleDelete}
                    objectToDelete="Entry registry"
                    objectType="nonbinary"
                />
            </div>
        </Fragment>
    );
};

const EntryDetailsEdit = ({registryEntryId, registryEntry, t, toggleEditMode}: EntryDetailsViewType) => {
    const {control, handleSubmit, formState} = useForm<RegistryEntryDTO>({mode: "onChange"});

    const registryEntryUpdateMutation = useUpdateRegistryMutation(registryEntryId);
    const handleEntryUpdate = (formData: RegistryEntryDTO) => {
        registryEntryUpdateMutation.mutate(formData);
    };

    const typeOptions = Object.values(RegistryEntryType).map((registryEntryType: RegistryEntryType, index: number) => {
        return {
            key: index,
            text: t(`enums.RegistryEntryType.${registryEntryType}`),
            value: registryEntryType,
        };
    });

    return (
        <Form>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding">
                <Grid.Row>
                    <Grid.Column width={6}>
                        <strong>{t("Code")} *: </strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Controller
                            control={control}
                            defaultValue={registryEntry.code}
                            name={"code"}
                            rules={{required: true, maxLength: 20, pattern: codeValidation()}}
                            render={({field: {value, name, onChange, onBlur}}) => (
                                <Form.Input
                                    required
                                    name={name}
                                    placeholder={t("Code")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="20"
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <strong>{t("Label")} *: </strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Controller
                            control={control}
                            defaultValue={registryEntry.label}
                            name={"label"}
                            rules={{required: true}}
                            render={({field: {value, name, onChange, onBlur}}) => (
                                <Form.Input
                                    required
                                    name={name}
                                    placeholder={t("Label")}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength="90"
                                    error={getErrorMessage(t, formState.errors, name)}
                                />
                            )}
                        />
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <strong>{t("Type")} *: </strong>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Controller
                            control={control}
                            defaultValue={registryEntry.type}
                            name={"type"}
                            rules={{required: true}}
                            render={({field: {value, name, onChange, onBlur}}) => (
                                <Form.Select
                                    fluid
                                    required
                                    name={name}
                                    placeholder={t("Type")}
                                    onChange={(_, {value}) => onChange(value)}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, formState.errors, name)}
                                    options={typeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className="bottom-button-card">
                <Divider />
                <Button secondary name="cancel" onClick={toggleEditMode}>
                    {t("Cancel")}
                </Button>
                <ModalUpdate
                    isModalDisabled={Object.keys(formState.errors).length !== 0}
                    isPending={registryEntryUpdateMutation.isPending}
                    isError={registryEntryUpdateMutation.isError}
                    isSuccess={registryEntryUpdateMutation.isSuccess}
                    error={registryEntryUpdateMutation.error}
                    resetMutation={registryEntryUpdateMutation.reset}
                    onSuccess={toggleEditMode}
                    onValidate={handleSubmit(handleEntryUpdate)}
                    objectToUpdate={t("Entry registry")}
                    objectType="nonbinary"
                />
            </div>
        </Form>
    );
};

export default EntryDetails;
