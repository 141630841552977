export type ESelectionMatrixDTO = {
    eSelectionDimensionDTOs: Array<ESelectionDimensionDTO>;
    eSelectionValueDTOs: Array<ESelectionValueDTO>;
};

export type ESelectionDimensionDTO = {
    type: ESelectionDimensionType;
    eSelectionDimensionValueDTOs: Array<ESelectionDimensionValueDTO>;
};

export type ESelectionDimensionValueDTO = {
    min: number | string;
    max: number | string;
};

export type ESelectionValueDTO = {
    id: number | null;
    value: string;
    eSelectionDimensionValueRecapDTOs: Array<ESelectionDimensionValueRecapDTO>;
};

export type ESelectionDimensionValueRecapDTO = {
    type: ESelectionDimensionType;
    min: number;
    max: number;
    rank: number | null;
};

export enum ESelectionDimensionType {
    AGE = "AGE",
    DC = "DC",
    IJ = "IJ",
    FICTIVE = "FICTIVE",
}

export type ESelectionMatrixExportDTO = {
    lines: Array<ESelectionMatrixExportDTOLine>;
};

export type ESelectionMatrixExportDTOLine = {
    cells: Array<ESelectionMatrixExportDTOCell>;
};

export type ESelectionMatrixExportDTOCell = {
    id: number;
    type: ESelectionMatrixExportDTOCellType;
    colspan: number;
    value: string;
};

export enum ESelectionMatrixExportDTOCellType {
    DIM = "DIM",
    VAL = "VAL",
    DVAL = "DVAL",
    VIDE = "VIDE",
}
