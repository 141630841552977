import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalUpdateForm from "../../../../Components/Modals/ModalUpdateForm";
import {useUpdateGlobalInfosPricingCriteriaMutation} from "../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType} from "../../../../Services/PricingCriterias/Types";
import FormPricingCriterias from "./FormPricingCriterias";

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
}

type PropsType = {
    pricingCriteriaName: string;
    pricingCriteriaType: PricingCriteriaItemType | undefined;
    pricingCriteriaSaveQuote: boolean;
};

const ModalUpdatePricingCriteria = ({...props}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const updatePricingCriteriaMutation = useUpdateGlobalInfosPricingCriteriaMutation(
        params.versionId,
        params.pricingCriteriaId
    );
    const updatePricingCriteriaForm = useForm<any>();
    const submitUpdatePricingCriteria = (form: any) => {
        updatePricingCriteriaMutation.mutate({...form});
    };

    const [defaultValueName, setDefaultValueName] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<PricingCriteriaItemType | undefined>(undefined);
    const [defaultValueSaveQuote, setDefaultValueSaveQuote] = useState<boolean>(false);

    useEffect(() => {
        setDefaultValueName(props.pricingCriteriaName);
        setDefaultValueType(props.pricingCriteriaType);
        setDefaultValueSaveQuote(props.pricingCriteriaSaveQuote);
    }, [props.pricingCriteriaName, props.pricingCriteriaType, props.pricingCriteriaSaveQuote]);

    const resetInputs = () => {
        setDefaultValueName(props.pricingCriteriaName);
        setDefaultValueType(props.pricingCriteriaType);
        setDefaultValueSaveQuote(props.pricingCriteriaSaveQuote);
    };

    return (
        <ModalUpdateForm
            isValidationDisabled={Object.keys(updatePricingCriteriaForm.formState.errors).length !== 0}
            isPending={updatePricingCriteriaMutation.isPending}
            isSuccess={updatePricingCriteriaMutation.isSuccess}
            isError={updatePricingCriteriaMutation.isError}
            resetMutation={updatePricingCriteriaMutation.reset}
            error={updatePricingCriteriaMutation.error}
            onValidate={updatePricingCriteriaForm.handleSubmit(submitUpdatePricingCriteria)}
            onCancel={() => resetInputs()}
            openButtonIcon="edit"
            objectToUpdate={t("Pricing criteria")}
            openModalButtonColor="blue"
            validateButtonColor="green"
            objectToUpdateContext="male"
            renderModalContent={() => {
                return (
                    <FormPricingCriterias
                        errors={updatePricingCriteriaForm.formState.errors}
                        control={updatePricingCriteriaForm.control}
                        defaultValueName={defaultValueName}
                        setDefaultValueName={setDefaultValueName}
                        defaultValueType={defaultValueType}
                        setDefaultValueType={setDefaultValueType}
                        defaultValueSaveQuote={defaultValueSaveQuote}
                        setDefaultValueSaveQuote={setDefaultValueSaveQuote}
                    />
                );
            }}
        />
    );
};

export default ModalUpdatePricingCriteria;
