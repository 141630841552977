import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SidebarItem, SidebarMenu } from "../Components/Sidebar/Sidebar";
import { useGetVersionsQuery } from "../Services/Version/Queries";

export const useVersionSidebarMenus = (productId: string) => {
    const {t} = useTranslation();
    const {data: versionList, status: requestStatusVersionList} = useGetVersionsQuery(productId);

    const [menus, setMenus] = useState<Array<SidebarMenu>>([]);

    useEffect(() => {
        const adaptDataForSidebar = () => {
            if (requestStatusVersionList === "success" && versionList !== undefined) {
                const formattedData = [];

                if (versionList.filter((version) => version.pipDate === null).length > 0) {
                    formattedData.push({
                        label: "working version",
                        items: versionList.filter((version) => version.pipDate === null),
                    });
                }

                if (versionList.filter((version) => version.pipDate !== null).length > 0) {
                    formattedData.push({
                        label: "validated versions",
                        items: versionList.filter((version) => version.pipDate !== null),
                    });
                }

                const sidebarMenus = formattedData.map(
                    (versionType) =>
                        new SidebarMenu(
                            t(versionType.label),
                            versionType.items.map(
                                (version: any) =>
                                    new SidebarItem(
                                        version.label,
                                        version.label,
                                        `/products/${productId}/versions/${version.id}`
                                    )
                            )
                        )
                );
                setMenus(sidebarMenus);
            }
        };

        adaptDataForSidebar();
    }, [requestStatusVersionList, versionList, productId, t]);

    return menus;
};
