import { useTranslation } from "react-i18next";
import { Menu } from "semantic-ui-react";
import { useGetCoveragesQuery, useGetOptionsByGroupQuery } from "../../../../../Services/InsurancePackage/Queries";
import { CoverageDTO, Option } from "../../../../../Services/InsurancePackage/Types";
import { DeductibleCapitalActiveItemType, DeductibleCapitalSelectedTypeEnum } from "./DeductibleCapitalsList";

type DeductibleCapitalSelectMenuType = {
    coverageGroupId: number;
    activeItem: DeductibleCapitalActiveItemType | null;
    setActiveItem: React.Dispatch<React.SetStateAction<DeductibleCapitalActiveItemType | null>>;
};

const DeductibleCapitalSelectMenu = ({coverageGroupId, activeItem, setActiveItem}: DeductibleCapitalSelectMenuType) => {
    const {t} = useTranslation();

    const getCoveragesQuery = useGetCoveragesQuery(String(coverageGroupId));
    const getOptionsByGroupQuery = useGetOptionsByGroupQuery(String(coverageGroupId));

    return (
        <>
            {getCoveragesQuery.data?.map((coverage: CoverageDTO) => (
                <Menu.Item
                    style={{color: "black", padding: "8px 16px 8px 15%", fontSize: "1rem"}}
                    key={coverage.coverageCode}
                    name={coverage.coverageCode}
                    active={
                        activeItem?.id === coverage.id &&
                        activeItem?.type === DeductibleCapitalSelectedTypeEnum.COVERAGE
                    }
                    onClick={() => setActiveItem({type: DeductibleCapitalSelectedTypeEnum.COVERAGE, id: coverage.id})}>
                    {t("Coverage")} - {coverage.coverageCode}
                </Menu.Item>
            ))}
            {getOptionsByGroupQuery.data?.map((option: Option) => (
                <Menu.Item
                    style={{color: "black", padding: "8px 16px 8px 15%", fontSize: "1rem"}}
                    key={option.code}
                    name={option.code}
                    active={
                        activeItem?.id === option.id && activeItem?.type === DeductibleCapitalSelectedTypeEnum.OPTION
                    }
                    onClick={() => setActiveItem({type: DeductibleCapitalSelectedTypeEnum.OPTION, id: option.id})}>
                    {t("Option")} - {option.code} ({option.registry.code})
                </Menu.Item>
            ))}
        </>
    );
};

export default DeductibleCapitalSelectMenu;
