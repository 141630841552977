import { Control, Controller, DeepMap, FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, Grid, GridColumn } from "semantic-ui-react";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";
import { codeValidation } from "../../../../Global/Form/Validation";

type FormDuplicatePricingGridPropsType = {
    control: Control<any>;
    errors: DeepMap<any, FieldErrors>;
    defaultValueCode: string;
    setDefaultValueCode: React.Dispatch<React.SetStateAction<string>>;
    defaultValueLabel: string;
    setDefaultValueLabel: React.Dispatch<React.SetStateAction<string>>;
};

const FormDuplicatePricingGrid = ({
    control,
    errors,
    defaultValueCode,
    setDefaultValueCode,
    defaultValueLabel,
    setDefaultValueLabel,
}: FormDuplicatePricingGridPropsType) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <GridColumn>
                        <Controller
                            control={control}
                            rules={{required: true, maxLength: 30, pattern: codeValidation()}}
                            defaultValue={defaultValueCode}
                            name={"duplicated_pricing_grid_code"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueCode(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueCode}
                                    maxLength="30"
                                    error={getErrorMessage(t, errors, "duplicated_pricing_grid_code", undefined, 30)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true, maxLength: 100}}
                            defaultValue={defaultValueLabel}
                            name={"duplicated_pricing_grid_label"}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(e, {value}) => {
                                        setDefaultValueLabel(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={defaultValueLabel}
                                    maxLength="100"
                                    error={getErrorMessage(t, errors, "duplicated_pricing_grid_label", undefined, 100)}
                                />
                            )}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormDuplicatePricingGrid;
