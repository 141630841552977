import axios from "axios";
import {buildCustomError} from "../../../Services/RequestUtils";
import axiosInstance from "../Coverlife.axios.instance";
import {CommercialReduction} from "./Types";

export const getCommercialReductions = async (): Promise<CommercialReduction[]> => {
    try {
        const response = await axiosInstance.get<CommercialReduction[]>("/commercial_reductions");
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const exportCommercialReductions = async (): Promise<unknown> => {
    try {
        const response = await axiosInstance.get("/commercial_reductions/_export", {
            responseType: "arraybuffer",
        });
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const importCommercialReductions = async (formData: FormData): Promise<void> => {
    try {
        return await axiosInstance.post("/commercial_reductions/_import", formData, {
            headers: {"Content-Type": "multipart/form-data"},
        });
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
