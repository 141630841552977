import {Dispatch, SetStateAction} from "react";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid, GridColumn} from "semantic-ui-react";
import getErrorMessage from "../../../Global/Form/ErrorMessage";
import {codeValidation, useDateValidation} from "../../../Global/Form/Validation";

type FormInsurancePackageProps = {
    control: Control<FormInsurancePackageFormTypes>;
    errors: FieldErrors<FormInsurancePackageFormTypes>;
    modifiedInsurancePackage: FormInsurancePackageFormTypes;
    setModifiedInsurancePackage: Dispatch<SetStateAction<FormInsurancePackageFormTypes>>;
};

export type FormInsurancePackageFormTypes = {
    code: string;
    label: string;
    startDate: string;
    endDate: string | null;
    endDateEndorsement: string | null;
};

const FormInsurancePackage = ({
    control,
    errors,
    modifiedInsurancePackage,
    setModifiedInsurancePackage,
}: FormInsurancePackageProps) => {
    const {t} = useTranslation();

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row centered>
                    <GridColumn>
                        <Controller
                            name="code"
                            defaultValue={modifiedInsurancePackage.code}
                            rules={{required: true, maxLength: 10, pattern: codeValidation()}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    autoFocus
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Code")}
                                    placeholder={t("Code")}
                                    onChange={(_, {value}) => {
                                        setModifiedInsurancePackage({...modifiedInsurancePackage, code: value});
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={10}
                                    error={getErrorMessage(t, errors, name, undefined, 10)}
                                />
                            )}
                        />

                        <Controller
                            name="label"
                            defaultValue={modifiedInsurancePackage.label}
                            rules={{required: true, maxLength: 50}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"text"}
                                    name={name}
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    onChange={(_, {value}) => {
                                        setModifiedInsurancePackage({...modifiedInsurancePackage, label: value});
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    maxLength={50}
                                    error={getErrorMessage(t, errors, name, undefined, 50)}
                                />
                            )}
                        />

                        <Controller
                            name="startDate"
                            defaultValue={modifiedInsurancePackage.startDate.split("T")[0]}
                            rules={{required: true, validate: {date: useDateValidation}}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    required
                                    type={"date"}
                                    name={name}
                                    label={t("Commercialization date")}
                                    onChange={(_, {value}) => {
                                        setModifiedInsurancePackage({...modifiedInsurancePackage, startDate: value});
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            name="endDate"
                            defaultValue={
                                modifiedInsurancePackage.endDate ? modifiedInsurancePackage.endDate.split("T")[0] : ""
                            }
                            rules={{validate: {date: useDateValidation}}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    type={"date"}
                                    name={name}
                                    label={t("Subscription commercialization end date")}
                                    onChange={(_, {value}) => {
                                        setModifiedInsurancePackage({...modifiedInsurancePackage, endDate: value});
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />

                        <Controller
                            name="endDateEndorsement"
                            defaultValue={
                                modifiedInsurancePackage.endDateEndorsement
                                    ? modifiedInsurancePackage.endDateEndorsement.split("T")[0]
                                    : ""
                            }
                            rules={{validate: {date: useDateValidation}}}
                            control={control}
                            render={({field: {name, value, onChange, onBlur}}) => (
                                <Form.Input
                                    fluid
                                    type={"date"}
                                    name={name}
                                    label={t("Endorsement commercialization end date")}
                                    onChange={(_, {value}) => {
                                        setModifiedInsurancePackage({
                                            ...modifiedInsurancePackage,
                                            endDateEndorsement: value,
                                        });
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={value}
                                    error={getErrorMessage(t, errors, name)}
                                />
                            )}
                        />
                    </GridColumn>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default FormInsurancePackage;
