import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {Link} from "react-router-dom";
import {Button, Header, Icon, Modal, Progress} from "semantic-ui-react";
import axiosInstance from "../../Services/ProductEngine.instance";
import coverlifeAxiosInstance from "../Services/Coverlife.axios.instance";
import {HealthProductFormContext} from "./HealthProductFormContext";
import {FormValidationType, SPECIFIC_INFORMATION_STEP_NAME} from "./InputsByStepHealthProductForm";

const HealthProductSubmissionModal: React.FC = () => {
    const {t} = useTranslation();
    const nbStep = 8;
    const [open, setOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [error, setError] = useState(false);
    const [created, setCreated] = useState(false);
    const [productId, setProductId] = useState(false);
    const {state, validate} = useContext(HealthProductFormContext);
    const queryClient = useQueryClient();

    const productHealthCreationRecursiveRequest = (
        endpoint: string,
        stepCount: number,
        productId: number | undefined = undefined
    ) => {
        if (stepCount <= nbStep) {
            coverlifeAxiosInstance
                .post(endpoint, state)
                .then((response) => {
                    setCurrentStep(stepCount);
                    const uri = response.headers["location"];
                    const uriTab = uri.split("/");
                    productHealthCreationRecursiveRequest(
                        "/".concat(uriTab.slice(uriTab.length - 6).join("/")),
                        stepCount + 1,
                        response.data
                    );

                    if (stepCount === 8) {
                        setCreated(true);
                        setProductId(response.data);
                        queryClient.invalidateQueries({queryKey: ["productsByRiskType"]});
                    }
                })
                .catch(() => {
                    setError(true);
                    if (productId !== undefined && stepCount > 1) {
                        axiosInstance.delete(`/products/${productId}`);
                    }
                });
        }
    };

    const handleProductSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (validate(SPECIFIC_INFORMATION_STEP_NAME, state.questions, FormValidationType.IN_STEP_VALIDATION)) {
            setOpen(true);
            productHealthCreationRecursiveRequest("/products/health", 1);
        }
    };

    const getProgressMessage = () => {
        if (error) {
            return t("An error has occurred please contact support");
        } else if (currentStep === nbStep && created) {
            return t("Product created successfully");
        } else {
            return t("Product creation in progress");
        }
    };

    return (
        <>
            <Button positive onClick={(event) => handleProductSubmit(event)}>
                {t("Create new product")}
            </Button>
            <Modal basic open={open} size="small" closeOnDimmerClick={false}>
                <Header icon>
                    <Icon name="box" />
                    {t("Creation of the new product")}
                </Header>
                <Modal.Content>
                    <Progress
                        color={error ? "red" : "grey"}
                        success={created}
                        error={error}
                        inverted
                        active
                        progress="percent"
                        value={currentStep}
                        total={nbStep}
                    >
                        {getProgressMessage()}
                    </Progress>
                </Modal.Content>
                <Modal.Actions>
                    {currentStep === nbStep && (
                        <Button basic inverted as={Link} to={`/`}>
                            <Icon name="arrow left" /> {t("Back to the homepage")}
                        </Button>
                    )}
                    {!error && created && productId !== undefined && (
                        <Button color="green" inverted as={Link} to={`/products/${productId}`}>
                            {t("Go to product")} <Icon name="arrow right" />
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default HealthProductSubmissionModal;
