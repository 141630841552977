import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router";
import {Accordion, Divider, Grid, Message, Tab} from "semantic-ui-react";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import {useAddRejectionRuleMutation, useGetRejectionRulesMutation} from "../../../../../Services/RejectionRule/Queries";
import {useGetVersionQuery} from "../../../../../Services/Version/Queries";
import FormAddRejectionRule from "./FormAddRejectionRule";
import RejectionRulesAccordion from "./RejectionRulesAccordion";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type RejectionRulePropsType = {
    versionId: string;
};

const RejectionRules = ({versionId}: RejectionRulePropsType) => {
    const {t} = useTranslation();
    const [activeConditionIndex, setActiveConditionIndex] = useState<number>(0);

    const addRejectionRuleForm = useForm<any>();

    const params = useParams() as IParams;
    const getVersion = useGetVersionQuery(params.id, params.versionId);
    const getRejectionRules = useGetRejectionRulesMutation(versionId);
    const addRejectionRuleMutation = useAddRejectionRuleMutation(versionId);

    const onAddConditionValidation = (form: any) => {
        const condition = {
            ifCondition: "( ( 1 == 1 ) )",
            label: defaultValueLabel,
            errorMessage: defaultValueErrorMessage,
        };
        addRejectionRuleMutation.mutate(condition);
    };

    const [defaultValueLabel, setDefaultValueLabel] = useState<string>("");
    const [defaultValueErrorMessage, setDefaultValueErrorMessage] = useState<string>("");

    return (
        <Tab.Pane>
            <Message info>
                <Message.Header>{t("Pricing rejection rules")}</Message.Header>
                <Message.Content>
                    {t(
                        "If one of theses rejection's exclusion conditions is evaluated like true, then the pricing will be rejected with his affected error message"
                    )}
                </Message.Content>
            </Message>
            <Divider />
            {getRejectionRules.status === "success" &&
            getRejectionRules.data !== undefined &&
            getRejectionRules.data.length === 0 ? (
                <Message warning style={{marginBottom: 0}}>
                    <Message.Header>{t("No rejection rules")}</Message.Header>
                    <Message.Content>{t("There is no rejection rules on this version")}</Message.Content>
                </Message>
            ) : (
                getRejectionRules.status === "success" &&
                getRejectionRules.data !== undefined &&
                getVersion.status === "success" &&
                getVersion.data !== undefined && (
                    <Accordion styled fluid>
                        {getRejectionRules.data.map((condition, index) => (
                            <RejectionRulesAccordion
                                key={index}
                                index={index}
                                condition={condition}
                                activeIndex={activeConditionIndex}
                                setActiveIndex={setActiveConditionIndex}
                                version={getVersion.data}
                            />
                        ))}
                    </Accordion>
                )
            )}
            {getVersion.status === "success" && getVersion.data && getVersion.data.pipDate === null && (
                <Grid style={{marginTop: 0}}>
                    <Grid.Row>
                        <Grid.Column textAlign="center">
                            <ModalAdd
                                isValidationDisabled={Object.keys(addRejectionRuleForm.formState.errors).length !== 0}
                                isPending={addRejectionRuleMutation.isPending}
                                isSuccess={addRejectionRuleMutation.isSuccess}
                                isError={addRejectionRuleMutation.isError}
                                resetMutation={addRejectionRuleMutation.reset}
                                error={addRejectionRuleMutation.error}
                                onValidate={addRejectionRuleForm.handleSubmit(onAddConditionValidation)}
                                onSuccess={() => {
                                    setDefaultValueLabel("");
                                    setDefaultValueErrorMessage("");
                                }}
                                objectToAdd="Condition"
                                objectToAddContext="female"
                                openModalButtonName="add-rejection-condition"
                                renderModalContent={() => (
                                    <FormAddRejectionRule
                                        control={addRejectionRuleForm.control}
                                        errors={addRejectionRuleForm.formState.errors}
                                        defaultValueLabel={defaultValueLabel}
                                        setDefaultValueLabel={setDefaultValueLabel}
                                        defaultValueErrorMessage={defaultValueErrorMessage}
                                        setDefaultValueErrorMessage={setDefaultValueErrorMessage}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Tab.Pane>
    );
};

export default RejectionRules;
