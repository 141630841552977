import React from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button, Grid} from "semantic-ui-react";
import {useGetSelfCompanyQuery} from "../../../Services/Company/Queries";
import {useDeleteVersionMutation, useUpdateVersionMutation} from "../../../Services/Version/Queries";
import {VersionDTO} from "../../../Services/Version/Types";
import FormVersion from "../../Forms/FormVersions";
import ModalDelete from "../../Modals/ModalDelete";
import ModalUpdate from "../../Modals/ModalUpdate";
import ModalUpdateForm from "../../Modals/ModalUpdateForm";

type VersionActionsProspType = {
    version: VersionDTO;
    versionList: VersionDTO[];
};

const VersionActions = ({version, versionList}: VersionActionsProspType) => {
    const {t} = useTranslation();

    const companyQueryResult = useGetSelfCompanyQuery();
    const versionUpdateMutation = useUpdateVersionMutation(String(version.productId), String(version.id));

    // Update Label & Effective Date
    const [defaultValueVersionLabel, setDefaultValueVersionLabel] = React.useState<string>(version.label);
    const [defaultValueEffectiveDate, setDefaultValueEffectiveDate] = React.useState<string>(version.effectiveDate);
    const resetUpdateVersionForm = () => {
        setDefaultValueVersionLabel(version.label);
        setDefaultValueEffectiveDate(version.effectiveDate);
    };

    const updateVersionForm = useForm<{label: string; effectiveDate: string}>({mode: "onBlur", shouldUnregister: true});
    const handleVersionUpdate = (form: {label: string; effectiveDate: string}) => {
        versionUpdateMutation.mutate({...form, pipDate: null});
    };

    const handleVersionValidation = () => {
        const date = new Date();
        let currentDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
        versionUpdateMutation.mutate({
            label: version.label,
            effectiveDate: version.effectiveDate,
            pipDate: currentDateTime,
        });
    };

    const handleVersionInvalidation = () => {
        if (!isProductionEnv()) {
            versionUpdateMutation.mutate({
                label: version.label,
                effectiveDate: version.effectiveDate,
                pipDate: null,
            });
        }
    };

    const versionDeleteMutation = useDeleteVersionMutation(String(version.productId), String(version.id));

    const noWorkingVersion = () => versionList.find((version) => version.pipDate === null) === undefined;

    const isProductionEnv = () => {
        return (
            companyQueryResult.data === undefined ||
            companyQueryResult.data.environmentName === "" ||
            companyQueryResult.data.environmentName.toLowerCase() === "production"
        );
    };

    return (
        <>
            <Grid.Column textAlign={"right"} className={"breadcrumb-actions-0"}>
                <div className={"breadcrumb-actions-button-list"}>
                    {version.pipDate === null && (
                        <>
                            <ModalUpdateForm
                                isValidationDisabled={Object.keys(updateVersionForm.formState.errors).length !== 0}
                                isPending={versionUpdateMutation.isPending}
                                isSuccess={versionUpdateMutation.isSuccess}
                                isError={versionUpdateMutation.isError}
                                resetMutation={versionUpdateMutation.reset}
                                error={versionUpdateMutation.error}
                                onValidate={updateVersionForm.handleSubmit(handleVersionUpdate)}
                                onCancel={resetUpdateVersionForm}
                                onClose={resetUpdateVersionForm}
                                openButtonIcon="edit"
                                objectToUpdate={t("Version")}
                                openModalButtonColor="blue"
                                validateButtonColor="green"
                                objectToUpdateContext="female"
                                renderModalContent={() => {
                                    return (
                                        <FormVersion
                                            errors={updateVersionForm.formState.errors}
                                            control={updateVersionForm.control}
                                            defaultValueVersionLabel={defaultValueVersionLabel}
                                            setDefaultValueVersionLabel={setDefaultValueVersionLabel}
                                            defaultValueEffectiveDate={defaultValueEffectiveDate}
                                            setDefaultValueEffectiveDate={setDefaultValueEffectiveDate}
                                        />
                                    );
                                }}
                            />

                            <ModalUpdate
                                isPending={versionUpdateMutation.isPending}
                                isSuccess={versionUpdateMutation.isSuccess}
                                isError={versionUpdateMutation.isError}
                                resetMutation={versionUpdateMutation.reset}
                                error={versionUpdateMutation.error}
                                onValidate={handleVersionValidation}
                                objectToUpdate={t("Version")}
                                objectType="female"
                                customContent={t("You are about to validate version")}
                                openModalButtonText={t("Validate", {context: "female", name: t("version")})}
                                openModalButtonName="validate-version"
                            />
                        </>
                    )}

                    <Button
                        name="test-version"
                        color={"teal"}
                        as={Link}
                        to={`/products/${version.productId}/versions/${version.id}/mass_tests`}>
                        {t("Test the version")}
                    </Button>

                    {version.pipDate === null && versionList.length > 1 && (
                        <ModalDelete
                            openModalButtonName="delete-version"
                            isPending={versionDeleteMutation.isPending}
                            isSuccess={versionDeleteMutation.isSuccess}
                            isError={versionDeleteMutation.isError}
                            resetMutation={versionDeleteMutation.reset}
                            onValidate={() => versionDeleteMutation.mutate()}
                            objectToDelete={"Version"}
                            objectType={"female"}
                        />
                    )}

                    {companyQueryResult.data !== undefined &&
                        noWorkingVersion() &&
                        !isProductionEnv() && (
                            <ModalUpdate
                                isPending={versionUpdateMutation.isPending}
                                isSuccess={versionUpdateMutation.isSuccess}
                                isError={versionUpdateMutation.isError}
                                resetMutation={versionUpdateMutation.reset}
                                error={versionUpdateMutation.error}
                                onValidate={handleVersionInvalidation}
                                objectToUpdate={t("Version")}
                                objectType="female"
                                customContent={t("You are about to invalidate version")}
                                openModalButtonText={t("Invalidate", {context: "female", name: t("version")})}
                                openModalButtonName="invalidate-version"
                            />
                        )}
                </div>
            </Grid.Column>
        </>
    );
};

export default VersionActions;
