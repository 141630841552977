import {Icon, List, Placeholder} from "semantic-ui-react";
import {useGetPricingGridDimensionsQuery} from "../../Services/PricingGrid/Queries";
import {DimensionGridDTO, DimensionGridValueDTO, DimensionType} from "../../Services/PricingGrid/Types";
import "./SearchDimensionGridValueModal.css";

type SearchDimensionGridValueModalPropsType = {
    gridId: string;
    gridCode: string;
    gridLabel: string;
    search: string;
    onClickOnEntity: (gridCode: string, dimensionCode: string) => void;
};

export const SearchDimensionGridValueModal = ({
    gridId,
    gridCode,
    gridLabel,
    onClickOnEntity,
    search,
}: SearchDimensionGridValueModalPropsType) => {
    const {data: dimensions, status} = useGetPricingGridDimensionsQuery(gridId, true);

    const shouldShowDimensionsAfterSearch = (gridCode: string, search: string): boolean => {
        return (
            search !== "" &&
            !gridCode.toLowerCase().includes(search.toLowerCase()) &&
            !gridLabel.toLowerCase().includes(search.toLowerCase())
        );
    };

    const displayValue = (accumulator: string, currentValue: DimensionGridValueDTO): string => {
        if (currentValue.value !== null) {
            return accumulator + ", " + currentValue.value;
        } else if (currentValue.min !== null) {
            return accumulator + ", " + currentValue.min + " - " + currentValue.max;
        } else if (currentValue.minDate !== null) {
            return accumulator + ", " + currentValue.minDate + " - " + currentValue.maxDate;
        } else {
            return "";
        }
    }

    return (
        <List
            className="dimensionList"
            verticalAlign="middle"
            style={{display: shouldShowDimensionsAfterSearch(gridCode, search) ? "none" : "block"}}
        >
            {dimensions?.map((dimension: DimensionGridDTO) => (
                <List.Item
                    disabled={dimension.dimensionType === DimensionType.T}
                    key={"card_grid_" + dimension.code}
                    onClick={() => onClickOnEntity(gridCode, dimension.code)}
                >
                    <List.Content>
                        <List.Header>
                            {dimension.code} - {dimension.label}
                        </List.Header>
                        <div className="values">
                            <Icon name="arrow right" />
                            <span style={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>
                                {dimension.dimensionGridValueDTOs
                                    .reduce((accumulator, currentValue) => displayValue(accumulator, currentValue), "")
                                    .slice(2)}
                            </span>
                        </div>
                    </List.Content>
                </List.Item>
            ))}
            {status === "pending" && (
                <>
                    <List.Item className="dimensionsPending">
                        <List.Content>
                            <Placeholder fluid>
                                <Placeholder.Header>
                                    <Placeholder.Line length="medium" />
                                    <Placeholder.Line length="full" />
                                </Placeholder.Header>
                            </Placeholder>
                        </List.Content>
                    </List.Item>
                    <List.Item className="dimensionsPending">
                        <List.Content>
                            <Placeholder fluid>
                                <Placeholder.Header>
                                    <Placeholder.Line length="medium" />
                                    <Placeholder.Line length="full" />
                                </Placeholder.Header>
                            </Placeholder>
                        </List.Content>
                    </List.Item>
                    <List.Item className="dimensionsPending">
                        <List.Content>
                            <Placeholder fluid>
                                <Placeholder.Header>
                                    <Placeholder.Line length="medium" />
                                    <Placeholder.Line length="full" />
                                </Placeholder.Header>
                            </Placeholder>
                        </List.Content>
                    </List.Item>
                </>
            )}
        </List>
    );
};
