import axios, { AxiosError } from "axios";
import { ProductRegistryTaxJoin } from "../ProductBehaviour/Types";
import axiosInstance from "../ProductEngine.instance";
import { buildCustomError } from "../RequestUtils";
import {
    CoverageDTO,
    CoverageAddDTO,
    CoverageGroup,
    CoverageRuleDTO,
    CoverageUpdateDTO,
    DerogatoryTaxDTO,
    FeeCharge,
    FeeChargeAddDTO,
    FeeChargeDTO,
    InsurancePackageExclusion,
    InsurancePackageItem,
    Option,
    OptionAddDTO,
    OptionUpdateDTO,
    Coverage,
    InsurancePackageExclusionDTO
} from "./Types";

/* --------- FORMULES --------- */
export const getInsurancePackages = async (versionId: string): Promise<Array<InsurancePackageItem>> => {
    try {
        const response = await axiosInstance.get<Array<InsurancePackageItem>>(
            `/versions/${versionId}/insurance_packages`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getInsurancePackage = async (
    versionId: string,
    insurancePackageId: string
): Promise<InsurancePackageItem> => {
    try {
        const response = await axiosInstance.get<InsurancePackageItem>(
            `/versions/${versionId}/insurance_packages/${insurancePackageId}`
        );
    
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateInsurancePackage = async (
    versionId: string,
    insurancePackage: InsurancePackageItem
): Promise<InsurancePackageItem> => {
    try {
        const response = await axiosInstance.put<InsurancePackageItem>(
            `/versions/${versionId}/insurance_packages/${insurancePackage.id}`,
            insurancePackage
        );

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteInsurancePackage = async (versionId: string, insurancePackageId: string): Promise<void> => {
    try {
        const response = await axiosInstance.delete<void>(`/versions/${versionId}/insurance_packages/${insurancePackageId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addInsurancePackage = async (
    versionId: string,
    insurancePackage: InsurancePackageItem
): Promise<InsurancePackageItem> => {
    try {
        const response = await axiosInstance.post<InsurancePackageItem>(
            `/versions/${versionId}/insurance_packages`,
            insurancePackage
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicateInsurancePackage = async (
    versionId: string,
    insurancePackageId: string,
    code: string,
    label: string
): Promise<InsurancePackageItem> => {
    try {
        const response = await axiosInstance.post<InsurancePackageItem>(
            `/versions/${versionId}/insurance_packages/${insurancePackageId}/_duplicate?code=${code}&label=${label}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* --------- GROUPE DE GARANTIES --------- */
export const getCoverageGroups = async (insurancePackageId: string): Promise<Array<CoverageGroup>> => {
    try {
        const response = await axiosInstance.get<Array<CoverageGroup>>(
            `/insurance_packages/${insurancePackageId}/coverage_groups`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCoverageGroup = async (insurancePackageId: string, coverageGroupId: string): Promise<CoverageGroup> => {
    try {
        const response = await axiosInstance.get<CoverageGroup>(
            `/insurance_packages/${insurancePackageId}/coverage_groups/${coverageGroupId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addCoverageGroups = async (
    insurancePackageId: string,
    coverageGroup: CoverageGroup
): Promise<CoverageGroup> => {
    try {
        const response = await axiosInstance.post<CoverageGroup>(
            `/insurance_packages/${insurancePackageId}/coverage_groups`,
            coverageGroup
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateCoverageGroups = async (
    insurancePackageId: string,
    coverageGroupId: string,
    coverageGroup: CoverageGroup
): Promise<CoverageGroup> => {
    try {
        const response = await axiosInstance.put<CoverageGroup>(
            `/insurance_packages/${insurancePackageId}/coverage_groups/${coverageGroupId}`,
            coverageGroup
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteCoverageGroups = async (insurancePackageId: string, coverageGroupId: string) => {
    try {
        const response = await axiosInstance.delete(
            `/insurance_packages/${insurancePackageId}/coverage_groups/${coverageGroupId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* --------- GARANTIES --------- */
export const getCoverages = async (coverageGroupId: string): Promise<Array<CoverageDTO>> => {
    try {
        const response = await axiosInstance.get<Array<CoverageDTO>>(`/coverage_groups/${coverageGroupId}/coverages`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getCoverage = async (coverageGroupId: string, coverageId: string): Promise<CoverageRuleDTO> => {
    try {
        const response = await axiosInstance.get<CoverageRuleDTO>(
            `/coverage_groups/${coverageGroupId}/coverages/${coverageId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateCoverage = async (
    coverageGroupId: string,
    coverageId: string,
    coverageUpdateDTO: CoverageUpdateDTO
): Promise<Coverage> => {
    try {
        const response = await axiosInstance.put<Coverage>(
            `coverage_groups/${coverageGroupId}/coverages/${coverageId}`,
            coverageUpdateDTO
        );

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteCoverage = async (coverageGroupId: string, coverageId: string) => {
    try {
        await axiosInstance.delete(`coverage_groups/${coverageGroupId}/coverages/${coverageId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addCoverage = async (coverageGroupId: string, coverageAddDTO: CoverageAddDTO): Promise<CoverageDTO> => {
    try {
        const response = await axiosInstance.post<CoverageDTO>(`coverage_groups/${coverageGroupId}/coverages`, coverageAddDTO);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* --------- FRAIS --------- */
export const getFeeCharges = async (insurancePackageId: string): Promise<Array<FeeCharge>> => {
    try {
        const response = await axiosInstance.get<Array<FeeCharge>>(
            `/insurance_packages/${insurancePackageId}/fee_charges`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getFeeCharge = async (insurancePackageId: string, feeChargeId: string): Promise<FeeChargeDTO> => {
    try {
        const response = await axiosInstance.get<FeeChargeDTO>(
            `/insurance_packages/${insurancePackageId}/fee_charges/${feeChargeId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteFeeCharge = async (insurancePackageId: string, feeChargeId: string) => {
    try {
        await axiosInstance.delete(`insurance_packages/${insurancePackageId}/fee_charges/${feeChargeId}`);
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateFeeCharge = async (
    insurancePackageId: string,
    feeChargeId: string,
    feeChargeUpdateDTO: FeeChargeDTO
): Promise<FeeCharge> => {
    try {
        const response = await axiosInstance.put<FeeCharge>(
            `insurance_packages/${insurancePackageId}/fee_charges/${feeChargeId}`,
            feeChargeUpdateDTO
        );

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addFeeCharge = async (insurancePackageId: string, feeChargeAddDTO: FeeChargeAddDTO): Promise<FeeCharge> => {
    try {
        const response = await axiosInstance.post<FeeCharge>(
            `insurance_packages/${insurancePackageId}/fee_charges`,
            feeChargeAddDTO
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* --------- OPTIONS --------- */
export const getOption = async (coverageGroupId: string, optionId: string) => {
    try {
        const response = await axiosInstance.get<Option>(`coverage_groups/${coverageGroupId}/options/${optionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getOptionsByGroup = async (coverageGroupId: string) => {
    try {
        const response = await axiosInstance.get<Array<Option>>(`coverage_groups/${coverageGroupId}/options`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addOption = async (coverageGroupId: string, optionAddDTO: OptionAddDTO) => {
    try {
        const response = await axiosInstance.post(`coverage_groups/${coverageGroupId}/options`, optionAddDTO);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteOption = async (coverageGroupId: string, optionId: string) => {
    try {
        const response = await axiosInstance.delete(`coverage_groups/${coverageGroupId}/options/${optionId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateOption = async (
    coverageGroupId: string,
    optionId: string,
    optionUpdateDTO: OptionUpdateDTO
): Promise<Option> => {
    try {
        const response = await axiosInstance.put<Option>(
            `coverage_groups/${coverageGroupId}/options/${optionId}`,
            optionUpdateDTO
        );

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicateOption = async (coverageGroupId: string, optionId: string, code: string, label: string) => {
    try {
        const response = await axiosInstance.post(
            `coverage_groups/${coverageGroupId}/options/${optionId}/_duplicate?code=${code}&label=${label}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

/* --------- Conditions --------- */

export const addInsurancePackageCondition = async (
    insurancePackageId: string,
    insurancePackageCondition: InsurancePackageExclusionDTO
): Promise<InsurancePackageExclusion> => {
    try {
        const response = await axiosInstance.post<InsurancePackageExclusion>(
            `/insurance_packages/${insurancePackageId}/exclusions`,
            insurancePackageCondition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};
/* --------- DEROGATORIES TAXES --------- */
export const getDerogatoriesTaxes = async (
    insurancePackageId: string,
    registryCode: string
): Promise<Array<ProductRegistryTaxJoin>> => {
    try {
        const response = await axiosInstance.get<Array<ProductRegistryTaxJoin>>(
            `/insurance_packages/${insurancePackageId}/derogatories_taxes/${registryCode}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getInsurancePackageConditions = async (
    insurancePackageId: string
): Promise<Array<InsurancePackageExclusion>> => {
    try {
        const response = await axiosInstance.get<Array<InsurancePackageExclusion>>(
            `/insurance_packages/${insurancePackageId}/exclusions`
        );

        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateInsurancePackageCondition = async (
    insurancePackageId: string,
    insurancePackageExclusionConditionId: number,
    insurancePackageExclusionCondition: InsurancePackageExclusion
): Promise<InsurancePackageExclusion> => {
    try {
        const response = await axiosInstance.put<InsurancePackageExclusion>(
            `/insurance_packages/${insurancePackageId}/exclusions/${insurancePackageExclusionConditionId}`,
            insurancePackageExclusionCondition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteInsurancePackageCondition = async (
    insurancePackageId: string,
    insurancePackageExclusionConditionId: number
) => {
    try {
        const response = await axiosInstance.delete(
            `/insurance_packages/${insurancePackageId}/exclusions/${insurancePackageExclusionConditionId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addCoverageTax = async (
    insurancePackageId: string,
    derogatoryTax: DerogatoryTaxDTO
): Promise<DerogatoryTaxDTO> => {
    try {
        const response = await axiosInstance.post<DerogatoryTaxDTO>(
            `/insurance_packages/${insurancePackageId}/derogatories_taxes`,
            derogatoryTax
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateDerogatoryTax = async (
    insurancePackageId: string,
    productRegistryTaxJoinId: string,
    derogatoryTax: DerogatoryTaxDTO
): Promise<DerogatoryTaxDTO> => {
    try {
        const response = await axiosInstance.put<DerogatoryTaxDTO>(
            `/insurance_packages/${insurancePackageId}/derogatories_taxes/${productRegistryTaxJoinId}`,
            derogatoryTax
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deleteDerogatoryTax = async (
    insurancePackageId: string,
    productRegistryTaxJoinId: string
): Promise<void> => {
    try {
        const response = await axiosInstance.delete(
            `/insurance_packages/${insurancePackageId}/derogatories_taxes/${productRegistryTaxJoinId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const reorderDerogatoryTaxConditionMutation = async (insurancePackageId: string, productRegistryTaxJoinId: string, order: number): Promise<void> => {
    try {
        const response = await axiosInstance.patch(`/insurance_packages/${insurancePackageId}/derogatories_taxes/${productRegistryTaxJoinId}/_reorder?order=${order}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}

export const reorderInsurancePackageExclusionConditionMutation = async (insurancePackageId: string, insurancePackageExclusionId: string, order: number): Promise<void> => {
    try {
        const response = await axiosInstance.patch(`/insurance_packages/${insurancePackageId}/exclusions/${insurancePackageExclusionId}/_reorder?order=${order}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}
