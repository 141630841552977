import { useTranslation } from "react-i18next";
import { Params, useParams } from "react-router-dom";
import { Label, LabelGroup, Message, Segment } from "semantic-ui-react";
import { ComputingRuleCondition, RulePart } from "../../../../Services/ComputingRule/Types";
import { ComputingRuleRoundingMode } from "../../../../Services/InsurancePackage/Types";

interface IParams extends Params {
    coverageId: string;
    optionId: string;
    feeChargeId: string;
}
type ConditionViewPropsType = {
    computingRuleCondition: ComputingRuleCondition;
};

const ComputingRuleAssignmentView = ({computingRuleCondition}: ConditionViewPropsType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;

    return (
        <>
            {computingRuleCondition.rulePartList.length === 0 ? (
                <Message
                    warning
                    header={t("No computing rule")}
                    content={
                        params.coverageId !== undefined
                            ? t("Without computing rule, coverage will not be added to insurance package")
                            : params.optionId !== undefined
                            ? t("Without computing rule, option will not be added to insurance package")
                            : params.feeChargeId !== undefined
                            ? t("Without computing rule, fee will not be added to insurance package")
                            : t("Without computing rule, coverage will not be added to insurance package")
                    }></Message>
            ) : (
                <>
                    <Label size="large" basic style={{border: "none"}} horizontal>
                        {t("Computing rule") + " : "}
                    </Label>
                    <Segment secondary className="assignment">
                        {computingRuleCondition.rulePartList.map((rulePart: RulePart, index) => (
                            <Label
                                key={index}
                                size="large"
                                basic
                                style={{
                                    border: "none",
                                    margin: 0,
                                    padding: "0.1em",
                                    minWidth: "2em",
                                    background: "none #fff0",
                                }}
                                horizontal
                                title={t("enums.ConditionKindEnum." + rulePart.kind)}>
                                {t(rulePart.value.trim())}
                            </Label>
                        ))}
                    </Segment>
                    <LabelGroup>
                        <Label size="large" basic style={{border: "none"}} horizontal>
                            {t("Computing rule rounding mode") + " : "}
                        </Label>
                        <Label size="large" className="roundingMode" horizontal>
                            {t(
                                "enums.ComputingRuleRoundingMode." +
                                    ComputingRuleRoundingMode[computingRuleCondition.roundingMode]
                            )}
                        </Label>
                        <Label size="large" basic style={{border: "none"}} horizontal>
                            {t("Number of decimal") + " : "}
                        </Label>
                        <Label size="large" className="decimalCount" horizontal>
                            {computingRuleCondition.decimalCount}
                        </Label>
                    </LabelGroup>
                </>
            )}
        </>
    );
};

export default ComputingRuleAssignmentView;
