import {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Button, Divider, Grid, Header, Radio} from "semantic-ui-react";
import ModalDelete from "../../../../Components/Modals/ModalDelete";
import {useDeleteSplittingMutation} from "../../../../Services/Splitting/Queries";
import {Splitting, SplittingType} from "../../../../Services/Splitting/Types";

type SplittingDetailsReadProps = {
    productId: string;
    splitting: Splitting;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

const SplittingDetailsRead = ({productId, splitting, setEdit}: SplittingDetailsReadProps) => {
    const {t} = useTranslation();

    const deleteSplittingMutation = useDeleteSplittingMutation(productId, String(splitting.id));
    const handleDelete = () => deleteSplittingMutation.mutate();

    return (
        <Fragment>
            <Grid columns="equal" verticalAlign="middle" className="grid-padding" style={{padding: "10px"}}>
                <Grid.Row style={{paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Splitting type")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{t(`enums.SplittingType.${splitting.splittingType}`)}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Code")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{splitting.code}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Label")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{splitting.label}</Grid.Column>
                </Grid.Row>

                {splitting.splittingType === SplittingType.X && (
                    <Fragment>
                        <Grid.Row style={{paddingTop: "1rem", paddingBottom: "0"}}>
                            <Grid.Column>
                                <Header>{t("Duration")}</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Unity")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>{t(`enums.SplittingUnit.${splitting.unit}`)}</Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "0", paddingBottom: "1rem"}}>
                            <Grid.Column width={8}>
                                <strong>{t("Duration")} : </strong>
                            </Grid.Column>
                            <Grid.Column width={8}>{splitting.duration}</Grid.Column>
                        </Grid.Row>
                    </Fragment>
                )}

                <Grid.Row style={{paddingTop: "0", paddingBottom: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Coefficient increase")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>{splitting.increaseCoef}</Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop: "0"}}>
                    <Grid.Column width={8}>
                        <strong>{t("Default splitting")} : </strong>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Radio aria-label={"defaultSplitting"} toggle readOnly checked={splitting.defaultSplitting} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div className={"bottom-button-card"} style={{bottom: 0}}>
                <Divider />

                <Button primary name="edit-splitting" onClick={() => setEdit(true)}>
                    {t("edit")}
                </Button>

                <ModalDelete
                    openModalButtonName="deleteSplitting"
                    isSuccess={deleteSplittingMutation.isSuccess}
                    isPending={deleteSplittingMutation.isPending}
                    isError={deleteSplittingMutation.isError}
                    error={deleteSplittingMutation.error}
                    resetMutation={deleteSplittingMutation.reset}
                    onValidate={handleDelete}
                    objectToDelete="Splitting"
                    objectType="male"
                />
            </div>
        </Fragment>
    );
};

export default SplittingDetailsRead;
