import { useTranslation } from "react-i18next";
import { Dimmer, Loader, Message, Segment, Tab, Table } from "semantic-ui-react";
import { useGetPricingGridViewQuery } from "../../../../../Services/PricingGrid/Queries";
import {
    CellTypeEnum,
    PricingGridCellType,
    PricingGridLineType,
} from "../../../../../Services/PricingGrid/Types";
import { CustomError } from "../../../../../Services/RequestUtils";

type PricingGridViewType = {
    versionId: string;
    gridId: string;
}

const PricingGridView = ({versionId, gridId}: PricingGridViewType) => {
    const {t} = useTranslation();

    const {
        data: viewData,
        status: viewStatus,
        error: viewError,
        isFetching,
    } = useGetPricingGridViewQuery(versionId, gridId);

    const error = viewError as CustomError | null;
    const errorMessage = error?.message;

    const setCSSstyle = (cellType: CellTypeEnum) => {
        switch (cellType) {
            case CellTypeEnum.DIM:
                return {backgroundColor: "#EDEDED", fontWeight: "bold"};
            case CellTypeEnum.VAL:
                return {backgroundColor: "white"};
            case CellTypeEnum.DVAL:
                return {backgroundColor: "#F3F4F5"};
            case CellTypeEnum.VIDE:
                return {background: "white", border: "none"};
        }
    };

    return (
        <Tab.Pane style={{overflow: !isFetching ? "auto" : "none"}}>
            {viewStatus === "error" && error !== null && errorMessage !== undefined && error.status === 400 && (
                <Message info header={t("No grid")} content={t(errorMessage)} />
            )}

            {!isFetching && viewStatus === "success" && viewData !== undefined && (
                <Table compact celled>
                    <Table.Body>
                        {viewData.lines.slice(1).map((row: PricingGridLineType, rowIndex: number) => (
                            <Table.Row textAlign={"center"} key={`row-${rowIndex}`} data-cy={`row-${rowIndex}`}>
                                {row.cells.map((cell: PricingGridCellType, columnIndex: number) => (
                                    <Table.Cell
                                        key={`cell-${rowIndex}-${columnIndex}`}
                                        data-cy={`cell-${rowIndex}-${columnIndex}`}
                                        colSpan={cell.colspan}
                                        style={setCSSstyle(cell.type)}>
                                        {cell.value}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}  
            {isFetching && error === null &&(
                <div style={{height: "100%"}}>
                    <Segment style={{height: "400px"}}>
                        <Dimmer active inverted>
                            <Loader size="massive" inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                </div>
            )}
        </Tab.Pane>
    );
};

export default PricingGridView;
