import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Grid, GridRow, Input, Label, Menu, Tab} from "semantic-ui-react";
import {v4 as uuidv4} from "uuid";
import FormRestApiAccess from "../../../../Components/Forms/FormRestApiAccess";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import SimpleList from "../../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../../Components/SimpleList/SimpleListTypes";
import {useAddApplicationAccessMutation, useGetApplicationAccessesQuery} from "../../../../Services/Company/Queries";
import {ApplicationAccessAddUpdateDTO, Role, UserGroupDTO} from "../../../../Services/Company/Types";

const applicationAccessesTableColumns = [
    {
        text: "Partenaire",
        dataField: "username",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Rôle",
        dataField: "role",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Groupe",
        dataField: "groupName",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Authentification",
        dataField: "authentifactionMode",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "",
        dataField: "/transversal_settings/rest_api_access/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

type PropsType = {
    companyId: string;
};

const RestApiAccessRightsList = ({companyId}: PropsType) => {
    const {t} = useTranslation();

    const addApplicationAccessMutation = useAddApplicationAccessMutation(companyId);
    const addApplicationAccessForm = useForm();

    const [search, setSearch] = useState("");

    const RestApiAccessFilter = (searchValue: string) => {
        if (applicationAccessesQueryResult.data !== undefined && applicationAccessesQueryResult.data.length > 0) {
            return applicationAccessesQueryResult.data
                .filter(
                    (applicationAccess) =>
                        applicationAccess.username.toLowerCase().includes(searchValue.toLowerCase()) ||
                        applicationAccess.role.toLowerCase().includes(searchValue.toLowerCase()) ||
                        applicationAccess.groupName.toLowerCase().includes(searchValue.toLowerCase()) ||
                        applicationAccess.identifier?.toLowerCase().includes(searchValue.toLowerCase())
                )
                .map((applicationAccess) => {
                    return {
                        ...applicationAccess,
                        authentifactionMode: applicationAccess.identifier ? "oAuth2" : "Bearer token",
                    };
                });
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleApplicationAccessSubmit = (formData: any) => {
        const newApplicationAccess: ApplicationAccessAddUpdateDTO = {
            username: formData.username,
            identifier: formData.identifier,
            token: formData.token,
            role: Role.APPORTEUR,
            groupId: formData.selectedItem.id,
        };

        addApplicationAccessMutation.mutate(newApplicationAccess);
    };

    const [defaultValueUserName, setDefaultValueUserName] = useState<string>("");
    const [defaultValueAuthentificationMode, setDefaultValueAuthentificationMode] = useState<string>("");
    const [defaultValueIdentifier, setDefaultValueIdentifier] = useState<string>(defaultValueUserName);
    const [defaultValueToken, setDefaultValueToken] = useState<string>(uuidv4());
    const [defaultValueRole, setDefaultValueRole] = useState<string | null>(null);
    const [defaultValueSelectedItem, setDefaultValueSelectedItem] = useState<UserGroupDTO | null>(null);

    const applicationAccessesQueryResult = useGetApplicationAccessesQuery(companyId, true);

    return (
        <>
            <Tab.Pane>
                <Grid divided>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            {applicationAccessesQueryResult && applicationAccessesQueryResult?.status === "success" && (
                                <>
                                    <Menu borderless secondary style={{border: "0", boxShadow: "none", padding: 0}}>
                                        <Menu.Menu position="right">
                                            <Menu.Item>
                                                <Input
                                                    icon="search"
                                                    name="search"
                                                    placeholder={t("Find entity", {
                                                        context: "male",
                                                        entity: t("Partner").toLowerCase(),
                                                    })}
                                                    defaultValue={search}
                                                    onChange={(_, data) => setSearch(data.value)}
                                                />
                                            </Menu.Item>
                                            <Menu.Item>
                                                <ModalAdd
                                                    isValidationDisabled={
                                                        Object.keys(addApplicationAccessForm.formState.errors)
                                                            .length !== 0
                                                    }
                                                    isPending={addApplicationAccessMutation.isPending}
                                                    isSuccess={addApplicationAccessMutation.isSuccess}
                                                    isError={addApplicationAccessMutation.isError}
                                                    error={addApplicationAccessMutation.error}
                                                    resetMutation={addApplicationAccessMutation.reset}
                                                    onValidate={addApplicationAccessForm.handleSubmit(
                                                        handleApplicationAccessSubmit
                                                    )}
                                                    onSuccess={() => {
                                                        setDefaultValueUserName("");
                                                        setDefaultValueAuthentificationMode("");
                                                        setDefaultValueIdentifier("");
                                                        setDefaultValueToken(uuidv4());
                                                        setDefaultValueRole(null);
                                                        setDefaultValueSelectedItem(null);
                                                    }}
                                                    onCancel={() => {
                                                        setDefaultValueUserName("");
                                                        setDefaultValueAuthentificationMode("");
                                                        setDefaultValueIdentifier("");
                                                        setDefaultValueToken(uuidv4());
                                                        setDefaultValueRole(null);
                                                        setDefaultValueSelectedItem(null);
                                                    }}
                                                    objectToAdd={"Partner"}
                                                    iconOnOpenButton
                                                    openButtonIcon="plus"
                                                    renderModalContent={() => (
                                                        <FormRestApiAccess
                                                            control={addApplicationAccessForm.control}
                                                            errors={addApplicationAccessForm.formState.errors}
                                                            defaultValueUserName={defaultValueUserName}
                                                            setDefaultValueUserName={setDefaultValueUserName}
                                                            defaultValueAuthentificationMode={
                                                                defaultValueAuthentificationMode
                                                            }
                                                            setDefaultValueAuthentificationMode={
                                                                setDefaultValueAuthentificationMode
                                                            }
                                                            defaultValueIdentifier={defaultValueIdentifier}
                                                            setDefaultValueIdentifier={setDefaultValueIdentifier}
                                                            defaultValueToken={defaultValueToken}
                                                            setDefaultValueToken={setDefaultValueToken}
                                                            defaultValueRole={defaultValueRole}
                                                            setDefaultValueRole={setDefaultValueRole}
                                                            defaultValueSelectedItem={defaultValueSelectedItem}
                                                            setDefaultValueSelectedItem={setDefaultValueSelectedItem}
                                                        />
                                                    )}
                                                    objectToAddContext={"male"}
                                                />
                                            </Menu.Item>
                                        </Menu.Menu>
                                    </Menu>

                                    <SimpleList
                                        columns={applicationAccessesTableColumns}
                                        rows={RestApiAccessFilter(search) || []}
                                        globalData={[]}
                                    />
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <GridRow>
                        <Label attached="bottom right" basic style={{border: "none", marginRight: "20px"}} size="large">
                            {(RestApiAccessFilter(search)?.length || 0) + " " + t("Partners").toLowerCase()}
                        </Label>
                    </GridRow>
                </Grid>
            </Tab.Pane>
        </>
    );
};

export default RestApiAccessRightsList;
