import {useContext} from "react";
import {Params, useParams} from "react-router-dom";
import {Grid, Header} from "semantic-ui-react";
import {AuthContext} from "../../../../Components/AuthProvider/AuthProvider";
import Sections from "../../../../Components/CustomBreadcrumb/Fragments/Sections";
import TextOverflow from "../../../../Components/Text/TextOverflow";
import {QuestionDTO} from "../../../../Services/Question/Types";
import {displayAuditLog} from "../../../../Utils/AuditUtils";
import {useTranslation} from "react-i18next";

type BreadcrumbQuestionType = {
    question: QuestionDTO;
    sections: Array<{name: string; path: string}>;
};
interface IParams extends Params {
    versionId: string;
}

const BreadcrumbQuestion = ({question, sections}: BreadcrumbQuestionType) => {
    const {t} = useTranslation();
    const params = useParams() as IParams;
    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="header-container" data-cy="custom-breadcrumb-header">
                <Header as="h2" className="top-header">
                    <TextOverflow value={question.name} />
                </Header>
                <div className="sub-header">
                    <Grid>
                        <Grid.Column width={6} floated={"left"}>
                            <Sections sections={sections} />
                            {question !== undefined && user !== undefined && (
                                <div className="audit-display">
                                    <i>
                                        {displayAuditLog(
                                            {
                                                entityContext: "female",
                                                createdDate: question.createdDate,
                                                modifiedDate: question.modifiedDate,
                                                createdBy: question.createdBy,
                                                modifiedBy: question.modifiedBy,
                                                createdOnVersionId: question.createdOnVersionId,
                                            },
                                            user,
                                            params.versionId,
                                            t
                                        )}
                                    </i>
                                </div>
                            )}
                        </Grid.Column>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default BreadcrumbQuestion;
