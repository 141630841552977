import {Dispatch, SetStateAction} from "react";
import {Control, Controller, FieldErrors} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Form, Grid} from "semantic-ui-react";
import {RegistryEntryDTO, RegistryEntryType} from "../../../../Services/Registry/Types";
import getErrorMessage from "../../../Global/Form/ErrorMessage";

type FormRegistryProps = {
    control: Control<RegistryEntryDTO>;
    errors: FieldErrors<RegistryEntryDTO>;
    defaultValueForm: RegistryEntryDTO;
    setDefaultValueForm: Dispatch<SetStateAction<RegistryEntryDTO>>;
};

export const FormRegistry = ({control, errors, defaultValueForm, setDefaultValueForm}: FormRegistryProps) => {
    const {t} = useTranslation();

    const typeOptions = Object.values(RegistryEntryType).map((registryEntryType: RegistryEntryType, index: number) => {
        return {
            key: index,
            text: t(`enums.RegistryEntryType.${registryEntryType}`),
            value: registryEntryType,
        };
    });

    const handleChangeDefaultValue = <T extends keyof RegistryEntryDTO>(
        propertyChangedName: T,
        value: RegistryEntryDTO[T],
        onChange: (value: RegistryEntryDTO[keyof RegistryEntryDTO]) => void
    ) => {
        setDefaultValueForm({
            ...defaultValueForm,
            [propertyChangedName]: value,
        });
        onChange(value);
    };

    return (
        <Form id="modal-form">
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Controller
                            name="code"
                            defaultValue={defaultValueForm.code ?? ""}
                            rules={{required: true, maxLength: 15}}
                            control={control}
                            render={({field}) => (
                                <Form.Input
                                    {...field}
                                    autoFocus
                                    fluid
                                    required
                                    type="text"
                                    placeholder={t("Code")}
                                    label={t("Code")}
                                    maxLength={15}
                                    onChange={(_, {value}) =>
                                        handleChangeDefaultValue(field.name, value, field.onChange)
                                    }
                                    error={getErrorMessage(t, errors, field.name, undefined, 15)}
                                />
                            )}
                        />

                        <Controller
                            name="label"
                            defaultValue={defaultValueForm.label ?? ""}
                            rules={{required: true, maxLength: 90}}
                            control={control}
                            render={({field}) => (
                                <Form.Input
                                    {...field}
                                    required
                                    fluid
                                    type="text"
                                    label={t("Label")}
                                    placeholder={t("Label")}
                                    maxLength={90}
                                    onChange={(_, {value}) =>
                                        handleChangeDefaultValue(field.name, value, field.onChange)
                                    }
                                    error={getErrorMessage(t, errors, field.name, undefined, 90)}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            rules={{required: true}}
                            defaultValue={defaultValueForm.type ?? ""}
                            name={"type"}
                            render={({field}) => (
                                <Form.Select
                                    {...field}
                                    label={t("Choose an entry type")}
                                    required
                                    placeholder={t("Type")}
                                    onChange={(_, {value}) =>
                                        handleChangeDefaultValue(field.name, value as RegistryEntryType, field.onChange)
                                    }
                                    error={getErrorMessage(t, errors, field.name)}
                                    options={typeOptions}
                                />
                            )}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};
