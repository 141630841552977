export type ColumnSimpleList = {
    dataField: string;
    text: string;
    type: DataTypeColumnEnum;
};

export enum DataTypeColumnEnum {
    STRING = "STRING",
    DATE = "DATE",
    DATETIME = "DATETIME",
    LINK = "LINK",
    BOOLEAN = "BOOLEAN",
    ENUM = "ENUM",
    LIST = "LIST",
}
