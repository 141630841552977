import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Divider, Header, Segment} from "semantic-ui-react";
import FormVersion from "../../../../../Components/Forms/FormVersions";
import ModalAdd from "../../../../../Components/Modals/ModalAdd";
import SimpleList from "../../../../../Components/SimpleList/SimpleList";
import {DataTypeColumnEnum} from "../../../../../Components/SimpleList/SimpleListTypes";
import {useAddVersionMutation} from "../../../../../Services/Version/Queries";
import {VersionAddDTO, VersionDTO} from "../../../../../Services/Version/Types";

type ValidatedVersionsSegmentProps = {
    productId: string;
    validatedVersions: VersionDTO[];
    haveWorkingVersion: () => boolean;
};

const versionTableColumns = [
    {
        text: "Effective date",
        dataField: "effectiveDate",
        type: DataTypeColumnEnum.DATE,
    },
    {
        text: "Label",
        dataField: "label",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "",
        dataField: "/products/:productId/versions/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const ValidatedVersionsSegment: React.FC<ValidatedVersionsSegmentProps> = ({
    productId,
    validatedVersions,
    haveWorkingVersion,
}) => {
    const {t} = useTranslation();

    const addVersionMutation = useAddVersionMutation(productId);
    const addVersionForm = useForm<VersionAddDTO>();
    const isValidationDisabled = Object.keys(addVersionForm.formState.errors).length !== 0;
    const submitAddVersionForm = (formData: VersionAddDTO) => addVersionMutation.mutate(formData);

    const [defaultValueVersionLabel, setDefaultValueVersionLabel] = useState<string | undefined>();
    const [defaultValueEffectiveDate, setDefaultValueEffectiveDate] = useState<string | undefined>();

    const resetForm = () => {
        addVersionForm.reset();
        setDefaultValueVersionLabel(undefined);
        setDefaultValueEffectiveDate(undefined);
    };

    return (
        <Segment className="card-container">
            <Header as="h2" dividing>
                {t("Valid versions list")}
            </Header>
            <SimpleList
                tableName="validatedVersions"
                columns={versionTableColumns}
                globalData={[]}
                rows={validatedVersions}
            />
            {!haveWorkingVersion() && (
                <div className="bottom-button-card">
                    <Divider />
                    <ModalAdd
                        isValidationDisabled={isValidationDisabled}
                        isPending={addVersionMutation.isPending}
                        isSuccess={addVersionMutation.isSuccess}
                        isError={addVersionMutation.isError}
                        resetMutation={addVersionMutation.reset}
                        error={addVersionMutation.error}
                        onValidate={addVersionForm.handleSubmit(submitAddVersionForm)}
                        onSuccess={resetForm}
                        onCancel={resetForm}
                        objectToAdd="version"
                        objectToAddContext="female"
                        openModalButtonName="add-version"
                        renderModalContent={() => (
                            <FormVersion
                                errors={addVersionForm.formState.errors}
                                control={addVersionForm.control}
                                defaultValueVersionLabel={defaultValueVersionLabel}
                                setDefaultValueVersionLabel={setDefaultValueVersionLabel}
                                defaultValueEffectiveDate={defaultValueEffectiveDate}
                                setDefaultValueEffectiveDate={setDefaultValueEffectiveDate}
                            />
                        )}
                    />
                </div>
            )}
        </Segment>
    );
};

export default ValidatedVersionsSegment;
