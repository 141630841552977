import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, Params, useParams} from "react-router-dom";
import {Card, Grid, Input, Menu, Message, Segment, Tab} from "semantic-ui-react";
import {AuthContext} from "../../../../../Components/AuthProvider/AuthProvider";
import CardListPlaceholder from "../../../../../Components/Placeholder/CardListPlaceholder";
import Sort from "../../../../../Components/Sort/Sort";
import TextOverflow from "../../../../../Components/Text/TextOverflow";
import useContainerDimensions from "../../../../../Hooks/useContainerDimensions";
import {useGetInsurancePackagesQuery} from "../../../../../Services/InsurancePackage/Queries";
import {InsurancePackageItem} from "../../../../../Services/InsurancePackage/Types";
import {VersionDTO} from "../../../../../Services/Version/Types";
import {displayAuditLog} from "../../../../../Utils/AuditUtils";
import {sortOptionsCode, sortOptionsId, sortOptionsModificationDate} from "../../../../../Utils/SortUtils";
import ModalAddInsurancePackage from "../../../InsurancePackage/Fragments/ModalAddInsurancePackage";

interface IParams extends Params {
    id: string;
    versionId: string;
}

type PropsType = {
    version: VersionDTO | undefined;
};

const InsurancePackageList = ({version}: PropsType) => {
    const {t} = useTranslation();
    const userContext = useContext(AuthContext);
    const {id: productId, versionId} = useParams() as IParams;

    const getInsurancePackagesQuery = useGetInsurancePackagesQuery(versionId);
    const [sortMethod, setSortMethod] = useState<(a: any, b: any) => number>(() => (a: any, b: any) =>
        a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1
    );
    const sortOptions = [...sortOptionsCode, ...sortOptionsId, ...sortOptionsModificationDate];

    const [search, setSearch] = useState("");
    const hiddenBySearch = (code: string, label: string) => {
        return (
            code.toLowerCase().includes(search.toLowerCase()) ||
            label.toLowerCase().includes(search.toLowerCase()) ||
            search === ""
        );
    };

    const {columnNumber, columnNumberAsText, ref} = useContainerDimensions();

    return (
        <Tab.Pane style={{overflow: "auto"}}>
            <Grid divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu borderless style={{border: "0", boxShadow: "none", padding: 0}}>
                            <Menu.Menu position="right">
                                <Menu.Item>
                                    <Sort sortOptions={sortOptions} setSortMethod={setSortMethod} />
                                </Menu.Item>
                                <Menu.Item>
                                    <Input
                                        name="searchInsurancePackage"
                                        icon="search"
                                        placeholder={t("Find entity", {
                                            context: "female",
                                            entity: t("Insurance package").toLowerCase(),
                                        })}
                                        defaultValue={search}
                                        onChange={(event, data) => setSearch(data.value)}
                                    />
                                </Menu.Item>
                                {version !== undefined && version.pipDate === null && (
                                    <Menu.Item>
                                        <ModalAddInsurancePackage />
                                    </Menu.Item>
                                )}
                            </Menu.Menu>
                        </Menu>

                        {getInsurancePackagesQuery.isSuccess &&
                            getInsurancePackagesQuery.data !== undefined &&
                            getInsurancePackagesQuery.data.length === 0 && (
                                <Segment style={{border: "0", boxShadow: "none"}}>
                                    <Message
                                        warning
                                        header={t("No insurance package")}
                                        content={t("There is no insurance package for this version")}
                                    />
                                </Segment>
                            )}

                        <div className={"ui cards " + columnNumberAsText} ref={ref}>
                            {getInsurancePackagesQuery.isSuccess &&
                                getInsurancePackagesQuery.data !== undefined &&
                                getInsurancePackagesQuery.data.length !== 0 &&
                                getInsurancePackagesQuery.data
                                    .slice()
                                    .filter((insurancePackage: InsurancePackageItem) =>
                                        hiddenBySearch(insurancePackage.code, insurancePackage.label)
                                    )
                                    .sort(sortMethod)
                                    .map((insurancePackage: InsurancePackageItem, index: number) => (
                                        <Card
                                            key={`insurancePackage-${index}`}
                                            as={Link}
                                            to={`/products/${productId}/versions/${versionId}/insurance-packages/${insurancePackage.id}`}>
                                            <Card.Content>
                                                <Card.Header>
                                                    <TextOverflow value={insurancePackage.label} />
                                                </Card.Header>
                                                <Card.Meta>
                                                    <strong>
                                                        <TextOverflow value={insurancePackage.code} />
                                                    </strong>
                                                </Card.Meta>
                                                <Card.Description>
                                                    <div>
                                                        <strong>{t("Start")} : </strong>
                                                        {t("format_date", {value: insurancePackage.startDate})}
                                                    </div>
                                                    <div>
                                                        <strong>{t("End")} : </strong>
                                                        {insurancePackage.endDate === null
                                                            ? "-"
                                                            : t("format_date", {value: insurancePackage.endDate})}
                                                    </div>
                                                    <div>
                                                        <strong>{t("End endorsement")} : </strong>
                                                        {insurancePackage.endDateEndorsement === null
                                                            ? "-"
                                                            : t("format_date", {
                                                                  value: insurancePackage.endDateEndorsement,
                                                              })}
                                                    </div>
                                                </Card.Description>
                                            </Card.Content>

                                            {userContext.user !== undefined && (
                                                <Card.Content extra textAlign="right" style={{fontStyle: "italic"}}>
                                                    <span>
                                                        {displayAuditLog(
                                                            {
                                                                entityContext: "female",
                                                                createdDate: insurancePackage.createdDate,
                                                                modifiedDate: insurancePackage.modifiedDate,
                                                                createdBy: insurancePackage.createdBy,
                                                                modifiedBy: insurancePackage.modifiedBy,
                                                                createdOnVersionId: insurancePackage.createdOnVersionId,
                                                            },
                                                            userContext.user,
                                                            versionId,
                                                            t
                                                        )}
                                                    </span>
                                                </Card.Content>
                                            )}
                                        </Card>
                                    ))}
                        </div>

                        {getInsurancePackagesQuery.isLoading && <CardListPlaceholder />}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Tab.Pane>
    );
};

export default InsurancePackageList;
