import { useTranslation } from "react-i18next";
import { Icon, Popup } from "semantic-ui-react"

export const ConditionWarningEditForbidden = () => {
    const {t} = useTranslation();

    return (
        <Popup
            trigger={<Icon disabled size='big' color='orange' name='warning sign' />}
            content={t("You can not open this condition because she is too complex to be edited from the console")}
            position='top right'
        />
    )
}