import {useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Params, useParams} from "react-router-dom";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import {useAddPricingCriteriaMutation} from "../../../../Services/PricingCriterias/Queries";
import {PricingCriteriaItemType} from "../../../../Services/PricingCriterias/Types";
import FormPricingCriterias from "./FormPricingCriterias";

interface IParams extends Params {
    id: string;
    versionId: string;
    pricingCriteriaId: string;
}

type PropsType = {
    alternateOpenButton?: () => JSX.Element;
};

const ModalAddPricingCriteria = ({...props}: PropsType) => {
    const params = useParams() as IParams;
    const {t} = useTranslation();

    const addPricingCriteriaMutation = useAddPricingCriteriaMutation(params.id, params.versionId);
    const addPricingCriteriaForm = useForm<any>();
    const submitAddPricingCriteria = (form: any) => {
        const conditionOrder = 0;
        let fixedValue;
        switch (form.type) {
            case "STRING":
                fixedValue = "default";
                break;
            case "NUMERIC":
                fixedValue = "0";
                break;
            case "DATE":
                fixedValue = new Date().toISOString().split("T")[0];
        }

        const pricingCriteria = {
            ...form,
            pricingCriteriaConditions: [
                {
                    conditionOrder,
                    fixedValue,
                },
            ],
        };

        addPricingCriteriaMutation.mutate(pricingCriteria);
    };

    const [defaultValueName, setDefaultValueName] = useState<string>("");
    const [defaultValueType, setDefaultValueType] = useState<PricingCriteriaItemType | undefined>(undefined);
    const [defaultValueSaveQuote, setDefaultValueSaveQuote] = useState<boolean>(false);

    return (
        <ModalAdd
            isValidationDisabled={Object.keys(addPricingCriteriaForm.formState.errors).length !== 0}
            isPending={addPricingCriteriaMutation.isPending}
            isSuccess={addPricingCriteriaMutation.isSuccess}
            isError={addPricingCriteriaMutation.isError}
            resetMutation={addPricingCriteriaMutation.reset}
            error={addPricingCriteriaMutation.error}
            onValidate={addPricingCriteriaForm.handleSubmit(submitAddPricingCriteria)}
            onCancel={() => {
                setDefaultValueName("");
                setDefaultValueType(undefined);
                setDefaultValueSaveQuote(false);
            }}
            onSuccess={() => {
                setDefaultValueName("");
                setDefaultValueType(undefined);
                setDefaultValueSaveQuote(false);
            }}
            iconOnOpenButton
            openButtonIcon="add"
            objectToAdd="Pricing criteria"
            objectToAddContext="male"
            renderModalContent={() => (
                <FormPricingCriterias
                    errors={addPricingCriteriaForm.formState.errors}
                    control={addPricingCriteriaForm.control}
                    defaultValueName={defaultValueName}
                    setDefaultValueName={setDefaultValueName}
                    defaultValueType={defaultValueType}
                    setDefaultValueType={setDefaultValueType}
                    defaultValueSaveQuote={defaultValueSaveQuote}
                    setDefaultValueSaveQuote={setDefaultValueSaveQuote}
                />
            )}
            renderOpenButton={props.alternateOpenButton}
        />
    );
};

export default ModalAddPricingCriteria;
