import axios, {AxiosError} from "axios";
import axiosInstance from "../ProductEngine.instance";
import {buildCustomError} from "../RequestUtils";
import {
    NewPricingCriteriaConditionDTO,
    NewPricingCriteriaDTO,
    PricingCriteriaConditionDTO,
    PricingCriteriaItem,
    PricingCriterias,
    UpdateAssignmentPricingCriteriaConditionDTO,
} from "./Types";

export const getPricingCriterias = async (versionId: string): Promise<Array<PricingCriterias>> => {
    try {
        const response = await axiosInstance.get<Array<PricingCriterias>>(`/versions/${versionId}/pricing_criterias`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addPricingCriteria = async (
    versionId: string,
    pricingCriteria: NewPricingCriteriaDTO
): Promise<PricingCriteriaItem> => {
    try {
        const response = await axiosInstance.post<PricingCriteriaItem>(
            `/versions/${versionId}/pricing_criterias`,
            pricingCriteria
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updateGlobalInfosPricingCriteria = async (
    versionId: string,
    pricingCriteriaId: string,
    pricingCriteriaItem: PricingCriteriaItem
): Promise<PricingCriteriaItem> => {
    try {
        const response = await axiosInstance.patch<PricingCriteriaItem>(
            `/versions/${versionId}/pricing_criterias/${pricingCriteriaId}`,
            pricingCriteriaItem
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updatePricingCriteria = async (
    versionId: string,
    pricingCriteriaId: string,
    pricingCriteriaItem: PricingCriteriaItem
): Promise<PricingCriteriaItem> => {
    try {
        const response = await axiosInstance.put<PricingCriteriaItem>(
            `/versions/${versionId}/pricing_criterias/${pricingCriteriaId}`,
            pricingCriteriaItem
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const getPricingCriteria = async (
    versionId: string,
    pricingCriteriaId: string
): Promise<PricingCriteriaItem> => {
    try {
        const response = await axiosInstance.get<PricingCriteriaItem>(
            `/versions/${versionId}/pricing_criterias/${pricingCriteriaId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deletePricingCriteria = async (versionId: string, pricingCriteriaId: number): Promise<void> => {
    try {
        const response = await axiosInstance.delete(`/versions/${versionId}/pricing_criterias/${pricingCriteriaId}`);
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const updatePricingCriteriaCondition = async (
    pricingCriteriaId: string,
    conditionId: number,
    condition: UpdateAssignmentPricingCriteriaConditionDTO
) => {
    try {
        const response = await axiosInstance.put(
            `/pricing_criterias/${pricingCriteriaId}/conditions/${conditionId}`,
            condition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const deletePricingCriteriaCondition = async (pricingCriteriaId: string, conditionId: number) => {
    try {
        const response = await axiosInstance.delete(
            `/pricing_criterias/${pricingCriteriaId}/conditions/${conditionId}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const addPricingCriteriaCondition = async (
    pricingCriteriaId: string,
    pricingCriteriaCondition: NewPricingCriteriaConditionDTO
): Promise<PricingCriteriaConditionDTO> => {
    try {
        const response = await axiosInstance.post<PricingCriteriaConditionDTO>(
            `/pricing_criterias/${pricingCriteriaId}/conditions`,
            pricingCriteriaCondition
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const duplicatePricingCriteria = async (
    versionId: string,
    pricingCriteriaId: string,
    name: string,
    description: string
): Promise<PricingCriteriaItem> => {
    try {
        const response = await axiosInstance.post<PricingCriteriaItem>(
            `/versions/${versionId}/pricing_criterias/${pricingCriteriaId}/_duplicate?name=${name}&description=${description}`
        );
        return response.data;
    } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
};

export const reorderPricingCriteriaCondition = async (pricingCriteriaId: string, conditionId: string, order: number): Promise<void> => {
    try {
        const response = await axiosInstance.patch(`/pricing_criterias/${pricingCriteriaId}/conditions/${conditionId}/_reorder?order=${order}`);
        return response.data;
    } catch (e: unknown) {
        if (axios.isAxiosError(e)) {
            throw buildCustomError(e);
        } else {
            return Promise.reject(e);
        }
    }
}
