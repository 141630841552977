import React from "react";
import {useForm} from "react-hook-form";
import {Tab} from "semantic-ui-react";
import FormProductRetrocession from "../../../../Components/Forms/FormProductRetrocession";
import ModalAdd from "../../../../Components/Modals/ModalAdd";
import SimpleList from "../../../../Components/SimpleList/SimpleList";
import {ColumnSimpleList, DataTypeColumnEnum} from "../../../../Components/SimpleList/SimpleListTypes";
import {
    useAddProductRetrocessionsMutation,
    useGetProductRetrocessionsQuery,
} from "../../../../Services/ProductRetrocession/Queries";
import {ProductRetrocession, RetrocessionMode} from "../../../../Services/ProductRetrocession/Types";

type RetrocessionTabPaneProps = {
    productId: string;
};

const productRetrocessionTableColumns: Array<ColumnSimpleList> = [
    {
        text: "Business provider rank",
        dataField: "businessProviderRank",
        type: DataTypeColumnEnum.STRING,
    },
    {
        text: "Retrocession mode",
        dataField: "retrocessionMode",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "Base amount",
        dataField: "retrocessionType",
        type: DataTypeColumnEnum.ENUM,
    },
    {
        text: "",
        dataField: "/products/:productId/retrocessions/:id",
        type: DataTypeColumnEnum.LINK,
    },
];

const RetrocessionTabPane: React.FC<RetrocessionTabPaneProps> = ({productId}) => {
    const getRetrocessions = useGetProductRetrocessionsQuery(productId);

    const addProductRetrocessionMutation = useAddProductRetrocessionsMutation(productId);
    const addProductRetrocessionForm = useForm<ProductRetrocession>();
    const submitAddProductRetrocessionForm = (formData: ProductRetrocession) => {
        if (formData.retrocessionMode === RetrocessionMode.PRODUIT) {
            addProductRetrocessionMutation.mutate({
                ...formData,
                rateDerogation: null,
            });
        } else {
            addProductRetrocessionMutation.mutate({
                ...formData,
                retrocessionType: null,
                variableNameCoveragesBase: null,
                variableNameBaseRate: formData.rateDerogation ? formData.variableNameBaseRate : null,
            });
        }
    };

    return (
        <Tab.Pane style={{overflowX: "auto"}}>
            <SimpleList
                tableName="productRetrocession"
                columns={productRetrocessionTableColumns}
                globalData={[{key: "productId", value: productId}]}
                rows={getRetrocessions.isSuccess && getRetrocessions.data ? getRetrocessions.data : []}
                isLoading={getRetrocessions.isLoading}
            />

            <div className="bottom-button-card">
                <ModalAdd
                    isValidationDisabled={Object.keys(addProductRetrocessionForm.formState.errors).length !== 0}
                    isPending={addProductRetrocessionMutation.isPending}
                    isSuccess={addProductRetrocessionMutation.isSuccess}
                    isError={addProductRetrocessionMutation.isError}
                    resetMutation={addProductRetrocessionMutation.reset}
                    error={addProductRetrocessionMutation.error}
                    onValidate={addProductRetrocessionForm.handleSubmit(submitAddProductRetrocessionForm)}
                    onCancel={addProductRetrocessionForm.reset}
                    objectToAdd="Retrocession"
                    objectToAddContext="female"
                    openModalButtonName="add-product-retrocession"
                    renderModalContent={() => <FormProductRetrocession form={addProductRetrocessionForm} />}
                />
            </div>
        </Tab.Pane>
    );
};

export default RetrocessionTabPane;
