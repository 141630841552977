import {useMemo, useState} from "react";
import {Controller, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Container, Form, Grid, Message} from "semantic-ui-react";
import {
    Axe,
    DimensionGridDTO,
    DimensionOrderType,
    DimensionType,
    DimensionValueType,
    PricingGridFormType,
} from "../../../../../Services/PricingGrid/Types";
import getErrorMessage from "../../../../Global/Form/ErrorMessage";

type FormDimensionPropsType = {
    form: UseFormReturn<PricingGridFormType>
    dimension: DimensionGridDTO | undefined | null;
};

export const FormDimension = ({
    form,
    dimension,
}: FormDimensionPropsType) => {
    const {t} = useTranslation();

    const [dimensionTypeChange, setDimensionTypeChange] = useState<boolean>(false);
    const [dimensionValueTypeChange, setDimensionValueTypeChange] = useState<boolean>(false);

    const typeDimensionAxeOptions = [
        {key: 1, text: t("enums.DimensionAxeType." + Axe.L), value: Axe.L},
        {key: 2, text: t("enums.DimensionAxeType." + Axe.C), value: Axe.C},
    ];
    const typeDimensionOrderTypeOptions = [
        {key: 1, text: t("enums.DimensionOrderType." + DimensionOrderType.VALEUR), value: DimensionOrderType.VALEUR},
        {key: 2, text: t("enums.DimensionOrderType." + DimensionOrderType.ORDRE), value: DimensionOrderType.ORDRE},
    ];

    const dimensionType = form.watch("dimensionType");
    const dimensionValueType = form.watch("dimensionValueType");

    const dimensionValueTypeOptions = useMemo(() => {
        if (dimensionType === DimensionType.F) {
            return [
                {
                    key: 1,
                    text: t("enums.DimensionValueType." + DimensionValueType.INT),
                    value: DimensionValueType.INT,
                },
                {
                    key: 2,
                    text: t("enums.DimensionValueType." + DimensionValueType.STRING),
                    value: DimensionValueType.STRING,
                },
                {
                    key: 3,
                    text: t("enums.DimensionValueType." + DimensionValueType.DOUBLE),
                    value: DimensionValueType.DOUBLE,
                },
            ]
        } else if (dimensionType === DimensionType.T) {
            return [
                {
                    key: 1,
                    text: t("enums.DimensionValueType." + DimensionValueType.INT),
                    value: DimensionValueType.INT,
                },
                {
                    key: 2,
                    text: t("enums.DimensionValueType." + DimensionValueType.DOUBLE),
                    value: DimensionValueType.DOUBLE,
                },
                {
                    key: 3,
                    text: t("enums.DimensionValueType." + DimensionValueType.DATE),
                    value: DimensionValueType.DATE,
                },
            ]
        } else {
            return undefined;
        }
    }, [dimensionType]);

    const dimensionTypeOptions = [
        {key: 1, text: t("enums.DimensionType." + DimensionType.F), value: DimensionType.F},
        {key: 2, text: t("enums.DimensionType." + DimensionType.T), value: DimensionType.T},
    ];

    const handleChangeDimensionType = (onchange: (param: string) => void, value: DimensionType | undefined) => {
        if (dimension !== null && dimension !== undefined && value !== dimension.dimensionType) {
            setDimensionTypeChange(true);
        } else {
            setDimensionTypeChange(false);
        }

        if (value === DimensionType.T && dimensionValueType === DimensionValueType.STRING) {
            form.setValue("dimensionValueType", undefined);
        }

        if (value === DimensionType.F && dimensionValueType === DimensionValueType.DATE) {
            form.setValue("dimensionValueType", undefined);
        }

        if (value !== undefined) {
            onchange(value);
        }
    };

    const handleChangeDimensionValueType = (
        onchange: (param: string) => void,
        value: DimensionValueType | undefined
    ) => {
        if (dimension !== null && dimension !== undefined && value !== dimension.dimensionValueType) {
            setDimensionValueTypeChange(true);
        } else {
            setDimensionValueTypeChange(false);
        }

        if (value === DimensionValueType.STRING && dimensionType === DimensionType.T) {
            form.setValue("dimensionType", undefined);
        }

        if (value !== undefined) {
            onchange(value);
        }
    };

    return (
        <Container>
            <Form id="modal-form">
                <Grid centered columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Controller
                                control={form.control}
                                rules={{required: true}}
                                name={"code"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        autoFocus
                                        fluid
                                        type={"text"}
                                        name={name}
                                        label={t("Code")}
                                        placeholder={t("Code")}
                                        onChange={(_e, {value}) => {
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="20"
                                        error={getErrorMessage(t, form.formState.errors, "code")}
                                    />
                                )}
                            />

                            <Controller
                                control={form.control}
                                rules={{required: true}}
                                name={"label"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Input
                                        fluid
                                        type={"text"}
                                        name={name}
                                        label={t("Label")}
                                        placeholder={t("Label")}
                                        onChange={(_e, {value}) => {
                                            onChange(value);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        maxLength="50"
                                        error={getErrorMessage(t, form.formState.errors, "label")}
                                    />
                                )}
                            />

                            <Controller
                                rules={{required: true}}
                                control={form.control}
                                name={"dimensionType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        label={t("Dimension_type")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_type")}
                                        onChange={(_e, {value}) => {
                                            handleChangeDimensionType(onChange, value as DimensionType);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, form.formState.errors, "dimensionType")}
                                        options={dimensionTypeOptions}
                                    />
                                )}
                            />

                            <Controller
                                rules={{required: true}}
                                control={form.control}
                                name={"dimensionValueType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        disabled={dimensionValueTypeOptions === undefined}
                                        label={t("Dimension_value_type")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_value_type")}
                                        onChange={(_e, {value}) => {
                                            handleChangeDimensionValueType(onChange, value as DimensionValueType);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, form.formState.errors, "dimensionValueType")}
                                        options={dimensionValueTypeOptions ?? []}
                                    />
                                )}
                            />

                            <Controller
                                rules={{required: true}}
                                control={form.control}
                                name={"dimensionOrderType"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        label={t("Dimension_order_type")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_order_type")}
                                        value={value}
                                        onChange={(_e, {value}) => {
                                            onChange(value);
                                        }}
                                        error={getErrorMessage(t, form.formState.errors, "dimensionOrderType")}
                                        options={typeDimensionOrderTypeOptions}
                                    />
                                )}
                            />

                            <Controller
                                control={form.control}
                                rules={{required: true}}
                                name={"dimensionAxe"}
                                render={({field: {name, value, onChange, onBlur}}) => (
                                    <Form.Select
                                        fluid
                                        label={t("Dimension_axe")}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder={t("Dimension_axe")}
                                        onChange={(_e, {value}) => {
                                            onChange(value);
                                        }}
                                        value={value}
                                        error={getErrorMessage(t, form.formState.errors, "dimensionAxe")}
                                        options={typeDimensionAxeOptions}
                                    />
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column>
                        {(dimensionTypeChange || dimensionValueTypeChange) && (
                            <Message warning>
                                <Message.Header>{t("Warning")}</Message.Header>
                                <p>
                                    {t(
                                        "Changing the dimension type or value type will delete the values currently associated with that dimension"
                                    )}
                                </p>
                            </Message>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};
